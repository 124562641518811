import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import LocationForm from "./components/LocationForm"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../shared/models/service/ILocation"
import { LOCATION_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<ILocation>(LOCATION_ENDPOINT)
const redirectView = LOCATION_VIEW_URL

/**
 * This page will allow adding of a location.
 *
 * @returns {React.FC} the location add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()

  const props: IUseApiAddProps<ILocation> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const { saving, handleAdd, connectionError } = useApiAdd<ILocation>(props)
  const { t } = useTranslation()

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title={t("Add Location")} loading={saving} errors={form.formState.errors} />
        <FormErrors connectionError={connectionError} />
        <FormBox form={form} onSubmit={handleAdd}>
          <LocationForm form={form} />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default AddPage
