import React, { useCallback, useContext, useEffect } from "react"
import { Chip, Grid, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material"

import { type IUseApiReadProps, useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { CONTACT_ENDPOINT, type IContact } from "../../../../../shared/models/service/IContact"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import { CONTACT_VIEW_URL } from "../../../config/urls"
import { useTranslation } from "react-i18next"
import useEffectAsync from "../../../../../shared/hooks/useEffectAsync"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import { LookupContext } from "../../../../../shared/components/lookup/Lookup"
import TruncateText from "../../../../../shared/components/TruncateText"

const repository = new RestRepository<IContact>(CONTACT_ENDPOINT)

interface IProps extends IInfoViewProps {
  onChange?: (contact: IContact) => void
}

/**
 * Renders a page to view locations impairment details.
 *
 * @param {IProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const ContactLookupInfo: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { infoId, onChange } = props
  const apiReadProps: IUseApiReadProps<IContact> = {
    apiFunction: repository.read,
    itemId: infoId,
    dontCallOnMount: true,
  }
  const apiRead = useApiRead<IContact>(apiReadProps)
  const { data } = apiRead
  const { t } = useTranslation()
  const { setLookupDataSelected } = useContext(LookupContext)

  useEffectAsync(async () => {
    if (infoId !== null && !apiRead.loading) {
      await apiRead.call()
    }
  }, [infoId])

  useEffect(() => {
    if (data !== undefined) {
      onChange?.(data)
    }
  }, [data])

  const handleLookupDateSelected = useCallback(
    (value: string) => () => {
      setLookupDataSelected?.(value)
    },
    [data, setLookupDataSelected],
  )

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle to={`${CONTACT_VIEW_URL}/${infoId}`}>{data.name}</ItemViewerTitle>
            <Typography variant={"subtitle1"}>{data.job_title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
              {t("Contact Information")}
            </Typography>
            <PaperLocal sx={{ p: 0 }}>
              <List>
                <ListItem disablePadding secondaryAction={<Chip size="small" label={t("Full Name")} />}>
                  <ListItemButton onClick={handleLookupDateSelected(data.name)}>
                    <ListItemText primary={<TruncateText>{data.name}</TruncateText>} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding secondaryAction={<Chip size="small" label={t("First Name")} />}>
                  <ListItemButton onClick={handleLookupDateSelected(data.first_name)}>
                    <ListItemText primary={<TruncateText>{data.first_name}</TruncateText>} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding secondaryAction={<Chip size="small" label={t("Last Name")} />}>
                  <ListItemButton onClick={handleLookupDateSelected(data.last_name)}>
                    <ListItemText primary={<TruncateText>{data.last_name}</TruncateText>} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding secondaryAction={<Chip size="small" label={t("Job Title")} />}>
                  <ListItemButton onClick={handleLookupDateSelected(data.job_title)}>
                    <ListItemText primary={<TruncateText>{data.job_title}</TruncateText>} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding secondaryAction={<Chip size="small" label={t("Email")} />}>
                  <ListItemButton onClick={handleLookupDateSelected(data.email)}>
                    <ListItemText primary={<TruncateText>{data.email}</TruncateText>} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding secondaryAction={<Chip size="small" label={t("Phone")} />}>
                  <ListItemButton onClick={handleLookupDateSelected(data.phone)}>
                    <ListItemText primary={<TruncateText>{data.phone}</TruncateText>} />
                  </ListItemButton>
                </ListItem>
              </List>
            </PaperLocal>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ContactLookupInfo
