import React from "react"
import { type MouseEvent, useCallback, useEffect, useMemo, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  List,
  ListItemButton,
  Popover,
} from "@mui/material"
import useContentHeight from "../../hooks/useContentHeight"
import { type IFile, isImage } from "../../models/service/IFile"
import TruncateText from "../TruncateText"
import DialogControls from "../DialogControls"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import FileDownloadButton from "./FileDownloadButton"
import HtmlToolTip from "../HtmlToolTip"
import { Refresh } from "@mui/icons-material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import PreviewIcon from "@mui/icons-material/Preview"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import { useTranslation } from "react-i18next"

interface IProps {
  selectedFile: IFile | null
  files?: IFile[] | null
  withButtonText?: boolean
}

/**
 * Renders a file viewer dialog for displaying storage files.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the file viewer dialog component.
 */
const FileViewerDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { selectedFile, files = null, withButtonText = false } = props
  const height = useContentHeight(-15)

  const [openPreview, setPreviewOpen] = useState(false)

  const [currentFile, setCurrentFile] = useState<IFile | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [refresh, setRefresh] = useState<boolean>(true)

  const { t } = useTranslation()

  const openFileMenu = Boolean(anchorEl)

  const handleOpenFileMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (files !== null && files.length > 1) {
        setAnchorEl(event.currentTarget)
      }
    },
    [files],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSelectFile = useCallback(
    (file: IFile | null) => () => {
      if (file !== null) {
        setCurrentFile(file)
        handleClose()
      }
    },
    [],
  )

  const nextFile = useMemo(() => {
    if (files !== null && files.length > 1) {
      for (let index = 0; index < files.length; index++) {
        if (files[index].id === currentFile?.id) {
          if (index === files.length - 1) {
            return files[0]
          }
          return files[index + 1]
        }
      }
    }
    return null
  }, [currentFile, files])

  const prevFile = useMemo(() => {
    if (files !== null && files.length > 1) {
      for (let index = 0; index < files.length; index++) {
        if (files[index].id === currentFile?.id) {
          if (index === 0) {
            return files[files.length - 1]
          }
          return files[index - 1]
        }
      }
    }
    return null
  }, [currentFile, files])

  const handlePreviewOpen = useCallback(() => {
    setPreviewOpen(true)
  }, [])

  const handlePreviewClose = useCallback(async () => {
    setPreviewOpen(false)
  }, [])

  useEffect(() => {
    setCurrentFile(selectedFile)
  }, [selectedFile])

  useEffect(() => {
    setRefresh(true)
  }, [refresh])

  const currentFileUrl = useMemo(() => {
    if (currentFile?.file_preview !== undefined && isImage(currentFile)) {
      return [{ uri: currentFile.file_preview.read_url }]
    }
    if (currentFile?.file !== undefined) {
      return [{ uri: currentFile.file.read_url }]
    }
    return []
  }, [currentFile])

  return (
    <>
      {withButtonText ? (
        <Button startIcon={<PreviewIcon />} onClick={handlePreviewOpen}>
          {t("Preview")}
        </Button>
      ) : (
        <IconButton color="primary" onClick={handlePreviewOpen} size="small">
          <PreviewIcon />
        </IconButton>
      )}
      <Dialog fullWidth sx={{ zIndex: 1600 }} maxWidth="xl" open={openPreview} onClose={handlePreviewClose}>
        <DialogTitle sx={{ p: 1, pr: 2, pl: 2 }}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <TruncateText num={50} placement="top-start">
                {currentFile !== null ? `${currentFile.name}.${currentFile.ext}` : ""}
              </TruncateText>
            </Grid>
            <Grid item xs>
              {files !== null && files.length > 1 && (
                <>
                  <IconButton onClick={handleOpenFileMenu}>
                    <ArrowDropDownIcon />
                  </IconButton>
                  <Popover
                    open={openFileMenu}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                    <List>
                      {files?.map((file: IFile) => (
                        <ListItemButton
                          key={file.id}
                          selected={currentFile !== null && file.id === currentFile.id}
                          onClick={handleSelectFile(file)}>
                          {file.name}.{file.ext}
                        </ListItemButton>
                      ))}
                    </List>
                  </Popover>
                </>
              )}
            </Grid>

            {files !== null && (
              <>
                {files.length > 1 && (
                  <>
                    <Grid item>
                      <HtmlToolTip title={prevFile !== null ? `${prevFile.name}.${prevFile.ext}` : ""}>
                        <IconButton onClick={handleSelectFile(prevFile)}>
                          <ArrowBackIosNewIcon />
                        </IconButton>
                      </HtmlToolTip>
                    </Grid>
                    <Grid item>
                      <HtmlToolTip title={nextFile !== null ? `${nextFile.name}.${nextFile.ext}` : ""}>
                        <IconButton onClick={handleSelectFile(nextFile)}>
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </HtmlToolTip>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <HtmlToolTip title={t("Refresh file.")}>
                    <IconButton
                      onClick={() => {
                        setRefresh(false)
                      }}>
                      <Refresh />
                    </IconButton>
                  </HtmlToolTip>
                </Grid>
              </>
            )}
            {false && (
              <Grid item>
                <Button
                  component={Link}
                  href={"vnd.libreoffice.command:ofe|u|http://localhost:8000/api/admin/webdav/inspection_file/1"}>
                  {t("Edit")}
                </Button>
              </Grid>
            )}
            <Grid item>{currentFile !== null && <FileDownloadButton file={currentFile} />}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            "& a#pdf-download": { display: "none" },
            "& #header-bar": { display: "none" },
            "& #msdoc-renderer": { height },
            p: 0,
            height,
            overflow: "hidden",
          }}>
          {!openFileMenu && currentFile !== null && refresh && (
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              style={{ height }}
              config={{ header: { disableFileName: true } }}
              documents={currentFileUrl}
            />
          )}
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handlePreviewClose} buttonLabel={t("Close")} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FileViewerDialog
