import { type CSSObject, type Theme } from "@mui/material"

/**
 * Generates a CSS object for styling images within the theme.
 *
 * @param {Theme} theme - The theme object used to apply consistent styling.
 * @returns {CSSObject} A CSS object with styles for maximum image width and automatic height adjustment.
 */
export const markdownSx = {
  "& img": {
    width: "100%",
  },
  "& h2": {
    borderBottom: "1px solid #ccc",
  },
  "& p": {
    marginBottom: "16px",
    fontSize: "1rem", // Use the same typography measurements MUI applies
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontWeight: "bold",
    margin: "24px 0 8px",
  },
  "& a": {
    color: (theme: Theme) => theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}
