import React, { useCallback } from "react"
import { Alert, Button, Grid } from "@mui/material"
import { useFilterDataAvailable } from "./FiltersData"
import AddIcon from "@mui/icons-material/Add"
import { type IFilter } from "../../models/components/IFilter"
import { useTranslation } from "react-i18next"

interface IProps {
  field?: string
  children?: React.JSX.Element[]
}

/**
 * Filters generated reports based on the provided props.
 *
 * @param {IProps} props - The props object containing the field to be filtered.
 * @returns {React.ReactElement} - The filtered account component.
 */
const FilterReports = (props: IProps): React.ReactElement => {
  const { field = "is_report" } = props

  const filtersDataContext = useFilterDataAvailable(field, "Reports")
  const { t } = useTranslation()

  const handleAddFilter = useCallback(() => {
    const filter: IFilter = { title: "Reports", field, value: "true", display: "Reports" }
    filtersDataContext?.addFilter(filter)
  }, [field, filtersDataContext?.addFilter])

  return (
    <>
      {filtersDataContext?.selectedFilter?.value === field && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Alert severity="info">{t("Show only generated report files.")}</Alert>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddFilter}>
              {t("Add Filter")}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default FilterReports
