import { type IInspection } from "./IInspection"
import { type IFile } from "./IFile"
import { type IAccount } from "./IAccount"
import { type IOccupancy } from "./IOccupancy"
import { type IBuilding } from "./IBuilding"
import { type IServiceUser } from "./IServiceUser"
import type { TMetadataData } from "./IMetadataConfig"

export const LOCATION_ENDPOINT = "locations"
export const LOCATION_CITY_ENDPOINT = "location_cities"
export const LOCATION_STATE_REGION_ENDPOINT = "location_state_regions"
export const LOCATION_COUNTRY_ENDPOINT = "location_countries"
export const LOCATION_FILE_EXT_ENDPOINT = "location_file_ext"

export interface ILocation {
  id: number
  identifier: string
  name: string

  accounts: IAccount[]
  primary_staff: IServiceUser | null
  support_staff: IServiceUser[]
  occupancy: IOccupancy
  buildings: IBuilding[]

  address: string
  address_2: string
  city: string
  state_region: string
  postal_code: string
  country: string

  longitude: number
  latitude: number
  zoom: number

  notes: string
  metadata_data: TMetadataData | null

  inspections: IInspection[] | null
  latest_inspection: IInspection | null
  files: IFile[] | undefined | null

  created: string
  updated: string
}
