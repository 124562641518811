import React, { useCallback, useContext } from "react"
import {
  type IMetadataConfig,
  MetadataInputType,
  type TMetadataData,
  type TModelNames
} from "../../models/service/IMetadataConfig"
import { PanariskAppContext } from "../../../app/PanariskApp"
import TableCellData from "../tables/TableCellData"
import { nameToLabel } from "../../utilities/form_utility"
import TableCellCheckMark from "../tables/TableCellCheckMark"
import type { IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import FilterSelectOption from "../filters/FilterSelectOption"
import { SelectOptions } from "../../../config/config"
import TableCellNumber from "../tables/TableCellNumber"
import TableCellCurrency from "../tables/TableCellCurrency"
import TableCellArea from "../tables/TableCellArea"
import TableCellPercent from "../tables/TableCellPercent"
import TableCellCenter from "../tables/TableCellCenter"
import TableCellDate from "../tables/TableCellDate"

type TMetadataTableHeader = () => React.ReactElement[] | undefined

/**
 * Generates metadata-based table headers for a given model name and paging results.
 *
 * @param {TModelNames} modelName - The name of the model for which metadata table headers are being generated.
 * @param {IUseApiPagedResultsResponse<any>} pagingResults - Contains paging results and related data used to populate the table headers.
 * @returns {TMetadataTableHeader} A function that returns an array of React elements representing the table headers or undefined if metadata is not available.
 */
export const useMetadataTableHeader = (
  modelName: TModelNames,
  pagingResults: IUseApiPagedResultsResponse<any> | undefined,
): TMetadataTableHeader => {
  const { appSettings } = useContext(PanariskAppContext)
  const metadata = (appSettings?.serverInfo as Record<string, any>)?.[`${modelName}_metadata_config`] as IMetadataConfig | undefined

  return (): React.ReactElement[] | undefined => {
    return metadata?.fields.map(field => {
      const fieldName = `metadata_data__${field.name}`
      const theLabel = nameToLabel(field.name, field.title)
      return (
        <TableCellCenter key={fieldName} field={fieldName} pagingResults={pagingResults}>
          {theLabel}
        </TableCellCenter>
      )
    })
  }
}

type TMetadataTableRow = (data: TMetadataData | null) => React.ReactElement[] | undefined

/**
 * A helper function that creates a metadata table row renderer using the metadata configuration
 * associated with the given model name and optional units. The renderer is designed to process
 * metadata field types and render corresponding table cell components based on field characteristics.
 *
 * @param {TModelNames} modelName - The name of the model whose metadata configuration is used to generate the table row.
 * @returns {TMetadataTableRow} A function that takes metadata data and returns an array of table cell components
 *                              representing the metadata table row.
 */
export const useMetadataTableRow = (modelName: TModelNames): TMetadataTableRow => {
  const { appSettings } = useContext(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  const metadata = (appSettings?.serverInfo as Record<string, any>)?.[`${modelName}_metadata_config`] as IMetadataConfig | undefined

  return (data: TMetadataData | null) => {
    return metadata?.fields.map(field => {
      const fieldName = `metadata_data__${field.name}`
      if (field.input_type === MetadataInputType.TEXT) {
        return (
          <TableCellData key={field.name} field={fieldName}>
            {data?.[field.name] as string}
          </TableCellData>
        )
      }
      if (field.input_type === MetadataInputType.CHECKBOX) {
        return (
          <TableCellCheckMark key={fieldName} field={fieldName}>
            {data?.[field.name] as boolean}
          </TableCellCheckMark>
        )
      }
      if (field.input_type === MetadataInputType.INTEGER || field.input_type === MetadataInputType.FLOAT) {
        return (
          <TableCellNumber key={fieldName} field={fieldName}>
            {(data?.[field.name] as number) ?? 0}
          </TableCellNumber>
        )
      }
      if (field.input_type === MetadataInputType.CURRENCY) {
        return (
          <TableCellCurrency key={fieldName} field={fieldName}>
            {(data?.[field.name] as number) ?? 0}
          </TableCellCurrency>
        )
      }
      if (field.input_type === MetadataInputType.AREA) {
        return (
          <TableCellArea key={fieldName} field={fieldName} units={units}>
            {(data?.[field.name] as number) ?? 0}
          </TableCellArea>
        )
      }
      if (field.input_type === MetadataInputType.PERCENT) {
        return (
          <TableCellPercent key={fieldName} field={fieldName}>
            {(data?.[field.name] as number) ?? 0}
          </TableCellPercent>
        )
      }
      if (field.input_type === MetadataInputType.DATE) {
        return (
          <TableCellDate key={fieldName} field={fieldName}>
            {data?.[field.name] as string}
          </TableCellDate>
        )
      }
      return (
        <TableCellCenter key={fieldName} field={fieldName}>
          {data?.[field.name] ?? "Not Set"}
        </TableCellCenter>
      )
    })
  }
}

type TMetadataTableFilter = () => React.ReactNode[] | undefined

/**
 * Generates a metadata-based filter for a table using the model name provided.
 *
 * @param {TModelNames} modelName - The name of the model for which the metadata configuration will be retrieved.
 * @returns {TMetadataTableFilter} - A function that returns an array of React elements representing the filter options
 * or undefined.
 */
export const useMetadataTableFilter = (modelName: TModelNames): TMetadataTableFilter => {
  const { appSettings } = useContext(PanariskAppContext)
  const metadata = (appSettings?.serverInfo as Record<string, any>)?.[`${modelName}_metadata_config`] as IMetadataConfig | undefined

  /**
   * Generates filters based on metadata configuration.
   *
   * This function maps over the metadata fields provided in the application settings
   * to dynamically generate filter components. The type of filters generated depends on
   * the input type defined in the field's metadata.
   *
   * For example, if the field's input type is a checkbox, a `FilterSelectOption` component
   * will be returned with the appropriate properties.
   *
   * @returns {React.ReactNode[]} An array of React components representing filters based on metadata fields.
   */
  return useCallback((): React.ReactNode[] | undefined => {
    return metadata?.fields.map(field => {
      const fieldName = `${field.name}`
      const theLabel = nameToLabel(field.name, field.title)
      if (field.input_type === MetadataInputType.CHECKBOX) {
        return (
          <FilterSelectOption
            key={fieldName}
            field={fieldName}
            selectOption={SelectOptions.CHECKED_SELECTED}
            title={theLabel}
          />
        )
      }
      return <></>
    })
  }, [metadata])
}
