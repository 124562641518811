import React, { useCallback } from "react"
import { Alert, Button, Grid } from "@mui/material"
import { useFilterDataAvailable } from "./FiltersData"
import AddIcon from "@mui/icons-material/Add"
import { type IFilter } from "../../models/components/IFilter"
import { useTranslation } from "react-i18next"


const field = "is_driver"
/**
 * Filters if a contact is a driver.
 *
 * @returns {React.ReactElement} - The filtered drivers component.
 */
const FilterIsDriver = (): React.ReactElement => {
  const { t } = useTranslation()
  const filtersDataContext = useFilterDataAvailable(field, t("Is Driver"))

  const handleAddFilter = useCallback(() => {
    const filter: IFilter = { title: "Is Driver", field, value: "true", display: "Yes" }
    filtersDataContext?.addFilter(filter)
  }, [filtersDataContext?.addFilter])

  return (
    <>
      {filtersDataContext?.selectedFilter?.value === field && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Alert severity="info">{t("Show only drivers.")}</Alert>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddFilter}>
              {t("Add Filter")}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default FilterIsDriver
