import React, { Component, type ReactNode } from "react"
import { Box, Grid, Typography } from "@mui/material"

interface IProps {
  children: ReactNode
}

interface IState {
  hasError: boolean
}

/**
 * A React component that acts as an error boundary. Catches JavaScript errors in child components,
 * logs those errors, and displays a fallback UI instead of crashing the application.
 *
 * @param {IProps} The child components to render.
 * @returns {ReactNode} Fallback UI if an error is caught, otherwise renders children.
 */
class ErrorBoundary extends Component<IProps, IState> {
  state: IState

  /**
   * Creates an instance of the component to track errors using state.
   *
   * @param {IProps} props - The props object for the component.
   */
  constructor(props: IProps) {
    super(props)
    this.state = { hasError: false }
  }

  /**
   * Handles errors during rendering and updates the state to show a fallback UI.
   *
   * @returns {IState} The updated state with hasError set to true.
   */
  static getDerivedStateFromError(): IState {
    // Update state so fallback UI is shown
    return { hasError: true }
  }

  /**
   * Lifecycle method invoked when a descendant component throws an error.
   * Can be used to log error details or display a fallback UI.
   *
   * @param {Error} error - The error that was thrown.
   * @param {React.ErrorInfo} info - Information about the component stack trace.
   * @returns {void}
   */
  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    // You could log this error to an external service here
    console.error("Error caught in ErrorBoundary:", error, info)
  }

  /**
   * Renders the component, providing a fallback UI in case of an error.
   *
   * @returns {React.ReactNode } The fallback UI or the component's children.
   */
  render(): React.ReactNode {
    if (this.state.hasError) {
      // Fallback UI
      return (
        <Grid item sx={{ p: 1, textAlign: "center" }}>
          <Typography variant="h1">Something went wrong.</Typography>
          <Box component="p">We’re sorry for the inconvenience. Please try refreshing the page.</Box>
        </Grid>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary