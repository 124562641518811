import React, { useMemo } from "react"
import TableData from "../tables/TableData"
import TableActions from "../tables/TableActions"
import FileAddEdit from "./FileAddEdit"
import { Button, TableBody, TableHead, TableRow } from "@mui/material"
import TableCellData from "../tables/TableCellData"
import { type IFile } from "../../models/service/IFile"
import TableCellTruncate from "../tables/TableCellTruncate"
import TableCellDate from "../tables/TableCellDate"
import TableCellAction from "../tables/TableCellAction"
import FileDownloadButton from "./FileDownloadButton"
import ItemDelete from "../item_delete/ItemDelete"
import FileViewerDialog from "./FileViewerDialog"
import { type RestRepository } from "../../repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import BulkData from "../bulk/BulkData"
import BulkFileDelete from "../bulk/BulkFileDelete"
import ListData from "../lists/ListData"
import ListDataItem from "../lists/ListDataItem"
import { type IMainModel } from "../../models/service/IMainModel"
import FiltersData from "../filters/FiltersData"
import FilterImages from "../filters/FilterImages"
import FilterReports from "../filters/FilterReports"
import { useDrawerWithUrl } from "../containers/DrawerRight"
import UploadIcon from "@mui/icons-material/Upload"
import { Link } from "react-router-dom"
import TableCellFileTags from "../tables/TableCellFileTags"
import type { IFilter } from "../../models/components/IFilter"
import prettyBytes from "pretty-bytes"
import TableCellCheckMark from "../tables/TableCellCheckMark"
import BulkFileCopy from "../bulk/BulkFileCopy"
import FilterSearch from "../filters/FilterSearch"
import FilterFileExt from "../filters/FilterFileExt"
import { ACCOUNT_FILE_EXT_ENDPOINT } from "../../models/service/IAccount"
import { LOCATION_FILE_EXT_ENDPOINT } from "../../models/service/ILocation"
import { INSPECTION_FILE_EXT_ENDPOINT } from "../../models/service/IInspection"
import { LOCATION_IMPAIRMENT_FILE_EXT_ENDPOINT } from "../../models/service/ILocationImpairment"
import { SUMMARY_FILE_EXT_ENDPOINT } from "../../models/service/ISummary"
import { POLICY_FILE_EXT_ENDPOINT } from "../../models/service/IPolicy"
import { SERVICE_USER_EXT_ENDPOINT } from "../../models/service/IServiceUser"
import FilterFileTags from "../filters/FilterFileTags"
import { useTranslation } from "react-i18next"

interface IProps {
  parentId: number | string | undefined
  parentFieldName: string
  repository: RestRepository<IFile | IMainModel>
  pagingResults: IUseApiPagedResultsResponse<IFile> | undefined
  gotoFilters?: IFilter[]
  bulkCopy?: boolean
  canTag?: boolean
}

const extensionEndpoints: Record<string, string> = {
  account: ACCOUNT_FILE_EXT_ENDPOINT,
  location: LOCATION_FILE_EXT_ENDPOINT,
  inspection: INSPECTION_FILE_EXT_ENDPOINT,
  location_impairment: LOCATION_IMPAIRMENT_FILE_EXT_ENDPOINT,
  summary: SUMMARY_FILE_EXT_ENDPOINT,
  policy: POLICY_FILE_EXT_ENDPOINT,
  service_user: SERVICE_USER_EXT_ENDPOINT,
}

const hasReport: Record<string, boolean> = {
  inspection: true,
  summary: true,
}

/**
 * Function that renders a table to display files and perform related actions.
 *
 * @param {IProps} props - The props object containing the following properties:
 *   - parentId {string} - The ID of the parent entity.
 *   - parentFieldName {string} - The name of the field in the parent entity that contains the files.
 *   - repository {string} - The repository name where the files are stored.
 *   - pagingResults {Object} - The paging results object containing the data to be displayed.
 * @returns {React.ReactElement} - The rendered table component.
 */
const FilesIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { parentId, parentFieldName, repository, pagingResults, gotoFilters, bulkCopy = true, canTag = true } = props
  const drawerUrl = useDrawerWithUrl()
  const { t } = useTranslation()

  /**
   * Computes the endpoint associated with the file extension based on the given parent field name.
   *
   * This variable uses React's `useMemo` to memoize the calculation. It retrieves the endpoint
   * from the `extensionEndpoints` object if the `parentFieldName` is defined as a key within it.
   * If the key is not found, it defaults to returning `null`.
   *
   * The calculation is reevaluated whenever the `parentFieldName` changes, ensuring that
   * the endpoint provided is consistent with the current configuration of `parentFieldName`.
   *
   * Dependencies:
   * - `parentFieldName`: A string representing the key used to look up the corresponding endpoint
   *   in the `extensionEndpoints` object.
   * - `extensionEndpoints`: An object mapping file extension identifiers (or other related keys)
   *   to their corresponding endpoints.
   *
   * Returns:
   * A string representing the endpoint URL or identifier, or `null` if no corresponding
   * endpoint is found for the current `parentFieldName`.
   */
  const fileExtEndpoint = useMemo(() => {
    if (extensionEndpoints[parentFieldName] !== undefined) {
      return extensionEndpoints[parentFieldName]
    }
    return null
  }, [parentFieldName])

  /**
   * A memoized boolean value indicating whether to show the report filter based on
   * the availability of a report associated with a specific parent field name.
   *
   * This value is computed only once and cached for performance optimization
   * unless any of the dependencies specified in the dependency array change.
   * The dependency array is intentionally left empty to ensure the computation
   * remains constant during the component lifecycle.
   *
   * @type {boolean}
   */
  const showReportFilter: boolean = useMemo((): boolean => {
    if (hasReport[parentFieldName] !== undefined) {
      return hasReport[parentFieldName]
    }
    return false
  }, [])

  return (
    <>
      {pagingResults !== undefined && (
        <>
          <TableData
            pagingResults={pagingResults}
            itemRepository={repository}
            storageSuffix="files"
            gotoFilters={gotoFilters}>
            <BulkData>
              <BulkFileDelete />
              {bulkCopy ? <BulkFileCopy /> : <></>}
            </BulkData>
            <FiltersData pagingResults={pagingResults}>
              <FilterImages field="images" />
              <FilterFileTags />
              {showReportFilter ? <FilterReports /> : <></>}
              <FilterSearch />
              {fileExtEndpoint !== null ? <FilterFileExt endpoint={fileExtEndpoint} /> : <></>}
            </FiltersData>
            <TableActions>
              <Button startIcon={<UploadIcon />} component={Link} to={drawerUrl("file_upload")}>
                {t("File Upload")}
              </Button>
              <FileAddEdit
                parentId={parentId}
                useDropzone
                onChange={pagingResults.call}
                repository={repository}
                fieldName={parentFieldName}
                openCloseWithUrl={true}
                canTag={canTag}
              />
            </TableActions>
            <TableHead>
              <TableCellData field="id" pagingResults={pagingResults}>
                {t("ID")}
              </TableCellData>
              <TableCellData field="name" pagingResults={pagingResults}>
                {t("Name")}
              </TableCellData>
              <TableCellData field="ext" pagingResults={pagingResults}>
                {t("Ext")}
              </TableCellData>
              <TableCellData field="size" pagingResults={pagingResults}>
                {t("Size")}
              </TableCellData>
              <TableCellData field="exists" pagingResults={pagingResults}>
                {t("Exists")}
              </TableCellData>
              <TableCellData field="file_tags">{t("File Tags")}</TableCellData>
              <TableCellData field="caption" pagingResults={pagingResults}>
                {t("Caption")}
              </TableCellData>
              <TableCellData field="created" pagingResults={pagingResults}>
                {t("Created")}
              </TableCellData>
              <TableCellAction field="action">{t("Action")}</TableCellAction>
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IFile) => (
                <TableRow key={item.id}>
                  <TableCellData field="id">{item.id}</TableCellData>
                  <TableCellTruncate field="name">{item.name}</TableCellTruncate>
                  <TableCellData field="ext">{item.ext}</TableCellData>
                  <TableCellCheckMark field="exists">{item.exists}</TableCellCheckMark>
                  <TableCellData field="size">{prettyBytes(item.size ?? 0)}</TableCellData>
                  <TableCellFileTags field="file_tags">{item.file_tags}</TableCellFileTags>
                  <TableCellTruncate field="caption">{item.caption}</TableCellTruncate>
                  <TableCellDate field="created">{item.created}</TableCellDate>
                  <TableCellAction field="action">
                    <FileAddEdit
                      parentId={parentId}
                      useEdit
                      selectedFile={item}
                      onChange={pagingResults.call}
                      repository={repository}
                      fieldName={parentFieldName}
                      canTag={canTag}
                    />
                    <FileDownloadButton file={item} />
                    <ItemDelete repository={repository} item={item} onChange={pagingResults.call} />
                    <FileViewerDialog selectedFile={item} files={pagingResults.data?.results} />
                  </TableCellAction>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: IFile) => (
              <ListDataItem key={item.id} title={item.name}></ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default FilesIndex
