import React, { useCallback } from "react"
import { ImportExport } from "@mui/icons-material"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../../shared/models/service/IInspection"
import { buildApiAction } from "../../../../../shared/hooks/useApiAction"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IPaging } from "../../../../../shared/models/components/IPaging"
import AlertDialog from "../../../../../shared/components/AlertDialog"
import { Alert, Box, Grid } from "@mui/material"
import ForwardIcon from "@mui/icons-material/Forward"
import { useTranslation } from "react-i18next"

const inspectRepository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)

export interface IInspectionCopyFromProps {
  inspection: IInspection
  copyFromInspection: IInspection
  onComplete?: () => void
}

/**
 * Renders a button that triggers a copy action from a specified inspection.
 * todo add notification indicating any fields that might not be copied over.
 *
 * @param {IInspectionCopyFromProps} props - The properties object containing inspection details and the inspection to copy from.
 * @returns {React.ReactElement} A React element rendering the copy button.
 */
const InspectionCopyFrom: React.FC<IInspectionCopyFromProps> = (
  props: IInspectionCopyFromProps,
): React.ReactElement => {
  const { inspection, copyFromInspection, onComplete } = props

  const { t } = useTranslation()

  const apiAction = buildApiAction<IInspection>(inspectRepository, inspection.id)

  const handleCopyFrom = useCallback(async () => {
    const paging: IPaging = { filters: [{ field: "id", value: copyFromInspection.id }] }
    await apiAction.callAction("copy_from_inspection", paging)
    onComplete?.()
  }, [])

  return (
    <>
      <AlertDialog
        showIconAndText
        buttonText={t("Copy From")}
        buttonIcon={<ImportExport />}
        title={t("Copy from Inspection")}
        message={
          <>
            <Box sx={{ mb: 1 }}>{t("Are you sure you want to copy from this inspection?")}</Box>
            <Alert sx={{ display: "block" }} icon={false} severity="info">
              <Box>
                <Grid container alignItems="center">
                  <Grid item xs sx={{ textAlign: "center" }}>
                    <Box>{t("From")}</Box>
                    <strong>{copyFromInspection.identifier}</strong>
                  </Grid>
                  <Grid item>
                    <ForwardIcon fontSize="small" />
                  </Grid>
                  <Grid item xs sx={{ textAlign: "center" }}>
                    <Box>{t("To")}</Box>
                    <strong>{inspection.identifier}</strong>
                  </Grid>
                </Grid>
              </Box>
            </Alert>
            <Box sx={{ mt: 1 }}>
              {t("This will overwrite all information on the current inspection. This process cannot be undone.")}
            </Box>
          </>
        }
        onYes={handleCopyFrom}
      />
    </>
  )
}

export default InspectionCopyFrom
