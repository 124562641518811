import React from "react"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { CONTACT_ENDPOINT, type IContact } from "../../../../shared/models/service/IContact"
import { CONTACT_ADD_URL, CONTACT_VIEW_URL } from "../../config/urls"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import TableData from "../../../../shared/components/tables/TableData"
import TableCellDate from "../../../../shared/components/tables/TableCellDate"
import TableCellCenter from "../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import ListProperty from "../../../../shared/components/lists/ListProperty"
import TableCellPhone from "../../../../shared/components/tables/TableCellPhone"
import FiltersData from "../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../shared/components/filters/FilterSearch"
import FilterLocationCity from "../../../../shared/components/filters/FilterLocationCity"
import FilterLocationStateRegion from "../../../../shared/components/filters/FilterLocationStateRegion"
import FilterLocationCountry from "../../../../shared/components/filters/FilterLocationCountry"
import TableActions from "../../../../shared/components/tables/TableActions"
import ExcelDownloadButton from "../../../../shared/components/ExcelDownloadButton"
import TableCellCheckMark from "../../../../shared/components/tables/TableCellCheckMark"
import { SelectOptions } from "../../../../config/config"
import ViewSelectOption from "../../../../shared/components/display/ViewSelectOption"
import FilterCompany from "../../../../shared/components/filters/FilterCompany"
import FilterIsDriver from "../../../../shared/components/filters/FilterIsDriver"
import FilterSelectOption from "../../../../shared/components/filters/FilterSelectOption"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IContact>(CONTACT_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(CONTACT_ENDPOINT)

/**
 * Renders the index page for the contacts.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IContact>({ apiFunction: repository.findAll })
  const { data } = pagingResults
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={2}>
      <PageHeader title={t("Contact")} titlePlural={t("Contacts")} toAdd={CONTACT_ADD_URL} />
      <TableData pagingResults={pagingResults} to={CONTACT_VIEW_URL} repository={gotoRepo}>
        <TableActions>
          <ExcelDownloadButton repository={repository} paging={pagingResults.paging} />
        </TableActions>
        <FiltersData pagingResults={pagingResults}>
          <FilterSearch />
          <FilterLocationCity field="city" />
          <FilterLocationStateRegion field="state_region" />
          <FilterLocationCountry field="country" />
          <FilterCompany field="company" />
          <FilterIsDriver />
          <FilterSelectOption field="license_status" selectOption={SelectOptions.LICENSE_STATUS_OPTIONS} />
        </FiltersData>
        <TableHead>
          <TableCellCenter field="first_name" pagingResults={pagingResults}>
            {t("First Name")}
          </TableCellCenter>
          <TableCellCenter field="last_name" pagingResults={pagingResults}>
            {t("Last Name")}
          </TableCellCenter>
          <TableCellCenter field="job_title">{t("Job Title")}</TableCellCenter>
          <TableCellCenter field="phone">{t("Phone")}</TableCellCenter>
          <TableCellCenter field="email">{t("Email")}</TableCellCenter>
          <TableCellCenter field="city" pagingResults={pagingResults}>
            {t("City")}
          </TableCellCenter>
          <TableCellCenter field="state_region" pagingResults={pagingResults}>
            {t("State/Region")}
          </TableCellCenter>
          <TableCellCenter field="country" pagingResults={pagingResults}>
            {t("Country")}
          </TableCellCenter>
          <TableCellCenter field="is_driver" pagingResults={pagingResults}>
            {t("Is Driver")}
          </TableCellCenter>
          <TableCellCenter field="license_status" pagingResults={pagingResults}>
            {t("License Status")}
          </TableCellCenter>
          <TableCellCenter field="created" pagingResults={pagingResults}>
            {t("Created")}
          </TableCellCenter>
        </TableHead>
        <TableBody>
          {data?.results.map((item: IContact) => (
            <TableRow key={item.id}>
              <TableCellData field="first_name">{item.first_name}</TableCellData>
              <TableCellData field="last_name">{item.last_name}</TableCellData>
              <TableCellData field="job_title">{item.job_title}</TableCellData>
              <TableCellPhone field="phone" ext={item.phone_ext}>
                {item.phone}
              </TableCellPhone>
              <TableCellData field="email">{item.email}</TableCellData>
              <TableCellData field="address">{item.address}</TableCellData>
              <TableCellData field="city">{item.city}</TableCellData>
              <TableCellData field="state_region">{item.state_region}</TableCellData>
              <TableCellData field="country">{item.country}</TableCellData>
              <TableCellCheckMark field="is_driver">{item.is_driver}</TableCellCheckMark>
              <TableCellData field="license_status">
                <ViewSelectOption keyLookup={item.license_status} selectOption={SelectOptions.LICENSE_STATUS_OPTIONS} />
              </TableCellData>
              <TableCellDate field="created">{item.created}</TableCellDate>
            </TableRow>
          ))}
        </TableBody>
      </TableData>

      <ListData pagingResults={pagingResults}>
        {data?.results.map((item: IContact) => (
          <ListDataItem key={item.id} to={`${CONTACT_VIEW_URL}/${item.id}`} title={item.name}>
            <ListProperty>{item.job_title}</ListProperty>
          </ListDataItem>
        ))}
      </ListData>
    </Grid>
  )
}

export default IndexPage
