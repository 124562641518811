import DashboardIcon from "@mui/icons-material/Dashboard"
import { ADDONS_INDEX_URL, DASHBOARD_URL, INSPECTION_INDEX_URL } from "./urls"
import SavedSearchIcon from "@mui/icons-material/SavedSearch"
import LibraryAddIcon from "@mui/icons-material/LibraryAdd"
import { type ISideNav } from "../../../shared/components/SideNavMenu"

import { t } from "i18next"

/**
 * Represents the side navigation menu.
 *
 * @param {ISideNav[] | null} addonMenuItems - are additional menu items to be displayed.
 * @returns {Array<ISideNav | null>} - The children menu items.
 */
export const navMenu = (addonMenuItems: ISideNav[] | null = null): Array<ISideNav | null | boolean> => {
  return [
    {
      icon: DashboardIcon,
      url: DASHBOARD_URL,
      name: t("Dashboard"),
    },
    {
      icon: SavedSearchIcon,
      url: INSPECTION_INDEX_URL,
      name: t("Inspections"),
    },
    addonMenuItems !== null ? null : false,
    addonMenuItems !== null
      ? {
          icon: LibraryAddIcon,
          url: ADDONS_INDEX_URL,
          name: t("Addons"),
          children: addonMenuItems,
        }
      : null,
    addonMenuItems !== null ? null : false
  ]
}
