import { type IListItem } from "../components/IListItem"
import { type ILocation } from "./ILocation"
import { type IAccount } from "./IAccount"
import type IUser from "../users/IUser"
import { type IServiceUser } from "./IServiceUser"
import { type ICompany } from "./ICompany"

export const CONTACT_ENDPOINT = "contacts"

export interface IContact extends IListItem {
  id: number
  first_name: string
  last_name: string
  business_name: string
  job_title: string

  primary: boolean
  verified: string
  verified_by: IUser

  service_user: IServiceUser | null

  phone: string
  phone_ext: string

  phone_2: string
  phone_2_ext: string

  email: string
  email_2: string

  address: string
  address_2: string
  city: string
  state_region: string
  postal_code: string
  country: string

  longitude: number | string
  latitude: number | string
  zoom: number | string

  notes: string
  locations: ILocation[]
  accounts: IAccount[]

  is_driver: boolean
  license_number: string
  license_state_region: string
  license_points: number
  license_status: string

  driver_notes: string

  companies: ICompany[]

  created: string
  updated: string
}
