import React from "react"
import { useTranslation } from "react-i18next"

/**
 * Not found page component.
 *
 * @returns {React.FC} page not found message.
 */
const NotFound: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  return <p>{t("That page does not exist.")}</p>
}

export default NotFound
