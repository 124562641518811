import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import VendorForm from "./components/VendorForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { type IVendor, VENDOR_ENDPOINT } from "../../../../shared/models/service/IVendor"
import { VENDOR_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IVendor>(VENDOR_ENDPOINT)
const redirectView = VENDOR_VIEW_URL

/**
 * This page will allow editing of a vendor.
 *
 * @returns {React.FC} the vendor edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IVendor>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IVendor> = {
    apiFunction: repository.edit,
    setError: form.setError,
    redirectView,
  }
  const apiEdit = useApiEdit<IVendor>(props)
  const { t } = useTranslation()

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader
          title={t("Edit Vendor")}
          loading={apiEdit.saving || apiRead.loading}
          errors={form.formState.errors}
        />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={apiEdit.handleEdit}>
          <VendorForm vendor={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
