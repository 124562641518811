import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type IVehicle } from "../../../../../shared/models/service/IVehicle"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import MetadataForm from "../../../../../shared/components/metadata/MetadataForm"
import { useMetadataExists } from "../../../../../shared/components/metadata/UseMetadataExists"
import FormatNumberAndError from "../../../../../shared/components/format/FormatNumberAndError"
import FhMuiDateField from "../../../../../shared/components/forms/FhMuiDateField"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import { ItemPrefixes } from "../../../../../config/config"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import CompanyInfo from "../../companies/components/CompanyInfo"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import type { IListItem } from "../../../../../shared/models/components/IListItem"
import { COMPANY_ENDPOINT, type ICompany } from "../../../../../shared/models/service/ICompany"
import SelectFilteredMultiple, { useSelectFilteredMultiple } from "../../../../../shared/components/forms/SelectFilteredMultiple"
import { CONTACT_ENDPOINT, type IContact } from "../../../../../shared/models/service/IContact"
import ContactInfo from "../../contacts/components/ContactInfo"

interface IProps {
  form: UseFormReturn
  vehicle?: IVehicle | undefined | null
  isEdit?: boolean
}

const companyRepository = new RestRepository<ICompany | IListItem>(COMPANY_ENDPOINT)
const contactRepository = new RestRepository<IContact | IListItem>(CONTACT_ENDPOINT)

/**
 * Use this forms to add or edit a vehicle.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const VehicleForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { vehicle, form, isEdit = false } = props

  const [company, setCompany] = useSelectFilteredSingle("company", form)
  const [contacts, setContacts] = useSelectFilteredMultiple("contacts", form)
  
  const metadataExists = useMetadataExists("vehicle")

  useLoadFormData<IVehicle>(
    (data: IVehicle) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("license_plate", data.license_plate)
      form.setValue("vin", data.vin)
      form.setValue("year", data.year)
      form.setValue("make", data.make)
      form.setValue("model", data.model)
      form.setValue("color", data.color)
      form.setValue("purchase_price", data.purchase_price)
      form.setValue("purchase_date", data.purchase_date)
      form.setValue("registration_date", data.registration_date)
      form.setValue("renewal_date", data.renewal_date)

      form.setValue("notes", data.notes)
      form.setValue("metadata_data", data.metadata_data)
      
      setCompany(data.company)
      setContacts(data.contacts)
    },
    vehicle,
    isEdit,
    form.setValue,
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerDrawer title={"Company"} prefix={ItemPrefixes.company} infoView={CompanyInfo} />
            <ItemViewerDrawer title={"Contact"} prefix={ItemPrefixes.contact} infoView={ContactInfo} />
            <PaperLocal>
              {isEdit && <FhMuiHiddenField control={form.control} />}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
                </Grid>
                <Grid item xs={12}>
                  <SelectFilteredSingle
                    name="company"
                    defaultValue={company}
                    repository={companyRepository}
                    onChange={setCompany}
                    infoViewPrefix={ItemPrefixes.company}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectFilteredMultiple
                    name="contacts"
                    defaultValue={contacts}
                    repository={contactRepository}
                    onChange={setContacts}
                    itemViewPrefix={ItemPrefixes.contact}
                  />
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
          <Grid item xs={12}>
            <PaperLocal>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FhMuiRichTextField control={form.control} name="notes" />
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
          {metadataExists && (
            <Grid item xs={12}>
              <PaperLocal>
                <MetadataForm modelName="vehicle" form={form} />
              </PaperLocal>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="license_plate" />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="vin" />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} name="year" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="make" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="model" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="color" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="purchase_price" defaultValue={0} formatter={FormatNumberAndError} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiDateField control={form.control} name="purchase_date" />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiDateField control={form.control} name="registration_date" />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiDateField control={form.control} name="renewal_date" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default VehicleForm
