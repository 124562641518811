import React from "react"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { VEHICLE_ENDPOINT, type IVehicle } from "../../../../shared/models/service/IVehicle"
import VehicleIndex from "./components/VehicleIndex"

const repository = new RestRepository<IVehicle>(VEHICLE_ENDPOINT)

/**
 * Renders the index page for the vehicles.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IVehicle>({ apiFunction: repository.findAll })

  return (
    <Grid container alignItems="center" spacing={2}>
      <VehicleIndex pagingResults={pagingResults} />
    </Grid>
  )
}

export default IndexPage
