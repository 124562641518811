import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import VehicleForm from "./components/VehicleForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { type IVehicle, VEHICLE_ENDPOINT } from "../../../../shared/models/service/IVehicle"
import { VEHICLE_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useTranslation } from "react-i18next"
import moment from "moment/moment"

const repository = new RestRepository<IVehicle>(VEHICLE_ENDPOINT)
const redirectView = VEHICLE_VIEW_URL

/**
 * This page will allow editing of a vehicle.
 *
 * @returns {React.FC} the vehicle edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IVehicle>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IVehicle> = { apiFunction: repository.edit, setError: form.setError, redirectView }
  const apiEdit = useApiEdit<IVehicle>(props)

  const { t } = useTranslation()

  const handleSave = useCallback(
    async (vehicle: IVehicle) => {
      vehicle.purchase_date = vehicle.purchase_date !== null ? moment(vehicle.purchase_date).format("YYYY-MM-DD") : null
      vehicle.registration_date = vehicle.registration_date !== null ? moment(vehicle.registration_date)?.format("YYYY-MM-DD") : null
      vehicle.renewal_date = vehicle.renewal_date !== null ? moment(vehicle.renewal_date).format("YYYY-MM-DD") : null
      await apiEdit.handleEdit(vehicle)
    },
    [apiEdit.handleEdit],
  )

  return (
    <Container fixed>
      <FormProvider {...form}>
        <Grid container spacing={2}>
          <FormHeader
            title={t("Edit Vehicle")}
            loading={apiEdit.saving || apiRead.loading}
            errors={form.formState.errors}
          />
          <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
          <FormBox form={form} onSubmit={handleSave}>
            <VehicleForm vehicle={apiRead.data} form={form} isEdit />
          </FormBox>
        </Grid>
      </FormProvider>
    </Container>
  )
}

export default EditPage
