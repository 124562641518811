import React from "react"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import TableData from "../../../../../shared/components/tables/TableData"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import TableCellAction from "../../../../../shared/components/tables/TableCellAction"
import { type IReportWriterFormat } from "../../../../../shared/models/service/IReportFormat"
import TableCellCheckMark from "../../../../../shared/components/tables/TableCellCheckMark"
import { useTranslation } from "react-i18next"

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IReportWriterFormat> | undefined
  currentReportWriterFormat?: IReportWriterFormat | null
  cellActions?: (reportWriterFormat: IReportWriterFormat) => React.JSX.Element[] | React.JSX.Element | undefined
}

/**
 * A React functional component that renders a paginated list of policies.
 *
 * @param {IProps} props - The component properties.
 * @returns {React.ReactElement} The rendered component.
 */
const ReportWriterFormatIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, currentReportWriterFormat, cellActions } = props
  const { t } = useTranslation()

  return (
    <>
      {pagingResults !== undefined && (
        <>
          <TableData pagingResults={pagingResults}>
            <TableHead>
              <TableCellData field="name" pagingResults={pagingResults}>
                {t("Name")}
              </TableCellData>
              <TableCellData field="title">{t("Title")}</TableCellData>
              <TableCellData field="version" pagingResults={pagingResults}>
                {t("Version")}
              </TableCellData>
              <TableCellCenter field="current">{t("Current")}</TableCellCenter>
              <TableCellCenter field="created" pagingResults={pagingResults}>
                {t("Created")}
              </TableCellCenter>
              <TableCellCenter field="updated" pagingResults={pagingResults}>
                {t("Updated")}
              </TableCellCenter>
              {cellActions !== undefined && <TableCellAction field="action">{t("Action")}</TableCellAction>}
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IReportWriterFormat) => (
                <TableRow key={item.id}>
                  <TableCellData field="name">{item.name}</TableCellData>
                  <TableCellData field="title">{item.title}</TableCellData>
                  <TableCellData field="version">{item.version}</TableCellData>
                  <TableCellCheckMark field="current">{item.id === currentReportWriterFormat?.id}</TableCellCheckMark>
                  <TableCellDate field="created">{item.created}</TableCellDate>
                  <TableCellDate field="updated">{item.updated}</TableCellDate>
                  {cellActions !== undefined && <TableCellAction field="action">{cellActions(item)}</TableCellAction>}
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: IReportWriterFormat) => (
              <ListDataItem key={item.id} title={item.name} />
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default ReportWriterFormatIndex
