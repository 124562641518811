import React, { useCallback, useState } from "react"
import { Button, Grid, MenuItem, TextField } from "@mui/material"
import { useFilterDataAvailable } from "./FiltersData"
import AddIcon from "@mui/icons-material/Add"
import { type IFilter } from "../../models/components/IFilter"
import { nameToLabel } from "../../utilities/form_utility"
import { type SelectOptions } from "../../../config/config"
import { useSelectOptions } from "../../hooks/useSelectOptions"
import { type ISelectItem } from "../../models/components/ISelectItem"
import { useTranslation } from "react-i18next"

interface IProps {
  field: string
  selectOption: SelectOptions
  title?: string
}

/**
 * Filters an account based on the provided props.
 *
 * @param {IProps} props - The props object containing the field to be filtered.
 * @returns {React.ReactElement} - The filtered account component.
 */
const FilterSelectOption = (props: IProps): React.ReactElement => {
  const { field, selectOption, title } = props

  const [item, setItem] = useState<ISelectItem | null>(null)
  const theLabel = nameToLabel(field, title)

  const filtersDataContext = useFilterDataAvailable(field, theLabel)

  const selectOptions = useSelectOptions(selectOption)

  const { t } = useTranslation()

  const handleAddFilter = useCallback(() => {
    const filter: IFilter = { title: theLabel, field, value: item?.key, display: item?.value }
    filtersDataContext?.addFilter(filter)
  }, [item, field, theLabel, filtersDataContext?.addFilter])

  return (
    <>
      {filtersDataContext?.selectedFilter?.value === field && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={theLabel}
              select
              value={item?.key}
              onChange={event => {
                const selected = selectOptions.find(option => option.key === event.target.value)
                setItem(selected ?? null)
              }}>
              {selectOptions.map(item => (
                <MenuItem key={item.key} value={item.key}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddFilter}>
              {t("Add Filter")}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default FilterSelectOption
