import React, { useCallback, useState } from "react"
import DrawerRight from "../../../../shared/components/containers/DrawerRight"
import AddIcon from "@mui/icons-material/Add"
import { Button, Grid } from "@mui/material"
import SelectFilteredSingle from "../../../../shared/components/forms/SelectFilteredSingle"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import { type IPolicy, POLICY_ENDPOINT } from "../../../../shared/models/service/IPolicy"
import { type IPaging } from "../../../../shared/models/components/IPaging"
import useApiAction, { type IUseApiActionProps } from "../../../../shared/hooks/useApiAction"
import PolicyInfo from "../../pages/policies/components/PolicyInfo"
import { type IAccount } from "../../../../shared/models/service/IAccount"
import { useTranslation } from "react-i18next"

const policyListRepository = new RestRepository<IPolicy | IListItem>(POLICY_ENDPOINT)

interface IProps {
  onComplete: () => void
  parentId: number
  repository: RestRepository<IAccount>
}

/**
 * Represents a component for adding a policy.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered component.
 */
const AddPolicy: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onComplete, parentId, repository } = props
  const { t } = useTranslation()
  const [policy, setPolicy] = useState<IListItem | null>(null)

  const [closeDrawer, setCloseDrawer] = useState<boolean>(true)

  const actionProps: IUseApiActionProps<IAccount> = { repository, itemId: parentId }
  const apiAction = useApiAction<IAccount>(actionProps)

  const handleAddPolicy = useCallback(async () => {
    if (policy !== null) {
      const paging: IPaging = { filters: [{ field: "id", value: policy.id }] }
      await apiAction.callAction("add_policy", paging)
      setCloseDrawer(closeDrawer => !closeDrawer)
      onComplete()
    }
  }, [policy])

  return (
    <DrawerRight title={t("Add Policy")} icon={<AddIcon />} closeDrawer={closeDrawer}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <SelectFilteredSingle
            name="policy"
            defaultValue={policy}
            repository={policyListRepository}
            onChange={setPolicy}
          />
        </Grid>
        <Grid item xs />
        <Grid item>
          <Button onClick={handleAddPolicy} disabled={policy === null}>
            Add Policy
          </Button>
        </Grid>
        <Grid item xs={12}>
          {policy !== null && <PolicyInfo infoId={policy.id} />}
        </Grid>
      </Grid>
    </DrawerRight>
  )
}

export default AddPolicy
