import * as React from "react"
import { useCallback, useContext, useState } from "react"
import { Alert, BottomNavigation, BottomNavigationAction, Box, Divider, Grid, IconButton, Popover } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ReportIcon from "@mui/icons-material/Article"
import ImageIcon from "@mui/icons-material/Image"
import TableIcon from "@mui/icons-material/BorderAll"
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak"
import { type IRiskWriterSection } from "../models/IRiskWriterSection"
import { SectionType } from "../models/IRiskWriterSectionData"
import TruncateText from "../../TruncateText"
import { nameToLabel } from "../../../utilities/form_utility"
import { RiskWriterContext } from "../context/RiskWriterProvider"
import { useTranslation } from "react-i18next"

interface IProps {
  section: IRiskWriterSection
}

/**
 * Represents a content bar component.
 *
 * @param {IProps} props - The props for the component.
 * @returns {React.Element} - The rendered component.
 */
const RiskWriterContentBar: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { section } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { onAddContent } = useContext(RiskWriterContext)

  const { t } = useTranslation()

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const open = Boolean(anchorEl)

  const handleAddContent = useCallback(
    (contentType: SectionType) => () => {
      setAnchorEl(null)
      onAddContent?.(section.name, contentType)
    },
    [section],
  )

  return (
    <Box>
      {section.allowed_content_types !== undefined &&
        section.allowed_content_types !== null &&
        section.allowed_content_types.length > 0 && (
          <Grid container alignItems="center">
            <Grid item xs>
              <Divider />
            </Grid>
            <Grid item>
              <IconButton onClick={handleClick}>
                <AddIcon />
              </IconButton>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert
                  icon={<AddIcon fontSize="inherit" />}
                  severity="info"
                  sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                  <TruncateText>{nameToLabel(section.name, section.title)}</TruncateText>
                </Alert>
                <BottomNavigation showLabels>
                  {section.allowed_content_types.includes(SectionType.RICH_TEXT_EDITOR) && (
                    <BottomNavigationAction
                      label={t("Text")}
                      icon={<ReportIcon />}
                      onClick={handleAddContent(SectionType.RICH_TEXT_EDITOR)}
                    />
                  )}
                  {section.allowed_content_types.includes(SectionType.IMAGE_EDITOR) && (
                    <BottomNavigationAction
                      label={t("Image")}
                      icon={<ImageIcon />}
                      onClick={handleAddContent(SectionType.IMAGE_EDITOR)}
                    />
                  )}
                  {section.allowed_content_types.includes(SectionType.TABLE_EDITOR) && (
                    <BottomNavigationAction
                      label={t("Table")}
                      icon={<TableIcon />}
                      onClick={handleAddContent(SectionType.TABLE_EDITOR)}
                    />
                  )}
                  {section.allowed_content_types.includes(SectionType.TEMPLATE_EDITOR) && (
                    <BottomNavigationAction
                      label={t("Templates")}
                      icon={<InsertPageBreakIcon />}
                      onClick={handleAddContent(SectionType.TEMPLATE_EDITOR)}
                    />
                  )}
                </BottomNavigation>
              </Popover>
            </Grid>
            <Grid item xs>
              <Divider />
            </Grid>
          </Grid>
        )}
    </Box>
  )
}

export default RiskWriterContentBar
