import React, { useCallback, useState } from "react"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import DialogControls from "../DialogControls"
import { type RestRepository } from "../../repositories/RestRepository"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import FhMuiTextField from "../forms/FhMuiTextField"
import EditIcon from "@mui/icons-material/Edit"
import { type IPaging } from "../../models/components/IPaging"
import ErrorMessage from "../ErrorMessage"

interface IProps {
  field: string
  value: string | null
  parentId: number
  repository: RestRepository<any>
  onChange?: () => Promise<void>
}

/**
 * EmailChangeDialog is a React functional component used to display a dialog
 * for updating an email address. It provides input validation and communicates
 * changes via a repository action. The dialog can be opened or closed, and
 * includes save and cancel controls.
 *
 * @param {IProps} props - The component's properties.
 * @returns {React.ReactElement} - The rendered EmailChangeDialog component.
 */
const EmailChangeDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { field, value, parentId, repository, onChange } = props
  const [open, setOpen] = useState(false)
  const form = useForm()
  const { t } = useTranslation()
  const axiosRequest = useAxiosRequest()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    form.setValue(field, value)
    setOpen(true)
  }, [value])

  const handleSave = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      const paging: IPaging = {
        filters: [{ field, value: form.getValues(field) }],
      }
      await repository.action(parentId, "change_login_email", paging)
      handleClose()
      await onChange?.()
    })
  }, [field, form, parentId])

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>{t("Change Email")}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {axiosRequest.error !== null && <ErrorMessage error={axiosRequest.errorMessage} />}
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormProvider {...form}>
              <FhMuiTextField control={form.control} name={field} />
            </FormProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogControls loading={axiosRequest.loading} onSave={handleSave} onCancel={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EmailChangeDialog
