import React from "react"
import { Grid, Typography } from "@mui/material"
import FormatDate from "../format/FormatDate"

interface IProps {
  children: string | null | undefined
  label: string
}

/**
 * Renders a list property with formatted date value.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered list property with formatted date value.
 */
const ListPropertyDate: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, label } = props

  return (
    <>
      <Grid item>
        <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
          {label}
        </Typography>
      </Grid>
      <Grid item xs>
        <FormatDate value={children} />
      </Grid>
    </>
  )
}

export default ListPropertyDate
