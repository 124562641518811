import React, { type SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react"
import {
  Autocomplete,
  type ChipProps,
  CircularProgress,
  Grid,
  Popper,
  type PopperProps,
  TextField,
  Tooltip,
} from "@mui/material"
import useDebounce from "react-debounced"
import ErrorMessage from "../ErrorMessage"
import { type IListItem } from "../../models/components/IListItem"
import { type IFilter } from "../../models/components/IFilter"
import { type RestRepository } from "../../repositories/RestRepository"
import { CONNECTION_ERROR, type IConnectionError } from "../../models/components/IConnectionError"
import { type IPaging } from "../../models/components/IPaging"
import { type UseFormReturn } from "react-hook-form/dist/types"
import ErrorIcon from "@mui/icons-material/Error"
import { nameToLabel } from "../../utilities/form_utility"
import { useDrawerWithUrl } from "../containers/DrawerRight"
import { useNavigate } from "react-router-dom"
import TruncateText from "../TruncateText"
import type { Control } from "react-hook-form/dist/types/form"

export type TUseSelectFilteredMultipleResponse = [items: IListItem[], handleItems: (items1?: IListItem[]) => void]

/**
 * A method to handle selecting and filtering multiple items.
 *
 * @param {string} name - The name of the field.
 * @param {UseFormReturn} form - The forms instance returned by the useForm hook.
 * @returns {TUseSelectFilteredMultipleResponse} - An array consisting of the selected and filtered items and a handler function.
 */
export const useSelectFilteredMultiple = (name: string, form: UseFormReturn): TUseSelectFilteredMultipleResponse => {
  const [items, setItems] = useState<IListItem[]>([])

  const handleItems = useCallback(
    (items1?: IListItem[]) => {
      form.clearErrors(name)
      if (items1 !== undefined) {
        form.setValue(
          name,
          items1.map(i => i.id),
        )
        setItems(items1.map(i => ({ id: i.id, name: i.name })))
      } else {
        form.setValue(name, [])
        setItems([])
      }
    },
    [name, form],
  )

  return [items, handleItems]
}

interface ISelectFilteredMultipleProps {
  name: string
  label?: string
  defaultValue?: IListItem[] | null
  filters?: IFilter[]
  repository?: RestRepository<IListItem>
  onChange: (listItems: IListItem[]) => void
  itemViewPrefix?: string
  control?: Control
}

/**
 * This component provides a multiple autocomplete forms select.
 *
 * @param {ISelectFilteredMultipleProps} props See ISelectFilteredMultipleProps for details.
 * @returns {React.FC} the component.
 */
const SelectFilteredMultiple: React.FC<ISelectFilteredMultipleProps> = (
  props: ISelectFilteredMultipleProps,
): React.ReactElement => {
  const { name, defaultValue, filters = [], label, repository, onChange, itemViewPrefix, control } = props
  const debounce = useDebounce()

  const fieldState = control?.getFieldState(name)

  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const [listItems, setListItems] = useState<readonly IListItem[]>([])
  const [selectedListItems, setSelectedListItems] = useState<IListItem[]>([])
  const [error, setError] = useState<IConnectionError | null>(null)

  const navigate = useNavigate()
  const drawerUrl = useDrawerWithUrl()

  const handleOpen = useCallback(async () => {
    if (repository === undefined) {
      setOpen(true)
      return
    }
    setError(null)
    setOpen(true)
    setLoading(true)
    try {
      const paging: IPaging = { filters }
      let results = await repository.list(paging)
      results = results.filter(item => !selectedListItems.some(selectedItem => selectedItem.id === item.id))
      results = [...results, ...selectedListItems]
      setListItems(results)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response as IConnectionError)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [filters, selectedListItems])

  // noinspection DuplicatedCode
  const handleSearch = useCallback(
    async (event: SyntheticEvent, search: string) => {
      if (repository === undefined) {
        setListItems([{ id: search, name: search }, ...selectedListItems])
        return
      }
      debounce(async () => {
        setError(null)
        setLoading(true)
        try {
          const paging: IPaging = { filters: [...filters, { field: "search", value: search }] }
          let results = await repository.list(paging)
          results = results.filter(item => !selectedListItems.some(selectedItem => selectedItem.id === item.id))
          results = [...results, ...selectedListItems]
          setListItems(results)
        } catch (reason: any) {
          if (reason?.response !== undefined) {
            setError(reason.response as IConnectionError)
          } else {
            setError(CONNECTION_ERROR)
          }
        }
        setLoading(false)
      })
    },
    [filters, selectedListItems],
  )

  const handleListItemClick = useCallback((event: SyntheticEvent<Element, Event>, items: Array<string | IListItem>) => {
    setSelectedListItems([...items] as IListItem[])
    setListItems([...items] as IListItem[])
    onChange([...items] as IListItem[])
  }, [])

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      setSelectedListItems([...defaultValue])
      setListItems([...defaultValue])
    }
  }, [defaultValue])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const getOptionLabel = useCallback((option: string | IListItem) => {
    return `${(option as IListItem).id} - ${(option as IListItem).name}`
  }, [])

  const theLabel = nameToLabel(name, label)

  const ChipProps = useMemo((): ChipProps<"div"> => {
    return {
      clickable: true,
      onClick: (event: React.MouseEvent<HTMLDivElement>) => {
        const name = (event.target as HTMLDivElement).textContent
        const selectedItem = selectedListItems.find(item => getOptionLabel(item) === name)
        if (itemViewPrefix !== undefined) {
          const url = drawerUrl(`${itemViewPrefix}info/${selectedItem?.id}`)
          navigate(url)
        }
      },
    }
  }, [selectedListItems, itemViewPrefix])

  /**
   * This is needed so the autocomplete can be used in right drawer on the report writer.
   * See public/index.html for styling.
   */
  const PopperSelectFilter = useCallback((props: PopperProps) => {
    return <Popper {...props} id="AutocompletePopper" />
  }, [])

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Autocomplete
          open={open}
          multiple
          freeSolo={repository === undefined}
          onOpen={handleOpen}
          defaultValue={[]}
          onClose={handleClose}
          PopperComponent={PopperSelectFilter}
          onChange={handleListItemClick}
          ChipProps={ChipProps}
          value={selectedListItems}
          onInputChange={handleSearch}
          isOptionEqualToValue={(option: IListItem, value: IListItem) => option.id === value.id}
          getOptionLabel={getOptionLabel}
          options={listItems}
          loading={loading}
          renderOption={(props, option: IListItem) => (
            <li {...props} key={option.id}>
              <TruncateText>{getOptionLabel(option)}</TruncateText>
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              name={name}
              label={theLabel}
              helperText={fieldState?.error?.message}
              error={fieldState?.invalid}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : <></>}
                    {params.InputProps.endAdornment}
                    {error !== null && (
                      <Tooltip title={<ErrorMessage error={error} />} sx={{ p: 0 }} placement="right">
                        <ErrorIcon color="error" />
                      </Tooltip>
                    )}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default SelectFilteredMultiple
