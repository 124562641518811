import React from "react"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { COMPANY_ENDPOINT, type ICompany } from "../../../../shared/models/service/ICompany"
import CompanyIndex from "./components/CompanyIndex"

const repository = new RestRepository<ICompany>(COMPANY_ENDPOINT)

/**
 * Renders the index page for the companies.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<ICompany>({ apiFunction: repository.findAll })

  return (
    <Grid container alignItems="center" spacing={2}>
      <CompanyIndex pagingResults={pagingResults} />
    </Grid>
  )
}

export default IndexPage
