import React, { useCallback, useMemo, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import { type IInspection, reportWriterFormatAsListItem } from "../../../../../shared/models/service/IInspection"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../../shared/models/service/IAccount"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../../shared/models/service/ILocation"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import FhMuiDateField from "../../../../../shared/components/forms/FhMuiDateField"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import { type IVendor, VENDOR_ENDPOINT } from "../../../../../shared/models/service/IVendor"
import { ItemPrefixes, PriorityTypes } from "../../../../../config/config"
import {
  type IReportFormat,
  type IReportWriterFormat,
  REPORT_FORMAT_ENDPOINT,
  REPORT_WRITER_FORMAT_ENDPOINT,
} from "../../../../../shared/models/service/IReportFormat"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import LocationInfo from "../../locations/components/LocationInfo"
import ServiceUserInfo from "../../service_users/components/ServiceUserInfo"
import VendorInfo from "../../vendors/components/VendorInfo"
import ReportWriterFormatInfo from "../../../../../shared/pages/report_format/components/ReportWriterFormatInfo"
import { type IPriority, PRIORITY_ENDPOINT } from "../../../../../shared/models/service/IPriority"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type IInspectionType, INSPECTION_TYPE_ENDPOINT } from "../../../../../shared/models/service/IInspectionType"
import {
  type IInspectionStatus,
  INSPECTION_STATUS_ENDPOINT,
} from "../../../../../shared/models/service/IInspectionStatus"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FormatNumberAndError from "../../../../../shared/components/format/FormatNumberAndError"
import { useMetadataExists } from "../../../../../shared/components/metadata/UseMetadataExists"
import MetadataForm from "../../../../../shared/components/metadata/MetadataForm"
import { useAxiosRequest } from "../../../../../shared/hooks/useAxiosRequest"
import { useTranslation } from "react-i18next"

interface IProps {
  form: UseFormReturn
  inspection?: IInspection | undefined | null
  isEdit?: boolean
}

const accountRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)
const priorityRepository = new RestRepository<IPriority | IListItem>(PRIORITY_ENDPOINT)
const locationRepository = new RestRepository<ILocation | IListItem>(LOCATION_ENDPOINT)
const reportFormatRepository = new RestRepository<IReportFormat | IListItem>(REPORT_FORMAT_ENDPOINT)
const reportWriterFormatRepository = new RestRepository<IReportWriterFormat | IListItem>(REPORT_WRITER_FORMAT_ENDPOINT)
const vendorRepository = new RestRepository<IVendor | IListItem>(VENDOR_ENDPOINT)
const serviceUserRepository = new RestRepository<IServiceUser | IListItem>(SERVICE_USER_ENDPOINT)
const typeRepository = new RestRepository<IInspectionType | IListItem>(INSPECTION_TYPE_ENDPOINT)
const statusRepository = new RestRepository<IInspectionStatus | IListItem>(INSPECTION_STATUS_ENDPOINT)

/**
 * Use this forms to add or edit an inspection.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const InspectionForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspection, form, isEdit = false } = props
  const { t } = useTranslation()

  const [account, setAccount] = useSelectFilteredSingle("account", form)
  const [location, setLocation] = useSelectFilteredSingle("location", form)
  const [priority, setPriority] = useSelectFilteredSingle("priority", form)
  const [reportFormat, setReportFormat] = useSelectFilteredSingle("report_format", form)
  const [reportFormat1, setReportFormat1] = useState<IReportFormat | null>(null)
  const [reportWriterFormat, setReportWriterFormat] = useSelectFilteredSingle("report_writer_format", form)
  const [vendor, setVendor] = useSelectFilteredSingle("vendor", form)
  const [vendorMember, setVendorMember] = useSelectFilteredSingle("vendor_member", form)
  const [completedBy, setCompletedBy] = useSelectFilteredSingle("completed_by", form)
  const [inspectionType, setInspectionType] = useSelectFilteredSingle("inspection_type", form)
  const [inspectionStatus, setInspectionStatus] = useSelectFilteredSingle("inspection_status", form)
  const metadataExists = useMetadataExists("inspection")

  const axiosRequest = useAxiosRequest()

  const handleReportFormat = useCallback(async (reportFormat2: IListItem | null) => {
    setReportFormat(null)
    setReportFormat1(null)
    if (reportFormat2 !== null) {
      await axiosRequest.callRequest(async () => {
        const response = await reportFormatRepository.read(reportFormat2.id)
        setReportFormat(response)
        setReportFormat1(response as IReportFormat)
        const reportWriterFormat1 = (response as IReportFormat)?.report_writer_format
        const reportWriterFormat2: IListItem | null = reportWriterFormatAsListItem(reportWriterFormat1)
        setReportWriterFormat(reportWriterFormat2)
      })
    }
  }, [])

  const hasReportWriterFormat = useMemo(() => {
    return reportFormat1?.use_report_writer === true
  }, [reportFormat1, reportWriterFormat])

  useLoadFormData<IInspection>(
    (data: IInspection) => {
      form.setValue("id", data.id)
      form.setValue("completed", data.completed)
      form.setValue("notes", data.notes)
      form.setValue("fee", data.fee)

      form.setValue("vendor_due_date", data.vendor_due_date)
      form.setValue("vendor_site_visit_date", data.vendor_site_visit_date)
      form.setValue("vendor_submitted_date", data.vendor_submitted_date)
      form.setValue("vendor_returned_date", data.vendor_returned_date)
      form.setValue("metadata_data", data.metadata_data)

      setAccount(data.account)
      setLocation(data.location)
      setPriority(data.priority)
      setInspectionStatus(data.inspection_status)
      setInspectionType(data.inspection_type)
      setReportFormat(data.report_format)
      setReportFormat1(data.report_format)
      const reportWriterFormat2: IListItem | null = reportWriterFormatAsListItem(data.report_writer_format)
      setReportWriterFormat(reportWriterFormat2)

      setVendor(data.vendor)
      setVendorMember(data.vendor_member)
      setCompletedBy(data.completed_by)
    },
    inspection,
    isEdit,
    form.setValue,
  )

  return (
    <>
      {isEdit && <FhMuiHiddenField control={form.control} />}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
          <ItemViewerDrawer title={t("Vendor")} prefix={ItemPrefixes.vendor} infoView={VendorInfo} />
          <ItemViewerDrawer title={t("Service User")} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
          <ItemViewerDrawer
            title={t("Report Format")}
            prefix={ItemPrefixes.reportFormat}
            infoView={ReportWriterFormatInfo}
          />
          <PaperLocal>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="account"
                  defaultValue={account}
                  repository={accountRepository}
                  onChange={setAccount}
                  control={form.control}
                />
              </Grid>
              <Grid item xs={12}>
                {account !== null && (
                  <SelectFilteredSingle
                    name="location"
                    defaultValue={location}
                    filters={[{ field: "accounts", value: account.id }]}
                    repository={locationRepository}
                    onChange={setLocation}
                    infoViewPrefix={ItemPrefixes.location}
                    control={form.control}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectFilteredSingle
                  name="priority"
                  defaultValue={priority}
                  filters={[{ field: "priority_type", value: PriorityTypes.INSPECTION }]}
                  repository={priorityRepository}
                  onChange={setPriority}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectFilteredSingle
                  name="inspection_status"
                  defaultValue={inspectionStatus}
                  repository={statusRepository}
                  onChange={setInspectionStatus}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="inspection_type"
                  label={t("Type")}
                  defaultValue={inspectionType}
                  repository={typeRepository}
                  onChange={setInspectionType}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="report_format"
                  defaultValue={reportFormat}
                  repository={reportFormatRepository}
                  onChange={handleReportFormat}
                  infoViewPrefix={ItemPrefixes.reportFormat}
                  control={form.control}
                />
              </Grid>
              {hasReportWriterFormat && (
                <Grid item xs={12}>
                  <SelectFilteredSingle
                    name="report_writer_format"
                    defaultValue={reportWriterFormat}
                    filters={[{ field: "report_format", value: reportFormat?.id }]}
                    repository={reportWriterFormatRepository}
                    onChange={setReportWriterFormat}
                  />
                </Grid>
              )}
            </Grid>
          </PaperLocal>
          <PaperLocal sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <FhMuiTextField control={form.control} name="fee" defaultValue={0} formatter={FormatNumberAndError} />
              </Grid>
              <Grid item xs={12} md={7}>
                <FhMuiDateField control={form.control} name="vendor_due_date" />
              </Grid>
              <Grid item xs={12} md={7}>
                <FhMuiDateField control={form.control} name="vendor_site_visit_date" />
              </Grid>
              <Grid item xs={12} md={7}>
                <FhMuiDateField control={form.control} name="vendor_submitted_date" />
              </Grid>
              <Grid item xs={12} md={7}>
                <FhMuiDateField control={form.control} name="vendor_returned_date" />
              </Grid>
            </Grid>
          </PaperLocal>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaperLocal>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectFilteredSingle
                      name="vendor"
                      defaultValue={vendor}
                      repository={vendorRepository}
                      onChange={setVendor}
                      infoViewPrefix={ItemPrefixes.vendor}
                    />
                  </Grid>
                  {vendor !== null && (
                    <Grid item xs={12}>
                      <SelectFilteredSingle
                        name="vendor_member"
                        label={t("Assigned To")}
                        defaultValue={vendorMember}
                        filters={[{ field: "vendor_members", value: vendor.id }]}
                        repository={serviceUserRepository}
                        onChange={setVendorMember}
                        infoViewPrefix={ItemPrefixes.serviceUser}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} />
                  <Grid item xs={12} md={5}>
                    <FhMuiDateField control={form.control} name="completed" />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <SelectFilteredSingle
                      name="completed_by"
                      defaultValue={completedBy}
                      repository={serviceUserRepository}
                      onChange={setCompletedBy}
                    />
                  </Grid>
                </Grid>
              </PaperLocal>
            </Grid>
            {metadataExists && (
              <Grid item xs={12}>
                <PaperLocal>
                  <MetadataForm modelName="inspection" form={form} />
                </PaperLocal>
              </Grid>
            )}
            <Grid item xs={12}>
              <PaperLocal>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FhMuiRichTextField control={form.control} name="notes" />
                  </Grid>
                </Grid>
              </PaperLocal>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default InspectionForm
