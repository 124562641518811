import React from "react"
import { Alert, Box, Grid, List, Paper, useMediaQuery, useTheme } from "@mui/material"
import ErrorMessage from "../ErrorMessage"
import ListPaging from "../tables/TablePaging"
import { type IUseApiPagedResultsResponse } from "../../hooks/useApiPagedLocal"
import { useTranslation } from "react-i18next"

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<any>
  children?: React.JSX.Element[] | React.JSX.Element | undefined
  show?: boolean
}

/**
 * Displays list data using Material UI components.
 *
 * @param {IProps} props - The properties for displaying list data.
 * @returns {React.ReactElement} - The list data component.
 */
const ListData: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, children, show = false } = props
  const { loading, error, data } = pagingResults

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const showNoneFound = !loading && (data === undefined || data.results.length === 0)
  const { t } = useTranslation()

  return isSmall || show ? (
    <Grid item xs={12}>
      <Paper sx={{ p: 0 }}>
        {error !== undefined && (
          <Box sx={{ m: 1, mt: 1 }}>
            <ErrorMessage error={error} />
          </Box>
        )}

        <List disablePadding>{children}</List>

        {showNoneFound && (
          <Box sx={{ pt: 0.25 }}>
            <Alert color="info" sx={{ m: 1 }}>
              {t("No results found.")}
            </Alert>
          </Box>
        )}

        <Box sx={{ p: 0.25 }}>
          <ListPaging pagingResults={pagingResults} size={isSmall || show ? "small" : "medium"} />
        </Box>
      </Paper>
    </Grid>
  ) : (
    <></>
  )
}

export default ListData
