import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import ServiceUserForm from "./components/ServiceUserForm"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../shared/models/service/IServiceUser"
import { SERVICE_USER_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)
const redirectView = SERVICE_USER_VIEW_URL

/**
 * This page will allow adding of a service user.
 *
 * @returns {React.FC} the service user add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()

  const props: IUseApiAddProps<IServiceUser> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const { saving, handleAdd, connectionError } = useApiAdd<IServiceUser>(props)
  const { t } = useTranslation()

  const handleSave = useCallback(
    async (serviceUser: IServiceUser) => {
      serviceUser.latitude = serviceUser.latitude === "" ? 0 : serviceUser.latitude
      serviceUser.longitude = serviceUser.longitude === "" ? 0 : serviceUser.longitude
      serviceUser.zoom = serviceUser.zoom === "" ? 0 : serviceUser.zoom
      await handleAdd(serviceUser)
    },
    [handleAdd],
  )

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title={t("Add Service User")} loading={saving} errors={form.formState.errors} />
        <FormErrors connectionError={connectionError} />
        <FormBox form={form} onSubmit={handleSave}>
          <ServiceUserForm form={form} />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default AddPage
