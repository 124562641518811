import React, { useCallback } from "react"
import { Grid } from "@mui/material"
import { type ILocationImpairment, LOCATION_IMPAIRMENT_ENDPOINT } from "../../../models/service/ILocationImpairment"
import { RestRepository } from "../../../repositories/RestRepository"
import { useApiRead } from "../../../hooks/useApiRead"
import ViewProperty from "../../../components/ViewProperty"
import FormatDate from "../../../components/format/FormatDate"
import { LoadingButton } from "@mui/lab"
import ViewListItems from "../../../components/display/ViewListItems"
import useApiAction, { type IUseApiActionProps } from "../../../hooks/useApiAction"
import { type IInfoViewProps } from "../../../components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../components/item_viewer/ItemViewerTitle"
import { LOCATION_IMPAIRMENT_VIEW_URL } from "../../../../apps/admin/config/urls"
import TruncateText from "../../../components/TruncateText"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<ILocationImpairment>(LOCATION_IMPAIRMENT_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const ImpairmentInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const apiRead = useApiRead<ILocationImpairment>({ apiFunction: repository.read, itemId: infoId })
  const { data } = apiRead

  const { t } = useTranslation()

  const actionProps: IUseApiActionProps<ILocationImpairment> = { repository, itemId: data?.id }
  const apiAction = useApiAction<ILocationImpairment>(actionProps)

  const handleToggleCompleted = useCallback(async () => {
    await apiAction.callAction("toggle_completed")
    await apiRead.call()
  }, [apiAction.callAction])

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle to={`${LOCATION_IMPAIRMENT_VIEW_URL}/${data.id}`}>
              <TruncateText>{data.name}</TruncateText>
            </ItemViewerTitle>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Location")}>
              <ViewListItems>{data.location}</ViewListItems>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Start Date")}>
              <FormatDate value={data.start_date} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("End Date")}>
              <FormatDate value={data.end_date} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Type")}>{data.impairment_type?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Status")}>{data.status?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("System Type")}>{data.system_type?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("System Name")}>{data.system_name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Tags")}>{data.tags.map(tag => tag.name).join(", ")}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Completed")}>
              <LoadingButton
                onClick={handleToggleCompleted}
                color={data.completed === null ? "primary" : "error"}
                loading={apiAction.saving}>
                {data.completed === null ? t("Complete") : t("Reopen")}
              </LoadingButton>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Completed By")} show={data.completed !== null}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs>
                  <FormatDate value={data.completed} />
                </Grid>
                <Grid item>
                  <ViewListItems>{data.completed_by}</ViewListItems>
                </Grid>
              </Grid>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Description")} vertical>
              {data.description}
            </ViewProperty>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ImpairmentInfo
