import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IPolicy, POLICY_ENDPOINT } from "../../../../../shared/models/service/IPolicy"
import { POLICY_VIEW_URL } from "../../../config/urls"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import FormatDate from "../../../../../shared/components/format/FormatDate"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import { useTranslation } from "react-i18next"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"

const repository = new RestRepository<IPolicy>(POLICY_ENDPOINT)

/**
 * Renders a page to view policy details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const PolicyInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const { data } = useApiRead<IPolicy>({ apiFunction: repository.read, itemId: infoId })
  const { t } = useTranslation()

  return (
    <>
      {data !== undefined && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ItemViewerTitle to={`${POLICY_VIEW_URL}/${infoId}`}>{data.name}</ItemViewerTitle>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Start Date")}>
                <FormatDate value={data.start_date} />
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("End Date")}>
                <FormatDate value={data.end_date} />
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Description")}>
                <ViewRichText>{data.description}</ViewRichText>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Notify Days Before End Date")}>{data.notify_days_before_end_date}</ViewProperty>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default PolicyInfo
