import React, { useCallback } from "react"
import { Divider, Grid } from "@mui/material"

import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { INSPECTION_VIEW_URL } from "../../../config/urls"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../../shared/models/service/IInspection"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import FormatDate from "../../../../../shared/components/format/FormatDate"
import { ItemPrefixes } from "../../../../../config/config"
import ViewListItems from "../../../../../shared/components/display/ViewListItems"
import ViewMap from "../../../../../shared/components/display/ViewMap"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import useApiAction, { type IUseApiActionProps } from "../../../../../shared/hooks/useApiAction"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import TruncateText from "../../../../../shared/components/TruncateText"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"

const repository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)

/**
 * Renders a page to view inspection details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const InspectionInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const apiRead = useApiRead<IInspection>({ apiFunction: repository.read, itemId: infoId })
  const { data } = apiRead

  const { t } = useTranslation()

  const actionProps: IUseApiActionProps<IInspection> = { repository, itemId: data?.id }
  const apiAction = useApiAction<IInspection>(actionProps)

  const handleToggleCompleted = useCallback(async () => {
    await apiAction.callAction("toggle_completed")
    await apiRead.call()
  }, [apiAction.callAction])

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle to={`${INSPECTION_VIEW_URL}/${data.id}`}>
              <TruncateText>{data.name}</TruncateText>
            </ItemViewerTitle>
          </Grid>
          <Grid item xs={12}>
            <ViewMap place={data.location} places={data.buildings} />
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Account")}>{data.account?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Location")}>
              <ViewListItems>{data.location}</ViewListItems>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Type")}>{data.inspection_type?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Report Format")}>{data.report_format?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Buildings")}>{data.buildings.length}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Priority")}>{data.priority?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Vendor")}>{data.vendor?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Vendor Member")}>
              <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.vendor_member}</ViewListItems>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Vendor Due Date")}>
              <FormatDate value={data.vendor_due_date} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Notes")}>
              <ViewRichText>{data.notes}</ViewRichText>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Vendor Site Visit Date")}>
              <FormatDate value={data.vendor_site_visit_date} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Vendor Submitted Date")}>
              <FormatDate value={data.vendor_submitted_date} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Vendor Returned Date")}>
              <FormatDate value={data.vendor_returned_date} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Completed")}>
              <LoadingButton
                onClick={handleToggleCompleted}
                color={data.completed === null ? "primary" : "error"}
                loading={apiAction.saving}>
                {data.completed === null ? t("Complete") : t("Reopen")}
              </LoadingButton>
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Completed By")} show={data.completed !== null}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs>
                  <FormatDate value={data.completed} />
                </Grid>
                <Grid item>
                  <ViewListItems>{data.completed_by}</ViewListItems>
                </Grid>
              </Grid>
            </ViewProperty>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default InspectionInfo
