import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Drawer,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { type IFile } from "../../models/service/IFile"
import useEffectAsync from "../../hooks/useEffectAsync"
import { useOnDemandPaged } from "../../hooks/useApiPagedLocal"
import { type IFilter } from "../../models/components/IFilter"
import ViewLoading from "../ViewLoading"
import TablePaging from "../tables/TablePaging"
import { nameToLabel } from "../../utilities/form_utility"
import FileAddEdit from "./FileAddEdit"
import { RestRepository } from "../../repositories/RestRepository"
import { useTranslation } from "react-i18next"

export interface IImageChooserContext {
  itemId?: string | number | undefined
  drawerOpen?: boolean
  setDrawerOpen?: (open: boolean) => void
  onClose?: () => void
  closeOnFileSelect: boolean
  fileEndpoint?: string
  filters?: IFilter[]
  onFileSelected?: (file: IFile) => void
  fileSelected?: IFile | null
}

export const ImageChooserContext = createContext<IImageChooserContext>({ closeOnFileSelect: true })

interface IProps {
  itemId: string | number | undefined
  field: string
  fileEndpoint: string
  closeOnFileSelect?: boolean
  children?: React.JSX.Element | React.JSX.Element[] | undefined
}

/**
 * FileChooserProvider is a React function component that provides a file chooser context to its children.
 * It allows the user to select a file and handles the state of the file chooser.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered file chooser component with the provided children.
 */
export const ImageChooserProvider: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { itemId, field, fileEndpoint, children, closeOnFileSelect = true } = props

  const filters: IFilter[] = [
    {
      canBeDelete: false,
      title: nameToLabel(field),
      display: `This ${nameToLabel(field)}`,
      field,
      value: itemId,
    },
    {
      canBeDelete: false,
      title: "Images",
      display: "Images",
      field: "images",
      value: "true",
    },
  ]

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [fileSelected, setFileSelected] = useState<IFile | null>(null)

  const handleCloseChooser = useCallback(() => {
    setDrawerOpen(false)
    setFileSelected(null)
  }, [])

  const handleFileSelected = useCallback((file: IFile) => {
    setFileSelected(file)
  }, [])

  const fileChooserContext: IImageChooserContext = {
    itemId,
    drawerOpen,
    setDrawerOpen,
    onClose: handleCloseChooser,
    fileSelected,
    fileEndpoint,
    filters,
    onFileSelected: handleFileSelected,
    closeOnFileSelect,
  }

  return (
    <ImageChooserContext.Provider value={fileChooserContext}>
      <ImageChooser />
      {children}
    </ImageChooserContext.Provider>
  )
}

/**
 * Method to render a file chooser component.
 *
 * @returns {React.ReactElement} - The rendered file chooser component.
 */
const ImageChooser: React.FC = (): React.ReactElement => {
  const {
    itemId,
    drawerOpen,
    onClose,
    fileEndpoint = "",
    filters = [],
    onFileSelected,
    closeOnFileSelect,
  } = useContext(ImageChooserContext)

  const [search, setSearch] = useState<string>("")
  const [width, setWidth] = useState<number>(450)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const repository = useMemo(() => new RestRepository<IFile>(fileEndpoint), [fileEndpoint])

  const pagingResults = useOnDemandPaged<IFile>(fileEndpoint, filters, "FILE_CHOOSER")
  const { data, loading } = pagingResults

  const { t } = useTranslation()

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }, [])

  const handleStartSearch = useCallback(() => {
    const filters: IFilter[] = [{ field: "search", value: search }]
    pagingResults.handleFilter(filters)
  }, [search])

  const handleSelectFile = useCallback(
    (file: IFile) => () => {
      if (closeOnFileSelect) {
        onClose?.()
      }
      onFileSelected?.(file)
    },
    [closeOnFileSelect],
  )

  useEffect(() => {
    setWidth(isSmall ? window.outerWidth - 20 : 450)
  }, [isSmall])

  useEffectAsync(async () => {
    if (drawerOpen === true) {
      await pagingResults.call()
    }
  }, [drawerOpen])

  return (
    <>
      <Drawer open={drawerOpen} onClose={onClose} anchor="right" sx={{ zIndex: 1503 }}>
        <Box sx={{ width }} role="presentation">
          <Box sx={{ p: 2 }}>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs>
                <Typography variant="h4">{t("Choose Image")}</Typography>
              </Grid>
              <Grid item>
                <Button startIcon={<CloseIcon />} onClick={onClose}>
                  {t("Close")}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ViewLoading loading={loading} inline />
              </Grid>
              <Grid item xs={12}>
                <FileAddEdit
                  parentId={itemId}
                  useDropzone
                  onChange={pagingResults.call}
                  repository={repository}
                  fieldName="inspection"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <TextField fullWidth label={t("Search")} value={search} onChange={handleSearch} />
                  </Grid>
                  <Grid item>
                    <Button onClick={handleStartSearch}>{t("Search")}</Button>
                  </Grid>
                </Grid>
              </Grid>
              {!loading && data?.results.length === 0 && (
                <Grid item xs={12}>
                  <Alert severity="info">{t("No images available.")}</Alert>
                </Grid>
              )}
              {!loading && (
                <>
                  {data?.results.map((file: IFile) => {
                    return (
                      <Grid key={file.id} item xs={6}>
                        <Card sx={{ maxWidth: (width - 10) / 2 }}>
                          <CardActionArea onClick={handleSelectFile(file)}>
                            <CardMedia
                              sx={{ minHeight: (width - 10) / 2, width: (width - 10) / 2 }}
                              image={file?.file_thumbnail?.read_url}
                              title={file?.caption}
                            />
                          </CardActionArea>
                          <Box sx={{ p: 1 }}>
                            {file.name}.{file.ext}
                          </Box>
                        </Card>
                      </Grid>
                    )
                  })}
                  <Grid item xs={12}>
                    <TablePaging pagingResults={pagingResults} size="small" />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}
