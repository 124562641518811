import React from "react"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import { VEHICLE_ADD_URL, VEHICLE_VIEW_URL } from "../../../config/urls"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import TableData from "../../../../../shared/components/tables/TableData"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import TableActions from "../../../../../shared/components/tables/TableActions"
import ExcelDownloadButton from "../../../../../shared/components/ExcelDownloadButton"
import type { IFilter } from "../../../../../shared/models/components/IFilter"
import {
  useMetadataTableFilter,
  useMetadataTableHeader,
  useMetadataTableRow,
} from "../../../../../shared/components/metadata/UseMetadataTable"
import { VEHICLE_ENDPOINT, type IVehicle } from "../../../../../shared/models/service/IVehicle"
import { useTranslation } from "react-i18next"
import TableCellCurrency from "../../../../../shared/components/tables/TableCellCurrency"
import FilterCompany from "../../../../../shared/components/filters/FilterCompany"
import TableCellListItems from "../../../../../shared/components/tables/TableCellListItems"
import FilterDate from "../../../../../shared/components/filters/FilterDate"

const repository = new RestRepository<IVehicle>(VEHICLE_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(VEHICLE_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IVehicle> | undefined
  showHeader?: boolean
  gotoFilters?: IFilter[]
}

const modelName = "vehicle"

/**
 * The IndexPage component is a React functional component that renders a page for displaying
 *
 * @param {IProps} props - The properties passed to the component.
 * @param {object} props.pagingResults - The paginated results containing vehicle data to be displayed.
 * @param {boolean} props.showHeader - A flag indicating whether to show the page header.
 * @param {Function} props.gotoFilters - Callback function invoked for navigating to filters.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const VehicleIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, gotoFilters } = props
  const { t } = useTranslation()

  const metadataTableHeader = useMetadataTableHeader(modelName, pagingResults)
  const metadataTableRow = useMetadataTableRow(modelName)
  const metadataTableFilters = useMetadataTableFilter(modelName)

  return (
    <>
      {showHeader && <PageHeader title="Vehicle" titlePlural="Vehicles" toAdd={VEHICLE_ADD_URL} />}
      {pagingResults !== undefined && (
        <>
          <TableData
            pagingResults={pagingResults}
            to={VEHICLE_VIEW_URL}
            repository={gotoRepo}
            gotoFilters={gotoFilters}>
            <TableActions>
              <ExcelDownloadButton repository={repository} paging={pagingResults.paging} />
            </TableActions>
            <FiltersData pagingResults={pagingResults} modelName="vehicle">
              <FilterSearch />
              <FilterCompany field="company" />
              <FilterDate field="purchase_date" title={t("Purchase Date")} />
              <FilterDate field="registration_date" title={t("Registration Date")} />
              <FilterDate field="renewal_date" title={t("Renewal Date")} />
              {metadataTableFilters()}
            </FiltersData>
            <TableHead>
              <TableCellCenter field="name" pagingResults={pagingResults}>
                {t("Name")}
              </TableCellCenter>
              <TableCellCenter field="company" pagingResults={pagingResults}>
                {t("Company")}
              </TableCellCenter>
              <TableCellCenter field="year" pagingResults={pagingResults}>
                {t("Year")}
              </TableCellCenter>
              <TableCellCenter field="make" pagingResults={pagingResults}>
                {t("Make")}
              </TableCellCenter>
              <TableCellCenter field="model" pagingResults={pagingResults}>
                {t("Model")}
              </TableCellCenter>
              <TableCellCenter field="color" pagingResults={pagingResults}>
                {t("Color")}
              </TableCellCenter>
              <TableCellCenter field="license_plate" pagingResults={pagingResults}>
                {t("License Plate")}
              </TableCellCenter>
              <TableCellCenter field="vin" pagingResults={pagingResults}>
                {t("VIN")}
              </TableCellCenter>
              <TableCellCenter field="purchase_date" pagingResults={pagingResults}>
                {t("Purchase Date")}
              </TableCellCenter>
              <TableCellCenter field="purchase_price" pagingResults={pagingResults}>
                {t("Purchase Price")}
              </TableCellCenter>
              <TableCellCenter field="registration_date" pagingResults={pagingResults}>
                {t("Registration Date")}
              </TableCellCenter>
              <TableCellCenter field="renewal_date" pagingResults={pagingResults}>
                {t("Renewal Date")}
              </TableCellCenter>
              {metadataTableHeader()}
              <TableCellCenter field="created" pagingResults={pagingResults}>
                {t("Created")}
              </TableCellCenter>
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IVehicle) => (
                <TableRow key={item.id}>
                  <TableCellData field="name">{item.name}</TableCellData>
                  <TableCellListItems field="company">{item.company}</TableCellListItems>
                  <TableCellData field="year">{item.year}</TableCellData>
                  <TableCellData field="make">{item.make}</TableCellData>
                  <TableCellData field="model">{item.model}</TableCellData>
                  <TableCellData field="color">{item.color}</TableCellData>
                  <TableCellData field="license_plate">{item.license_plate}</TableCellData>
                  <TableCellData field="vin">{item.vin}</TableCellData>
                  <TableCellDate field="purchase_date">{item.purchase_date}</TableCellDate>
                  <TableCellCurrency field="purchase_price">{item.purchase_price}</TableCellCurrency>
                  <TableCellDate field="registration_date">{item.registration_date}</TableCellDate>
                  <TableCellDate field="renewal_date">{item.renewal_date}</TableCellDate>
                  {metadataTableRow(item.metadata_data)}
                  <TableCellDate field="created">{item.created}</TableCellDate>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: IVehicle) => (
              <ListDataItem key={item.id} to={`${VEHICLE_VIEW_URL}/${item.id}`} title={item.name} />
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default VehicleIndex
