import React from "react"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { SUMMARY_ADD_URL, SUMMARY_VIEW_URL } from "../../config/urls"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import TableData from "../../../../shared/components/tables/TableData"
import TableCellDate from "../../../../shared/components/tables/TableCellDate"
import TableCellCenter from "../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import FiltersData from "../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../shared/components/filters/FilterSearch"
import { SUMMARY_ENDPOINT, type ISummary } from "../../../../shared/models/service/ISummary"
import TableCellTruncate from "../../../../shared/components/tables/TableCellTruncate"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<ISummary>(SUMMARY_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(SUMMARY_ENDPOINT)

/**
 * Renders the index page for the summaries.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<ISummary>({ apiFunction: repository.findAll })
  const { data } = pagingResults
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={2}>
      <PageHeader title={t("Summary")} titlePlural={t("Summaries")} toAdd={SUMMARY_ADD_URL} />
      <TableData pagingResults={pagingResults} to={SUMMARY_VIEW_URL} repository={gotoRepo}>
        <FiltersData pagingResults={pagingResults}>
          <FilterSearch />
        </FiltersData>
        <TableHead>
          <TableCellCenter field="name" pagingResults={pagingResults}>
            {t("Name")}
          </TableCellCenter>
          <TableCellCenter field="report_format">{t("Report Format")}</TableCellCenter>
          <TableCellCenter field="notes">{t("Notes")}</TableCellCenter>
          <TableCellCenter field="created" pagingResults={pagingResults}>
            {t("Created")}
          </TableCellCenter>
        </TableHead>
        <TableBody>
          {data?.results.map((item: ISummary) => (
            <TableRow key={item.id}>
              <TableCellData field="name">{item.name}</TableCellData>
              <TableCellData field="report_format">{item.report_format?.name}</TableCellData>
              <TableCellTruncate isRichText field="notes">
                {item.notes}
              </TableCellTruncate>
              <TableCellDate field="created">{item.created}</TableCellDate>
            </TableRow>
          ))}
        </TableBody>
      </TableData>

      <ListData pagingResults={pagingResults}>
        {data?.results.map((item: ISummary) => (
          <ListDataItem key={item.id} to={`${SUMMARY_VIEW_URL}/${item.id}`} title={item.name} />
        ))}
      </ListData>
    </Grid>
  )
}

export default IndexPage
