import React from "react"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../shared/models/service/IInspection"
import InspectionIndex from "./components/InspectionIndex"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../config/config"
import LocationInfo from "../locations/components/LocationInfo"
import { useTranslation } from "react-i18next"
import useLocationFilter from "../../../../shared/hooks/useLocationFilter"
import { CHART_FILTER_TITLE } from "../../../../shared/components/widgets/prebuilt/ChartBuilderWidget"

const repository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)

/**
 * Renders the index page for the inspections.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IInspection>({
    apiFunction: repository.findAll,
    dontCallOnMount: true,
  })
  useLocationFilter(pagingResults, CHART_FILTER_TITLE)
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={2}>
      <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
      <InspectionIndex pagingResults={pagingResults} />
    </Grid>
  )
}

export default IndexPage
