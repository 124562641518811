import DashboardIcon from "@mui/icons-material/Dashboard"
import {
  ACCOUNT_INDEX_URL,
  ADDONS_INDEX_URL,
  COMPANY_INDEX_URL,
  CONTACT_INDEX_URL,
  DASHBOARD_URL,
  EMAIL_TEMPLATE_INDEX_URL,
  FILE_TAG_INDEX_URL,
  IMPORT_URL,
  INSPECTION_INDEX_URL,
  INSPECTION_RECOMMENDATION_INDEX_URL,
  LOCATION_IMPAIRMENT_INDEX_URL,
  LOCATION_INDEX_URL,
  MAP_URL,
  OCCUPANCY_INDEX_URL,
  POLICY_INDEX_URL,
  RECOMMENDATION_INDEX_URL,
  REPORT_FORMAT_INDEX_URL,
  REST_API_KEY_INDEX_URL,
  SERVICE_USER_INDEX_URL,
  SETTING_VIEW_URL,
  SUMMARY_INDEX_URL,
  VEHICLE_INDEX_URL,
  VENDOR_INDEX_URL
} from "./urls"
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload"
import CarRentalIcon from "@mui/icons-material/CarRental"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import MapIcon from "@mui/icons-material/Map"
import SavedSearchIcon from "@mui/icons-material/SavedSearch"
import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import RecommendIcon from "@mui/icons-material/Recommend"
import ContactsIcon from "@mui/icons-material/Contacts"
import SummarizeIcon from "@mui/icons-material/Summarize"
import SettingsIcon from "@mui/icons-material/Settings"
import LibraryAddIcon from "@mui/icons-material/LibraryAdd"
import PolicyIcon from "@mui/icons-material/Policy"
import { type ISideNav } from "../../../shared/components/SideNavMenu"
import { ROLE_SYSTEM_MANAGER_GROUP } from "../../../config/permissions"
import {
  ADDON_COMPANIES,
  ADDON_IMPAIRMENTS,
  ADDON_SUMMARIES,
  ADDON_UI_IMPORTER,
  ADDON_VEHICLES
} from "../../../config/config"

import { t } from "i18next"

/**
 * Represents the side navigation menu.
 *
 * @param {ISideNav[] | null} addonMenuItems - are additional menu items to be displayed.
 * @returns {Array<ISideNav | null>} - The children menu items.
 */
export const navMenu = (addonMenuItems: ISideNav[] | null = null): Array<ISideNav | null | boolean> => {
  return [
    {
      icon: DashboardIcon,
      url: DASHBOARD_URL,
      name: t("Dashboard"),
    },
    {
      icon: AssuredWorkloadIcon,
      url: ACCOUNT_INDEX_URL,
      name: t("Accounts"),
    },
    {
      icon: LocationCityIcon,
      url: LOCATION_INDEX_URL,
      name: t("Locations"),
    },
    {
      icon: MapIcon,
      url: MAP_URL,
      name: t("Map"),
    },
    {
      icon: SavedSearchIcon,
      url: INSPECTION_INDEX_URL,
      name: t("Inspections"),
    },
    {
      icon: RecommendIcon,
      url: INSPECTION_RECOMMENDATION_INDEX_URL,
      name: t("Inspection Recs"),
    },
    {
      icon: ReportProblemIcon,
      url: LOCATION_IMPAIRMENT_INDEX_URL,
      name: t("Impairments"),
      addon: ADDON_IMPAIRMENTS
    },
    {
      icon: SummarizeIcon,
      url: SUMMARY_INDEX_URL,
      name: t("Summaries"),
      addon: ADDON_SUMMARIES
    },
    {
      icon: PolicyIcon,
      url: POLICY_INDEX_URL,
      name: t("Policies"),
    },
    {
      icon: ContactsIcon,
      url: CONTACT_INDEX_URL,
      name: t("Contacts"),
    },
    {
      icon: CarRentalIcon,
      url: VEHICLE_INDEX_URL,
      name: t("Vehicles"),
      addon: ADDON_VEHICLES
    },
    addonMenuItems !== null ? null : false,
    addonMenuItems !== null
      ? {
          icon: LibraryAddIcon,
          url: ADDONS_INDEX_URL,
          name: t("Addons"),
          children: addonMenuItems,
        }
      : null,
    addonMenuItems !== null ? null : false,
    {
      icon: SettingsIcon,
      url: SETTING_VIEW_URL,
      name: t("Settings"),
      accessGroups: [ROLE_SYSTEM_MANAGER_GROUP],
    },
    {
      url: REPORT_FORMAT_INDEX_URL,
      name: t("Report Formats"),
      accessGroups: [ROLE_SYSTEM_MANAGER_GROUP],
    },
    { url: FILE_TAG_INDEX_URL, name: t("File Tags") },
    {
      url: COMPANY_INDEX_URL,
      name: t("Companies"),
      accessGroups: [ROLE_SYSTEM_MANAGER_GROUP],
      addon: ADDON_COMPANIES
    },
    {
      url: EMAIL_TEMPLATE_INDEX_URL,
      name: t("Email Templates"),
      accessGroups: [ROLE_SYSTEM_MANAGER_GROUP],
    },
    { url: OCCUPANCY_INDEX_URL, name: t("Occupancy") },
    { url: RECOMMENDATION_INDEX_URL, name: t("Recommendations") },
    {
      url: SERVICE_USER_INDEX_URL,
      name: t("Service Users"),
      accessGroups: [ROLE_SYSTEM_MANAGER_GROUP],
    },
    { url: VENDOR_INDEX_URL, name: t("Vendors") },
    {
      url: IMPORT_URL,
      name: t("Import"),
      accessGroups: [ROLE_SYSTEM_MANAGER_GROUP],
      addon: ADDON_UI_IMPORTER
    },
    {
      url: REST_API_KEY_INDEX_URL,
      name: t("API Keys"),
      accessGroups: [ROLE_SYSTEM_MANAGER_GROUP],
    },
  ]
}
