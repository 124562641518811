import React from "react"
import { Container, Grid, Typography } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { type IOccupancy, OCCUPANCY_ENDPOINT } from "../../../../shared/models/service/IOccupancy"
import { OCCUPANCY_EDIT_URL } from "../../config/urls"
import ViewProperty from "../../../../shared/components/ViewProperty"
import CheckMark from "../../../../shared/components/display/CheckMark"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IOccupancy>(OCCUPANCY_ENDPOINT)

/**
 * Renders a page to view an occupancy details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data } = useApiRead<IOccupancy>({ apiFunction: repository.read })
  const { t } = useTranslation()

  return (
    <Container fixed>
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title={t("Occupancy")} toEdit={`${OCCUPANCY_EDIT_URL}/${data.id}`} />
          <Grid item xs={12} lg={7}>
            <PaperLocal>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="textSecondary">
                    {data.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ViewProperty label={t("SIC Code")}>{data.sic_code}</ViewProperty>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ViewProperty label={t("Enabled")}>
                    <CheckMark value={data.enabled} />
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Notes")} vertical>
                    <ViewRichText>{data.notes}</ViewRichText>
                  </ViewProperty>
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
