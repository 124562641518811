import React, { useCallback } from "react"
import { Grid, Tab } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../shared/models/service/IAccount"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatNumber from "../../../../shared/components/format/FormatNumber"
import FormatDate from "../../../../shared/components/format/FormatDate"
import { ACCOUNT_EDIT_URL, ACCOUNT_INDEX_URL } from "../../config/urls"
import PageViewBody from "../../../../shared/components/pages/PageViewBody"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import { useNavigate, useParams } from "react-router-dom"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../shared/models/service/ILocation"
import LocationIndex from "../locations/components/LocationIndex"
import { ACCOUNT_FILE_ENDPOINT, type IFile } from "../../../../shared/models/service/IFile"
import FilesIndex from "../../../../shared/components/files/FilesIndex"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ContactInfo from "../contacts/components/ContactInfo"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { ItemPrefixes } from "../../../../config/config"
import { useTranslation } from "react-i18next"
import LocationInfo from "../locations/components/LocationInfo"
import { type IPolicy, POLICY_ENDPOINT } from "../../../../shared/models/service/IPolicy"
import PolicyIndex from "../policies/components/PolicyIndex"
import TabsList from "../../../../shared/components/tabs/TabsList"
import RemovePolicy from "../../components/vendors/RemovePolicy"
import AddPolicy from "../../components/vendors/AddPolicy"
import { type IFilter } from "../../../../shared/models/components/IFilter"
import MetadataDisplay from "../../../../shared/components/metadata/MetadataDisplay"
import { useMetadataExists } from "../../../../shared/components/metadata/UseMetadataExists"
import ItemDeleteDrawer from "../../../../shared/components/item_delete/ItemDeleteDrawer"
import AccountInfo from "./components/AccountInfo"

const repository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)
const mainRepository = new RestRepository<IMainModel>(ACCOUNT_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(ACCOUNT_FILE_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data, loading, call } = useApiRead<IAccount>({ apiFunction: repository.read })
  const { tab, handleTabChange } = useTabPanel()
  const { id } = useParams()
  const { t } = useTranslation()

  const theFilter: IFilter[] = [
    {
      field: "accounts",
      value: id,
      title: "Account",
      display: "This Account",
      canBeDelete: false,
    },
  ]

  const theFilesFilter: IFilter[] = [
    {
      field: "account",
      value: id,
      title: "Account",
      display: "This Account",
      canBeDelete: false,
    },
  ]
  const filesPagingResults = useOnDemandPaged<IFile>(ACCOUNT_FILE_ENDPOINT, theFilesFilter, "FILES")
  const locationPagingResults = useOnDemandPaged<ILocation>(LOCATION_ENDPOINT, theFilter, "LOCATIONS")
  const policyPagingResults = useOnDemandPaged<IPolicy>(POLICY_ENDPOINT, theFilter, "POLICY")

  const metadataExists = useMetadataExists("account")

  const navigate = useNavigate()
  const handleDelete = useCallback(() => {
    navigate(ACCOUNT_INDEX_URL)
  }, [])

  return (
    <PageViewBody loading={loading}>
      {data !== undefined && (
        <>
          <PageHeader
            title={t("Account")}
            titlePlural={`${data.name} ${data.identifier}`}
            onRefresh={call}
            extraOptions={
              <ItemDeleteDrawer
                itemId={id}
                onDelete={handleDelete}
                infoView={AccountInfo}
                repository={mainRepository}
                name="account"
              />
            }
            toEdit={`${ACCOUNT_EDIT_URL}/${data.id}`}
          />
          <Grid item xs={12}>
            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Overview")} value={0} />
              <Tab label={t("Locations")} value={1} />
              <Tab label={t("Policies")} value={2} />
              <Tab label={t("Files")} value={3} />
            </TabsList>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tab} index={0}>
              <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
              <ItemViewerDrawer title={t("Contact")} prefix={ItemPrefixes.contact} infoView={ContactInfo} />
              <ItemViewerDrawer
                title={t("Service User")}
                prefix={ItemPrefixes.serviceUser}
                infoView={ServiceUserInfo}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PaperLocal>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <ViewProperty label={t("Locations")}>
                              <FormatNumber value={data.location_count} prefixUnits={false} />
                            </ViewProperty>
                          </Grid>
                          <Grid item xs={12}>
                            <ViewProperty label={t("Created")}>
                              <FormatDate value={data.created} />
                            </ViewProperty>
                          </Grid>
                        </Grid>
                      </PaperLocal>
                    </Grid>
                    {metadataExists && (
                      <Grid item xs={12}>
                        <PaperLocal>
                          <MetadataDisplay modelName="account" data={data.metadata_data} />
                        </PaperLocal>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <PaperLocal>
                        <ViewProperty label={t("Notes")} vertical>
                          <ViewRichText>{data.notes}</ViewRichText>
                        </ViewProperty>
                      </PaperLocal>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Account Managers")}>
                          <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.account_managers}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Brokers")}>
                          <ViewListItems prefix={ItemPrefixes.contact}>{data.brokers}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Primary Contact")}>
                          <ViewListItems prefix={ItemPrefixes.contact}>{data.contact}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Additional Contacts")}>
                          <ViewListItems prefix={ItemPrefixes.contact}>{data.contacts}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1} onChange={locationPagingResults.call}>
              <LocationIndex
                pagingResults={locationPagingResults}
                showHeader={false}
                useInfoView
                gotoFilters={[{ field: "accounts", value: data.id }]}
              />
            </TabPanel>
            <TabPanel value={tab} index={2} onChange={policyPagingResults.call}>
              <PolicyIndex
                pagingResults={policyPagingResults}
                showHeader={false}
                cellActions={(policy: IPolicy) => (
                  <RemovePolicy
                    policy={policy}
                    repository={repository}
                    parentId={data?.id}
                    onComplete={policyPagingResults.call}
                  />
                )}
                tableActions={
                  <AddPolicy repository={repository} parentId={data?.id} onComplete={policyPagingResults.call} />
                }
              />
            </TabPanel>
            <TabPanel value={tab} index={3} onChange={filesPagingResults.call}>
              <FilesIndex
                parentId={id}
                parentFieldName={t("account")}
                repository={filesRepository}
                pagingResults={filesPagingResults}
              />
            </TabPanel>
          </Grid>
        </>
      )}
    </PageViewBody>
  )
}

export default ViewPage
