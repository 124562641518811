import React from "react"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { REPORT_FORMAT_VIEW_URL } from "../../config/urls"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import TableData from "../../../../shared/components/tables/TableData"
import TableCellCenter from "../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import ListProperty from "../../../../shared/components/lists/ListProperty"
import FiltersData from "../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../shared/components/filters/FilterSearch"
import TableCellDate from "../../../../shared/components/tables/TableCellDate"
import TableCellCheckMark from "../../../../shared/components/tables/TableCellCheckMark"
import { type IReportFormat, REPORT_FORMAT_ENDPOINT } from "../../../../shared/models/service/IReportFormat"
import TableCellTruncate from "../../../../shared/components/tables/TableCellTruncate"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IReportFormat>(REPORT_FORMAT_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(REPORT_FORMAT_ENDPOINT)

/**
 * Renders report format page for the report format.
 *
 * @returns {React.ReactElement} The rendered report format page.
 */
const ReportFormatPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IReportFormat>({ apiFunction: repository.findAll })
  const { data } = pagingResults
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={2}>
      <PageHeader title={t("Report Format")} titlePlural={t("Report Formats")} />
      <TableData pagingResults={pagingResults} to={REPORT_FORMAT_VIEW_URL} repository={gotoRepo}>
        <FiltersData pagingResults={pagingResults}>
          <FilterSearch />
        </FiltersData>
        <TableHead>
          <TableCellCenter field="name" pagingResults={pagingResults}>
            {t("Name")}
          </TableCellCenter>
          <TableCellCenter field="use_report_writer" pagingResults={pagingResults}>
            {t("Uses Report Writer")}
          </TableCellCenter>
          <TableCellCenter field="description" pagingResults={pagingResults}>
            {t("Description")}
          </TableCellCenter>
          <TableCellCenter field="created" pagingResults={pagingResults}>
            {t("Created")}
          </TableCellCenter>
        </TableHead>
        <TableBody>
          {data?.results.map((item: IReportFormat) => (
            <TableRow key={item.id}>
              <TableCellData field="name">{item.name}</TableCellData>
              <TableCellCheckMark field="use_report_writer">{item.use_report_writer}</TableCellCheckMark>
              <TableCellTruncate field="description">{item.description}</TableCellTruncate>
              <TableCellDate field="created">{item.created}</TableCellDate>
            </TableRow>
          ))}
        </TableBody>
      </TableData>

      <ListData pagingResults={pagingResults}>
        {data?.results.map((item: IReportFormat) => (
          <ListDataItem key={item.id} to={`${REPORT_FORMAT_VIEW_URL}/${item.id}`} title={item.name}>
            <ListProperty>{item.created}</ListProperty>
          </ListDataItem>
        ))}
      </ListData>
    </Grid>
  )
}

export default ReportFormatPage
