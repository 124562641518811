import React, { useEffect, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import { type IBuilding } from "../../../../../shared/models/service/IBuilding"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import { type IOccupancy, OCCUPANCY_ENDPOINT } from "../../../../../shared/models/service/IOccupancy"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../../shared/models/service/ILocation"
import FhMap from "../../../../../shared/components/forms/FhMap"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import MetadataForm from "../../../../../shared/components/metadata/MetadataForm"
import { useMetadataExists } from "../../../../../shared/components/metadata/UseMetadataExists"
import { useTranslation } from "react-i18next"

interface IProps {
  form: UseFormReturn
  building?: IBuilding | undefined | null
  isEdit?: boolean
  thisLocation?: ILocation
  goSmall?: boolean
}

const locationRepository = new RestRepository<ILocation | IListItem>(LOCATION_ENDPOINT)
const occupancyRepository = new RestRepository<IOccupancy | IListItem>(OCCUPANCY_ENDPOINT)

/**
 * Use this forms to add or edit a building.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const BuildingForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { building, thisLocation, form, isEdit = false, goSmall = false } = props
  const { t } = useTranslation()

  const [location, setLocation] = useSelectFilteredSingle("location", form)
  const [occupancy, setOccupancy] = useSelectFilteredSingle("occupancy", form)
  const [redraw, setRedraw] = useState<boolean>(false)

  const metadataExists = useMetadataExists("building")

  useLoadFormData<IBuilding>(
    (data: IBuilding) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("longitude", data.longitude)
      form.setValue("latitude", data.latitude)
      form.setValue("zoom", data.zoom)
      form.setValue("metadata_data", data.metadata_data)
      form.setValue("notes", data.notes)

      setLocation(data.location)
      setOccupancy(data.occupancy)
      setRedraw(redraw => !redraw)
    },
    building,
    isEdit,
    form.setValue,
  )

  useEffect(() => {
    if (thisLocation !== undefined) {
      setLocation(thisLocation)
      form.setValue("longitude", thisLocation.longitude)
      form.setValue("latitude", thisLocation.latitude)
      form.setValue("zoom", thisLocation.zoom)
    }
  }, [thisLocation])

  return (
    <>
      {isEdit && <FhMuiHiddenField control={form.control} />}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={goSmall ? 12 : 6}>
          <PaperLocal noPaper={goSmall}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {thisLocation !== undefined ? (
                  <ViewProperty label={t("Location")}>{thisLocation.name}</ViewProperty>
                ) : (
                  <SelectFilteredSingle
                    name="location"
                    defaultValue={location}
                    repository={locationRepository}
                    onChange={setLocation}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="occupancy"
                  defaultValue={occupancy}
                  repository={occupancyRepository}
                  onChange={setOccupancy}
                />
              </Grid>
              <Grid item xs={12}>
                <FhMuiRichTextField control={form.control} name="notes" />
              </Grid>
              {metadataExists && (
                <Grid item xs={12}>
                  <MetadataForm modelName="building" form={form} />
                </Grid>
              )}
            </Grid>
          </PaperLocal>
        </Grid>
        <Grid item xs={12} lg={goSmall ? 12 : 6}>
          <PaperLocal noPaper={goSmall}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <FhMuiTextField control={form.control} name="zoom" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FhMuiTextField control={form.control} name="latitude" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FhMuiTextField control={form.control} name="longitude" />
              </Grid>
              <Grid item xs={12}>
                <FhMap form={form} redraw={redraw} />
              </Grid>
            </Grid>
          </PaperLocal>
        </Grid>
      </Grid>
    </>
  )
}

export default BuildingForm
