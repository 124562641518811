import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import EmailTemplateForm from "./components/EmailTemplateForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import ErrorMessage from "../../../../shared/components/ErrorMessage"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import FormBox from "../../../../shared/components/forms/FormBox"
import { EMAIL_TEMPLATE_ENDPOINT, type IEmailTemplate } from "../../../../shared/models/service/IEmailTemplate"
import { EMAIL_TEMPLATE_VIEW_URL } from "../../config/urls"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IEmailTemplate>(EMAIL_TEMPLATE_ENDPOINT)
const redirectView = EMAIL_TEMPLATE_VIEW_URL

/**
 * This page will allow editing of an email template.
 *
 * @returns {React.FC} the email template edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IEmailTemplate>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IEmailTemplate> = {
    apiFunction: repository.edit,
    setError: form.setError,
    redirectView,
  }
  const { saving, handleEdit, connectionError } = useApiEdit<IEmailTemplate>(props)

  const { t } = useTranslation()

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader
          title={t("Edit Email Template")}
          loading={saving || apiRead.loading}
          errors={form.formState.errors}
        />
        <Grid item xs={12}>
          <ErrorMessage error={apiRead.error} />
          <ErrorMessage error={connectionError} />
        </Grid>
        <FormBox form={form} onSubmit={handleEdit}>
          <EmailTemplateForm emailTemplate={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
