import React, { useCallback, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Alert, Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import {
  type ILocation,
  LOCATION_CITY_ENDPOINT,
  LOCATION_COUNTRY_ENDPOINT,
  LOCATION_ENDPOINT,
  LOCATION_STATE_REGION_ENDPOINT,
} from "../../../../../shared/models/service/ILocation"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../../shared/models/service/IAccount"
import SelectFilteredMultiple, {
  useSelectFilteredMultiple,
} from "../../../../../shared/components/forms/SelectFilteredMultiple"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import FhMap from "../../../../../shared/components/forms/FhMap"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../../config/config"
import AddressSearch from "./AddressSearch"
import { SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import ServiceUserInfo from "../../service_users/components/ServiceUserInfo"
import MetadataForm from "../../../../../shared/components/metadata/MetadataForm"
import { useMetadataExists } from "../../../../../shared/components/metadata/UseMetadataExists"
import { type IMainModel } from "../../../../../shared/models/service/IMainModel"
import useMultiFieldDuplicateCheck, {
  type IDuplicateCheckProps,
} from "../../../../../shared/hooks/useMultiFieldDuplicateCheck"
import { useTranslation } from "react-i18next"

interface IProps {
  form: UseFormReturn
  location?: ILocation | undefined | null
  isEdit?: boolean
}

const locationRepository = new RestRepository<IMainModel>(LOCATION_ENDPOINT)

const accountRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)
const serviceUserRepository = new RestRepository<IListItem>(SERVICE_USER_ENDPOINT)
const cityRepository = new RestRepository<IListItem>(LOCATION_CITY_ENDPOINT)
const stateRepository = new RestRepository<IListItem>(LOCATION_STATE_REGION_ENDPOINT)
const countryRepository = new RestRepository<IListItem>(LOCATION_COUNTRY_ENDPOINT)

/**
 * Use this forms to add or edit a location.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const LocationForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { location, form, isEdit = false } = props

  const { t } = useTranslation()
  const duplicateCheckProps1: IDuplicateCheckProps = {
    repository: locationRepository,
    form,
    fields: ["name"],
  }
  const duplicateCheck1 = useMultiFieldDuplicateCheck(duplicateCheckProps1)

  const duplicateCheckProps2: IDuplicateCheckProps = {
    repository: locationRepository,
    form,
    fields: ["address"],
  }
  const duplicateCheck2 = useMultiFieldDuplicateCheck(duplicateCheckProps2)

  const metadataExists = useMetadataExists("location")
  const [addressSearch, setAddressSearch] = useState<string>("")
  const [redraw, setRedraw] = useState<boolean>(false)

  const [accounts, setAccounts] = useSelectFilteredMultiple("accounts", form)
  const [primaryStaff, setPrimaryStaff] = useSelectFilteredSingle("primary_staff", form)
  const [supportStaff, setSupportStaff] = useSelectFilteredMultiple("support_staff", form)

  const [city, setCity] = useSelectFilteredSingle("city", form)
  const [stateRegion, setStateRegion] = useSelectFilteredSingle("state_region", form)
  const [country, setCountry] = useSelectFilteredSingle("country", form)

  useLoadFormData<ILocation>(
    (data: ILocation) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("address", data.address ?? "")
      form.setValue("address_2", data.address_2 ?? "")
      form.setValue("postal_code", data.postal_code ?? "")
      form.setValue("longitude", data.longitude)
      form.setValue("latitude", data.latitude)
      form.setValue("zoom", data.zoom)
      form.setValue("notes", data.notes)

      setAccounts(data.accounts)
      setPrimaryStaff(data.primary_staff)
      setSupportStaff(data.support_staff)
      setCity({ id: data.city, name: data.city })
      setStateRegion({ id: data.state_region, name: data.state_region })
      setCountry({ id: data.country, name: data.country })
      setAddressSearch(data.address)
      setRedraw(redraw => !redraw)
    },
    location,
    isEdit,
    form.setValue,
  )

  const handleAddressSearch = useCallback(() => {
    duplicateCheck2.checkDuplicates()
    setAddressSearch(form.getValues("address") as string)
  }, [form])

  const handleFoundAddress = useCallback(
    (data: ILocation) => {
      form.setValue("address", data.address)
      form.setValue("address_2", data.address_2)
      form.setValue("postal_code", data.postal_code)
      form.setValue("longitude", data.longitude)
      form.setValue("latitude", data.latitude)
      form.setValue("metadata_data", data.metadata_data)
      setCity({ id: data.city, name: data.city })
      setStateRegion({ id: data.state_region, name: data.state_region })
      setCountry({ id: data.country, name: data.country })
      setRedraw(redraw => !redraw)
    },
    [form, redraw],
  )

  const handleRedraw = useCallback(async () => {
    setRedraw(redraw => !redraw)
  }, [redraw])

  return (
    <>
      {isEdit && <FhMuiHiddenField control={form.control} />}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <PaperLocal>
            <ItemViewerDrawer title={t("Service User")} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FhMuiTextField
                  control={form.control}
                  name="name"
                  rules={requiredRule()}
                  onChange={duplicateCheck1.checkDuplicates}
                />
              </Grid>
              {duplicateCheck1.isDuplicate && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    {t("This location might already exist.")} {duplicateCheck1.duplicateName}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <SelectFilteredMultiple
                  name="accounts"
                  defaultValue={accounts}
                  repository={accountRepository}
                  onChange={setAccounts}
                  control={form.control}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="primary_staff"
                  label={t("Primary Staff")}
                  defaultValue={primaryStaff}
                  repository={serviceUserRepository}
                  onChange={setPrimaryStaff}
                  infoViewPrefix={ItemPrefixes.serviceUser}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredMultiple
                  name="support_staff"
                  defaultValue={supportStaff}
                  repository={serviceUserRepository}
                  onChange={setSupportStaff}
                  itemViewPrefix={ItemPrefixes.serviceUser}
                />
              </Grid>
            </Grid>
          </PaperLocal>
          {metadataExists && (
            <PaperLocal sx={{ mt: 2 }}>
              <MetadataForm modelName="location" form={form} />
            </PaperLocal>
          )}
          <PaperLocal sx={{ mt: 2 }}>
            <FhMuiRichTextField control={form.control} name="notes" />
          </PaperLocal>
        </Grid>
        <Grid item xs={12} lg={6}>
          <PaperLocal>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <FhMuiTextField control={form.control} name="address" onChange={handleAddressSearch} />
              </Grid>
              <Grid item>
                <AddressSearch address={addressSearch} onFound={handleFoundAddress} />
              </Grid>
              {duplicateCheck2.isDuplicate && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    {t("This location might already exist.")} {duplicateCheck2.duplicateName}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="address_2" />
              </Grid>
              <Grid item xs={12} lg={8}>
                <SelectFilteredSingle
                  freeSolo
                  showId={false}
                  name="city"
                  defaultValue={city}
                  repository={cityRepository}
                  onChange={setCity}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FhMuiTextField control={form.control} name="postal_code" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SelectFilteredSingle
                  freeSolo
                  showId={false}
                  name="state_region"
                  defaultValue={stateRegion}
                  repository={stateRepository}
                  onChange={setStateRegion}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SelectFilteredSingle
                  freeSolo
                  showId={false}
                  name="country"
                  defaultValue={country}
                  repository={countryRepository}
                  onChange={setCountry}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FhMuiTextField control={form.control} name="zoom" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FhMuiTextField control={form.control} name="latitude" onChange={handleRedraw} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FhMuiTextField control={form.control} name="longitude" onChange={handleRedraw} />
              </Grid>
              <Grid item xs={12}>
                <FhMap form={form} redraw={redraw} />
              </Grid>
            </Grid>
          </PaperLocal>
        </Grid>
      </Grid>
    </>
  )
}

export default LocationForm
