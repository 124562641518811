import React, { useCallback, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Alert, Grid, Tab } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { CONTACT_ENDPOINT, type IContact } from "../../../../../shared/models/service/IContact"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import FhMuiPhoneField from "../../../../../shared/components/forms/FhMuiPhoneField"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import {
  type ILocation,
  LOCATION_CITY_ENDPOINT,
  LOCATION_COUNTRY_ENDPOINT,
  LOCATION_STATE_REGION_ENDPOINT,
} from "../../../../../shared/models/service/ILocation"
import AddressSearch from "../../locations/components/AddressSearch"
import FhMap from "../../../../../shared/components/forms/FhMap"
import { useTranslation } from "react-i18next"
import useMultiFieldDuplicateCheck, {
  type IDuplicateCheckProps,
} from "../../../../../shared/hooks/useMultiFieldDuplicateCheck"
import { type IMainModel } from "../../../../../shared/models/service/IMainModel"
import TabPanel, { useTabPanel } from "../../../../../shared/components/tabs/TabPanel"
import TabsList from "../../../../../shared/components/tabs/TabsList"
import FhMuiCheckboxField from "../../../../../shared/components/forms/FhMuiCheckboxField"
import SelectFilteredMultiple, {
  useSelectFilteredMultiple,
} from "../../../../../shared/components/forms/SelectFilteredMultiple"
import { COMPANY_ENDPOINT, type ICompany } from "../../../../../shared/models/service/ICompany"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes, SelectOptions } from "../../../../../config/config"
import CompanyInfo from "../../companies/components/CompanyInfo"
import ServiceUserInfo from "../../service_users/components/ServiceUserInfo"
import { useSelectOptions } from "../../../../../shared/hooks/useSelectOptions"
import FhMuiSelectField from "../../../../../shared/components/forms/FhMuiSelectField"

const companyRepository = new RestRepository<ICompany | IListItem>(COMPANY_ENDPOINT)
const contactsRepository = new RestRepository<IMainModel>(CONTACT_ENDPOINT)

const cityRepository = new RestRepository<IListItem>(LOCATION_CITY_ENDPOINT)
const stateRepository = new RestRepository<IListItem>(LOCATION_STATE_REGION_ENDPOINT)
const countryRepository = new RestRepository<IListItem>(LOCATION_COUNTRY_ENDPOINT)

interface IProps {
  form: UseFormReturn
  contact?: IContact | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit a contact.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const ContactForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { contact, form, isEdit = false } = props

  const { t } = useTranslation()
  const { tab, handleTabChange } = useTabPanel()

  const duplicateCheckProps: IDuplicateCheckProps = {
    repository: contactsRepository,
    form,
    fields: ["first_name", "last_name"],
  }
  const duplicateCheck = useMultiFieldDuplicateCheck(duplicateCheckProps)
  const { isDuplicate, checkDuplicates, duplicateName } = duplicateCheck

  const licenseStatusOptions = useSelectOptions(SelectOptions.LICENSE_STATUS_OPTIONS)

  const [addressSearch, setAddressSearch] = useState<string>("")
  const [redraw, setRedraw] = useState<boolean>(false)
  const [city, setCity] = useSelectFilteredSingle("city", form)
  const [stateRegion, setStateRegion] = useSelectFilteredSingle("state_region", form)
  const [country, setCountry] = useSelectFilteredSingle("country", form)
  const [companies, setCompanies] = useSelectFilteredMultiple("companies", form)

  useLoadFormData<IContact>(
    (data: IContact) => {
      form.setValue("id", data.id)
      form.setValue("first_name", data.first_name)
      form.setValue("last_name", data.last_name)
      form.setValue("business_name", data.business_name)
      form.setValue("job_title", data.job_title)
      form.setValue("email", data.email)
      form.setValue("phone", data.phone)
      form.setValue("phone_ext", data.phone_ext)
      form.setValue("email_2", data.email_2)
      form.setValue("phone_2", data.phone_2)
      form.setValue("phone_2_ext", data.phone_2_ext)

      form.setValue("address", data.address ?? "")
      form.setValue("address_2", data.address_2 ?? "")
      form.setValue("postal_code", data.postal_code ?? "")
      form.setValue("longitude", data.longitude ?? "")
      form.setValue("latitude", data.latitude ?? "")
      form.setValue("zoom", data.zoom ?? 17)

      setCity(data.city !== null ? { id: data.city, name: data.city } : null)
      setStateRegion(data.state_region !== null ? { id: data.state_region, name: data.state_region } : null)
      setCountry(data.country !== null ? { id: data.country, name: data.country } : null)
      setAddressSearch(data.address)
      setRedraw(redraw => !redraw)

      form.setValue("notes", data.notes)

      form.setValue("is_driver", data.is_driver)
      form.setValue("license_number", data.license_number)
      form.setValue("license_state_region", data.license_state_region)
      form.setValue("license_points", data.license_points)
      form.setValue("license_status", data.license_status)
      form.setValue("driver_notes", data.driver_notes)

      setCompanies(data.companies)
    },
    contact,
    isEdit,
    form.setValue,
  )

  const handleAddressSearch = useCallback(() => {
    setAddressSearch(form.getValues("address") as string)
  }, [form])

  const handleFoundAddress = useCallback(
    (data: ILocation) => {
      form.setValue("address", data.address)
      form.setValue("address_2", data.address_2)
      form.setValue("postal_code", data.postal_code)
      form.setValue("longitude", data.longitude)
      form.setValue("latitude", data.latitude)
      form.setValue("metadata_data", data.metadata_data)
      setCity({ id: data.city, name: data.city })
      setStateRegion({ id: data.state_region, name: data.state_region })
      setCountry({ id: data.country, name: data.country })
      setRedraw(redraw => !redraw)
    },
    [form, redraw],
  )

  const handleRedraw = useCallback(async () => {
    setRedraw(redraw => !redraw)
  }, [redraw])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TabsList value={tab} onChange={handleTabChange}>
          <Tab label={t("Overview")} value={0} />
          <Tab label={t("Driver")} value={1} />
        </TabsList>
      </Grid>
      <Grid item xs={12}>
        <ItemViewerDrawer title={t("Company")} prefix={ItemPrefixes.company} infoView={CompanyInfo} />
        <ItemViewerDrawer title={t("Service User")} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
        <TabPanel value={tab} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <PaperLocal>
                {isEdit && <FhMuiHiddenField control={form.control} />}
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <FhMuiTextField
                      control={form.control}
                      name="first_name"
                      rules={requiredRule()}
                      onChange={checkDuplicates}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FhMuiTextField control={form.control} name="last_name" onChange={checkDuplicates} />
                  </Grid>
                  {isDuplicate && (
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        {t("This contact might already exist.")} {duplicateName}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FhMuiTextField control={form.control} name="job_title" />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiTextField control={form.control} name="business_name" />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectFilteredMultiple
                      name="companies"
                      defaultValue={companies}
                      repository={companyRepository}
                      onChange={setCompanies}
                      itemViewPrefix={ItemPrefixes.company}
                      control={form.control}
                    />
                  </Grid>
                  <Grid item xs={12} lg>
                    <FhMuiPhoneField control={form.control} name="phone" />
                  </Grid>
                  <Grid item>
                    <FhMuiTextField control={form.control} name="phone_ext" />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiTextField control={form.control} name="email" disabled={contact?.service_user !== undefined && contact.service_user !== null} />
                  </Grid>
                  {contact?.service_user !== undefined && contact.service_user !== null && (
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        {t("This contact is linked to a Service User. The email can only be change on the Service User.")}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} lg>
                    <FhMuiPhoneField control={form.control} name="phone_2" />
                  </Grid>
                  <Grid item>
                    <FhMuiTextField control={form.control} name="phone_2_ext" />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiTextField control={form.control} name="email_2" />
                  </Grid>
                </Grid>
              </PaperLocal>
              <PaperLocal sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FhMuiRichTextField control={form.control} name="notes" />
                  </Grid>
                </Grid>
              </PaperLocal>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PaperLocal>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <FhMuiTextField control={form.control} name="address" onChange={handleAddressSearch} />
                      </Grid>
                      <Grid item>
                        <AddressSearch address={addressSearch} onFound={handleFoundAddress} />
                      </Grid>
                      <Grid item xs={12}>
                        <FhMuiTextField control={form.control} name="address_2" />
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <SelectFilteredSingle
                          freeSolo
                          showId={false}
                          name="city"
                          defaultValue={city}
                          repository={cityRepository}
                          onChange={setCity}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FhMuiTextField control={form.control} name="postal_code" />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <SelectFilteredSingle
                          freeSolo
                          showId={false}
                          name="state_region"
                          defaultValue={stateRegion}
                          repository={stateRepository}
                          onChange={setStateRegion}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <SelectFilteredSingle
                          freeSolo
                          showId={false}
                          name="country"
                          defaultValue={country}
                          repository={countryRepository}
                          onChange={setCountry}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FhMuiTextField control={form.control} name="zoom" />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FhMuiTextField control={form.control} name="latitude" onChange={handleRedraw} />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FhMuiTextField control={form.control} name="longitude" onChange={handleRedraw} />
                      </Grid>
                      <Grid item xs={12}>
                        <FhMap form={form} redraw={redraw} />
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <PaperLocal>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FhMuiCheckboxField control={form.control} name="is_driver" />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiTextField control={form.control} name="license_number" />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiTextField control={form.control} name="license_state_region" />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiTextField control={form.control} name="license_points" defaultValue={0} />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiSelectField control={form.control} name="license_status" items={licenseStatusOptions} />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiRichTextField control={form.control} name="driver_notes" />
                  </Grid>
                </Grid>
              </PaperLocal>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default ContactForm
