import React, { useCallback } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Button, Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../../config/config"
import ContactInfo from "../../contacts/components/ContactInfo"
import ServiceUserInfo from "../../service_users/components/ServiceUserInfo"
import { type IRestApiKey, restPermissions } from "../../../../../shared/models/service/IRestApiKey"
import FhMuiCheckboxField from "../../../../../shared/components/forms/FhMuiCheckboxField"
import FhMuiDateField from "../../../../../shared/components/forms/FhMuiDateField"
import { useTranslation } from "react-i18next"

interface IProps {
  form: UseFormReturn
  restApiKey?: IRestApiKey | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit a rest api key.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const RestApiKeyForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { restApiKey, form, isEdit = false } = props
  const { t } = useTranslation()

  useLoadFormData<IRestApiKey>(
    (data: IRestApiKey) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("expiry_date", data.expiry_date)
      form.setValue("revoked", data.revoked)

      for (const permission of restPermissions) {
        form.setValue(`${permission.id}_create`, (data as any)[`${permission.id}_create`])
        form.setValue(`${permission.id}_read`, (data as any)[`${permission.id}_read`])
        form.setValue(`${permission.id}_update`, (data as any)[`${permission.id}_update`])
        form.setValue(`${permission.id}_delete`, (data as any)[`${permission.id}_delete`])
      }
    },
    restApiKey,
    isEdit,
    form.setValue,
  )

  const handleSelectAll = useCallback(() => {
    for (const permission of restPermissions) {
      form.setValue(`${permission.id}_create`, true)
      form.setValue(`${permission.id}_read`, true)
      form.setValue(`${permission.id}_update`, true)
      form.setValue(`${permission.id}_delete`, true)
    }
  }, [form.setValue])

  const handleClearAll = useCallback(() => {
    for (const permission of restPermissions) {
      form.setValue(`${permission.id}_create`, false)
      form.setValue(`${permission.id}_read`, false)
      form.setValue(`${permission.id}_update`, false)
      form.setValue(`${permission.id}_delete`, false)
    }
  }, [form.setValue])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={5}>
        <PaperLocal>
          <ItemViewerDrawer title={t("Contact")} prefix={ItemPrefixes.contact} infoView={ContactInfo} />
          <ItemViewerDrawer title={t("Service User")} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
          {isEdit && <FhMuiHiddenField control={form.control} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12}>
              <FhMuiDateField control={form.control} name="expiry_date" />
            </Grid>
            {isEdit && (
              <Grid item xs={12}>
                <FhMuiCheckboxField control={form.control} name="revoked" />
              </Grid>
            )}
          </Grid>
        </PaperLocal>
      </Grid>
      {isEdit && (
        <Grid item xs={12} lg={12}>
          <PaperLocal>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Button onClick={handleSelectAll}>{t("Select All")}</Button>
                <Button onClick={handleClearAll}>{t("Clear All")}</Button>
              </Grid>
              <Grid item xs={4}>
                <strong>{t("Access")}</strong>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "center" }}>
                <strong>{t("Create")}</strong>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "center" }}>
                <strong>{t("Read")}</strong>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "center" }}>
                <strong>{t("Update")}</strong>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "center" }}>
                <strong>{t("Delete")}</strong>
              </Grid>

              {restPermissions.map(permission => {
                return (
                  <React.Fragment key={permission.id}>
                    <Grid item xs={4}>
                      {permission.name}
                    </Grid>
                    <Grid item xs={2}>
                      <FhMuiCheckboxField control={form.control} name={`${permission.id}_create`} showLabel={false} />
                    </Grid>
                    <Grid item xs={2}>
                      <FhMuiCheckboxField control={form.control} name={`${permission.id}_read`} showLabel={false} />
                    </Grid>
                    <Grid item xs={2}>
                      <FhMuiCheckboxField control={form.control} name={`${permission.id}_update`} showLabel={false} />
                    </Grid>
                    <Grid item xs={2}>
                      <FhMuiCheckboxField control={form.control} name={`${permission.id}_delete`} showLabel={false} />
                    </Grid>
                  </React.Fragment>
                )
              })}
            </Grid>
          </PaperLocal>
        </Grid>
      )}
    </Grid>
  )
}

export default RestApiKeyForm
