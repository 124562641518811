import React, { type ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { type IRiskWriterSection } from "../../models/IRiskWriterSection"
import {
  type IRiskWriterSectionData,
  type ISectionDataTable,
  SectionDataTableDisplayType,
  SectionType,
} from "../../models/IRiskWriterSectionData"
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material"
import { RiskWriterContext } from "../../context/RiskWriterProvider"
import AlertDialog from "../../../AlertDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import { RiskWriterTableBody, RiskWriterTableHeader, useRiskWriterTable } from "../RiskWriterTableEditor"
import { nameToLabel } from "../../../../utilities/form_utility"
import EditIcon from "@mui/icons-material/Edit"
import HtmlToolTip from "../../../HtmlToolTip"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"
import { filterRows } from "../../utilities/table_utilities"
import TabPanel, { useTabPanel } from "../../../tabs/TabPanel"
import ChartEditor from "./charts/ChartEditor"
import ChartViewer from "./charts/ChartViewer"
import TabsList from "../../../tabs/TabsList"
import { useTranslation } from "react-i18next"

interface IProps {
  section: IRiskWriterSection
  content: IRiskWriterSectionData
  viewerOnly: boolean
  small: boolean
}

/**
 * ContentTextEditor is a React functional component that renders a text editor for content.
 *
 * @param {object} props - The props for the component.
 * @param {object} props.section - The section object.
 * @param {object} props.content - The content object.
 * @returns {React.ReactElement} The rendered component.
 */
const ContentTableEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { section, content, viewerOnly, small } = props
  const { currentContent, onEditContent, onChangeContent, onDeleteContent, onContentMove } =
    useContext(RiskWriterContext)
  const [tableName, setTableName] = useState<string>("")
  const { tab, handleTabChange } = useTabPanel({ useSearchParams: false })

  const { getTableRowsData, getTableFormat, getTables, getTableLabel } = useRiskWriterTable()

  const { t } = useTranslation()

  const sectionDataTable = content.data as ISectionDataTable

  const tableFormat = useMemo(() => {
    if (tableName == null) {
      return null
    }
    const tableFormat = getTableFormat(tableName)
    return tableFormat ?? null
  }, [getTableFormat, tableName])

  const tableData = useMemo(() => {
    if (tableName == null) {
      return null
    }
    const tableData1 = getTableRowsData(tableName)
    const limit = sectionDataTable?.limit ?? 20
    if (content?.data !== undefined && content.data !== null && tableFormat !== null) {
      const tableFilters = sectionDataTable.table_filters
      const sortColumnIndex = tableFormat.columns.findIndex(col => col.name === sectionDataTable?.sort_column)
      const sortAsc = sectionDataTable?.sort_asc ?? true

      let tableFiltered = filterRows(tableFilters, tableFormat.columns, tableData1)

      // Sorting logic with null checks
      tableFiltered = tableFiltered.sort((a, b) => {
        const valA = a[sortColumnIndex]
        const valB = b[sortColumnIndex]

        if (valA === null && valB !== null) return sortAsc ? -1 : 1
        if (valA !== null && valB === null) return sortAsc ? 1 : -1
        if (valA === null && valB === null) return 0

        if (typeof valA === "number" && typeof valB === "number") {
          return sortAsc ? valA - valB : valB - valA
        }

        if (typeof valA === "string" && typeof valB === "string") {
          return sortAsc ? valA.localeCompare(valB) : valB.localeCompare(valA)
        }
        return 0
      })

      return tableFiltered.slice(0, limit)
    }
    return tableData1.slice(0, limit)
  }, [getTableRowsData, tableName, content, tableFormat])

  const displayType = useMemo((): SectionDataTableDisplayType => {
    if (content?.data !== undefined) {
      const displayType1 = sectionDataTable?.display_type
      if (displayType1 !== undefined) {
        return displayType1
      }
    }
    return SectionDataTableDisplayType.Table
  }, [content?.data])

  const handleContentMoveUp = useCallback(() => {
    onContentMove?.(content, "up")
  }, [content])

  const handleContentMoveDown = useCallback(() => {
    onContentMove?.(content, "down")
  }, [content])

  const handleEditContent = useCallback(() => {
    onEditContent?.(section, content)
  }, [section, content])

  const handleSaveTable = useCallback(() => {
    const sectionContent: ISectionDataTable = { ...sectionDataTable, table_name: tableName }
    onChangeContent?.(content.name, sectionContent)
  }, [tableName, content])

  const handleDeleteContent = useCallback(async () => {
    onDeleteContent?.(content)
  }, [tableName, content])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTableName(event.target.value)
      const sectionContent: ISectionDataTable = {
        ...sectionDataTable,
        table_name: event.target.value,
        display_type: SectionDataTableDisplayType.Table,
      }
      onChangeContent?.(content.name, sectionContent, false)
    },
    [content],
  )

  const handleChartEditorChange = useCallback(
    (sectionDataTable: ISectionDataTable) => {
      const sectionContent: ISectionDataTable = {
        ...sectionDataTable,
        label_column: sectionDataTable.label_column,
        data_column: sectionDataTable.data_column,
        aggregate: sectionDataTable.aggregate,
        title: sectionDataTable.title,
        show_title: sectionDataTable.show_title,
      }
      onChangeContent?.(content.name, sectionContent, false)
    },
    [content],
  )

  const isEditing = useMemo(() => {
    return content.name === currentContent?.name
  }, [content.name, currentContent?.name])

  useEffect(() => {
    if (content.section_type === SectionType.TABLE_EDITOR) {
      setTableName(sectionDataTable?.table_name)
    }
  }, [content])

  return (
    <>
      {content.section_type === SectionType.TABLE_EDITOR && (
        <Box sx={small ? {} : { m: 1, mr: 0, ml: 0, p: 1, border: "1px dashed #ccc" }}>
          {content.data !== null && !small && (
            <Grid container alignItems="center">
              <Grid item xs>
                {(sectionDataTable.show_title === undefined || sectionDataTable.show_title) && (
                  <Typography variant="h6">
                    {sectionDataTable.title?.length !== undefined && sectionDataTable.title.length > 0
                      ? sectionDataTable.title
                      : getTableLabel(sectionDataTable.table_name)}
                  </Typography>
                )}
              </Grid>
              {tableData?.length !== undefined && <Grid item>Rows: {tableData?.length}</Grid>}
            </Grid>
          )}

          {content.data === null && <Alert severity="warning">{t("Select a table to be displayed.")}</Alert>}

          {tableFormat !== null && tableData !== null && isEditing && (
            <>
              <TabsList value={tab} onChange={handleTabChange}>
                <Tab label={t("Table")} value={0} />
                <Tab label={t("Options")} value={1} />
                {sectionDataTable.display_type !== SectionDataTableDisplayType.Table && (
                  <Tab label={t("Display")} value={2} />
                )}
              </TabsList>
              <TabPanel value={tab} index={0}>
                <TableContainer component={Paper} sx={{ p: 0, mt: 1, maxHeight: 400 }}>
                  <Table stickyHeader>
                    <RiskWriterTableHeader table={tableFormat} />
                    <RiskWriterTableBody table={tableFormat} rows={tableData} />
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ChartEditor
                  content={content}
                  tableFormat={tableFormat}
                  displayType={displayType}
                  sectionDataTable={sectionDataTable}
                  onChange={handleChartEditorChange}
                />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <ChartViewer
                  tableData={tableData}
                  tableFormat={tableFormat}
                  displayType={displayType}
                  sectionDataTable={sectionDataTable}
                />
              </TabPanel>
            </>
          )}
          {tableFormat !== null && tableData !== null && !isEditing && (
            <>
              {sectionDataTable.display_type === SectionDataTableDisplayType.Table ? (
                <TableContainer component={Paper} sx={{ p: 0, mt: 1, maxHeight: 400 }}>
                  <Table stickyHeader>
                    <RiskWriterTableHeader table={tableFormat} />
                    <RiskWriterTableBody table={tableFormat} rows={tableData} />
                  </Table>
                </TableContainer>
              ) : (
                <ChartViewer
                  tableData={tableData}
                  tableFormat={tableFormat}
                  displayType={displayType}
                  sectionDataTable={sectionDataTable}
                  small={small}
                />
              )}
            </>
          )}

          {!isEditing || viewerOnly ? (
            <>
              {content.data !== null && !viewerOnly && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs />
                  <Grid item>
                    <IconButton size="small" onClick={handleEditContent}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid container alignItems="center" spacing={2} sx={{ mt: 1 }}>
                <Grid item>
                  <HtmlToolTip title={t("Move this table up in the section.")}>
                    <IconButton color="primary" onClick={handleContentMoveUp} size="small">
                      <ArrowUpward />
                    </IconButton>
                  </HtmlToolTip>
                  <HtmlToolTip title={t("Move this table down in the section.")}>
                    <IconButton color="primary" onClick={handleContentMoveDown} size="small">
                      <ArrowDownward />
                    </IconButton>
                  </HtmlToolTip>
                </Grid>
                <Grid item xs>
                  <AlertDialog
                    buttonText={t("Delete")}
                    buttonIcon={<DeleteIcon />}
                    onYes={handleDeleteContent}
                    message={t("Are you sure you want to delete this content?")}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    value={tableName}
                    label={t("Select Table")}
                    onChange={handleChange}>
                    {getTables()?.map(table => {
                      return (
                        <MenuItem key={table.name} value={table.name}>
                          {nameToLabel(table.name, table.title)}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item>
                  <Button onClick={handleSaveTable}>{t("Done")}</Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default ContentTableEditor
