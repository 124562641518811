import React, { useCallback, useContext, useMemo } from "react"
import { type IRiskWriterSection } from "../models/IRiskWriterSection"
import { Box, FormControlLabel, Grid, Switch, type Theme, Typography } from "@mui/material"
import { nameToLabel } from "../../../utilities/form_utility"
import type { IRiskWriterSectionData } from "../models/IRiskWriterSectionData"
import RiskWriterContentEditor from "./RiskWriterContentEditor"
import RiskWriterContentBar from "./RiskWriterContentBar"
import { RiskWriterContext } from "../context/RiskWriterProvider"
import _ from "lodash"
import HtmlToolTip from "../../HtmlToolTip"
import InfoIcon from "@mui/icons-material/Info"
import { useTranslation } from "react-i18next"

interface IProps {
  section: IRiskWriterSection
  isSubSection?: boolean
  children?: React.JSX.Element[] | undefined
}

/**
 * Renders a section in the RiskWriter component.
 *
 * @param {IProps} props - The props for the RiskWriterSection component.
 * @param {IRiskWriterSection} props.section - The section object containing section data.
 * @param {React.ReactNode} props.children - The child components to render.
 *
 * @returns {React.ReactElement} - The rendered RiskWriterSection component.
 */
const RiskWriterSection: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { section, isSubSection = false, children } = props

  const { reportWriterData, setReportWriterData } = useContext(RiskWriterContext)
  const { t } = useTranslation()

  const contentsForSection = useCallback(
    (sectionId: string) => {
      return reportWriterData?.sections.filter(content => content.section_name === sectionId)
    },
    [reportWriterData],
  )

  /**
   * Toggle if the string section.name is in reportWriterData.not_needed_sections.
   */
  const handleToggleNotNeeded = useCallback(() => {
    setReportWriterData?.(prevReport => {
      if (prevReport !== null) {
        const newReport = _.cloneDeep(prevReport)

        const { not_needed_sections: notNeededSections = [] } = newReport

        // Checking if section.name exists in the not_needed_sections
        if (notNeededSections.includes(section.name)) {
          // Remove section.name from not_needed_sections if it exists
          newReport.not_needed_sections = notNeededSections.filter(item => item !== section.name)
        } else {
          // Add section.name if it does not exist
          newReport.not_needed_sections = [...notNeededSections, section.name]
        }

        return newReport
      }
      return null
    })
  }, [section, reportWriterData])

  const isNotNeededSection = useMemo((): boolean => {
    if (reportWriterData?.not_needed_sections !== undefined) {
      return reportWriterData.not_needed_sections.includes(section.name)
    }
    return false
  }, [section, reportWriterData])

  return (
    <Box sx={{ mb: 1 }}>
      <Grid container spacing={2} alignItems="end">
        <Grid item xs={12} lg sx={{ color: "primary.main" }}>
          <Typography variant={isSubSection ? "h5" : "h4"} id={`section-${section.name}`} sx={{ mt: 2 }}>
            {nameToLabel(section.name, section.title)}
          </Typography>
        </Grid>
        {section.required ? (
          <Grid item>
            <Box sx={(theme: Theme) => ({ color: theme.palette.error.main, mr: 2 })}>* required</Box>
          </Grid>
        ) : (
          <Grid item>
            <FormControlLabel
              control={<Switch onClick={handleToggleNotNeeded} checked={isNotNeededSection} />}
              label={t("Not Needed")}
            />
          </Grid>
        )}
        {section.helper_text !== null && (
          <Grid item>
            <Box sx={{ ml: -3, mt: 1, cursor: "pointer" }}>
              <HtmlToolTip title={<Box dangerouslySetInnerHTML={{ __html: section.helper_text }} />} placement="left">
                <InfoIcon color="secondary" />
              </HtmlToolTip>
            </Box>
          </Grid>
        )}
      </Grid>
      {!isNotNeededSection && (
        <>
          <Grid container>
            {contentsForSection(section.name)?.map((content: IRiskWriterSectionData) => {
              return (
                <Grid key={content.name} item xs={12} md={section.cols ?? 12}>
                  <RiskWriterContentEditor section={section} content={content} />
                </Grid>
              )
            })}
          </Grid>
          <RiskWriterContentBar section={section} />
          {children}
        </>
      )}
    </Box>
  )
}

export default RiskWriterSection
