import React, { useCallback, useState } from "react"
import DrawerRight from "../../../../shared/components/containers/DrawerRight"
import AddIcon from "@mui/icons-material/Add"
import { Button, Grid } from "@mui/material"
import SelectFilteredSingle from "../../../../shared/components/forms/SelectFilteredSingle"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../shared/models/service/IServiceUser"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import { type IPaging } from "../../../../shared/models/components/IPaging"
import useApiAction, { type IUseApiActionProps } from "../../../../shared/hooks/useApiAction"
import ServiceUserInfo from "../../pages/service_users/components/ServiceUserInfo"
import { useTranslation } from "react-i18next"

const serviceUserListRepository = new RestRepository<IServiceUser | IListItem>(SERVICE_USER_ENDPOINT)

interface IProps {
  onComplete: () => void
  parentId: number
  repository: RestRepository<IMainModel>
}

/**
 * Represents a component for adding a service user.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered component.
 */
const AddServiceUser: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onComplete, parentId, repository } = props
  const { t } = useTranslation()
  const [serviceUser, setServiceUser] = useState<IListItem | null>(null)

  const [closeDrawer, setCloseDrawer] = useState<boolean>(true)

  const actionProps: IUseApiActionProps<IMainModel> = { repository, itemId: parentId }
  const apiAction = useApiAction<IMainModel>(actionProps)

  const handleAddServiceUser = useCallback(async () => {
    if (serviceUser !== null) {
      const paging: IPaging = { filters: [{ field: "id", value: serviceUser.id }] }
      await apiAction.callAction("add_service_user", paging)
      setCloseDrawer(closeDrawer => !closeDrawer)
      onComplete()
    }
  }, [serviceUser])

  return (
    <DrawerRight title={t("Add Service User")} icon={<AddIcon />} closeDrawer={closeDrawer}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <SelectFilteredSingle
            name="service_user"
            defaultValue={serviceUser}
            repository={serviceUserListRepository}
            onChange={setServiceUser}
          />
        </Grid>
        <Grid item xs />
        <Grid item>
          <Button onClick={handleAddServiceUser} disabled={serviceUser === null}>
            Add Service User
          </Button>
        </Grid>
        <Grid item xs={12}>
          {serviceUser !== null && <ServiceUserInfo infoId={serviceUser.id} />}
        </Grid>
      </Grid>
    </DrawerRight>
  )
}

export default AddServiceUser
