import React, { useCallback, useEffect, useState } from "react"
import { Badge, Box, Button, Drawer, Grid, IconButton, Link, Typography, useMediaQuery, useTheme } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { type OverridableStringUnion } from "@mui/types"
import { type ButtonPropsColorOverrides } from "@mui/material/Button/Button"
import useEffectAsync from "../../hooks/useEffectAsync"
import { useTranslation } from "react-i18next"

type TUseDrawerWithUrlResponse = (prefix: string) => string

/**
 * Returns a function that generates a URL with the given prefix and the current search parameters.
 *
 * @returns {TUseDrawerWithUrlResponse} - The generated URL with the prefix and search parameters.
 */
export const useDrawerWithUrl = (): TUseDrawerWithUrlResponse => {
  const location = useLocation()
  const [searchParams] = useSearchParams()

  return useCallback(
    (prefix: string) => {
      return `${location.pathname}/${prefix}?${searchParams.toString()}`
    },
    [location, searchParams],
  )
}

interface IProps {
  title: string
  icon?: React.JSX.Element
  children: React.JSX.Element[] | React.JSX.Element
  showButton?: boolean
  showIconButton?: boolean
  stayOpen?: boolean
  badge?: number
  useLink?: boolean
  closeDrawer?: boolean
  openCloseWithUrl?: boolean
  openUrlPrefix?: string
  onDrawerOpen?: () => Promise<void>
  onDrawerClose?: () => Promise<void>
  buttonColor?: OverridableStringUnion<
    "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    ButtonPropsColorOverrides
  >
  secondDrawer?: boolean
  hasMargin?: boolean
}

const WIDTH = 450

/**
 * Renders a right-side drawer with a button that toggles the drawer's visibility.
 *
 * @param {IProps} props - The props object.
 * @returns {React.ReactElement} - The rendered right-side drawer component.
 */
const DrawerRight: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    title,
    icon,
    children,
    openCloseWithUrl = false,
    openUrlPrefix,
    showButton = true,
    showIconButton = false,
    stayOpen = false,
    badge = 0,
    useLink = false,
    closeDrawer,
    buttonColor,
    onDrawerOpen,
    onDrawerClose,
    secondDrawer = false,
    hasMargin = true,
  } = props
  const [openDrawer, setOpenDrawer] = useState(false)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const [width, setWidth] = useState<number | null>(null)

  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { t } = useTranslation()

  const handleToggle = useCallback(async () => {
    if (openCloseWithUrl && openDrawer && "*" in params) {
      const parentPath = location.pathname.replace("/" + params["*"], "")
      navigate(`${parentPath}?${searchParams.toString()}`, { replace: true })
    }
    setOpenDrawer(open => !open)
    if (!openDrawer) {
      await onDrawerOpen?.()
    } else {
      await onDrawerClose?.()
    }
  }, [openDrawer, openCloseWithUrl, params, location, searchParams, onDrawerOpen, onDrawerClose])

  useEffect(() => {
    setWidth(isSmall ? null : WIDTH)
  }, [isSmall])

  useEffectAsync(async () => {
    setOpenDrawer(false)
    await onDrawerClose?.()
  }, [closeDrawer, onDrawerClose])

  useEffectAsync(async () => {
    if (openCloseWithUrl && openUrlPrefix !== undefined && "*" in params) {
      const parts = params["*"]?.split("/")
      if (parts !== undefined && parts[0] === `${openUrlPrefix}` && parts.length > 0) {
        setOpenDrawer(true)
        await onDrawerOpen?.()
      }
    }
  }, [params, openCloseWithUrl, openUrlPrefix])

  return (
    <>
      {showButton && !openCloseWithUrl && (
        <Badge badgeContent={badge} color="secondary">
          {isSmall || showIconButton ? (
            <IconButton onClick={handleToggle} color={buttonColor}>
              {icon}
            </IconButton>
          ) : (
            <Button startIcon={icon} color={buttonColor} onClick={handleToggle}>
              {title}
            </Button>
          )}
        </Badge>
      )}
      {useLink && !openCloseWithUrl && (
        <Link onClick={handleToggle} sx={{ cursor: "pointer" }}>
          {title}
        </Link>
      )}
      <Drawer
        open={openDrawer || stayOpen}
        onClose={handleToggle}
        anchor="right"
        sx={{ zIndex: secondDrawer ? 1510 : 1410 }}>
        <Box sx={{ width }}>
          <Box sx={{ p: hasMargin ? 2 : 0, pt: 2 }}>
            <Grid container>
              <Grid item xs>
                <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Button startIcon={<CloseIcon />} onClick={handleToggle}>
                  {t("Close")}
                </Button>
              </Grid>
            </Grid>
            {children}
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default DrawerRight
