import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import { type FieldValues, type SubmitHandler, useForm } from "react-hook-form"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../../shared/hooks/useApiEdit"
import FormBox from "../../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../../shared/components/forms/FormErrors"
import RiskWriterForm from "../../../../../shared/components/risk_writer/components/RiskWriterForm"
import { type TRiskWriterFormData } from "../../../../../shared/components/risk_writer/models/TRiskWriterFormData"
import type { IItemEditorProps } from "../../../../../shared/components/item_editor/ItemEditorDialog"
import useEffectAsync from "../../../../../shared/hooks/useEffectAsync"
import ViewLoading from "../../../../../shared/components/ViewLoading"
import { ImageChooserProvider } from "../../../../../shared/components/files/ImageChooser"
import { INSPECTION_FILE_ENDPOINT } from "../../../../../shared/models/service/IFile"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../../shared/models/service/IInspection"
import { type IMainModel } from "../../../../../shared/models/service/IMainModel"
import { LookupProvider } from "../../../../../shared/components/lookup/Lookup"

const repository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)

interface IPatch extends IMainModel {
  risk_writer_brief_data: TRiskWriterFormData
}

const briefRepository = new RestRepository<IPatch>(INSPECTION_ENDPOINT)

/**
 * EditPage method is used to render a page for editing a specific item.
 *
 * @param {IItemEditorProps} props - The props containing the itemId and onSave callback.
 * @returns {React.ReactElement} - The rendered page element.
 */
const InspectionBriefEdit: React.FC<IItemEditorProps> = (props: IItemEditorProps): React.ReactElement => {
  const { itemId, onSave, requestSave, setRequestSave, modelSource } = props
  const form = useForm()
  const apiRead = useApiRead<IInspection>({ apiFunction: repository.read, itemId })

  const propsApi: IUseApiEditProps<IPatch> = {
    apiFunction: briefRepository.patch,
    setError: form.setError,
  }
  const apiEdit = useApiEdit<IPatch>(propsApi)

  const reportWriterFormat = apiRead.data?.report_writer_format?.data

  const handleSave = useCallback(
    async (data: TRiskWriterFormData) => {
      if (apiRead.data !== undefined) {
        const result = await apiEdit.handleEdit({ id: apiRead.data.id, name: "", risk_writer_brief_data: data })
        if (result !== undefined) {
          onSave()
        }
      }
    },
    [apiEdit, apiRead.data],
  )

  useEffectAsync(async () => {
    if (requestSave) {
      await form?.handleSubmit(handleSave as SubmitHandler<FieldValues>)()
      setRequestSave(false)
    }
  }, [requestSave])

  return (
    <Container fixed>
      <ViewLoading loading={apiEdit.saving || apiRead.loading} />
      <Grid container spacing={2}>
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={handleSave} showActionPanel={false}>
          {reportWriterFormat !== undefined && reportWriterFormat !== null && (
            <ImageChooserProvider
              itemId={apiRead.data?.id}
              field={modelSource ?? ""}
              fileEndpoint={INSPECTION_FILE_ENDPOINT}>
              <LookupProvider>
                <RiskWriterForm
                  data={apiRead.data?.risk_writer_brief_data}
                  format={reportWriterFormat}
                  fileEndpoint={INSPECTION_FILE_ENDPOINT}
                  formType="brief"
                  form={form}
                  isEdit
                />
              </LookupProvider>
            </ImageChooserProvider>
          )}
        </FormBox>
      </Grid>
    </Container>
  )
}

export default InspectionBriefEdit
