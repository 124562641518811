import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FileTagForm from "./components/FileTagForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import ErrorMessage from "../../../../shared/components/ErrorMessage"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { FILE_TAG_ENDPOINT, type IFileTag } from "../../../../shared/models/service/IFileTag"
import { FILE_TAG_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IFileTag>(FILE_TAG_ENDPOINT)
const redirectView = FILE_TAG_VIEW_URL

/**
 * This page will allow editing of a file tag.
 *
 * @returns {React.FC} the file tag edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IFileTag>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IFileTag> = { apiFunction: repository.edit, setError: form.setError, redirectView }
  const { saving, handleEdit, connectionError } = useApiEdit<IFileTag>(props)

  const { t } = useTranslation()

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title={t("Edit File Tag")} loading={saving || apiRead.loading} errors={form.formState.errors} />
        <Grid item xs={12}>
          <ErrorMessage error={apiRead.error} />
          <ErrorMessage error={connectionError} />
        </Grid>
        <FormBox form={form} onSubmit={handleEdit}>
          <FileTagForm fileTag={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
