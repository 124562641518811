import React, { useCallback, useContext, useMemo, useState } from "react"
import { Alert, Container, Divider, Grid, Tab } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { INSPECTION_EDIT_URL } from "../../config/urls"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../shared/models/service/IInspection"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatDate from "../../../../shared/components/format/FormatDate"
import RiskWriterFormView from "../../../../shared/components/risk_writer/components/RiskWriterFormView"
import TabsList from "../../../../shared/components/tabs/TabsList"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import { type IFile, INSPECTION_FILE_ENDPOINT } from "../../../../shared/models/service/IFile"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import { type IFilter } from "../../../../shared/models/components/IFilter"
import { useParams } from "react-router-dom"
import FilesIndex from "../../../../shared/components/files/FilesIndex"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import InspectionRecommendationIndex from "../inspection_recommendations/components/InspectionRecommendationIndex"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../shared/models/service/IInspectionRecommendation"
import ItemViewerDrawer, { useItemEditDialogUrl } from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import LocationInfo from "../locations/components/LocationInfo"
import { ItemPrefixes } from "../../../../config/config"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ViewMap from "../../../../shared/components/display/ViewMap"
import { useTranslation } from "react-i18next"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import { LoadingButton } from "@mui/lab"
import { buildApiAction } from "../../../../shared/hooks/useApiAction"
import BuildingInfo from "../buildings/components/BuildingInfo"
import PrimaryButton from "../../../../shared/components/PrimaryButton"
import InspectionBuildingIndex from "../../../../shared/components/inspection_buildings/components/InspectionBuildingIndex"
import {
  type IInspectionBuilding,
  INSPECTION_BUILDING_ENDPOINT,
} from "../../../../shared/models/service/IInspectionBuilding"
import AddBuilding from "../../../../shared/components/inspection_buildings/components/AddBuilding"
import ReportWriterFormatInfo from "../../../../shared/pages/report_format/components/ReportWriterFormatInfo"
import {
  checkHasRiskWriter,
  checkRiskWriterHasBrief,
  riskWriterBriefTitle,
  riskWriterBuildingFormTitle,
} from "../../../../shared/models/service/IReportFormat"
import InspectionBuildingInfo from "../../../../shared/components/inspection_buildings/components/InspectionBuildingInfo"
import InspectionRecInfo from "../inspection_recommendations/components/InspectionRecommendationInfo"
import ViewLoading from "../../../../shared/components/ViewLoading"
import ItemEditorDialog from "../../../../shared/components/item_editor/ItemEditorDialog"
import InspectionRecommendationEdit from "../inspection_recommendations/components/InspectionRecommendationEdit"
import InspectionRecommendationAdd from "../../../../shared/pages/inspection_recommendations/components/InspectionRecommendationAdd"
import InspectionBriefEdit from "../inspection_briefs/components/InspectionBriefEdit"
import InspectionBuildingEdit from "../../../../shared/components/inspection_buildings/components/InspectionBuildingEdit"
import { useAxiosRequest } from "../../../../shared/hooks/useAxiosRequest"
import InspectionIndex from "./components/InspectionIndex"
import LogEntriesTable from "../../../../shared/components/log_entries/LogEntriesTable"
import { type ILogEntry } from "../../../../shared/models/service/ILogEntry"
import { ImageChooserProvider } from "../../../../shared/components/files/ImageChooser"
import RiskWriterValidate from "../../../../shared/components/risk_writer_support/RiskWriterValidate"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import InspectionCopyFrom from "./components/InspectionCopyFrom"
import ViewRiskWriterInspection, {
  type IGeneratedContent,
} from "../../../../shared/components/display/ViewRiskWriterInspection"
import EditInspectionVendorMember from "../../../../shared/components/inspections/EditInspectionVendorMember"
import VendorInfo from "../vendors/components/VendorInfo"
import type { IPanariskAppContext } from "../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import ViewCurrency from "../../../../shared/components/display/ViewCurrency"
import EditInspectionStatus from "../../../../shared/components/inspections/EditInspectionStatus"
import MetadataDisplay from "../../../../shared/components/metadata/MetadataDisplay"
import { useMetadataExists } from "../../../../shared/components/metadata/UseMetadataExists"
import EditReportWriterVersion from "../../../../shared/components/inspections/EditReportWriterVersion"
import InspectionMessage from "./components/InspectionMessage"
import ContactInfo from "../contacts/components/ContactInfo"
import AccountInfo from "../accounts/components/AccountInfo"
import DateChangeDialog from "../../../../shared/components/tools/DateChangeDialog"
import EditInspectionVendor from "../../../../shared/components/inspections/EditInspectionVendor"
import useEffectInit from "../../../../shared/hooks/useEffectInit"
import { type ISetting, SETTING_ENDPOINT } from "../../../../shared/models/service/ISetting"
import InspectionMessageIndex from "../inspection_messages/components/InspectionMessageIndex"
import {
  type IInspectionMessage,
  INSPECTION_MESSAGE_ENDPOINT,
} from "../../../../shared/models/service/IInspectionMessage"

const inspectRepository = new RestRepository<IInspection | IMainModel>(INSPECTION_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(INSPECTION_FILE_ENDPOINT)
const genContentRepository = new RestRepository<IGeneratedContent>(INSPECTION_ENDPOINT)
const settingsRepository = new RestRepository<ISetting>(SETTING_ENDPOINT)

/**
 * Renders a page to view inspection details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const repository = inspectRepository as RestRepository<IInspection>
  const apiRead = useApiRead<IInspection>({ apiFunction: repository.read })
  const { data } = apiRead
  const [setting, setSetting] = useState<ISetting | null>(null)

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units
  const settingId = appSettings.serverInfo?.setting_id

  const metadataExists = useMetadataExists("inspection")

  const { tab, handleTabChange } = useTabPanel()
  const { id } = useParams()
  const { t } = useTranslation()
  const axiosRequest = useAxiosRequest()

  const apiAction = buildApiAction<IInspection>(repository, id)
  const editBriefUrl = useItemEditDialogUrl(ItemPrefixes.inspectionBrief, data?.id)

  const theFilter: IFilter[] = [
    {
      canBeDelete: false,
      title: "Inspection",
      display: "This Inspection",
      field: "inspection",
      value: id,
    },
  ]

  const recsFilter: IFilter[] = [
    {
      canBeDelete: false,
      title: "Inspection Location",
      display: "This Inspection Location",
      field: "inspection_location",
      value: id,
    },
    {
      canBeDelete: false,
      title: "Completed",
      display: "Not Set",
      field: "completed",
      value: "not_set",
    },
    {
      canBeDelete: false,
      title: "Report Format",
      display: "This Report Format",
      field: "inspection_report_format",
      value: id,
    },
  ]

  const recPagingResults = useOnDemandPaged<IInspectionRecommendation>(
    INSPECTION_RECOMMENDATION_ENDPOINT,
    recsFilter,
    "RECOMMENDATIONS",
  )
  const buildingPagingResults = useOnDemandPaged<IInspectionBuilding>(
    INSPECTION_BUILDING_ENDPOINT,
    theFilter,
    "INSPECTION_BUILDING",
  )
  const filesPagingResults = useOnDemandPaged<IFile>(INSPECTION_FILE_ENDPOINT, theFilter, "FILES")

  const previousInspectionsFilter: IFilter[] = [
    {
      canBeDelete: false,
      title: "Previous Inspections",
      display: "Previous Inspections",
      field: "previous_inspections",
      value: id,
    },
  ]
  const inspectionsPagingResults = useOnDemandPaged<IInspection>(
    INSPECTION_ENDPOINT,
    previousInspectionsFilter,
    "PREVIOUS_INSPECTIONS",
  )

  const inspectionMessagesFilter: IFilter[] = [
    {
      canBeDelete: false,
      title: "Inspection Messages",
      display: "Inspection Messages",
      field: "messages",
      value: id,
    },
  ]
  const inspectionsMessagesPagingResults = useOnDemandPaged<IInspectionMessage>(
    INSPECTION_MESSAGE_ENDPOINT,
    inspectionMessagesFilter,
    "INSPECTION_MESSAGES",
  )

  const hasRiskWriter = useMemo(() => checkHasRiskWriter(data), [data])
  const hasRiskWriterBrief = useMemo(() => checkRiskWriterHasBrief(data), [data])
  const briefTitle = useMemo(() => riskWriterBriefTitle(data), [data])
  const buildingTitle = useMemo(() => riskWriterBuildingFormTitle(data), [data])
  const reportWriterFormat = data?.report_writer_format?.data

  const handleToggleCompleted = useCallback(async () => {
    await apiAction.callAction("toggle_completed")
    await apiRead.call()
  }, [apiAction.callAction])

  const logEntriesUrl = useMemo(() => `${INSPECTION_ENDPOINT}/${id}/log_entries`, [id])
  const logEntryPagingResults = useOnDemandPaged<ILogEntry>(logEntriesUrl, [], "LOG_ENTRIES")

  useEffectInit(async () => {
    if (settingId !== undefined) {
      const setting1 = await settingsRepository.read(settingId)
      setSetting(setting1)
    }
  }, [settingId])

  const initialReturnContacts = useMemo(() => {
    const contacts = []
    if (data !== undefined) {
      if (data.vendor_member_as_contact !== null) {
        contacts.push(data.vendor_member_as_contact)
      }
      if (data.vendor_lead_as_contact !== null) {
        contacts.push(data.vendor_lead_as_contact)
      }
    }
    return contacts
  }, [data])

  const handleReturnInspection = useCallback(async () => {
    await apiAction.callAction("return_inspection")
    await apiRead.call()
  }, [])

  const handleBuildingSaved = useCallback(async () => {
    await buildingPagingResults.call()
    await apiRead.call()
  }, [])

  return (
    <Container fixed>
      <>
        <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
        <ItemViewerDrawer title={t("Account")} prefix={ItemPrefixes.account} infoView={AccountInfo} />
        <ItemViewerDrawer title={t("Building")} prefix={ItemPrefixes.building} infoView={BuildingInfo} />
        <ItemViewerDrawer title={t("Service User")} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
        <ItemViewerDrawer title={t("Contact")} prefix={ItemPrefixes.contact} infoView={ContactInfo} />
        <ItemViewerDrawer title={t("Format")} prefix={ItemPrefixes.reportFormat} infoView={ReportWriterFormatInfo} />
        <ItemViewerDrawer title={t("Vendor")} prefix={ItemPrefixes.vendor} infoView={VendorInfo} />
        <ItemViewerDrawer
          title={buildingTitle}
          prefix={ItemPrefixes.inspectionBuilding}
          infoView={InspectionBuildingInfo}
          canEdit={hasRiskWriter}
        />
        <ItemViewerDrawer
          title={t("Recommendation")}
          prefix={ItemPrefixes.inspectionRec}
          infoView={InspectionRecInfo}
          canEdit
        />
        <ItemEditorDialog
          title={t("Recommendation")}
          prefix={ItemPrefixes.inspectionRec}
          itemEdit={InspectionRecommendationEdit}
          itemAdd={InspectionRecommendationAdd}
          onSave={recPagingResults.call}
          modelSource="inspection"
        />
        <ItemEditorDialog
          title={briefTitle}
          prefix={ItemPrefixes.inspectionBrief}
          itemEdit={InspectionBriefEdit}
          onSave={apiRead.call}
          modelSource="inspection"
        />
        <ItemEditorDialog
          title={t(buildingTitle)}
          prefix={ItemPrefixes.inspectionBuilding}
          itemEdit={InspectionBuildingEdit}
          onSave={handleBuildingSaved}
          modelSource="inspection"
        />
      </>
      <ViewLoading loading={axiosRequest.loading} />
      <Grid container spacing={2}>
        {data !== undefined && (
          <>
            <PageHeader
              title={t("Inspection")}
              titlePlural={`${t("Inspection")} ${data.identifier}`}
              toEdit={`${INSPECTION_EDIT_URL}/${data.id}`}
              onRefresh={apiRead.call}
            />
            <Grid item xs={12}>
              <TabsList value={tab} onChange={handleTabChange}>
                <Tab label={t("Overview")} value={0} />
                {hasRiskWriterBrief && <Tab label={t(briefTitle)} value={1} />}
                <Tab label={t("Buildings")} value={2} />
                <Tab label={t("Recommendations")} value={3} />
                <Tab label={t("Files")} value={4} />
                <Tab label={t("Previous Inspections")} value={5} />
                <Tab label={t("History")} value={6} />
                <Tab label={t("Messages")} value={7} />
              </TabsList>
              <TabPanel value={tab} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PaperLocal>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Account")}>
                                <ViewListItems prefix={ItemPrefixes.account}>{data.account}</ViewListItems>
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Location")}>
                                <ViewListItems prefix={ItemPrefixes.location}>{data.location}</ViewListItems>
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Buildings")}>{data.buildings.length}</ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item xs>
                                  <ViewProperty label={t("Status")}>{data.inspection_status?.name}</ViewProperty>
                                </Grid>
                                <Grid item>
                                  <EditInspectionStatus inspection={data} onChange={apiRead.call} />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Priority")}>{data.priority?.name}</ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Type")}>{data.inspection_type?.name}</ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Report Format")}>
                                <ViewListItems prefix={ItemPrefixes.reportFormat}>{data.report_format}</ViewListItems>
                              </ViewProperty>
                            </Grid>
                          </Grid>
                        </PaperLocal>
                      </Grid>
                      {hasRiskWriter && (
                        <Grid item xs={12}>
                          <PaperLocal>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <ViewProperty label={t("Report Writer")}>
                                  <RiskWriterValidate
                                    inspectionId={data.id}
                                    briefTitle={briefTitle}
                                    buildingTitle={buildingTitle}
                                  />
                                  <ImageChooserProvider
                                    itemId={id}
                                    field="inspection"
                                    fileEndpoint={INSPECTION_FILE_ENDPOINT}>
                                    <ViewRiskWriterInspection
                                      data={data}
                                      reportWriterFormat={data?.report_writer_format?.data}
                                      title={data.location.name}
                                      buildingPagingResults={buildingPagingResults}
                                      recPagingResults={recPagingResults}
                                      onReloadReport={apiRead.call}
                                      repository={inspectRepository}
                                      genContentRepository={genContentRepository}
                                      filesRepository={filesRepository}
                                    />
                                  </ImageChooserProvider>
                                </ViewProperty>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item xs>
                                    <ViewProperty label={t("Version")}>
                                      {data.report_writer_format?.version}
                                    </ViewProperty>
                                  </Grid>
                                  <Grid item>
                                    <EditReportWriterVersion inspection={data} onChange={apiRead.call} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </PaperLocal>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <PaperLocal>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Fee")}>
                                <ViewCurrency>{data.fee}</ViewCurrency>
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor Due Date")}>
                                <DateChangeDialog
                                  parentId={data.id}
                                  repository={inspectRepository}
                                  onChange={apiRead.call}
                                  field="vendor_due_date"
                                  value={data.vendor_due_date}
                                />
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor Site Visit Date")}>
                                <DateChangeDialog
                                  parentId={data.id}
                                  repository={inspectRepository}
                                  onChange={apiRead.call}
                                  field="vendor_site_visit_date"
                                  value={data.vendor_site_visit_date}
                                />
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor Submitted Date")}>
                                <DateChangeDialog
                                  parentId={data.id}
                                  repository={inspectRepository}
                                  onChange={apiRead.call}
                                  field="vendor_submitted_date"
                                  value={data.vendor_submitted_date}
                                />
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor Returned Date")}>
                                {data.vendor_returned_date !== null ? (
                                  <DateChangeDialog
                                    parentId={data.id}
                                    repository={inspectRepository}
                                    onChange={apiRead.call}
                                    field="vendor_returned_date"
                                    value={data.vendor_returned_date}
                                  />
                                ) : (
                                  <InspectionMessage
                                    title={t("Return")}
                                    inspection={data}
                                    emailTemplate={setting?.email_template_inspection_returned}
                                    initialContacts={initialReturnContacts}
                                    onComplete={handleReturnInspection}
                                  />
                                )}
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Completed")}>
                                <LoadingButton
                                  onClick={handleToggleCompleted}
                                  color={data.completed === null ? "primary" : "error"}
                                  loading={apiAction.saving}>
                                  {data.completed === null ? "Complete" : "Reopen"}
                                </LoadingButton>
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Completed By")} show={data.completed !== null}>
                                <Grid container alignItems="center" spacing={1}>
                                  <Grid item xs>
                                    <FormatDate value={data.completed} />
                                  </Grid>
                                  <Grid item>
                                    <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.completed_by}</ViewListItems>
                                  </Grid>
                                </Grid>
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Share")} show={data.completed !== null}>
                                <InspectionMessage
                                  title={t("Share")}
                                  inspection={data}
                                  emailTemplate={setting?.email_template_inspection_share}
                                />
                              </ViewProperty>
                            </Grid>
                          </Grid>
                        </PaperLocal>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PaperLocal>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Vendor")}>
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <ViewListItems prefix={ItemPrefixes.vendor}>{data.vendor}</ViewListItems>
                                  </Grid>
                                  <Grid item>
                                    <EditInspectionVendor inspection={data} onChange={apiRead.call} />
                                  </Grid>
                                </Grid>
                              </ViewProperty>
                            </Grid>
                            <Grid item xs={12}>
                              <ViewProperty label={t("Assigned To")}>
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <ViewListItems prefix={ItemPrefixes.serviceUser}>
                                      {data.vendor_member}
                                    </ViewListItems>
                                  </Grid>
                                  <Grid item>
                                    {data.vendor !== null && (
                                      <EditInspectionVendorMember
                                        vendor={data.vendor}
                                        inspection={data}
                                        onChange={apiRead.call}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </ViewProperty>
                            </Grid>
                          </Grid>
                        </PaperLocal>
                      </Grid>
                      <Grid item xs={12}>
                        <PaperLocal>
                          <ViewMap place={data.location} places={data.buildings} />
                        </PaperLocal>
                      </Grid>
                      {metadataExists && (
                        <Grid item xs={12}>
                          <PaperLocal>
                            <MetadataDisplay modelName="inspection" data={data.metadata_data} />
                          </PaperLocal>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <PaperLocal>
                          <ViewProperty label={t("Notes")} vertical>
                            <ViewRichText>{data.notes}</ViewRichText>
                          </ViewProperty>
                        </PaperLocal>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {hasRiskWriterBrief && (
                  <Grid container sx={{ mb: 2 }} spacing={2}>
                    <Grid item xs />
                    <Grid item>
                      <PrimaryButton to={editBriefUrl} edit>
                        {t(briefTitle)}
                      </PrimaryButton>
                    </Grid>
                    <Grid item xs={12}>
                      {data.risk_writer_brief_data === null && (
                        <Alert severity="warning">{t(`${t(briefTitle)} not started.`)}</Alert>
                      )}
                    </Grid>
                  </Grid>
                )}
                {data.risk_writer_brief_data !== null && hasRiskWriterBrief && (
                  <RiskWriterFormView
                    formType="brief"
                    units={units}
                    format={reportWriterFormat}
                    fileEndpoint={INSPECTION_FILE_ENDPOINT}>
                    {data.risk_writer_brief_data}
                  </RiskWriterFormView>
                )}
              </TabPanel>
              <TabPanel value={tab} index={2} onChange={buildingPagingResults.call}>
                <InspectionBuildingIndex
                  useInfoView
                  itemDelete
                  format={reportWriterFormat}
                  pagingResults={buildingPagingResults}
                  gotoFilters={[{ field: "inspection", value: data.id }] as IFilter[]}
                  tableActions={<AddBuilding inspection={data} onComplete={buildingPagingResults.call} />}
                />
              </TabPanel>
              <TabPanel value={tab} index={3} onChange={recPagingResults.call}>
                <InspectionRecommendationIndex
                  useInfoView
                  currentInspection={data}
                  pagingResults={recPagingResults}
                  fullPage={false}
                  itemDelete
                />
              </TabPanel>
              <TabPanel value={tab} index={4} onChange={filesPagingResults.call}>
                <FilesIndex
                  parentId={id}
                  parentFieldName="inspection"
                  repository={filesRepository}
                  pagingResults={filesPagingResults}
                />
              </TabPanel>
              <TabPanel value={tab} index={5} onChange={inspectionsPagingResults.call}>
                <InspectionIndex
                  useInfoView
                  pagingResults={inspectionsPagingResults}
                  showHeader={false}
                  parentInspection={data}
                  action={InspectionCopyFrom}
                  onActionChange={apiRead.call}
                />
              </TabPanel>
              <TabPanel value={tab} index={6} onChange={logEntryPagingResults.call}>
                <LogEntriesTable pagingResults={logEntryPagingResults} format={reportWriterFormat} inspection={data} />
              </TabPanel>
              <TabPanel value={tab} index={7} onChange={inspectionsMessagesPagingResults.call}>
                <InspectionMessageIndex pagingResults={inspectionsMessagesPagingResults} />
              </TabPanel>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default ViewPage
