import type { TMetadataData } from "./IMetadataConfig"

export const COMPANY_ENDPOINT = "companies"

export interface ICompany {
  id: number
  name: string

  address: string
  address_2: string
  city: string
  state_region: string
  postal_code: string
  country: string

  longitude: number | string
  latitude: number | string
  zoom: number | string

  notes: string
  metadata_data: TMetadataData | null

  created: string
  updated: string
}
