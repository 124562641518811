import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { type IVehicle, VEHICLE_ENDPOINT } from "../../../../shared/models/service/IVehicle"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatDate from "../../../../shared/components/format/FormatDate"
import { VEHICLE_EDIT_URL } from "../../config/urls"
import PageViewBody from "../../../../shared/components/pages/PageViewBody"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { useTranslation } from "react-i18next"
import MetadataDisplay from "../../../../shared/components/metadata/MetadataDisplay"
import { useMetadataExists } from "../../../../shared/components/metadata/UseMetadataExists"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ViewCurrency from "../../../../shared/components/display/ViewCurrency"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../../config/config"
import CompanyInfo from "../companies/components/CompanyInfo"
import ContactInfo from "../contacts/components/ContactInfo"

const repository = new RestRepository<IVehicle>(VEHICLE_ENDPOINT)

/**
 * Renders a page to view vehicle impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data, loading, call } = useApiRead<IVehicle>({ apiFunction: repository.read })
  const { t } = useTranslation()

  const metadataExists = useMetadataExists("vehicle")

  return (
    <PageViewBody loading={loading}>
      {data !== undefined && (
        <>
          <PageHeader
            title={t("Vehicle")}
            titlePlural={`${data.name}`}
            onRefresh={call}
            toEdit={`${VEHICLE_EDIT_URL}/${data.id}`}
          />
          <Grid item xs={12}>
            <ItemViewerDrawer title={"Company"} prefix={ItemPrefixes.company} infoView={CompanyInfo} />
            <ItemViewerDrawer title={"Contact"} prefix={ItemPrefixes.contact} infoView={ContactInfo} />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PaperLocal>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ViewProperty label={t("Name")}>{data.name}</ViewProperty>
                        </Grid>
                        <Grid item xs={12}>
                          <ViewProperty label={t("Company")}>
                            <ViewListItems prefix={ItemPrefixes.company}>{data.company}</ViewListItems>
                          </ViewProperty>
                        </Grid>
                        <Grid item xs={12}>
                          <ViewProperty label={t("Contacts")}>
                            <ViewListItems prefix={ItemPrefixes.contact}>{data.contacts}</ViewListItems>
                          </ViewProperty>
                        </Grid>
                        <Grid item xs={12}>
                          <ViewProperty label={t("Created")}>
                            <FormatDate value={data.created} />
                          </ViewProperty>
                        </Grid>
                      </Grid>
                    </PaperLocal>
                  </Grid>
                  {metadataExists && (
                    <Grid item xs={12}>
                      <PaperLocal>
                        <MetadataDisplay modelName="vehicle" data={data.metadata_data} />
                      </PaperLocal>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PaperLocal>
                      <ViewProperty label={t("Notes")} vertical>
                        <ViewRichText>{data.notes}</ViewRichText>
                      </ViewProperty>
                    </PaperLocal>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <PaperLocal>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ViewProperty label={t("License Plate")}>{data.license_plate}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("VIN")}>{data.vin}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Year")}>{data.year}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Make")}>{data.make}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Model")}>{data.model}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Color")}>{data.color}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Purchase Price")}>
                        <ViewCurrency>{data.purchase_price}</ViewCurrency>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Purchase Date")}>
                        <FormatDate value={data.purchase_date} />
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Registration Date")}>
                        <FormatDate value={data.registration_date} />
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Renewal Date")}>
                        <FormatDate value={data.renewal_date} />
                      </ViewProperty>
                    </Grid>
                  </Grid>
                </PaperLocal>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </PageViewBody>
  )
}

export default ViewPage
