import React, { useCallback, useState } from "react"
import DrawerRight from "../../../../../shared/components/containers/DrawerRight"
import { Grid, IconButton, List, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { axiosInstance, BASE_URL } from "../../../../../shared/utilities/request_utility"
import { useAxiosRequest } from "../../../../../shared/hooks/useAxiosRequest"
import ListItemText from "@mui/material/ListItemText"
import ListItemButton from "@mui/material/ListItemButton"
import { type ILocation } from "../../../../../shared/models/service/ILocation"
import ViewLoading from "../../../../../shared/components/ViewLoading"
import ErrorMessage from "../../../../../shared/components/ErrorMessage"
import { useTranslation } from "react-i18next"

interface IAddressSearchResult {
  place_id: string
  formatted_address: string
  longitude: number
  latitude: number
}

interface IProps {
  address: string
  onFound: (location: ILocation) => void
}

/**
 * AddressSearch is a React functional component that represents an address search drawer.
 *
 * It includes functionalities for searching addresses, displaying search results,
 * and selecting an address to fetch its details from an external API.
 *
 * @param {IProps} props - The properties passed to the component.
 * @param {string} props.address - The initial address to search for.
 * @param {Function} props.onFound - Callback function to handle the found address details.
 *
 * @returns {React.ReactElement} The rendered AddressSearch component.
 */
const AddressSearch: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { address, onFound } = props
  const axiosRequest = useAxiosRequest()
  const [closeDrawer, setCloseDrawer] = useState(true)
  const [search, setSearch] = useState<string>("")

  const [addressSearch, setAddressSearch] = useState<IAddressSearchResult[] | null>(null)
  const { t } = useTranslation()

  const handleOpen = useCallback(async () => {
    setAddressSearch(null)
    setSearch(address)
    await axiosRequest.callRequest(async () => {
      const url = `${BASE_URL}/lookups/locate_address/?address=${address}`
      const { data } = await axiosInstance.get<IAddressSearchResult[]>(url)
      setAddressSearch(data)
    })
  }, [address])

  const handleSearch = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      const url = `${BASE_URL}/lookups/locate_address/?address=${search}`
      const { data } = await axiosInstance.get<IAddressSearchResult[]>(url)
      setAddressSearch(data)
    })
  }, [search])

  const handleFillAddress = useCallback(
    (placeId: string) => async () => {
      const url = `${BASE_URL}/lookups/location_details/?place_id=${placeId}`
      await axiosRequest.callRequest(async () => {
        const { data } = await axiosInstance.get<ILocation>(url)
        onFound?.(data)
        setCloseDrawer(closeDrawer => !closeDrawer)
      })
    },
    [address],
  )

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }, [])

  return (
    <DrawerRight
      title={t("Address Search")}
      icon={<SearchIcon />}
      showIconButton
      onDrawerOpen={handleOpen}
      closeDrawer={closeDrawer}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <ErrorMessage error={axiosRequest.errorMessage} />
          <ViewLoading loading={axiosRequest.loading} />
        </Grid>
        <Grid item xs>
          <TextField fullWidth name="search" value={search} onChange={handleSearchChange} />
        </Grid>
        <Grid item>
          <IconButton onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <List>
            {addressSearch?.map(result => {
              return (
                <ListItemButton key={result.place_id} onClick={handleFillAddress(result.place_id)}>
                  <ListItemText primary={result.formatted_address} />
                </ListItemButton>
              )
            })}
          </List>
        </Grid>
      </Grid>
    </DrawerRight>
  )
}

export default AddressSearch
