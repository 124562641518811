import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import CompanyForm from "./components/CompanyForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { COMPANY_ENDPOINT, type ICompany } from "../../../../shared/models/service/ICompany"
import { COMPANY_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<ICompany>(COMPANY_ENDPOINT)
const redirectView = COMPANY_VIEW_URL

/**
 * This page will allow editing of a company.
 *
 * @returns {React.FC} the company edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const { t } = useTranslation()
  const apiRead = useApiRead<ICompany>({ apiFunction: repository.read })

  const props: IUseApiEditProps<ICompany> = { apiFunction: repository.edit, setError: form.setError, redirectView }
  const apiEdit = useApiEdit<ICompany>(props)

  const handleSave = useCallback(async (company: ICompany) => {
    company.latitude = company.latitude === "" ? 0 : company.latitude
    company.longitude = company.longitude === "" ? 0 : company.longitude
    company.zoom = company.zoom === "" ? 0 : company.zoom
    await apiEdit.handleEdit(company)
  }, [apiEdit.handleEdit])

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title={t("Edit Company")} loading={apiEdit.saving || apiRead.loading} errors={form.formState.errors} />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={handleSave}>
          <CompanyForm company={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
