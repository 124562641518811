import React from "react"
import { styles } from "../../styling/general"
import { Alert, Box } from "@mui/material"
import { useTranslation } from "react-i18next"

/**
 * Displayed when the user doesn't have access to a page.
 *
 * @returns {React.FC} the access denied page.
 */
const AccessDenied: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <Box sx={styles.page}>
      <Alert severity="error">{t("You do not have permission to access this page.")}</Alert>
    </Box>
  )
}

export default AccessDenied
