import React, { useCallback } from "react"
import AlertDialog from "../../../../../shared/components/AlertDialog"
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges"
import useApiAction, { type IUseApiActionProps } from "../../../../../shared/hooks/useApiAction"
import { type IPaging } from "../../../../../shared/models/components/IPaging"
import { type RestRepository } from "../../../../../shared/repositories/RestRepository"
import type { IReportFormat, IReportWriterFormat } from "../../../../../shared/models/service/IReportFormat"
import { Alert } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IProps {
  reportWriterFormat: IReportWriterFormat
  onComplete: () => void
  parentId: number
  repository: RestRepository<IReportFormat>
}

/**
 * Represents a component for adding a service user.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered component.
 */
const SetCurrentReportWriterFormat: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { reportWriterFormat, onComplete, parentId, repository } = props

  const actionProps: IUseApiActionProps<IReportFormat> = { repository, itemId: parentId }
  const apiAction = useApiAction<IReportFormat>(actionProps)

  const { t } = useTranslation()

  const handleYes = useCallback(async () => {
    const paging: IPaging = { filters: [{ field: "id", value: reportWriterFormat.id }] }
    await apiAction.callAction("set_current_report_writer_format", paging)
    onComplete()
  }, [reportWriterFormat])

  return (
    <AlertDialog
      buttonText="Set Current"
      buttonIcon={<PublishedWithChangesIcon />}
      title="Confirm Set Current Report Writer Format"
      message={
        <>
          <p>{t("Are you sure you want set the following report writer format as the current one?")}</p>
          <p>
            <em>{reportWriterFormat.title}</em>
          </p>
          <Alert severity="warning">{t("All future inspections and summaries will use this version.")}</Alert>
        </>
      }
      onYes={handleYes}
    />
  )
}

export default SetCurrentReportWriterFormat
