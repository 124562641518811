import React, { useCallback, useState } from "react"
import HelpIcon from "@mui/icons-material/Help"
import DrawerRight from "../containers/DrawerRight"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import useContentHeight from "../../hooks/useContentHeight"
import ViewLoading from "../ViewLoading"
import { axiosInstance, BASE_URL, getBaseApp } from "../../utilities/request_utility"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import ErrorMessage from "../ErrorMessage"
import { markdownSx } from "../../styling/markdown"

/**
 * Renders the HelpDocsDrawer component displaying help documentation.
 *
 * @returns {React.ReactElement} The rendered HelpDocsDrawer component.
 */
const HelpDocsDrawer: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const height = useContentHeight(-27)
  const [helpDocsHtml, setHelpDocsHtml] = useState<string>("")

  const axiosRequest = useAxiosRequest()

  const handleOpen = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      const url = `${BASE_URL}/lookups/help_docs/?docs_name=${getBaseApp()}`
      const response = await axiosInstance.get<string>(url)
      setHelpDocsHtml(response.data)
    })
  }, [])

  return (
    <DrawerRight
      buttonColor="inherit"
      title={t("Help")}
      icon={<HelpIcon />}
      hasMargin={false}
      onDrawerOpen={handleOpen}>
      <Box sx={{ height }}>
        <ViewLoading loading={axiosRequest.loading} inline />
        {axiosRequest.error && <ErrorMessage error={axiosRequest.errorMessage} />}
        <Box sx={markdownSx} dangerouslySetInnerHTML={{ __html: helpDocsHtml }} />
      </Box>
    </DrawerRight>
  )
}

export default HelpDocsDrawer
