import React, { useCallback, useState } from "react"
import SelectFilteredSingle from "./forms/SelectFilteredSingle"
import { type RestRepository } from "../repositories/RestRepository"
import { type IListItem } from "../models/components/IListItem"
import { type IFilter } from "../models/components/IFilter"
import { useNavigate } from "react-router-dom"
import { Badge, Box, Button, Grid } from "@mui/material"
import { Search } from "@mui/icons-material"
import type { IUseApiPagedResultsResponse } from "../hooks/useApiPagedLocal"
import HtmlTooltip from "./HtmlToolTip"
import { useTranslation } from "react-i18next"

export interface IProps {
  repository: RestRepository<IListItem>
  to: string
  filters?: IFilter[]
  label?: string
  onFocus?: () => void
  onBlur?: () => void
  searchable?: boolean
  pagingResults?: IUseApiPagedResultsResponse<any>
}

/**
 * Use this component to lookup and goto a page based on a list repository.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the goto component
 */
const Goto: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { t } = useTranslation()
  const { repository, to, filters, label = t("Goto"), onFocus, onBlur, searchable = false, pagingResults } = props
  const [focus, setFocus] = useState<boolean>(false)
  const navigate = useNavigate()
  const [search, setSearch] = useState<string | null>(null)

  const handleChange = useCallback(
    async (item?: IListItem | null) => {
      if (item?.id !== undefined) {
        navigate(`${to}/${item.id}`)
      }
    },
    [to],
  )

  const handleSearch = useCallback((search1: string | null) => {
    if (search1 !== "" && search1 !== "") {
      setSearch(search1)
    }
  }, [])

  const handleSearchFilter = useCallback(() => {
    const filters = pagingResults?.paging?.filters?.filter(filter1 => filter1.field !== "search")
    if (pagingResults !== undefined && search !== null) {
      const filter: IFilter = { title: t("Search"), field: "search", value: search, display: search }
      const theFilters: IFilter[] = filters !== undefined ? [...filters, filter] : [filter]
      pagingResults.handleFilter(theFilters)
    }
    setSearch(null)
  }, [search])

  const handleFocus = useCallback(() => {
    setFocus(true)
    onFocus?.()
  }, [onFocus])

  const handleBlur = useCallback(() => {
    setFocus(false)
    onBlur?.()
  }, [onFocus])

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <SelectFilteredSingle
          showId={false}
          name="goto"
          size="small"
          label={focus ? label : t("Goto")}
          filters={filters}
          onFocus={handleFocus}
          onBlur={handleBlur}
          repository={repository}
          onChange={handleChange}
          onSearch={handleSearch}
        />
      </Grid>
      {searchable && (
        <Grid item>
          <HtmlTooltip
            title={
              <Box>
                {t("Search for")}: <strong>{search}</strong>
              </Box>
            }>
            <Badge color="secondary" variant="dot" invisible={search === null || pagingResults === undefined}>
              <Button
                startIcon={<Search />}
                disabled={search === null || pagingResults === undefined}
                onClick={handleSearchFilter}>
                {t("Search")}
              </Button>
            </Badge>
          </HtmlTooltip>
        </Grid>
      )}
    </Grid>
  )
}

export default Goto
