import React, { useCallback } from "react"
import { Box } from "@mui/material"
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { CHART_COLORS } from "../../../../config/config"
import { type IStats } from "../../../models/service/IStats"
import ChartTooltip from "./ChartTooltip"
import { type IChartBuilderState } from "../models/IStatBuilderState"
import { truncateString } from "../../../utilities/format_utility"
import { useTranslation } from "react-i18next"

interface ITickProps {
  payload?: {
    value: string
  }
  x?: number
  y?: number
  onClick?: (label: string) => void
}

/**
 * CustomTick component.
 *
 * @param {ITickProps} props - The props for the CustomTick component.
 * @returns {React.ReactElement} The rendered CustomTick component.
 */
const CustomTick: React.FC<ITickProps> = (props: ITickProps): React.ReactElement => {
  const { x, y, payload, onClick } = props
  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    if (payload?.value != null) {
      onClick?.(payload.value)
    }
  }, [onClick, payload])

  const title = truncateString(payload?.value, 40)

  return (
    <g transform={`translate(${x},${y})`} onClick={handleClick} style={{ cursor: "pointer" }}>
      <text x={0} y={0} dy={6} textAnchor="end" fill="#666" transform="rotate(315)">
        {title === "" || title === undefined || title === null ? t("Unknown") : title}
      </text>
    </g>
  )
}

interface IProps {
  chartRef: React.RefObject<HTMLDivElement>
  data: IStats[]
  statBuilderStat: IChartBuilderState
  onClick?: (label: string) => void
}

/**
 * WidgetBarChart is a React functional component that renders a bar chart widget.
 *
 * @param {IProps} props - The props for the WidgetBarChart component.
 * @param {React.Ref} props.chartRef - The ref of the chart container.
 * @param {Array} props.data - The data to be displayed on the bar chart.
 * @returns {React.ReactElement} - The rendered bar chart widget.
 */
const WidgetBarChart: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { chartRef, data, statBuilderStat, onClick } = props

  return (
    <Box ref={chartRef}>
      <ResponsiveContainer width="100%" aspect={1}>
        <BarChart data={data} margin={{ bottom: 120 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="category" dataKey="label" angle={-40} textAnchor="end" tick={<CustomTick onClick={onClick} />} />
          <YAxis type="number" dataKey="value" />
          <Tooltip content={props => <ChartTooltip {...props} statBuilderStat={statBuilderStat} />} />
          <Bar dataKey="value" barSize={40}>
            {data.map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
export default WidgetBarChart
