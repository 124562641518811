import React, { useCallback, useContext } from "react"
import SearchIcon from "@mui/icons-material/Search"
import { IconButton, useMediaQuery, useTheme } from "@mui/material"
import { LookupContext } from "./Lookup"
import { type LookupType } from "./constants"

interface IProps {
  lookupType: LookupType
  disabled?: boolean
  small?: boolean
  lookupField: string
}

/**
 * Renders a lookup button component with a search icon that links to the corresponding drawer.
 *
 * @param {IProps} props - The properties for the component.
 * @returns {React.ReactElement} - The rendered lookup button component.
 */
const LookupButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { disabled = false, small = false, lookupType, lookupField } = props
  const { setDrawerOpen, setLookupType, setLookupField } = useContext(LookupContext)
  const isSmall = small || useMediaQuery(useTheme().breakpoints.down("md"))

  const handleClick = useCallback(() => {
    if (
      setDrawerOpen !== null &&
      setDrawerOpen !== undefined &&
      setLookupType !== null &&
      setLookupType !== undefined &&
      setLookupField !== null &&
      setLookupField !== undefined
    ) {
      setLookupType(lookupType)
      setLookupField(lookupField)
      setDrawerOpen(true)
    }
  }, [setDrawerOpen, setLookupType, lookupType])

  return (
    <IconButton disabled={disabled} size={isSmall ? "small" : "medium"} onClick={handleClick}>
      <SearchIcon fontSize={isSmall ? "small" : "medium"} />
    </IconButton>
  )
}

export default LookupButton
