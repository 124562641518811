import * as React from "react"
import { useCallback, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Tab,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import BookmarksIcon from "@mui/icons-material/Bookmarks"
import TabPanel, { useTabPanel } from "../tabs/TabPanel"
import DialogControls from "../DialogControls"
import { type IUseApiPagedResultsResponse, useApiPagedLocal } from "../../hooks/useApiPagedLocal"
import {
  FILTER_BOOKMARK_ENDPOINT,
  type IFilterBookmark,
  type TFilterBookmarkModelNames,
} from "../../models/service/IFilterBookmark"
import { RestRepository } from "../../repositories/RestRepository"
import TabsList from "../tabs/TabsList"
import ErrorMessage from "../ErrorMessage"
import ViewProperty from "../ViewProperty"
import useApiAdd from "../../hooks/useApiAdd"
import { useForm } from "react-hook-form"
import FormBox from "../forms/FormBox"
import FhMuiTextField from "../forms/FhMuiTextField"
import { type IPaging } from "../../models/components/IPaging"
import { type IFilter } from "../../models/components/IFilter"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import PaperLocal from "../containers/PaperLocal"
import { nameToLabel } from "../../utilities/form_utility"
import ViewFilterBookmark from "../display/ViewFilterBookmark"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IFilterBookmark>(FILTER_BOOKMARK_ENDPOINT)

interface IProps {
  /**
   * Should match the endpoint where the filters are being used.
   */
  modelName: TFilterBookmarkModelNames
  pagingResults: IUseApiPagedResultsResponse<any>
}

const dontCallOnMount = true
const apiFunction = repository.findAll
const prefix = "FILTER_BOOKMARKS"

/**
 * This component will allow for the bookmarking of filters.
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the filter bookmarking component.
 */
const FilterBookmarksDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, modelName } = props
  const { paging } = pagingResults

  const initialFilters: IFilter[] = [{ field: "model_name", value: modelName }]
  const [showFilters, setShowFilters] = useState<number[]>([])

  const prBookmark = useApiPagedLocal<IFilterBookmark>({ apiFunction, dontCallOnMount, initialFilters, prefix })

  const form = useForm()
  const apiAdd = useApiAdd<IFilterBookmark>({ apiFunction: repository.add, setError: form.setError, redirect: false })

  const [open, setOpen] = useState<boolean>(false)
  const { tab, handleTabChange } = useTabPanel({ useSearchParams: false })
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const { t } = useTranslation()

  const handleOpen = useCallback(async () => {
    setOpen(true)
    if (paging !== undefined && paging !== null) {
      const newPaging: IPaging = { filters: paging.filters, limit: paging.limit, ordering: paging.ordering }
      form.setValue("paging", newPaging)
      form.setValue("model_name", modelName)
    }
  }, [prBookmark, paging])

  const handleClose = useCallback(async () => {
    setOpen(false)
  }, [])

  const handleApplyFilters = useCallback(
    (bookmark: IFilterBookmark) => () => {
      const filters: IFilter[] | undefined = bookmark.paging.filters?.map(filter => ({ ...filter, cantDelete: false }))
      const paging1: IPaging = { filters, limit: bookmark.paging?.limit, ordering: bookmark.paging?.ordering }
      pagingResults.handleCompletePaging(paging1)
      setOpen(false)
    },
    [pagingResults],
  )

  const handleShowFilters = useCallback(
    (index: number) => () => {
      setShowFilters(showFilters => {
        if (showFilters.includes(index)) {
          return showFilters.filter(filterIndex => filterIndex !== index)
        }
        return [...showFilters, index]
      })
    },
    [],
  )

  const handleSubmit = useCallback(
    async (filterBookmark: IFilterBookmark) => {
      const filterBookmark1 = await apiAdd.handleAdd(filterBookmark, true)
      if (filterBookmark1 !== undefined) {
        form.reset()
        handleTabChange(null, 0)
      }
    },
    [form],
  )

  return (
    <>
      <Button startIcon={<BookmarksIcon />} onClick={handleOpen} size="small">
        {t("Bookmarks")}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth fullScreen={isSmall} maxWidth="sm" sx={{ zIndex: 2300 }}>
        <DialogTitle>{t("Filter Bookmarks")}</DialogTitle>
        <Box sx={{ p: 1 }}>
          {prBookmark?.error !== null && <ErrorMessage error={prBookmark.error} />}
          <TabsList value={tab} onChange={handleTabChange}>
            <Tab label={t("Bookmarks")} value={0} />
            <Tab
              label={t("Create")}
              value={1}
              disabled={paging?.filters === undefined || paging.filters.length === 0}
            />
          </TabsList>
        </Box>
        <DialogContent sx={{ p: 0 }}>
          <TabPanel index={0} value={tab} onChange={prBookmark.call}>
            <>
              {prBookmark.data?.results.length !== undefined && prBookmark.data.results.length > 0 && (
                <List>
                  {prBookmark.data?.results.map((bookmark, index) => {
                    return (
                      <React.Fragment key={bookmark.id}>
                        <ListItem
                          sx={{ pl: 0 }}
                          secondaryAction={
                            <Grid container spacing={1}>
                              <Grid item>
                                <IconButton edge="end" onClick={handleShowFilters(index)}>
                                  {showFilters.includes(bookmark.id) ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                              </Grid>
                            </Grid>
                          }>
                          <ListItemButton onClick={handleApplyFilters(bookmark)}>
                            <ListItemText primary={<strong>{bookmark.name}</strong>} />
                          </ListItemButton>
                        </ListItem>
                        <Collapse in={showFilters.includes(index)} timeout="auto" unmountOnExit>
                          <PaperLocal sx={{ m: 1, mt: 0 }}>
                            <ViewFilterBookmark bookmark={bookmark} />
                          </PaperLocal>
                        </Collapse>
                      </React.Fragment>
                    )
                  })}
                </List>
              )}
              {prBookmark.data?.results.length === undefined ||
                (prBookmark.data?.results.length === 0 && (
                  <Alert severity="info">{t("No filters bookmarks found.")}</Alert>
                ))}
            </>
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <>
              {paging?.filters !== undefined && paging.filters.length > 0 && (
                <Paper sx={{ p: 1 }}>
                  <FormBox form={form} onSubmit={handleSubmit} showCancel={false}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FhMuiTextField control={form.control} name="name" />
                      </Grid>
                      <Grid item xs={12}>
                        <FhMuiTextField control={form.control} name="notes" multiline />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Box sx={{ p: 2, pb: 0 }}>
                          <ViewProperty label={t("Sort")}>
                            {paging.ordering !== undefined
                              ? `${nameToLabel(paging.ordering.field)} - ${paging.ordering.direction}`
                              : t("Default")}
                          </ViewProperty>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Box sx={{ p: 2, pb: 0 }}>
                          <ViewProperty label={t("Limit")}>{paging.limit ?? t("Default")}</ViewProperty>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ p: 2, pb: 0 }}>
                          <ViewProperty label={t("Filters")}>
                            <List dense>
                              {paging.filters.map((filter, index) => (
                                <ListItem key={index}>
                                  <ListItemText primary={`${filter.title} - ${filter.display}`} />
                                </ListItem>
                              ))}
                            </List>
                          </ViewProperty>
                        </Box>
                      </Grid>
                    </Grid>
                  </FormBox>
                </Paper>
              )}
            </>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <DialogControls buttonLabel={t("Close")} onSave={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FilterBookmarksDialog
