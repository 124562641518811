import React, { useCallback, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { type ICompany } from "../../../../../shared/models/service/ICompany"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import MetadataForm from "../../../../../shared/components/metadata/MetadataForm"
import { useMetadataExists } from "../../../../../shared/components/metadata/UseMetadataExists"
import AddressSearch from "../../locations/components/AddressSearch"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import FhMap from "../../../../../shared/components/forms/FhMap"
import {
  type ILocation,
  LOCATION_CITY_ENDPOINT,
  LOCATION_COUNTRY_ENDPOINT,
  LOCATION_STATE_REGION_ENDPOINT,
} from "../../../../../shared/models/service/ILocation"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import type { IListItem } from "../../../../../shared/models/components/IListItem"

interface IProps {
  form: UseFormReturn
  company?: ICompany | undefined | null
  isEdit?: boolean
}

const cityRepository = new RestRepository<IListItem>(LOCATION_CITY_ENDPOINT)
const stateRepository = new RestRepository<IListItem>(LOCATION_STATE_REGION_ENDPOINT)
const countryRepository = new RestRepository<IListItem>(LOCATION_COUNTRY_ENDPOINT)

/**
 * Use this forms to add or edit a company.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const CompanyForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { company, form, isEdit = false } = props
  
  const metadataExists = useMetadataExists("company")

  const [addressSearch, setAddressSearch] = useState<string>("")
  const [redraw, setRedraw] = useState<boolean>(false)

  const [city, setCity] = useSelectFilteredSingle("city", form)
  const [stateRegion, setStateRegion] = useSelectFilteredSingle("state_region", form)
  const [country, setCountry] = useSelectFilteredSingle("country", form)

  useLoadFormData<ICompany>(
    (data: ICompany) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("notes", data.notes)
      form.setValue("metadata_data", data.metadata_data)
      form.setValue("address", data.address)
      form.setValue("address_2", data.address_2)
      form.setValue("postal_code", data.postal_code)
      form.setValue("longitude", data.longitude)
      form.setValue("latitude", data.latitude)
      form.setValue("zoom", data.zoom)

      setCity({ id: data.city, name: data.city })
      setStateRegion({ id: data.state_region, name: data.state_region })
      setCountry({ id: data.country, name: data.country })
      setAddressSearch(data.address)
      setRedraw(redraw => !redraw)
    },
    company,
    isEdit,
    form.setValue,
  )

  const handleAddressSearch = useCallback(() => {
    setAddressSearch(form.getValues("address") as string)
  }, [form])

  const handleFoundAddress = useCallback(
    (data: ILocation) => {
      form.setValue("address", data.address)
      form.setValue("address_2", data.address_2)
      form.setValue("postal_code", data.postal_code)
      form.setValue("longitude", data.longitude)
      form.setValue("latitude", data.latitude)
      form.setValue("metadata_data", data.metadata_data)
      setCity({ id: data.city, name: data.city })
      setStateRegion({ id: data.state_region, name: data.state_region })
      setCountry({ id: data.country, name: data.country })
      setRedraw(redraw => !redraw)
    },
    [form, redraw],
  )

  const handleRedraw = useCallback(async () => {
    setRedraw(redraw => !redraw)
  }, [redraw])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaperLocal>
              {isEdit && <FhMuiHiddenField control={form.control} />}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
          <Grid item xs={12}>
            <PaperLocal>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FhMuiRichTextField control={form.control} name="notes" />
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
          {metadataExists && (
            <Grid item xs={12}>
              <PaperLocal>
                <MetadataForm modelName="company" form={form} />
              </PaperLocal>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PaperLocal>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <FhMuiTextField control={form.control} name="address" onChange={handleAddressSearch} />
            </Grid>
            <Grid item>
              <AddressSearch address={addressSearch} onFound={handleFoundAddress} />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="address_2" />
            </Grid>
            <Grid item xs={12} lg={8}>
              <SelectFilteredSingle
                freeSolo
                showId={false}
                name="city"
                defaultValue={city}
                repository={cityRepository}
                onChange={setCity}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FhMuiTextField control={form.control} name="postal_code" />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                freeSolo
                showId={false}
                name="state_region"
                defaultValue={stateRegion}
                repository={stateRepository}
                onChange={setStateRegion}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFilteredSingle
                freeSolo
                showId={false}
                name="country"
                defaultValue={country}
                repository={countryRepository}
                onChange={setCountry}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FhMuiTextField control={form.control} name="zoom" />
            </Grid>
            <Grid item xs={12} md={4}>
              <FhMuiTextField control={form.control} name="latitude" onChange={handleRedraw} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FhMuiTextField control={form.control} name="longitude" onChange={handleRedraw} />
            </Grid>
            <Grid item xs={12}>
              <FhMap form={form} redraw={redraw} />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default CompanyForm
