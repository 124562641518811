import React, { useMemo } from "react"
import { Button } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { type ItemPrefixes } from "../../../config/config"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"

interface IProps {
  prefix: ItemPrefixes
}

/**
 * Renders an "Add Recommendation" button that directs the user to the corresponding edit path.
 *
 * @param {IProps} props - The input props object.
 * @returns {React.ReactElement} The rendered "Add Recommendation" button.
 */
const ItemEditorAddButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { prefix } = props

  const location = useLocation()

  const { t } = useTranslation()

  const buildEditPath = useMemo(() => {
    return `${location.pathname}/${prefix}add`
  }, [location.pathname, prefix])

  return (
    <>
      <Button startIcon={<AddIcon />} component={Link} to={buildEditPath}>
        {t("Add")}
      </Button>
    </>
  )
}
export default ItemEditorAddButton
