import React, { useContext } from "react"
import { alpha, type CSSObject, Divider, List, type Theme } from "@mui/material"
import NavMenuLink from "../layouts/components/NavMenuLink"
import { styled } from "@mui/material/styles"
import MuiDrawer from "@mui/material/Drawer"
import {
  ADDON_COMPANIES,
  ADDON_IMPAIRMENTS,
  ADDON_SUMMARIES,
  ADDON_UI_IMPORTER,
  ADDON_VEHICLES,
  DRAWER_WIDTH,
} from "../../config/config"
import useAuth from "../hooks/useAuth"
import { PanariskAppContext } from "../../app/PanariskApp"
import { useTranslation } from "react-i18next"

export interface ISideNav {
  icon?: React.ComponentType
  url: string
  name: string
  children?: ISideNav[] | null
  accessGroups?: string[]
  addon?: string
}

/**
 * A function that returns a CSS object with styles for an opened drawer.
 *
 * @param {Theme} theme - The theme object containing transition properties.
 * @returns {CSSObject} - The CSS object with styles for an opened drawer.
 */
const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: alpha(theme.palette.text.secondary, 0.05),
  border: "none",
  overflowX: "hidden",
})

/**
 * A mixin that applies styles for a closed component.
 *
 * @param {Theme} theme - The theme object.
 * @returns {CSSObject} the css
 */
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: alpha(theme.palette.text.secondary, 0.05),
  border: "none",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== "open" })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  backgroundColor: theme.palette.background.paper,
  boxSizing: "border-box",
  ...(open === true && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(open === false && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

interface IProps {
  drawerOpen: boolean
  children?: React.ReactNode
  sideMenu?: Array<ISideNav | null | boolean> | null
}

/**
 * Renders the side navigation menu component.
 *
 * @param {IProps} props - The props object for the component.
 * @returns {React.ReactElement} The rendered side navigation menu.
 */
const SideNavMenu: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { drawerOpen, children, sideMenu } = props
  const { currentUser, isUserInGroups } = useAuth()
  const { appSettings } = useContext(PanariskAppContext)
  const { t } = useTranslation()

  return (
    <>
      <Drawer variant={"permanent"} open={drawerOpen}>
        <DrawerHeader />
        <List
          sx={{
            "& .MuiButtonBase-root.Mui-selected": (theme: Theme) => ({
              backgroundColor: alpha(theme.palette.primary.main, 0.13),
              color: theme.palette.primary.main,
            }),
            "& .MuiButtonBase-root": (theme: Theme) => ({ color: alpha(theme.palette.text.secondary, 0.6) }),
          }}>
          {sideMenu?.map((sideNav, index) => {
            if (sideNav === true || sideNav === false) {
              return null
            }
            if (sideNav?.accessGroups !== undefined && isUserInGroups?.(sideNav?.accessGroups, currentUser) === false) {
              return null
            }
            if (sideNav?.addon === ADDON_SUMMARIES && appSettings.serverInfo?.addons.includes(ADDON_SUMMARIES) === false) {
              return null
            }
            if (sideNav?.addon === ADDON_IMPAIRMENTS && appSettings.serverInfo?.addons.includes(ADDON_IMPAIRMENTS) === false) {
              return null
            }
            if (sideNav?.addon === ADDON_VEHICLES && appSettings.serverInfo?.addons.includes(ADDON_VEHICLES) === false) {
              return null
            }
            if (sideNav?.addon === ADDON_COMPANIES && appSettings.serverInfo?.addons.includes(ADDON_COMPANIES) === false) {
              return null
            }
            if (sideNav?.addon === ADDON_UI_IMPORTER && appSettings.serverInfo?.addons.includes(ADDON_UI_IMPORTER) === false) {
              return null
            }
            if (sideNav === null) {
              return <Divider key={index} />
            }
            if (!drawerOpen && sideNav.icon === undefined) {
              return null
            }
            if (sideNav.children !== undefined && sideNav.children !== null && sideNav.children.length > 0) {
              return (
                <NavMenuLink key={index} icon={sideNav.icon} url={sideNav.url} name={sideNav.name} open={drawerOpen}>
                  {drawerOpen && (
                    <>
                      {sideNav.children.map((child, index) => {
                        return <NavMenuLink key={index} url={child.url} name={t(child.name)} open={drawerOpen} />
                      })}
                    </>
                  )}
                </NavMenuLink>
              )
            }

            return (
              <NavMenuLink key={index} icon={sideNav.icon} url={sideNav.url} name={t(sideNav.name)} open={drawerOpen} />
            )
          })}
        </List>
        {children}
      </Drawer>
    </>
  )
}

export default SideNavMenu
