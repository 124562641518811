import React, { useCallback, useState } from "react"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import AddIcon from "@mui/icons-material/ImportExport"
import { saveAs } from "file-saver"
import DrawerRight from "../../../../shared/components/containers/DrawerRight"
import UploadIcon from "@mui/icons-material/Upload"
import FileDropZone from "../../../../shared/components/files/FileDropZone"
import type { IFileUpload } from "../../../../shared/models/components/IFileUpload"
import * as Xlsx from "xlsx"
import { useForm } from "react-hook-form"
import FhMuiTextField from "../../../../shared/components/forms/FhMuiTextField"
import { nameToLabel } from "../../../../shared/utilities/form_utility"
import FhMuiCheckboxField from "../../../../shared/components/forms/FhMuiCheckboxField"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import type { IListItem } from "../../../../shared/models/components/IListItem"
import { LOCATION_ENDPOINT } from "../../../../shared/models/service/ILocation"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import { useAxiosRequest } from "../../../../shared/hooks/useAxiosRequest"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../shared/models/service/IAccount"
import SelectFilteredMultiple, {
  useSelectFilteredMultiple,
} from "../../../../shared/components/forms/SelectFilteredMultiple"
import { ItemPrefixes } from "../../../../config/config"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import AccountInfo from "../accounts/components/AccountInfo"
import ViewLoading from "../../../../shared/components/ViewLoading"
import DownloadIcon from "@mui/icons-material/Download"
import { useTranslation } from "react-i18next"

const accountRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)

export enum Models {
  LOCATION,
}

/**
 * Retrieves a repository based on the given model.
 *
 * @param {Models | undefined} model - The model to retrieve the repository for.
 * @returns {RestRepository<IMainModel>} The repository for the given model, or null if not found.
 */
const getRepo = (model: Models | null): RestRepository<IMainModel> | null => {
  if (model === Models.LOCATION) {
    return new RestRepository<IMainModel>(LOCATION_ENDPOINT)
  }
  return null
}

/**
 * A function to retrieve the filename associated with a specific model.
 *
 * @param {Models | null} model - The model for which the filename is being determined.
 *                                It can either be a valid `Models` value or `null`.
 * @returns {string | null} The filename associated with the provided model.
 *                          Returns `"locations"` if the input model is `Models.LOCATION`,
 *                          otherwise returns `null`.
 */
const getFilename = (model: Models | null): string | null => {
  if (model === Models.LOCATION) {
    return "locations"
  }
  return null
}

/**
 * Retrieves an array of column definitions based on the provided model.
 *
 * @param {Models | null} model - The model specifying the type of columns to retrieve. If the model is `Models.LOCATION`,
 * location-specific columns will be returned. If null or other values, null will be returned.
 * @returns {Array<Record<string, string>> | null} Returns an array of column definitions if a valid model is provided,
 * or null otherwise.
 */
const getColumns = (model: Models | null): Array<Record<string, string>> | null => {
  if (model === Models.LOCATION) {
    return [
      {
        name: "",
        address: "",
        address_2: "",
        city: "",
        state_region: "",
        postal_code: "",
        country: "",
        longitude: "",
        latitude: "",
        area: "",
        notes: "",
      },
    ]
  }
  return null
}

export const AVAILABLE_MODELS: IListItem[] = [
  {
    id: Models.LOCATION,
    name: "Location",
  },
]

/**
 * Renders the index page with statistics and top impairments/recommendations.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const [data, setData] = useState<Array<Record<string, any>>>([])
  const [model, setModel] = useState<Models>(Models.LOCATION)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const axiosRequest = useAxiosRequest()

  const { t } = useTranslation()

  const form = useForm()

  const [accounts, setAccounts] = useSelectFilteredMultiple("accounts", form)

  const handleModelChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setModel(parseInt(event.target.value))
  }, [])

  const handleFileUpdate = useCallback(async (files: IFileUpload[]) => {
    if (files.length > 0) {
      const file = files[0]
      const reader = new FileReader()
      reader.onloadstart = () => {
        setLoading(true)
      }
      reader.onload = event => {
        if (event.target?.result !== undefined && event.target.result !== null) {
          const data = new Uint8Array(event.target.result as ArrayBuffer)
          const workbook = Xlsx.read(data, { type: "array" })
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const jsonData = Xlsx.utils.sheet_to_json(worksheet, { defval: "" })
          setData(jsonData as Array<Record<string, any>>)
          let index = 0
          for (const row of jsonData as Array<Record<string, any>>) {
            form.setValue(`data.${index}.checked`, true)
            for (const key in row) {
              form.setValue(`data.${index}.${key}`, row[key])
            }
            index++
          }
        }
        setLoading(false)
      }
      reader.readAsArrayBuffer(file.file)
    }
  }, [])

  const handleImportData = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      setLoading(true)
      const repository = getRepo(model)
      const dataImport = (form.getValues("data") as Array<Record<string, any>>).filter(row => row.checked as boolean)
      let index = 0
      for (const row of dataImport) {
        const newItem = { ...row, accounts: accounts.map(account => account.id) } as any
        await repository?.add(newItem as IMainModel)
        index++
        setProgress(Math.round((index / dataImport.length) * 100))
      }
      setLoading(false)
      setData([])
    })
  }, [model, accounts])

  const handleTemplateDownload = useCallback(() => {
    const filename = getFilename(model)
    const columns = getColumns(model)

    if (filename !== null && columns !== null) {
      // Create a new workbook and a worksheet
      const workbook = Xlsx.utils.book_new()
      const worksheet = Xlsx.utils.json_to_sheet(columns)

      // Append the worksheet to the workbook
      Xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1")

      // Write the workbook to a binary array
      const excelBuffer = Xlsx.write(workbook, { bookType: "xlsx", type: "array" })

      // Create a Blob from the binary array
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" })

      // Use file-saver to save the Blob as a file
      saveAs(blob, `${filename}.xlsx`)
    }
  }, [model])

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <PageHeader
          title={t("Import")}
          extraOptions={
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <DrawerRight title={t("File Import")} icon={<UploadIcon />}>
                  <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                      <FileDropZone single onChange={handleFileUpdate} loading={loading} />
                    </Grid>
                  </Grid>
                </DrawerRight>
              </Grid>
            </Grid>
          }
        />
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                select
                label={t("Destination")}
                name="model"
                value={model}
                onChange={handleModelChange}>
                {AVAILABLE_MODELS.map(model => {
                  return (
                    <MenuItem key={model.id} value={model.id}>
                      {model.name}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            {model === Models.LOCATION && (
              <Grid item xs={12} lg>
                <ItemViewerDrawer title={t("Account")} prefix={ItemPrefixes.account} infoView={AccountInfo} />
                <SelectFilteredMultiple
                  name="accounts"
                  defaultValue={accounts}
                  repository={accountRepository}
                  onChange={setAccounts}
                  itemViewPrefix={ItemPrefixes.account}
                />
              </Grid>
            )}
            <Grid item>
              <Button startIcon={<DownloadIcon />} onClick={handleTemplateDownload} disabled={loading}>
                {t("Template")}
              </Button>
            </Grid>
            <Grid item>
              <Button startIcon={<AddIcon />} onClick={handleImportData} disabled={loading || data.length === 0}>
                {t("Start Import")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {data.length > 0 && (
          <>
            <Grid item xs={12}>
              <ViewLoading loading={loading} inline={true} progress={progress} />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ p: 0, width: "100%", overflowY: "hidden" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {Object.keys(data[0]).map(key => (
                        <TableCell key={key}>{nameToLabel(key)}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ textAlign: "center", p: 0 }}>
                          <FhMuiCheckboxField
                            control={form.control}
                            name={`data.${index}.checked`}
                            showLabel={false}
                            disabled={loading}
                          />
                        </TableCell>
                        {Object.keys(row).map(key => (
                          <TableCell key={`data.${index}.${key}`} sx={{ p: 1 }}>
                            <FhMuiTextField
                              control={form.control}
                              name={`data.${index}.${key}`}
                              showLabel={false}
                              size="small"
                              disabled={loading}
                              sx={{
                                "& input": { textAlign: "center" },
                                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#fff" },
                              }}
                            />
                            <Box
                              sx={{
                                fontSize: 16,
                                pl: 2,
                                pr: 2,
                                height: 0,
                                visibility: "hidden",
                              }}>
                              {row[key]}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default IndexPage
