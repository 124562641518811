import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { COMPANY_ENDPOINT, type ICompany } from "../../../../../shared/models/service/ICompany"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import FormatDate from "../../../../../shared/components/format/FormatDate"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import { COMPANY_VIEW_URL } from "../../../config/urls"

const repository = new RestRepository<ICompany>(COMPANY_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const CompanyInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const { data } = useApiRead<ICompany>({ apiFunction: repository.read, itemId: infoId })

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle to={`${COMPANY_VIEW_URL}/${data.id}`}>{data.name}</ItemViewerTitle>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Created">
              <FormatDate value={data.created} />
            </ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label="Notes" vertical>
              <ViewRichText>{data.notes}</ViewRichText>
            </ViewProperty>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default CompanyInfo
