import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { COMPANY_ENDPOINT, type ICompany } from "../../../../shared/models/service/ICompany"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatDate from "../../../../shared/components/format/FormatDate"
import { COMPANY_EDIT_URL } from "../../config/urls"
import PageViewBody from "../../../../shared/components/pages/PageViewBody"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { useTranslation } from "react-i18next"
import MetadataDisplay from "../../../../shared/components/metadata/MetadataDisplay"
import { useMetadataExists } from "../../../../shared/components/metadata/UseMetadataExists"
import TruncateText from "../../../../shared/components/TruncateText"
import ViewMap from "../../../../shared/components/display/ViewMap"

const repository = new RestRepository<ICompany>(COMPANY_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data, loading, call } = useApiRead<ICompany>({ apiFunction: repository.read })
  const { t } = useTranslation()

  const metadataExists = useMetadataExists("company")

  return (
    <PageViewBody loading={loading}>
      {data !== undefined && (
        <>
          <PageHeader
            title={t("Company")}
            titlePlural={`${data.name}`}
            onRefresh={call}
            toEdit={`${COMPANY_EDIT_URL}/${data.id}`}
          />
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PaperLocal>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ViewProperty label={t("Name")}>
                            <TruncateText>{data.name}</TruncateText>
                          </ViewProperty>
                        </Grid>
                        <Grid item xs={12}>
                          <ViewProperty label={t("Created")}>
                            <FormatDate value={data.created} />
                          </ViewProperty>
                        </Grid>
                      </Grid>
                    </PaperLocal>
                  </Grid>
                  {metadataExists && (
                    <Grid item xs={12}>
                      <PaperLocal>
                        <MetadataDisplay modelName="company" data={data.metadata_data} />
                      </PaperLocal>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PaperLocal>
                      <ViewProperty label={t("Notes")} vertical>
                        <ViewRichText>{data.notes}</ViewRichText>
                      </ViewProperty>
                    </PaperLocal>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <PaperLocal>
                  <ViewMap place={data} />
                </PaperLocal>
                <PaperLocal sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ViewProperty label="Address">{data.address}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="City">{data.city}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="State/Region">{data.state_region}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Postal Code">{data.postal_code}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label="Country">{data.country}</ViewProperty>
                    </Grid>
                  </Grid>
                </PaperLocal>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </PageViewBody>
  )
}

export default ViewPage
