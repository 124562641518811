import React, { useCallback, useState } from "react"
import DrawerRight from "../containers/DrawerRight"
import UploadIcon from "@mui/icons-material/Upload"
import { Grid } from "@mui/material"
import FileDropZone from "./FileDropZone"
import MuiFile from "../forms/MuiFile"
import useAzureStorage from "../../hooks/useAzureStorage"
import type { IStorageFile } from "../../models/service/IStorageFile"
import type { IFileUpload } from "../../models/components/IFileUpload"
import { useTranslation } from "react-i18next"

interface IProps {
  selectedFile: IStorageFile | null | undefined
  onDrawerClose?: () => Promise<void>
}

/**
 * FileUploadDrawer is a React functional component that provides a user interface for uploading files using Azure Storage.
 * It renders a drawer with a file upload UI and manages the upload process.
 *
 * @param {IProps} props - The properties object.
 * @param {File | null | undefined} props.selectedFile - The selected file to be uploaded.
 * @param {Function} [props.onDrawerClose] - Callback function that is called when the drawer is closed.
 * @returns {React.ReactElement} The rendered component.
 */
const FileUploadDrawer: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { selectedFile, onDrawerClose } = props
  const [saving, setSaving] = useState(false)
  const { progress, uploadFile } = useAzureStorage()

  const { t } = useTranslation()

  const handleFileUpdate = useCallback(
    async (files: IFileUpload[]) => {
      if (selectedFile !== undefined && selectedFile !== null) {
        setSaving(true)

        const fileUpload = files[0]
        await uploadFile(selectedFile, fileUpload.file)

        await onDrawerClose?.()
        setSaving(false)
      }
    },
    [selectedFile, onDrawerClose],
  )

  return (
    <DrawerRight
      title={t("File Upload")}
      icon={<UploadIcon />}
      stayOpen={saving}
      showButton={false}
      onDrawerClose={onDrawerClose}
      openCloseWithUrl={true}
      openUrlPrefix="file_upload">
      <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <FileDropZone single onChange={handleFileUpdate} loading={saving} progress={progress} />
        </Grid>
        <Grid item xs={12}>
          {!saving && <MuiFile single onChange={handleFileUpdate} clear={!saving} />}
        </Grid>
      </Grid>
    </DrawerRight>
  )
}

export default FileUploadDrawer
