import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import ContactForm from "./components/ContactForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { CONTACT_ENDPOINT, type IContact } from "../../../../shared/models/service/IContact"
import { CONTACT_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IContact>(CONTACT_ENDPOINT)
const redirectView = CONTACT_VIEW_URL

/**
 * This page will allow editing of a contact.
 *
 * @returns {React.FC} the contact edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IContact>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IContact> = { apiFunction: repository.edit, setError: form.setError, redirectView }
  const apiEdit = useApiEdit<IContact>(props)
  const { t } = useTranslation()

  const handleSave = useCallback(
    async (contact: IContact) => {
      contact.latitude = contact.latitude === "" ? 0 : contact.latitude
      contact.longitude = contact.longitude === "" ? 0 : contact.longitude
      contact.zoom = contact.zoom === "" ? 0 : contact.zoom
      await apiEdit.handleEdit(contact)
    },
    [apiEdit],
  )

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader
          title={t("Edit Contact")}
          loading={apiEdit.saving || apiRead.loading}
          errors={form.formState.errors}
        />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={handleSave}>
          <ContactForm contact={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
