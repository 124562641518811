import { type TMetadataData } from "./IMetadataConfig"
import { type ICompany } from "./ICompany"
import { type IContact } from "./IContact"
import type moment from "moment/moment"

export const VEHICLE_ENDPOINT = "vehicles"

export interface IVehicle {
  id: number
  /**
   * Represents the unique name of the vehicle.
   */
  name: string

  /**
   * The license plate number of the vehicle.
   */
  license_plate: string | null

  /**
   * The VIN (Vehicle Identification Number) of the vehicle.
   */
  vin: string | null

  /**
   * The year of manufacture for the vehicle.
   */
  year: string | null

  /**
   * The make (manufacturer) of the vehicle.
   */
  make: string | null

  /**
   * The model of the vehicle.
   */
  model: string | null

  /**
   * The color of the vehicle.
   */
  color: string | null

  /**
   * The purchase price of the vehicle.
   */
  purchase_price: number | null

  /**
   * The date of purchase for the vehicle.
   */
  purchase_date: string | moment.Moment | null

  /**
   * The registration date of the vehicle.
   */
  registration_date: string | moment.Moment | null

  /**
   * The renewal date of the vehicle's registration.
   */
  renewal_date: string | moment.Moment | null

  /**
   * A reference to the associated company.
   */
  company: ICompany | null

  /**
   * A list of associated contacts.
   */
  contacts: IContact[]

  /**
   * Notes about the vehicle.
   */
  notes: string | null

  /**
   * JSON metadata associated with the vehicle.
   */
  metadata_data: TMetadataData | null

  /**
   * The date the vehicle was created in the system.
   */
  created: string

  /**
   * The date the vehicle was last updated in the system.
   */
  updated: string
}
