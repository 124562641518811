import { type ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { type IPagedResults } from "../models/components/IPagedResults"
import { type IPaging } from "../models/components/IPaging"
import { CONNECTION_ERROR, type IConnectionError } from "../models/components/IConnectionError"
import { type IOrdering } from "../models/components/IOrdering"
import { type IFilter } from "../models/components/IFilter"
import { type AxiosError } from "axios"
import { RestRepository } from "../repositories/RestRepository"
import { type IMainModel } from "../models/service/IMainModel"
import useLocalStorage from "beautiful-react-hooks/useLocalStorage"
import { useLocation } from "react-router-dom"
import { StoragePrefixes } from "./useLocalStorageHelpers"
import type { Location } from "@remix-run/router"
import { getBaseApp } from "../utilities/request_utility"

export interface IUseApiPagedResultsResponse<T> {
  call: (paging1?: IPaging) => Promise<void>
  loading: boolean
  count: number
  paging?: IPaging | null
  error: IConnectionError | undefined
  data: IPagedResults<T> | undefined
  handlePaging: (_e: ChangeEvent<unknown> | null, page: number) => void
  handleOrdering: (ordering: IOrdering) => void
  handleLimit: (limit: number) => void
  handleFilter: (filters: IFilter[]) => void
  handleCompletePaging: (paging: IPaging) => void
}

export interface IUseApiPagedResultsProps<T> {
  apiFunction: (paging?: IPaging | null) => Promise<IPagedResults<T>>
  prefix?: string
  dontCallOnMount?: boolean
  initialFilters?: IFilter[]
  defaultLimit?: number
}

/**
 * Retrieves the storage key for a given location and prefix.
 *
 * @param {Location} location - The current location object.
 * @param {string} prefix - The prefix to prepend to the storage key.
 * @returns {string} - The storage key.
 */
const getStorageKey = (location: Location, prefix: string): string => {
  const storageKey = location.pathname.replace(/\/$/, "").replace(/^\//, "")
  return `${StoragePrefixes.paging}${getBaseApp()}_${prefix}_${storageKey}`
}

/**
 * This hook will do all the heavy lifting of paging api results.
 *
 * @param {IUseApiPagedResultsProps} props see IUseApiEditProps<T> for details.
 * @returns {IUseApiPagedResultsResponse} edit state
 */
export const useApiPagedLocal = <T>(props: IUseApiPagedResultsProps<T>): IUseApiPagedResultsResponse<T> => {
  const { apiFunction, prefix = "P", dontCallOnMount, initialFilters = [], defaultLimit = 10 } = props
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const storageKey = getStorageKey(location, prefix)
  const defaultValue: IPaging = { filters: initialFilters, page: 1, offset: 0, limit: defaultLimit }
  const [paging, setPaging] = useLocalStorage<IPaging>(storageKey, defaultValue)
  const [error, setError] = useState<IConnectionError | undefined>()
  const [data, setData] = useState<IPagedResults<T> | undefined>()
  const didFirstLoad = useRef<boolean>(false)

  const limit = useMemo(() => (paging?.limit !== undefined ? paging.limit : 5), [paging])
  const count = useMemo(() => (data?.count !== undefined ? Math.ceil(data.count / limit) : 0), [data, limit])

  const call = useCallback(
    async (paging1?: IPaging) => {
      setLoading(true)
      try {
        const results = await apiFunction(paging1 ?? paging)
        setData(results)
        setError(undefined)
      } catch (reason) {
        setData(undefined)
        if ((reason as AxiosError)?.response !== undefined) {
          setError((reason as AxiosError).response as IConnectionError)
        } else {
          setError(CONNECTION_ERROR)
        }
      }
      setLoading(false)
    },
    [apiFunction, paging],
  )

  const handlePaging = useCallback(
    async (_e: ChangeEvent<unknown> | null, page: number) => {
      const paging1: IPaging = { ...paging, page, offset: (page - 1) * limit }
      setPaging(paging1)
      await call(paging1)
    },
    [paging, limit, call],
  )

  const handleLimit = useCallback(
    async (newLimit: number) => {
      const paging1: IPaging = { ...paging, limit: newLimit }
      setPaging(paging1)
      await call(paging1)
    },
    [paging, call],
  )

  const handleOrdering = useCallback(
    async (ordering: IOrdering) => {
      const paging1: IPaging = { ...paging, ordering }
      setPaging(paging1)
      await call(paging1)
    },
    [paging, call],
  )

  const handleFilter = useCallback(
    async (filters: IFilter[]) => {
      const paging1 = { ...paging, filters, offset: 0, page: 1 }
      setPaging(paging1)
      await call(paging1)
    },
    [paging, call],
  )

  const handleCompletePaging = useCallback(
    async (paging2: IPaging) => {
      const paging1 = { ...paging, ...paging2 }
      setPaging(paging1)
      await call(paging1)
    },
    [paging, call],
  )

  useEffect(() => {
    if (!didFirstLoad.current && !loading && (dontCallOnMount === undefined || !dontCallOnMount)) {
      didFirstLoad.current = true
      void (async () => {
        await call()
      })()
    }
  }, [])

  return {
    call,
    loading,
    error,
    data,
    count,
    paging,
    handlePaging,
    handleLimit,
    handleOrdering,
    handleFilter,
    handleCompletePaging,
  }
}

/**
 * Executes an API call with pagination using a specified endpoint and filters.
 * This function returns paged results.
 *
 * @template T - The type of the main model.
 * @param {string} endpoint - The API endpoint to call.
 * @param {IFilter[]} filters - The filters to apply to the API call.
 * @param {string} [prefix] - An optional prefix for the endpoint.
 *
 * @returns {IUseApiPagedResultsResponse<T>} - The paged results response.
 */
export const useOnDemandPaged = <T extends IMainModel>(
  endpoint: string,
  filters: IFilter[],
  prefix?: string,
): IUseApiPagedResultsResponse<T> => {
  const location = useLocation()
  if (prefix !== undefined) {
    const storageKey = getStorageKey(location, prefix)
    localStorage.removeItem(storageKey)
  }
  const repository = useMemo(() => new RestRepository<T>(endpoint), [endpoint])
  const fileProps: IUseApiPagedResultsProps<T> = {
    prefix,
    apiFunction: repository.findAll,
    dontCallOnMount: true,
    initialFilters: filters,
  }
  return useApiPagedLocal<T>(fileProps)
}
