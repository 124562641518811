import React from "react"
import { Container, Grid } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import VehicleForm from "./components/VehicleForm"
import { VEHICLE_ENDPOINT, type IVehicle } from "../../../../shared/models/service/IVehicle"
import { VEHICLE_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IVehicle>(VEHICLE_ENDPOINT)
const redirectView = VEHICLE_VIEW_URL

/**
 * This page will allow adding of a vehicle.
 *
 * @returns {React.FC} the vehicle add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const { t } = useTranslation()

  const props: IUseApiAddProps<IVehicle> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const apiAdd = useApiAdd<IVehicle>(props)

  return (
    <Container fixed>
      <FormProvider {...form}>
        <Grid container spacing={2}>
          <FormHeader title={t("Add Vehicle")} loading={apiAdd.saving} errors={form.formState.errors} />
          <FormErrors connectionError={apiAdd.connectionError} />
          <FormBox form={form} onSubmit={apiAdd.handleAdd}>
            <VehicleForm form={form} />
          </FormBox>
        </Grid>
      </FormProvider>
    </Container>
  )
}

export default AddPage
