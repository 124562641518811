import React, { useCallback, useState } from "react"
import { Button, Grid } from "@mui/material"
import { useFilterDataAvailable } from "./FiltersData"
import { RestRepository } from "../../repositories/RestRepository"
import { type IListItem } from "../../models/components/IListItem"
import { CONTACT_ENDPOINT } from "../../models/service/IContact"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import AddIcon from "@mui/icons-material/Add"
import { type IFilter } from "../../models/components/IFilter"
import { useTranslation } from "react-i18next"

interface IProps {
  field: string
  children?: React.JSX.Element[]
}

const repository = new RestRepository<IListItem>(CONTACT_ENDPOINT)

/**
 * Filters by contact based on the provided props.
 *
 * @param {IProps} props - The props object containing the field to be filtered.
 * @returns {React.ReactElement} - The filtered contact component.
 */
const FilterContact = (props: IProps): React.ReactElement => {
  const { field } = props

  const [item, setItem] = useState<IListItem | null>(null)
  const filtersDataContext = useFilterDataAvailable(field, "Contact")
  const { t } = useTranslation()

  const handleAddFilter = useCallback(() => {
    const filter: IFilter = { title: "Contact", field, value: item?.id, display: item?.name }
    filtersDataContext?.addFilter(filter)
  }, [item, field, filtersDataContext?.addFilter])

  return (
    <>
      {filtersDataContext?.selectedFilter?.value === field && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="contact"
              label={t("Contact")}
              defaultValue={item}
              repository={repository}
              onChange={setItem}
            />
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button startIcon={<AddIcon />} onClick={handleAddFilter}>
              {t("Add Filter")}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default FilterContact
