import React from "react"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../../shared/models/service/IAccount"
import { ACCOUNT_ADD_URL, ACCOUNT_VIEW_URL } from "../../../config/urls"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import TableData from "../../../../../shared/components/tables/TableData"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import TableCellContact from "../../../../../shared/components/tables/TableCellContact"
import TableCellListItems from "../../../../../shared/components/tables/TableCellListItems"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import TableActions from "../../../../../shared/components/tables/TableActions"
import ExcelDownloadButton from "../../../../../shared/components/ExcelDownloadButton"
import type { IFilter } from "../../../../../shared/models/components/IFilter"
import {
  useMetadataTableFilter,
  useMetadataTableHeader,
  useMetadataTableRow,
} from "../../../../../shared/components/metadata/UseMetadataTable"
import { useTranslation } from "react-i18next"
import FilterAccountManager from "../../../../../shared/components/filters/FilterAccountManager"
import FilterContact from "../../../../../shared/components/filters/FilterContact"

const repository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(ACCOUNT_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IAccount> | undefined
  showHeader?: boolean
  gotoFilters?: IFilter[]
}

const modelName = "account"

/**
 * The IndexPage component is a React functional component that renders a page for displaying
 * a list of accounts with various details like identifier, name, account managers, primary contact,
 * and locations. It supports pagination and filtering of account data.
 *
 * @param {IProps} props - The properties passed to the component.
 * @param {object} props.pagingResults - The paginated results containing account data to be displayed.
 * @param {boolean} props.showHeader - A flag indicating whether to show the page header.
 * @param {Function} props.gotoFilters - Callback function invoked for navigating to filters.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const AccountIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, gotoFilters } = props
  const { t } = useTranslation()

  const metadataTableHeader = useMetadataTableHeader(modelName, pagingResults)
  const metadataTableRow = useMetadataTableRow(modelName)
  const metadataTableFilters = useMetadataTableFilter(modelName)

  return (
    <>
      {showHeader && <PageHeader title={t("Account")} titlePlural="Accounts" toAdd={ACCOUNT_ADD_URL} />}
      {pagingResults !== undefined && (
        <>
          <TableData
            pagingResults={pagingResults}
            to={ACCOUNT_VIEW_URL}
            repository={gotoRepo}
            gotoFilters={gotoFilters}>
            <TableActions>
              <ExcelDownloadButton repository={repository} paging={pagingResults.paging} />
            </TableActions>
            <FiltersData pagingResults={pagingResults} modelName="account">
              <FilterSearch />
              <FilterAccountManager field="account_managers" />
              <FilterContact field="contact" />
              {metadataTableFilters()}
            </FiltersData>
            <TableHead>
              <TableCellCenter field="identifier">Identifier</TableCellCenter>
              <TableCellCenter field="name" pagingResults={pagingResults}>
                Name
              </TableCellCenter>
              <TableCellCenter field="account_managers">Account Managers</TableCellCenter>
              <TableCellCenter field="contact" pagingResults={pagingResults}>
                Primary Contact
              </TableCellCenter>
              <TableCellCenter field="location_count" pagingResults={pagingResults}>
                Locations
              </TableCellCenter>
              {metadataTableHeader()}
              <TableCellCenter field="created" pagingResults={pagingResults}>
                Created
              </TableCellCenter>
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IAccount) => (
                <TableRow key={item.id}>
                  <TableCellCenter field="identifier">{item.identifier}</TableCellCenter>
                  <TableCellData field="name">{item.name}</TableCellData>
                  <TableCellListItems field="account_managers">{item.account_managers}</TableCellListItems>
                  <TableCellContact field="contact">{item.contact}</TableCellContact>
                  <TableCellCenter field="location_count">{item.location_count}</TableCellCenter>
                  {metadataTableRow(item.metadata_data)}
                  <TableCellDate field="created">{item.created}</TableCellDate>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: IAccount) => (
              <ListDataItem key={item.id} to={`${ACCOUNT_VIEW_URL}/${item.id}`} title={item.name} />
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default AccountIndex
