import React, { useCallback, useContext, useState } from "react"
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  type Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { LOGIN_URL, PROFILE_URL } from "../../apps/admin/config/urls"

import MuiAppBar, { type AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"

import MenuIcon from "@mui/icons-material/Menu"
import { styled } from "@mui/material/styles"
import { APP_VERSION, COMPANY_NAME, DRAWER_WIDTH } from "../../config/config"
import useAuth from "../hooks/useAuth"
import { PanariskAppContext } from "../../app/PanariskApp"
import { Link, useNavigate } from "react-router-dom"
import AppHelmet from "../components/AppHelmet"
import SideNavMenu from "../components/SideNavMenu"
import { type IPageLayoutProps } from "./models/IPageLayoutProps"
import { SnackbarProvider } from "notistack"
import MessageNotifications from "../components/MessageNotifications"
import ViewImage from "../components/display/ViewImage"
import { ABOUT_URL } from "../../apps/recs/config/urls"
import Maintenance from "../components/Maintenance"
import ErrorBoundary from "../components/ErrorBoundry"
import { useTranslation } from "react-i18next"
import HelpDocsDrawer from "../components/help/HelpDocsDrawer"
import TruncateText from "../components/TruncateText"

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open === true && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

/**
 * Default layout for the site.
 *
 * @param {IPageLayoutProps} props See IProps for details.
 * @returns {React.FC<IPageLayoutProps>} the default layout.
 */
const DefaultLayout: React.FC<IPageLayoutProps> = (props: IPageLayoutProps): React.ReactElement => {
  const { children, component: Component, title } = props
  const { currentUser, logout, isLoggedIn } = useAuth()
  const navigate = useNavigate()
  const theme = useTheme()

  const { t } = useTranslation()

  const isSmall = useMediaQuery(theme.breakpoints.down("md"))

  const { appSettings, setAppSettings, actionItem, sideMenu, siteName } = useContext(PanariskAppContext)

  const handleDrawerOpen = useCallback(() => {
    setAppSettings?.(appSettings => ({ ...appSettings, openNavMenu: true }))
  }, [])

  const handleDrawerClose = useCallback(() => {
    setAppSettings?.(appSettings => ({ ...appSettings, openNavMenu: false }))
  }, [])

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }, [])

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null)
  }, [])

  const handleLogout = useCallback(() => {
    logout?.()
    navigate(LOGIN_URL)
  }, [logout])

  const drawerOpen = appSettings.openNavMenu && !isSmall

  return (
    <>
      <SnackbarProvider>
        <AppHelmet title={`${COMPANY_NAME} | ${siteName} - ${title}`} />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ "& .MuiPaper-root": { p: 0 } }}>
            <AppBar position="fixed" color="primary" sx={{ pl: 1, pr: 1 }}>
              <Toolbar>
                {!isSmall && (
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    onClick={appSettings.openNavMenu ? handleDrawerClose : handleDrawerOpen}
                    sx={{ ml: 0, mr: 1 }}>
                    <MenuIcon />
                  </IconButton>
                )}
                <Box sx={{ mr: 2, mt: 0.5 }}>
                  <ViewImage
                    src={appSettings.serverInfo?.logo_small?.read_url}
                    height={50}
                    width={50}
                    logoBlue={false}
                  />
                </Box>
                {!isSmall ? (
                  <>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                      {appSettings?.serverInfo?.company_name} - {siteName}
                    </Typography>
                    <Box sx={{ mr: 1 }}>
                      <Maintenance asButton={true} codeVersion={APP_VERSION} />
                    </Box>
                    <Box sx={{ mr: 1 }}>
                      <HelpDocsDrawer />
                    </Box>
                    <Typography component="div" sx={{ mr: 1, fontWeight: "bold" }}>
                      {t("Hi")} {currentUser?.user.first_name}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
                      <TruncateText>{`${appSettings?.serverInfo?.company_name} - ${siteName}`}</TruncateText>
                    </Typography>
                    {actionItem}
                  </>
                )}
                <Box sx={{ flexGrow: 0 }}>
                  {!isSmall && (
                    <Tooltip title={t("Open Profile Options")}>
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        {currentUser?.user.profile.social_photo !== undefined &&
                        currentUser?.user.profile.social_photo !== null ? (
                          <Avatar
                            alt={currentUser.user.name}
                            src={currentUser.user.profile.social_photo}
                            sx={{ border: "2px solid white" }}
                            variant="circular"
                          />
                        ) : (
                          <Avatar alt={currentUser?.user.name} sx={{ border: "2px solid white" }} variant="circular" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    keepMounted
                    transformOrigin={{ vertical: isSmall ? "bottom" : "top", horizontal: "right" }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}>
                    <MenuItem component={Link} to={PROFILE_URL}>
                      <Typography textAlign="center">{t("Profile")}</Typography>
                    </MenuItem>
                    <MenuItem component={Link} to={ABOUT_URL}>
                      <Typography textAlign="center">{t("About")}</Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                      <Typography textAlign="center">{t("Logout")}</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Toolbar>
            </AppBar>
            <SideNavMenu drawerOpen={drawerOpen} sideMenu={sideMenu}>
              {isSmall && (
                <Box sx={{ position: "absolute", bottom: 10, left: 9, zIndex: 1000 }}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {currentUser?.user.profile.social_photo !== undefined &&
                      currentUser?.user.profile.social_photo !== null && (
                        <Avatar
                          alt={currentUser.user.name}
                          src={currentUser.user.profile.social_photo}
                          sx={{ border: "2px solid white" }}
                          variant="circular"
                        />
                      )}
                  </IconButton>
                </Box>
              )}
              {isLoggedIn === true && <MessageNotifications />}
            </SideNavMenu>
          </Box>

          <Box
            sx={(theme: Theme) => ({
              p: 2,
              width: drawerOpen ? `calc(100% - ${DRAWER_WIDTH}px)` : `calc(100% - ${theme.spacing(9)})`,
            })}>
            <Toolbar />
            <ErrorBoundary>
              <>
                {children}
                {Component !== undefined && <Component {...props} />}
              </>
            </ErrorBoundary>
          </Box>
        </Box>
      </SnackbarProvider>
    </>
  )
}

export default DefaultLayout
