import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import { type IEmailTemplate } from "../../../../../shared/models/service/IEmailTemplate"
import { HtmlButton } from "react-simple-wysiwyg"
import { FormProvider } from "react-hook-form"

interface IProps {
  form: UseFormReturn
  emailTemplate?: IEmailTemplate | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit an email template.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const EmailTemplateForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { emailTemplate, form, isEdit = false } = props

  useLoadFormData<IEmailTemplate>(
    (data: IEmailTemplate) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("subject", data.subject)
      form.setValue("content", data.content)
    },
    emailTemplate,
    isEdit,
    form.setValue,
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PaperLocal>
          <FormProvider {...form}>
            {isEdit && <FhMuiHiddenField control={form.control} />}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
              </Grid>
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="subject" rules={requiredRule()} />
              </Grid>
              <Grid item xs={12}>
                <FhMuiRichTextField control={form.control} name="content" additionalButtons={<HtmlButton />} />
              </Grid>
            </Grid>
          </FormProvider>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default EmailTemplateForm
