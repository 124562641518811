import React, { createContext, useCallback, useState, useContext } from "react"
import { Alert, Box, Button, Drawer, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import { CONTACT_ENDPOINT } from "../../models/service/IContact"
import { type IListItem } from "../../models/components/IListItem"
import { RestRepository } from "../../repositories/RestRepository"
import ContactLookupInfo from "../../../apps/admin/pages/contacts/components/ContactLookupInfo"
import CloseIcon from "@mui/icons-material/Close"
import { type LookupType, LOOKUP_FIELD_LABELS } from "./constants"

export interface ILookupContext {
  drawerOpen?: boolean
  setDrawerOpen?: (open: boolean) => void
  onClose?: () => void
  lookupType?: LookupType | null
  setLookupType?: (type: LookupType) => void
  lookupField?: string
  setLookupField?: (name: string) => void
  lookupDataSelected?: string
  setLookupDataSelected?: (data: string) => void
}

/**
 * Default context for the lookup functionality
 */
export const LookupContext = createContext<ILookupContext>({})

interface IProps {
  children?: React.JSX.Element | React.JSX.Element[] | undefined
}

/**
 * Provider component for lookup functionality
 *
 * @param {IProps} props - Component properties
 * @returns {React.ReactElement} The rendered provider component
 */
export const LookupProvider: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [lookupType, setLookupType] = useState<LookupType | null>(null)
  const [lookupField, setLookupField] = useState<string>("")
  const [lookupDataSelected, setLookupDataSelected] = useState<string>("")

  /**
   * Handles closing the lookup drawer
   */
  const handleClose = useCallback((): void => {
    setDrawerOpen(false)
    setLookupType(null)
    setLookupField("")
    setLookupDataSelected("")
  }, [])

  const lookupContext: ILookupContext = {
    drawerOpen,
    setDrawerOpen,
    onClose: handleClose,
    lookupType,
    setLookupType,
    lookupField,
    setLookupField,
    lookupDataSelected,
    setLookupDataSelected,
  }

  return (
    <LookupContext.Provider value={lookupContext}>
      <Lookup />
      {children}
    </LookupContext.Provider>
  )
}

/**
 * Component that renders the lookup drawer
 *
 * @returns {React.ReactElement} The rendered lookup drawer
 */
const Lookup: React.FC = (): React.ReactElement => {
  const { drawerOpen, onClose } = useContext(LookupContext)
  const { t } = useTranslation()
  const contactRepository = new RestRepository<IListItem>(CONTACT_ENDPOINT)
  const [recListItem, setRecListItem] = useState<IListItem | null>(null)
  const { lookupField } = useContext(LookupContext)

  return (
    <Drawer open={drawerOpen === true} onClose={onClose} anchor="right" sx={{ zIndex: 1500 }}>
      <Box sx={{ width: "450px" }} role="presentation">
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs>
              <Typography variant="h4">{t("Choose Contact")}</Typography>
            </Grid>
            <Grid item>
              <Button startIcon={<CloseIcon />} onClick={onClose}>
                {t("Close")}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={12}>
              <Alert severity="info">{t(`Select information to ${LOOKUP_FIELD_LABELS[lookupField as keyof typeof LOOKUP_FIELD_LABELS] ?? lookupField}`)}</Alert>
            </Grid>
            <Grid item xs={12}>
              <SelectFilteredSingle
                name="contact"
                repository={contactRepository}
                onChange={setRecListItem}
                defaultValue={recListItem}
              />
            </Grid>
            <Grid item xs />
          </Grid>
          {recListItem?.id !== undefined && <ContactLookupInfo infoId={recListItem.id} />}
        </Box>
      </Box>
    </Drawer>
  )
}
