import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { SUMMARY_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { SUMMARY_ENDPOINT, type ISummary } from "../../../../shared/models/service/ISummary"
import SummaryForm from "./components/SummaryForm"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<ISummary>(SUMMARY_ENDPOINT)
const redirectView = SUMMARY_VIEW_URL

/**
 * This page will allow editing of an account.
 *
 * @returns {React.FC} the account edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<ISummary>({ apiFunction: repository.read })

  const props: IUseApiEditProps<ISummary> = { apiFunction: repository.edit, setError: form.setError, redirectView }
  const apiEdit = useApiEdit<ISummary>(props)
  const { t } = useTranslation()

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader
          title={t("Edit Summary")}
          loading={apiEdit.saving || apiRead.loading}
          errors={form.formState.errors}
        />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={apiEdit.handleEdit}>
          <SummaryForm summary={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
