import React from "react"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import { type IPolicy, POLICY_ENDPOINT } from "../../../../../shared/models/service/IPolicy"
import { POLICY_ADD_URL, POLICY_VIEW_URL } from "../../../config/urls"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import TableData from "../../../../../shared/components/tables/TableData"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import TableActions from "../../../../../shared/components/tables/TableActions"
import ExcelDownloadButton from "../../../../../shared/components/ExcelDownloadButton"
import TableCellTruncate from "../../../../../shared/components/tables/TableCellTruncate"
import TableCellAction from "../../../../../shared/components/tables/TableCellAction"
import FilterDate from "../../../../../shared/components/filters/FilterDate"
import FilterAccount from "../../../../../shared/components/filters/FilterAccount"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IPolicy>(POLICY_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(POLICY_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IPolicy> | undefined
  showHeader?: boolean
  tableActions?: React.JSX.Element[] | React.JSX.Element | undefined
  cellActions?: (policy: IPolicy) => React.JSX.Element[] | React.JSX.Element | undefined
}

/**
 * A React functional component that renders a paginated list of policies.
 *
 * @param {IProps} props - The component properties.
 * @returns {React.ReactElement} The rendered component.
 */
const PolicyIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, tableActions, cellActions } = props
  const { t } = useTranslation()

  return (
    <>
      {showHeader && <PageHeader title={t("Policy")} titlePlural={t("Policies")} toAdd={POLICY_ADD_URL} />}
      {pagingResults !== undefined && (
        <>
          <TableData pagingResults={pagingResults} to={POLICY_VIEW_URL} repository={gotoRepo}>
            <TableActions>
              <ExcelDownloadButton repository={repository} paging={pagingResults.paging} />
              {tableActions !== undefined ? <>{tableActions}</> : <></>}
            </TableActions>
            <FiltersData pagingResults={pagingResults} modelName="policy">
              <FilterSearch />
              <FilterDate field="start_date" title={t("Start Date")} />
              <FilterDate field="end_date" title={t("End Date")} />
              <FilterAccount field="accounts" />
            </FiltersData>
            <TableHead>
              <TableCellCenter field="name" pagingResults={pagingResults}>
                {t("Name")}
              </TableCellCenter>
              <TableCellCenter field="description">{t("Description")}</TableCellCenter>
              <TableCellCenter field="start_date" pagingResults={pagingResults}>
                {t("Start")}
              </TableCellCenter>
              <TableCellCenter field="end_date" pagingResults={pagingResults}>
                {t("End")}
              </TableCellCenter>
              <TableCellCenter field="created" pagingResults={pagingResults}>
                {t("Created")}
              </TableCellCenter>
              {cellActions !== undefined && <TableCellAction field="action">{t("Action")}</TableCellAction>}
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IPolicy) => (
                <TableRow key={item.id}>
                  <TableCellData field="name">{item.name}</TableCellData>
                  <TableCellTruncate isRichText field="description">
                    {item.description}
                  </TableCellTruncate>
                  <TableCellDate field="start_date">{item.start_date}</TableCellDate>
                  <TableCellDate field="end_date">{item.end_date}</TableCellDate>
                  <TableCellDate field="created">{item.created}</TableCellDate>
                  {cellActions !== undefined && <TableCellAction field="action">{cellActions(item)}</TableCellAction>}
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: IPolicy) => (
              <ListDataItem key={item.id} to={`${POLICY_VIEW_URL}/${item.id}`} title={item.name} />
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default PolicyIndex
