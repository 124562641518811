import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import RestApiKeyForm from "./components/RestApiKeyForm"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../../../shared/hooks/useApiEdit"
import { type IRestApiKey, REST_API_KEY_ENDPOINT } from "../../../../shared/models/service/IRestApiKey"
import { REST_API_KEY_VIEW_URL } from "../../config/urls"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IRestApiKey>(REST_API_KEY_ENDPOINT)
const redirectView = REST_API_KEY_VIEW_URL

/**
 * This page will allow editing of a rest api key.
 *
 * @returns {React.FC} the rest api key edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<IRestApiKey>({ apiFunction: repository.read })

  const props: IUseApiEditProps<IRestApiKey> = { apiFunction: repository.edit, setError: form.setError, redirectView }
  const apiEdit = useApiEdit<IRestApiKey>(props)
  const { t } = useTranslation()

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader
          title={t("Edit API Key")}
          loading={apiEdit.saving || apiRead.loading}
          errors={form.formState.errors}
        />
        <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
        <FormBox form={form} onSubmit={apiEdit.handleEdit}>
          <RestApiKeyForm restApiKey={apiRead.data} form={form} isEdit />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default EditPage
