import React from "react"
import { Container, Grid, Typography } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { RECOMMENDATION_EDIT_URL } from "../../config/urls"
import { type IRecommendation, RECOMMENDATION_ENDPOINT } from "../../../../shared/models/service/IRecommendation"
import ViewProperty from "../../../../shared/components/ViewProperty"
import { ItemPrefixes } from "../../../../config/config"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IRecommendation>(RECOMMENDATION_ENDPOINT)

/**
 * Renders a page to view recommendations details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data } = useApiRead<IRecommendation>({ apiFunction: repository.read })
  const { t } = useTranslation()

  return (
    <Container fixed>
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title={t("Recommendation")} toEdit={`${RECOMMENDATION_EDIT_URL}/${data.id}`} />
          <Grid item xs={12} lg={6}>
            <PaperLocal>
              <ItemViewerDrawer prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="textSecondary">
                    {data.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Report Format")}>{data.report_format?.name}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Owner")}>
                    <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.owner}</ViewListItems>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Type")}>{data.type?.name}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Priority")}>{data.priority?.name}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Risk Score Deduction")}>{data.risk_score_deduction}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Risk Score Category")}>{data.risk_score_category?.name}</ViewProperty>
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
          <Grid item xs={12} lg={6}>
            <PaperLocal>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ViewProperty label={t("Body")} vertical>
                    <ViewRichText>{data.body}</ViewRichText>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Loss Estimate")} vertical>
                    <ViewRichText>{data.loss_estimate}</ViewRichText>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label={t("Comment")} vertical>
                    <ViewRichText>{data.comment}</ViewRichText>
                  </ViewProperty>
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
