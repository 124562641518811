import React, { useCallback, useContext, useEffect, useState } from "react"
import { type ILocation } from "../../models/service/ILocation"
import { PanariskAppContext } from "../../../app/PanariskApp"
import { type IBuilding } from "../../models/service/IBuilding"
import { AdvancedMarker, Map, Pin, useMap } from "@vis.gl/react-google-maps"
import { DEFAULT_CENTER, DEFAULT_GOOGLE_MAP_ID, ItemPrefixes } from "../../../config/config"
import { useLocation, useNavigate } from "react-router-dom"
import { Circle } from "../map/Circle"
import { Alert, Button } from "@mui/material"
import MapIcon from "@mui/icons-material/Map"
import useAuth, { type IProfilePatch } from "../../hooks/useAuth"
import { type IServiceUser } from "../../models/service/IServiceUser"
import { type IContact } from "../../models/service/IContact"
import { type ICompany } from "../../models/service/ICompany"
import { type TPlace } from "../../models/components/IPlace"

/**
 * Checks if the given object is a location.
 *
 * @param {ILocation | IBuilding} location - The object to be checked.
 * @returns {boolean} - True if the object is a valid location, false otherwise.
 */
function isLocation(location: TPlace): location is ILocation {
  return (location as ILocation).buildings !== undefined
}

interface IProps {
  place: TPlace
  places?: TPlace[]
  showInfo?: boolean
  initHide?: boolean
}

const height = "400px"

/**
 * Renders a map view with markers for a specific place and its related locations/buildings.
 * If the Google Maps API key is provided and valid, the map will be displayed.
 * If the initHide prop is set to true, the map will be initially hidden and a button to show the map will be displayed.
 *
 * @param {IProps} props - The props for the ViewMap component.
 * @returns {React.ReactElement} The rendered map view.
 */
const ViewMap: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { place, places, showInfo = true, initHide = false } = props

  const map = useMap()
  const { appSettings } = useContext(PanariskAppContext)
  const { currentUser, updateProfile } = useAuth()
  const mapType = currentUser?.user.profile.map_type ?? "satellite"
  const location = useLocation()
  const navigate = useNavigate()
  const [showMap, setShowMap] = React.useState<boolean>(!initHide)

  const [selectedLocation, setSelectedLocation] = useState<TPlace | null>(null)

  const handleShowPlace = useCallback(
    (place: ILocation | IBuilding | IContact | IServiceUser | ICompany) => () => {
      const prefix = isLocation(place) ? ItemPrefixes.location : ItemPrefixes.building
      navigate(`${location.pathname}/${prefix}info/${place.id}`)
      setSelectedLocation(place)
    },
    [location],
  )

  const handleMapTypeChange = useCallback(async () => {
    if (map?.getMapTypeId() !== undefined && currentUser?.user.profile.id !== undefined) {
      const patch: IProfilePatch = { id: currentUser.user.profile.id, name: "", map_type: map.getMapTypeId() }
      await updateProfile?.(patch)
    }
  }, [map, currentUser])

  const handleShow = useCallback(() => {
    setShowMap(true)
  }, [])

  useEffect(() => {
    if (map !== null && place.latitude !== null && place.longitude !== null) {
      map.setCenter({ lat: place.latitude as number, lng: place.longitude as number })
    }
  }, [map, place.latitude, place.longitude])

  useEffect(() => {
    if (!location.pathname.includes("info")) {
      setSelectedLocation(null)
    }
  }, [location.pathname])

  return (
    <>
      {appSettings.serverInfo?.google_maps_api_key !== undefined &&
      place.latitude !== null &&
      place.longitude !== null &&
      place.latitude !== 0 &&
      place.longitude !== 0 ? (
        <>
          {initHide && !showMap && (
            <Alert icon={<MapIcon />} severity="info" action={<Button onClick={handleShow}>Show Map</Button>} />
          )}
          {showMap && (
            <Map
              mapId={DEFAULT_GOOGLE_MAP_ID}
              style={{ flexGrow: "1", height }}
              onMapTypeIdChanged={handleMapTypeChange}
              mapTypeId={mapType}
              defaultZoom={place.zoom as number}
              defaultCenter={DEFAULT_CENTER}>
              <AdvancedMarker
                onClick={showInfo ? handleShowPlace(place) : undefined}
                position={{ lat: place.latitude as number, lng: place.longitude as number }}
              />

              {selectedLocation !== null && (
                <Circle
                  center={{ lat: selectedLocation.latitude as number, lng: selectedLocation.longitude as number }}
                  radius={20}
                />
              )}
              {isLocation(place) &&
                places === undefined &&
                place.buildings.map((building: IBuilding) => {
                  return (
                    <AdvancedMarker
                      key={building.id}
                      onClick={showInfo ? handleShowPlace(building) : undefined}
                      position={{ lat: building.latitude, lng: building.longitude }}>
                      <Pin background={"#0f9d58"} borderColor={"#006425"} glyphColor={"#60d98f"} />
                    </AdvancedMarker>
                  )
                })}
              {places?.map((place: TPlace) => {
                return (
                  <AdvancedMarker
                    key={place.id}
                    onClick={showInfo ? handleShowPlace(place) : undefined}
                    position={{ lat: place.latitude as number, lng: place.longitude as number }}>
                    <Pin background={"#003f5c"} borderColor={"#58508d"} glyphColor={"#bc5090"} />
                  </AdvancedMarker>
                )
              })}
            </Map>
          )}
        </>
      ) : (
        <Alert severity="warning">Map is not available.</Alert>
      )}
    </>
  )
}

export default ViewMap
