import React from "react"
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import PrimaryButton from "../PrimaryButton"
import RefreshIcon from "@mui/icons-material/Refresh"
import TruncateText from "../TruncateText"
import HelpDocsDrawer from "../help/HelpDocsDrawer"
import { useTranslation } from "react-i18next"

interface IProps {
  title: string
  titlePlural?: string
  toAdd?: string
  toEdit?: string | undefined
  extraOptions?: React.ReactElement
  onRefresh?: () => Promise<void> | void
  pageId?: number
}

/**
 * Renders the page header component.
 *
 * @param {IProps} props - The props object containing the necessary data for rendering.
 * @returns {React.ReactElement} The rendered page header component.
 */
const PageHeader: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { title, titlePlural, toAdd, toEdit, extraOptions, onRefresh, pageId } = props
  const theTitle = titlePlural ?? title

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const { t } = useTranslation()

  return (
    <>
      {!isSmall ? (
        <Grid item xs={12}>
          <Box sx={{ p: 2, pl: 0, pr: 0 }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                <Typography variant="h4" color="textSecondary">
                  <TruncateText num={50}>{theTitle}</TruncateText>
                </Typography>
              </Grid>
              {pageId !== undefined && (
                <Grid item>
                  <HelpDocsDrawer />
                </Grid>
              )}
              {onRefresh !== undefined && (
                <Grid item>
                  <Button onClick={onRefresh} color="primary" startIcon={<RefreshIcon />}>
                    {t("Refresh")}
                  </Button>
                </Grid>
              )}
              <Grid item>{extraOptions}</Grid>
              {toAdd !== undefined && (
                <Grid item>
                  <PrimaryButton to={toAdd}>
                    {t("Add")} {title}
                  </PrimaryButton>
                </Grid>
              )}
              {toEdit !== undefined && (
                <Grid item>
                  <PrimaryButton to={toEdit} edit>
                    {t("Edit")} {title}
                  </PrimaryButton>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" color="textSecondary">
                <TruncateText num={50}>{theTitle}</TruncateText>
              </Typography>
            </Grid>
            <Grid item xs />
            {pageId !== undefined && (
              <Grid item>
                <HelpDocsDrawer />
              </Grid>
            )}
            {onRefresh !== undefined && (
              <Grid item>
                <Button onClick={onRefresh} color="primary" startIcon={<RefreshIcon />}>
                  {t("Refresh")}
                </Button>
              </Grid>
            )}
            <Grid item>{extraOptions}</Grid>
            {toAdd !== undefined && (
              <Grid item>
                <PrimaryButton to={toAdd}>
                  {t("Add")} {title}
                </PrimaryButton>
              </Grid>
            )}
            {toEdit !== undefined && (
              <Grid item>
                <PrimaryButton to={toEdit} edit>
                  {t("Edit")} {title}
                </PrimaryButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PageHeader
