import React, { useCallback, useContext, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { nameToLabel, numbersOnlyRule } from "../../../utilities/form_utility"
import { InputType } from "../models/IRiskWriterFormat"
import type { IRiskWriterTableData, TRiskWriterTableRows } from "../models/IRiskWriterData"
import { RiskWriterContext } from "../context/RiskWriterProvider"
import { useForm } from "react-hook-form"
import FhMuiTextField from "../../forms/FhMuiTextField"
import TableCellNumber from "../../tables/TableCellNumber"
import TableCellCenter from "../../tables/TableCellCenter"
import TableCellArea from "../../tables/TableCellArea"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import type { IColumn, IRiskWriterTable } from "../models/IRiskWriterTable"
import FhMuiCheckboxField from "../../forms/FhMuiCheckboxField"
import CheckMark from "../../display/CheckMark"
import FhMuiSelectField from "../../forms/FhMuiSelectField"
import { useRiskWriterOptions } from "../hooks/useRiskWriterOptions"
import FhMuiDateField from "../../forms/FhMuiDateField"
import TableCellDate from "../../tables/TableCellDate"
import TableCellCurrency from "../../tables/TableCellCurrency"
import TableCellPercent from "../../tables/TableCellPercent"
import PaperLocal from "../../containers/PaperLocal"
import TableCellTruncate from "../../tables/TableCellTruncate"
import { useTranslation } from "react-i18next"

interface IUseGetTableDataResponse {
  getTableData: (tableName: string) => IRiskWriterTableData | null
  getTableRowsData: (tableName: string) => TRiskWriterTableRows
  getTableFormat: (tableName: string) => IRiskWriterTable | undefined
  getTables: () => IRiskWriterTable[] | null | undefined
  getTableLabel: (tableName: string) => string | undefined
}

/**
 * Retrieves table data for a specific table name.
 *
 * @returns {IUseGetTableDataResponse} - The table data as an array of arrays.
 */
export const useRiskWriterTable = (): IUseGetTableDataResponse => {
  const { reportWriterData, reportWriterFormat } = useContext(RiskWriterContext)

  const getTableData = useCallback(
    (tableName: string): IRiskWriterTableData | null => {
      return reportWriterData?.tables?.find(td => td.name === tableName) ?? null
    },
    [reportWriterData],
  )

  const getTableRowsData = useCallback(
    (tableName: string): TRiskWriterTableRows => {
      return reportWriterData?.tables?.find(td => td.name === tableName)?.rows ?? []
    },
    [reportWriterData],
  )

  const getTableFormat = useCallback(
    (tableName: string): IRiskWriterTable | undefined => {
      return reportWriterFormat?.tables?.find(t => t.name === tableName)
    },
    [reportWriterFormat],
  )

  const getTableLabel = useCallback(
    (tableName: string): string | undefined => {
      const tableFormat = getTableFormat(tableName)
      return tableFormat !== undefined ? nameToLabel(tableFormat.name, tableFormat.title) : undefined
    },
    [getTableFormat],
  )

  const getTables = useCallback((): IRiskWriterTable[] | null | undefined => {
    return reportWriterFormat?.tables
  }, [reportWriterFormat])

  return { getTableData, getTableRowsData, getTableFormat, getTables, getTableLabel }
}

interface IRiskWriterTableHeaderProps {
  table: IRiskWriterTable
  showAction?: boolean
}

/**
 * Renders the header for a risk writer table.
 *
 * @param {IRiskWriterTableHeaderProps} props - The properties for the risk writer table header.
 * @param {object} props.table - The table object containing the columns.
 * @param {boolean} props.showAction - Flag to determine whether to show the action column.
 * @returns {React.ReactElement} - The rendered table header component.
 */
export const RiskWriterTableHeader: React.FC<IRiskWriterTableHeaderProps> = (
  props: IRiskWriterTableHeaderProps,
): React.ReactElement => {
  const { table, showAction = false } = props
  return (
    <TableHead>
      <TableRow>
        {table.columns.map(column => {
          return (
            <TableCellCenter key={column.name} wrap>
              <>
                {nameToLabel(column.name, column.title)}
                {column.units !== undefined && column.units !== null && (
                  <Box component="sup" sx={{ pl: 1 }}>
                    ({column.units})
                  </Box>
                )}
              </>
            </TableCellCenter>
          )
        })}
        {showAction && <TableCellCenter>Action</TableCellCenter>}
      </TableRow>
    </TableHead>
  )
}

interface IRiskWriterTableBodyProps {
  table: IRiskWriterTable
  rows: TRiskWriterTableRows | undefined
}

/**
 * Renders the body of a risk writer table.
 *
 * @param {IRiskWriterTableBodyProps} props - The component props.
 * @param {IRiskWriterTable} props.table - The table object.
 * @param {TRiskWriterTableRows | undefined} props.rows - The rows array.
 * @returns {React.ReactElement} The rendered table body.
 */
export const RiskWriterTableBody: React.FC<IRiskWriterTableBodyProps> = (
  props: IRiskWriterTableBodyProps,
): React.ReactElement => {
  const { table, rows } = props
  const { reportWriterFormat, units } = useContext(RiskWriterContext)
  const { getOptionValue } = useRiskWriterOptions({ format: reportWriterFormat })

  return (
    <TableBody>
      {rows?.map((row, index) => {
        return (
          <TableRow key={index}>
            {table.columns.map((column: IColumn, index: number) => {
              if (column.input_type === InputType.FLOAT) {
                return (
                  <TableCellNumber twoDecimalPlaces key={column.name}>
                    {row?.[index] as number}
                  </TableCellNumber>
                )
              }
              if (column.input_type === InputType.CURRENCY) {
                return <TableCellCurrency key={column.name}>{row?.[index] as number}</TableCellCurrency>
              }
              if (column.input_type === InputType.AREA) {
                return (
                  <TableCellArea key={column.name} units={units}>
                    {row?.[index] as number}
                  </TableCellArea>
                )
              }
              if (column.input_type === InputType.PERCENT) {
                return <TableCellPercent key={column.name}>{row?.[index] as number}</TableCellPercent>
              }
              if (column.input_type === InputType.INTEGER) {
                return <TableCellNumber key={column.name}>{row?.[index] as number}</TableCellNumber>
              }
              if (column.input_type === InputType.CHECKBOX) {
                return (
                  <TableCell key={column.name} sx={{ textAlign: "center" }}>
                    <CheckMark value={row?.[index]} />
                  </TableCell>
                )
              }
              if (column.input_type === InputType.DATE) {
                return <TableCellDate key={column.name}>{row?.[index] as string}</TableCellDate>
              }
              if (column.input_type === InputType.SELECT) {
                return (
                  <TableCell key={column.name} sx={{ textAlign: "center" }}>
                    {column.options !== null && getOptionValue(column.options, row?.[index])}
                  </TableCell>
                )
              }
              return (
                <TableCellTruncate key={column.name} sx={{ textAlign: "center" }}>
                  {row?.[index]}
                </TableCellTruncate>
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

/**
 * Renders a table editor with options to edit tables.
 *
 * @returns {React.FC} A React element representing the table editor component.
 */
const RiskWriterTableEditor: React.FC = (): React.ReactElement => {
  const { reportWriterFormat, setReportWriterData } = useContext(RiskWriterContext)
  const { getSelectOptions } = useRiskWriterOptions({ format: reportWriterFormat })
  const form = useForm()
  const formKey = useForm()
  const [currentTable, setCurrentTable] = useState<IRiskWriterTable | null>(null)

  const { getTableData, getTableRowsData, getTableFormat, getTableLabel } = useRiskWriterTable()

  const { t } = useTranslation()

  const handleEditCurrentTable = useCallback(
    (table: IRiskWriterTable) => () => {
      setCurrentTable(table)
      const tableFormat = getTableFormat(table.name)
      const tableData = getTableData(table.name)
      if (tableData?.rows !== undefined && tableFormat !== undefined) {
        form.reset()
        formKey.reset()
        formKey.setValue("key", tableData.key)
        tableData.rows.forEach((row, index) => {
          tableFormat.columns.forEach((column, index1) => {
            form.setValue(`${index}.${column.name}`, row?.[index1])
          })
        })
      }
    },
    [getTableData, form, formKey],
  )

  const handleDoneCurrentTable = useCallback(async () => {
    const key = formKey.getValues("key")
    const isValid = await form.trigger()
    if (!isValid) {
      return
    }
    const newTableData: Record<string, Record<string, string | number | boolean | null>> = form.getValues()
    if (currentTable !== null) {
      setReportWriterData?.(data => {
        if (data !== null) {
          // noinspection DuplicatedCode
          const tableName = currentTable.name
          const newData = { ...data }
          const tableFormat = getTableFormat(tableName)
          let tableData = newData.tables.find(td => td.name === tableName)
          // Check if no table data has been created for the table.
          if (tableData?.rows === undefined) {
            tableData = { name: tableName, key, rows: [] } satisfies IRiskWriterTableData
            newData.tables.push(tableData)
          }
          if (tableFormat !== undefined) {
            tableData.key = key
            tableData.rows = tableData.rows.map((_, row) => Object.values(newTableData[row]))
          }
          return newData
        }
        return data
      })
    }
    setCurrentTable(null)
  }, [currentTable, form, formKey])

  const handleAddRow = useCallback(() => {
    if (currentTable !== null) {
      setReportWriterData?.(data => {
        if (data !== null) {
          // noinspection DuplicatedCode
          const tableName = currentTable.name
          const newData = { ...data }
          const tableFormat = getTableFormat(tableName)
          let tableData = newData.tables.find(td => td.name === tableName)
          // Check if no table data has been created for the table.
          if (tableData?.rows === undefined) {
            tableData = { name: tableName, rows: [] } satisfies IRiskWriterTableData
            newData.tables.push(tableData)
          }
          if (tableFormat !== undefined) {
            tableData.rows = [
              ...tableData.rows,
              tableFormat.columns.map((column: IColumn) => {
                if (column.input_type === InputType.CHECKBOX) {
                  return false
                }
                if (column.input_type === InputType.INTEGER) {
                  return 0
                }
                if (column.input_type === InputType.RICH_TEXT || column.input_type === InputType.TEXT) {
                  return ""
                }
                return 0.0
              }),
            ]
          }
          return newData
        }
        return data
      })
    }
  }, [setReportWriterData, currentTable, reportWriterFormat])

  const handleRemoveRow = useCallback(
    (rowIndex: number) => () => {
      if (currentTable !== null) {
        setReportWriterData?.(data => {
          if (data !== null) {
            // noinspection DuplicatedCode
            const tableName = currentTable.name
            const newData = { ...data }
            const tableFormat = getTableFormat(tableName)
            const tableData = newData.tables.find(td => td.name === tableName)
            if (tableFormat !== undefined && tableData !== undefined) {
              // Filter the data to remove the row with given rowIndex
              tableData.rows = tableData.rows.filter((_, index) => index !== rowIndex)
              form.reset()
              tableData.rows.forEach((row, index) => {
                tableFormat.columns.forEach((column, index1) => {
                  form.setValue(`${index}.${column.name}`, row?.[index1])
                })
              })
            }
            return newData
          }
          return data
        })
      }
    },
    [setReportWriterData, currentTable, form, formKey],
  )

  return (
    <>
      {reportWriterFormat?.tables?.map(table => {
        return (
          <Grid key={table.name} item xs={12}>
            <PaperLocal sx={{ mt: 3 }}>
              <Box sx={{ pt: 2, pb: 2 }}>
                <Grid container>
                  <Grid item xs>
                    <Typography variant="h6">{getTableLabel(table.name)}</Typography>
                  </Grid>
                  <Grid item>
                    {t("Rows")}: {getTableRowsData(table.name)?.length}
                  </Grid>
                </Grid>
              </Box>
              <TableContainer component={Paper} sx={{ p: 0, mb: 2, maxHeight: 400 }}>
                <Table stickyHeader>
                  <RiskWriterTableHeader table={table} showAction={currentTable?.name === table.name} />
                  {currentTable?.name === table.name ? (
                    <TableBody>
                      {getTableRowsData(table.name)?.map((_, index) => {
                        return (
                          <TableRow key={index}>
                            {table.columns.map((column: IColumn) => {
                              if (
                                column.input_type === InputType.INTEGER ||
                                column.input_type === InputType.FLOAT ||
                                column.input_type === InputType.CURRENCY ||
                                column.input_type === InputType.AREA ||
                                column.input_type === InputType.YEAR
                              ) {
                                return (
                                  <TableCell key={`${index}.${column.name}`} sx={{ p: 1 }}>
                                    <FhMuiTextField
                                      control={form.control}
                                      name={`${index}.${column.name}`}
                                      size="small"
                                      showLabel={false}
                                      rules={numbersOnlyRule()}
                                      sx={{
                                        "& input": { textAlign: "center" },
                                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "#fff" },
                                      }}
                                    />
                                  </TableCell>
                                )
                              }
                              if (column.input_type === InputType.DATE) {
                                return (
                                  <TableCell key={`${index}.${column.name}`} sx={{ p: 1 }}>
                                    <FhMuiDateField
                                      control={form.control}
                                      name={`${index}.${column.name}`}
                                      sx={{
                                        "& input": { textAlign: "center" },
                                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "#fff" },
                                      }}
                                    />
                                  </TableCell>
                                )
                              }
                              if (column.input_type === InputType.CHECKBOX) {
                                return (
                                  <TableCell key={`${index}.${column.name}`} sx={{ p: 1, textAlign: "center" }}>
                                    <FhMuiCheckboxField
                                      showLabel={false}
                                      control={form.control}
                                      name={`${index}.${column.name}`}
                                    />
                                  </TableCell>
                                )
                              }
                              if (column.input_type === InputType.SELECT && column.options !== null) {
                                return (
                                  <TableCell key={`${index}.${column.name}`} sx={{ p: 1, textAlign: "center" }}>
                                    <FhMuiSelectField
                                      control={form.control}
                                      items={getSelectOptions(column.options)}
                                      name={`${index}.${column.name}`}
                                      allowBlank
                                      sx={{
                                        "& input": { textAlign: "center" },
                                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "#fff" },
                                      }}
                                    />
                                  </TableCell>
                                )
                              }
                              return (
                                <TableCell key={`${index}.${column.name}`} sx={{ p: 1 }}>
                                  <FhMuiTextField
                                    control={form.control}
                                    name={`${index}.${column.name}`}
                                    size="small"
                                    showLabel={false}
                                    sx={{
                                      "& input": { textAlign: "center" },
                                      "& .MuiOutlinedInput-notchedOutline": { borderColor: "#fff" },
                                    }}
                                  />
                                </TableCell>
                              )
                            })}
                            {currentTable !== null && (
                              <TableCellCenter>
                                <IconButton color="primary" size="small" onClick={handleRemoveRow(index)}>
                                  <RemoveIcon />
                                </IconButton>
                              </TableCellCenter>
                            )}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  ) : (
                    <RiskWriterTableBody table={table} rows={getTableRowsData(table.name)} />
                  )}
                </Table>
              </TableContainer>
              <Box sx={{ p: 2, pt: 0 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    {getTableRowsData(table.name).length === 0 && (
                      <Alert severity="warning">{t("No table information has been entered.")}</Alert>
                    )}
                    {currentTable?.name === table.name ? (
                      <Box sx={{ mt: 1 }}>
                        <FhMuiTextField control={formKey.control} name="key" />
                      </Box>
                    ) : (
                      <Box sx={{ mt: 1 }}>
                        <strong>{t("Key")}:</strong> {getTableData(table.name)?.key ?? "Not Set"}
                      </Box>
                    )}
                  </Grid>
                  <Grid item>
                    {currentTable !== null && (
                      <Button startIcon={<AddIcon />} onClick={handleAddRow}>
                        {t("Add Row")}
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!table.view_only && (
                      <>
                        {currentTable?.name === table.name ? (
                          <Button onClick={handleDoneCurrentTable}>{t("Done")}</Button>
                        ) : (
                          <IconButton size="small" color="primary" onClick={handleEditCurrentTable(table)}>
                            <EditIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </PaperLocal>
          </Grid>
        )
      })}
    </>
  )
}

export default RiskWriterTableEditor
