import React from "react"
import { Container, Grid, Tab, Typography } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import ViewProperty from "../../../../shared/components/ViewProperty"
import { EMAIL_TEMPLATE_EDIT_URL } from "../../config/urls"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { EMAIL_TEMPLATE_ENDPOINT, type IEmailTemplate } from "../../../../shared/models/service/IEmailTemplate"
import TabsList from "../../../../shared/components/tabs/TabsList"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import FilesIndex from "../../../../shared/components/files/FilesIndex"
import { useParams } from "react-router-dom"
import { EMAIL_TEMPLATE_FILE_ENDPOINT, type IFile } from "../../../../shared/models/service/IFile"
import { type IMainModel } from "../../../../shared/models/service/IMainModel"
import type { IFilter } from "../../../../shared/models/components/IFilter"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IEmailTemplate>(EMAIL_TEMPLATE_ENDPOINT)
const filesRepository = new RestRepository<IFile | IMainModel>(EMAIL_TEMPLATE_FILE_ENDPOINT)

/**
 * Renders a page to view email template details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data } = useApiRead<IEmailTemplate>({ apiFunction: repository.read })
  const { tab, handleTabChange } = useTabPanel()
  const { id } = useParams()
  const { t } = useTranslation()

  const theFilter: IFilter = {
    canBeDelete: false,
    title: "Email Template",
    display: "This Email Template",
    field: "email_template",
    value: id,
  }

  const filesPagingResults = useOnDemandPaged<IFile>(EMAIL_TEMPLATE_FILE_ENDPOINT, [theFilter], "FILES")

  return (
    <Container fixed>
      {data !== undefined && (
        <>
          <Grid container spacing={2}>
            <PageHeader title={t("Email Template")} toEdit={`${EMAIL_TEMPLATE_EDIT_URL}/${data.id}`} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TabsList value={tab} onChange={handleTabChange}>
                <Tab label={t("Overview")} value={0} />
                <Tab label={t("Files")} value={1} />
              </TabsList>
              <TabPanel value={tab} index={0}>
                <PaperLocal>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" color="textSecondary">
                        {data.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Subject")} vertical>
                        {data.subject}
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Content")} vertical>
                        <ViewRichText>{data.content}</ViewRichText>
                      </ViewProperty>
                    </Grid>
                  </Grid>
                </PaperLocal>
              </TabPanel>
              <TabPanel value={tab} index={1} onChange={filesPagingResults.call}>
                <FilesIndex
                  parentId={id}
                  parentFieldName="email_template"
                  repository={filesRepository}
                  pagingResults={filesPagingResults}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  )
}

export default ViewPage
