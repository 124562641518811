import React, { useCallback, useState } from "react"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import { buildApiAction } from "../../hooks/useApiAction"
import { type IInspection, INSPECTION_ENDPOINT } from "../../models/service/IInspection"
import { RestRepository } from "../../repositories/RestRepository"
import DrawerRight from "../containers/DrawerRight"
import { Alert, Box, Divider, Grid, List, Typography } from "@mui/material"
import ViewLoading from "../ViewLoading"
import ListItem from "@mui/material/ListItem"
import {
  type IRiskWriterFormError,
  type IRiskWriterTableError,
  type IRiskWriterValidationErrors,
} from "../risk_writer/models/IRiskWriterErrors"
import { nameToLabel } from "../../utilities/form_utility"
import ListItemText from "@mui/material/ListItemText"
import ErrorMessage from "../ErrorMessage"
import { useTranslation } from "react-i18next"
import TruncateText from "../TruncateText"

const inspectRepository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)

interface IProps {
  inspectionId: number
  briefTitle: string
  buildingTitle: string
}

/**
 * Validate risk writer data.
 *
 * @param {IProps} props - The properties for RiskWriterValidate component.
 * @returns {React.ReactElement} - The rendered validation component.
 */
const RiskWriterValidate: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspectionId, briefTitle, buildingTitle } = props
  const [validationErrors, setValidationErrors] = useState<IRiskWriterValidationErrors | null>(null)
  const axiosRequest = useAxiosRequest()
  const apiAction = buildApiAction<IInspection>(inspectRepository, inspectionId)
  const { t } = useTranslation()

  const handleValidate = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      setValidationErrors(null)
      const data = await apiAction.callAction<IRiskWriterValidationErrors>("validate_risk_writer_data")
      if (data !== undefined) {
        setValidationErrors(data)
      }
    })
  }, [])

  return (
    <>
      <DrawerRight title="Validate" onDrawerOpen={handleValidate}>
        <Grid container spacing={2}>
          <Box sx={{ mt: 2, width: "100%" }}>
            <ViewLoading loading={apiAction.saving} inline />
            <ErrorMessage error={apiAction.connectionError} />
          </Box>
          {validationErrors !== null && (
            <>
              <Grid item xs={12}>
                {validationErrors.total_errors > 0 ? (
                  <Alert severity="error">
                    {t("Errors found")}: {validationErrors.total_errors}
                  </Alert>
                ) : (
                  <Alert severity="success">{t("No errors found.")}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
                  {briefTitle}
                  <Divider />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {validationErrors.brief.length === 0 ? (
                  <Alert severity="info">{t("No errors found.")}</Alert>
                ) : (
                  <List>
                    {validationErrors.brief.map((error, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemText
                            primary={<Typography variant="h6">{nameToLabel(error.name, error.title)}</Typography>}
                            secondary={
                              <React.Fragment>
                                <Alert severity="error">{error.message}</Alert>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
                  {buildingTitle}
                  <Divider />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {validationErrors.building.map((group, index) => {
                  return (
                    <Box key={index}>
                      <Typography variant="h6">{group.name}</Typography>
                      {group.errors.length === 0 ? (
                        <Alert severity="info">{t("No errors found.")}</Alert>
                      ) : (
                        <List>
                          {group.errors.map((error, index1) => {
                            const error1 = error as IRiskWriterFormError
                            return (
                              <ListItem key={index1}>
                                <ListItemText
                                  primary={
                                    <Typography variant="h6">{nameToLabel(error1.name, error1.title)}</Typography>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <Alert severity="error">{error1.message}</Alert>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                            )
                          })}
                        </List>
                      )}
                    </Box>
                  )
                })}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
                  {t("Tables")}
                  <Divider />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {validationErrors.tables.map((group, index) => {
                  return (
                    <Box key={index}>
                      <Typography variant="h6">
                        <TruncateText>{nameToLabel(group.name)}</TruncateText>
                      </Typography>
                      {group.errors.length === 0 ? (
                        <Alert severity="info">{t("No errors found.")}</Alert>
                      ) : (
                        <List>
                          {group.errors.map((error, index1) => {
                            const error1 = error as IRiskWriterTableError
                            return (
                              <ListItem key={index1}>
                                <ListItemText
                                  primary={
                                    <Typography variant="h6">{`Column: ${nameToLabel(error1.column, error1.column_title)}, Row: ${error1.row}`}</Typography>
                                  }
                                  secondary={
                                    <React.Fragment>
                                      <Alert severity="error">{error1.error.message}</Alert>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                            )
                          })}
                        </List>
                      )}
                    </Box>
                  )
                })}
              </Grid>
            </>
          )}
        </Grid>
      </DrawerRight>
    </>
  )
}

export default RiskWriterValidate
