import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { BUILDING_ENDPOINT, type IBuilding } from "../../../../../shared/models/service/IBuilding"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import ViewMap from "../../../../../shared/components/display/ViewMap"
import useEffectAsync from "../../../../../shared/hooks/useEffectAsync"
import TruncateText from "../../../../../shared/components/TruncateText"
import { t } from "i18next"

const repository = new RestRepository<IBuilding>(BUILDING_ENDPOINT)

/**
 * Renders a page to view building details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const BuildingInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const apiRead = useApiRead<IBuilding>({ apiFunction: repository.read, itemId: infoId })
  const { data } = apiRead

  useEffectAsync(async () => {
    if (data !== undefined && data !== null) {
      await apiRead.call()
    }
  }, [infoId])

  return (
    <>
      {data !== undefined && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemViewerTitle>
              <TruncateText>{data.name}</TruncateText>
            </ItemViewerTitle>
          </Grid>
          <Grid item xs={12}>
            <ViewMap place={data} showInfo={false} />
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Location")}>{data.location?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Occupancy")}>{data.occupancy?.name}</ViewProperty>
          </Grid>
          <Grid item xs={12}>
            <ViewProperty label={t("Notes")} vertical>
              <ViewRichText>{data.notes}</ViewRichText>
            </ViewProperty>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default BuildingInfo
