import React from "react"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import { SERVICE_USER_ADD_URL, SERVICE_USER_VIEW_URL } from "../../../config/urls"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import TableData from "../../../../../shared/components/tables/TableData"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import ListProperty from "../../../../../shared/components/lists/ListProperty"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ServiceUserInfo from "./ServiceUserInfo"
import TableCellPhone from "../../../../../shared/components/tables/TableCellPhone"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import TableCellCheckMark from "../../../../../shared/components/tables/TableCellCheckMark"
import TableActions from "../../../../../shared/components/tables/TableActions"
import TableCellAction from "../../../../../shared/components/tables/TableCellAction"
import { ItemPrefixes } from "../../../../../config/config"
import PrivateComponent from "../../../../../shared/components/private_locking/PrivateComponent"
import { ROLE_SYSTEM_MANAGER_GROUP } from "../../../../../config/permissions"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import FilterLocationCity from "../../../../../shared/components/filters/FilterLocationCity"
import FilterLocationStateRegion from "../../../../../shared/components/filters/FilterLocationStateRegion"
import FilterLocationCountry from "../../../../../shared/components/filters/FilterLocationCountry"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import FilterVendor from "../../../../../shared/components/filters/FilterVendor"
import ExcelDownloadButton from "../../../../../shared/components/ExcelDownloadButton"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IListItem>(SERVICE_USER_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IServiceUser> | undefined
  showHeader?: boolean
  useInfoView?: boolean
  tableActions?: React.JSX.Element[] | React.JSX.Element | undefined
  cellActions?: (serviceUser: IServiceUser) => React.JSX.Element[] | React.JSX.Element | undefined
}

/**
 * Renders the index page for the service users.
 *
 * @param {IProps} props See props for details
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, useInfoView = false, tableActions, cellActions } = props
  const { t } = useTranslation()

  return (
    <>
      <PrivateComponent
        component={
          <>
            {showHeader && (
              <PageHeader title={t("Service User")} titlePlural={t("Service Users")} toAdd={SERVICE_USER_ADD_URL} />
            )}
          </>
        }
        groupNames={[ROLE_SYSTEM_MANAGER_GROUP]}
        componentElse={<>{showHeader && <PageHeader title={t("Service User")} titlePlural={t("Service Users")} />}</>}
      />
      {pagingResults !== undefined && (
        <>
          <ItemViewerDrawer title={t("Service User")} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
          <TableData
            pagingResults={pagingResults}
            toInfo={useInfoView}
            toInfoPrefix={ItemPrefixes.serviceUser}
            to={SERVICE_USER_VIEW_URL}
            repository={repository}>
            <TableActions>
              <ExcelDownloadButton repository={repository} paging={pagingResults.paging} />
              <>{tableActions}</>
            </TableActions>
            <FiltersData pagingResults={pagingResults} modelName="service_user">
              <FilterSearch />
              <FilterLocationCity field="city" />
              <FilterLocationStateRegion field="state_region" />
              <FilterLocationCountry field="country" />
              <FilterVendor field="vendor_leads" />
              <FilterVendor field="vendor_members" />
            </FiltersData>
            <TableHead>
              <TableCellCenter field="first_name" pagingResults={pagingResults}>
                {t("First Name")}
              </TableCellCenter>
              <TableCellCenter field="last_name" pagingResults={pagingResults}>
                {t("Last Name")}
              </TableCellCenter>
              <TableCellCenter field="phone" pagingResults={pagingResults}>
                {t("Phone")}
              </TableCellCenter>
              <TableCellCenter field="email" pagingResults={pagingResults}>
                {t("Email")}
              </TableCellCenter>
              <TableCellCenter field="city" pagingResults={pagingResults}>
                {t("City")}
              </TableCellCenter>
              <TableCellCenter field="state_region" pagingResults={pagingResults}>
                {t("State/Region")}
              </TableCellCenter>
              <TableCellCenter field="country" pagingResults={pagingResults}>
                {t("Country")}
              </TableCellCenter>
              <TableCellCenter field="last_login">{t("Last Login")}</TableCellCenter>
              <TableCellCenter field="has_enabled_user_account">{t("Enabled User")}</TableCellCenter>
              {cellActions !== undefined && <TableCellAction field="action">{t("Action")}</TableCellAction>}
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: IServiceUser) => (
                <TableRow key={item.id}>
                  <TableCellData field="first_name">{item.first_name}</TableCellData>
                  <TableCellData field="last_name">{item.last_name}</TableCellData>
                  <TableCellPhone field="phone" ext={item.phone_ext}>
                    {item.phone}
                  </TableCellPhone>
                  <TableCellData field="email">{item.email}</TableCellData>
                  <TableCellData field="address">{item.address}</TableCellData>
                  <TableCellData field="city">{item.city}</TableCellData>
                  <TableCellData field="state_region">{item.state_region}</TableCellData>
                  <TableCellData field="country">{item.country}</TableCellData>
                  <TableCellDate field="last_login">{item.last_login}</TableCellDate>
                  <TableCellCheckMark field="has_enabled_user_account">
                    {item.has_enabled_user_account}
                  </TableCellCheckMark>
                  {cellActions !== undefined && <TableCellAction field="action">{cellActions(item)}</TableCellAction>}
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: IServiceUser) => (
              <ListDataItem
                key={item.id}
                to={`${SERVICE_USER_VIEW_URL}/${item.id}`}
                title={`${item.first_name} ${item.last_name}`}>
                <ListProperty label={t("Phone")}>{item.phone}</ListProperty>
                <ListProperty label={t("Email")}>{item.email}</ListProperty>
              </ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default IndexPage
