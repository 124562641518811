import React, { type ChangeEvent, useCallback, useMemo, useState } from "react"
import { useBulkDataAvailable } from "./BulkData"
import { Alert, Button, CircularProgress, Grid, List, MenuItem, TextField, Typography } from "@mui/material"
import ListItem from "@mui/material/ListItem"
import CheckIcon from "@mui/icons-material/Check"
import { type IFile } from "../../models/service/IFile"
import TruncateText from "../TruncateText"
import ViewLoading from "../ViewLoading"
import { type IListItem } from "../../models/components/IListItem"
import type { ISelectItem } from "../../models/components/ISelectItem"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import { RestRepository } from "../../repositories/RestRepository"
import { ACCOUNT_ENDPOINT } from "../../models/service/IAccount"
import { LOCATION_ENDPOINT } from "../../models/service/ILocation"
import { INSPECTION_ENDPOINT } from "../../models/service/IInspection"
import { LOCATION_IMPAIRMENT_ENDPOINT } from "../../models/service/ILocationImpairment"
import { type IPaging } from "../../models/components/IPaging"
import { POLICY_ENDPOINT } from "../../models/service/IPolicy"
import { SERVICE_USER_ENDPOINT } from "../../models/service/IServiceUser"
import { t } from "i18next"

const field = "copy"

const destinations: ISelectItem[] = [
  { key: "AccountFile", value: "Account" },
  { key: "LocationFile", value: "Location" },
  { key: "InspectionFile", value: "Inspection" },
  { key: "LocationImpairmentFile", value: "Location Impairment" },
  { key: "PolicyFile", value: "Policy" },
  { key: "ServiceUser", value: "Service User" },
]

const accountRepository = new RestRepository<IListItem>(ACCOUNT_ENDPOINT)
const locationRepository = new RestRepository<IListItem>(LOCATION_ENDPOINT)
const inspectionRepository = new RestRepository<IListItem>(INSPECTION_ENDPOINT)
const impairmentRepository = new RestRepository<IListItem>(LOCATION_IMPAIRMENT_ENDPOINT)
const policyRepository = new RestRepository<IListItem>(POLICY_ENDPOINT)
const serviceUserRepository = new RestRepository<IListItem>(SERVICE_USER_ENDPOINT)

/**
 * Deletes multiple elements in bulk.
 *
 * @returns {React.ReactElement} - The React element rendered by the method.
 */
const BulkFileCopy: React.FC = (): React.ReactElement => {
  const bulkDataContext = useBulkDataAvailable(field, "Copy")
  const [files, setFiles] = useState<IFile[]>([])
  const [copied, setCopied] = useState<IFile[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [item, setItem] = useState<IListItem | null>(null)

  const [destination, setDestination] = useState<string>(destinations[0].key as string)

  const handleChangeDestination = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDestination(event.target.value)
  }, [])

  const repository = useMemo(() => {
    if (destination === destinations[0].key) {
      return accountRepository
    }
    if (destination === destinations[1].key) {
      return locationRepository
    }
    if (destination === destinations[2].key) {
      return inspectionRepository
    }
    if (destination === destinations[3].key) {
      return impairmentRepository
    }
    if (destination === destinations[4].key) {
      return policyRepository
    }
    if (destination === destinations[5].key) {
      return serviceUserRepository
    }
    return null
  }, [destination])

  const handleCopies = useCallback(async () => {
    if (bulkDataContext?.selectedRows !== undefined && bulkDataContext.selectedRows !== null && item !== null) {
      bulkDataContext.setProcessing(true)
      const filesToCopy: IFile[] = []
      setFiles([])
      setCopied([])
      for (const selectedRow of bulkDataContext.selectedRows) {
        try {
          const file: IFile = await bulkDataContext.repository?.read(selectedRow)
          if (file !== undefined) {
            filesToCopy.push(file)
            setFiles([...filesToCopy])
          }
        } catch (error) {
          console.log(error)
        }
      }
      for (const file of filesToCopy) {
        try {
          if (file.id !== undefined) {
            const paging: IPaging = {
              filters: [
                { field: "to", value: destination },
                { field: "id", value: item.id },
              ],
            }
            await bulkDataContext.repository?.action(file.id, "copy_file_to", paging)
            setCopied(copied => [...copied, file])
          }
        } catch (error) {
          console.log(error)
        }
      }
      setFiles([])
      bulkDataContext.setProcessing(false)
      bulkDataContext.onDone?.()
    }
  }, [bulkDataContext?.selectedRows, files, copied, item, destination])

  return (
    <>
      {bulkDataContext?.selectedBulk?.value === field && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label={t("Type")} select onChange={handleChangeDestination} value={destination}>
              {destinations.map(item => (
                <MenuItem key={item.key} value={item.key}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {repository !== null && (
            <Grid item xs={12}>
              <SelectFilteredSingle
                name="item_id"
                label={t("Destination")}
                defaultValue={item}
                repository={repository}
                onChange={setItem}
              />
            </Grid>
          )}
          {bulkDataContext.selectedRows?.length !== undefined &&
          bulkDataContext.selectedRows.length > 0 &&
          item !== null ? (
            <>
              <Grid item xs={12}>
                <Alert severity="warning">
                  {t("Are you sure you want to copy these files?")} <strong>{bulkDataContext.selectedRows?.length}</strong>
                </Alert>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Button onClick={handleCopies}>{t("Copy Files")}</Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Alert severity="info">{t("No destination selected.")}</Alert>
            </Grid>
          )}
          {bulkDataContext.processing && (
            <>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h5">{t("Copying Files")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <ViewLoading
                  loading={true}
                  inline
                  progress={copied.length === 0 ? undefined : (copied.length / files.length) * 100}
                  message="Copying..."
                />
              </Grid>
              <Grid item xs={12}>
                <List>
                  {files.map((file: IFile) => (
                    <ListItem
                      key={file.id}
                      secondaryAction={
                        <>{copied.some(f1 => f1.id === file.id) ? <CheckIcon /> : <CircularProgress size={20} />}</>
                      }>
                      <TruncateText>{file.name}</TruncateText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

export default BulkFileCopy
