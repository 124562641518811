import React from "react"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import { COMPANY_ADD_URL, COMPANY_VIEW_URL } from "../../../config/urls"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import TableData from "../../../../../shared/components/tables/TableData"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import TableActions from "../../../../../shared/components/tables/TableActions"
import ExcelDownloadButton from "../../../../../shared/components/ExcelDownloadButton"
import type { IFilter } from "../../../../../shared/models/components/IFilter"
import {
  useMetadataTableFilter,
  useMetadataTableHeader,
  useMetadataTableRow,
} from "../../../../../shared/components/metadata/UseMetadataTable"
import { COMPANY_ENDPOINT, type ICompany } from "../../../../../shared/models/service/ICompany"
import { useTranslation } from "react-i18next"
import TableCellTruncate from "../../../../../shared/components/tables/TableCellTruncate"

const repository = new RestRepository<ICompany>(COMPANY_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(COMPANY_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<ICompany> | undefined
  showHeader?: boolean
  gotoFilters?: IFilter[]
}

const modelName = "company"

/**
 * The IndexPage component is a React functional component that renders a page for displaying
 *
 * @param {IProps} props - The properties passed to the component.
 * @param {object} props.pagingResults - The paginated results containing company data to be displayed.
 * @param {boolean} props.showHeader - A flag indicating whether to show the page header.
 * @param {Function} props.gotoFilters - Callback function invoked for navigating to filters.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const CompanyIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, gotoFilters } = props

  const { t } = useTranslation()

  const metadataTableHeader = useMetadataTableHeader(modelName, pagingResults)
  const metadataTableRow = useMetadataTableRow(modelName)
  const metadataTableFilters = useMetadataTableFilter(modelName)

  return (
    <>
      {showHeader && <PageHeader title="Company" titlePlural="Companies" toAdd={COMPANY_ADD_URL} />}
      {pagingResults !== undefined && (
        <>
          <TableData
            pagingResults={pagingResults}
            to={COMPANY_VIEW_URL}
            repository={gotoRepo}
            gotoFilters={gotoFilters}>
            <TableActions>
              <ExcelDownloadButton repository={repository} paging={pagingResults.paging} />
            </TableActions>
            <FiltersData pagingResults={pagingResults} modelName="company">
              <FilterSearch />
              {metadataTableFilters()}
            </FiltersData>
            <TableHead>
              <TableCellCenter field="name" pagingResults={pagingResults}>
                {t("Name")}
              </TableCellCenter>
              <TableCellCenter field="address" pagingResults={pagingResults}>
                {t("Address")}
              </TableCellCenter>
              <TableCellCenter field="city" pagingResults={pagingResults}>
                {t("City")}
              </TableCellCenter>
              <TableCellCenter field="state_region" pagingResults={pagingResults}>
                {t("State/Region")}
              </TableCellCenter>
              <TableCellCenter field="country" pagingResults={pagingResults}>
                {t("Country")}
              </TableCellCenter>
              {metadataTableHeader()}
              <TableCellCenter field="created" pagingResults={pagingResults}>
                {t("Created")}
              </TableCellCenter>
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: ICompany) => (
                <TableRow key={item.id}>
                  <TableCellData field="name">{item.name}</TableCellData>
                  <TableCellTruncate field="address">{item.address}</TableCellTruncate>
                  <TableCellData field="city">{item.city}</TableCellData>
                  <TableCellTruncate field="state_region">{item.state_region}</TableCellTruncate>
                  <TableCellTruncate field="country">{item.country}</TableCellTruncate>
                  {metadataTableRow(item.metadata_data)}
                  <TableCellDate field="created">{item.created}</TableCellDate>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: ICompany) => (
              <ListDataItem key={item.id} to={`${COMPANY_VIEW_URL}/${item.id}`} title={item.name} />
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default CompanyIndex
