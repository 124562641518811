import React, { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Snackbar,
  Tab,
  TextField,
  Typography,
} from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatDate from "../../../../shared/components/format/FormatDate"
import PageViewBody from "../../../../shared/components/pages/PageViewBody"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import { useNavigate, useParams } from "react-router-dom"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { useTranslation } from "react-i18next"
import TabsList from "../../../../shared/components/tabs/TabsList"
import {
  type IReportFormat,
  type IReportWriterFormat,
  REPORT_FORMAT_ENDPOINT,
  REPORT_WRITER_FORMAT_ENDPOINT,
} from "../../../../shared/models/service/IReportFormat"
import CheckMark from "../../../../shared/components/display/CheckMark"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import UploadIcon from "@mui/icons-material/Upload"
import type { IStorageFile } from "../../../../shared/models/service/IStorageFile"
import { useDrawerWithUrl } from "../../../../shared/components/containers/DrawerRight"
import FileUploadDrawer from "../../../../shared/components/files/FileUploadDrawer"
import FileDownloadButton from "../../../../shared/components/files/FileDownloadButton"
import { type IFile } from "../../../../shared/models/service/IFile"
import FileViewerDialog from "../../../../shared/components/files/FileViewerDialog"
import useApiAction from "../../../../shared/hooks/useApiAction"
import { type IPaging } from "../../../../shared/models/components/IPaging"
import CopyToClipboard from "react-copy-to-clipboard"
import ViewLoading from "../../../../shared/components/ViewLoading"
import ErrorMessage from "../../../../shared/components/ErrorMessage"
import { useOnDemandPaged } from "../../../../shared/hooks/useApiPagedLocal"
import ReportWriterFormatIndex from "./components/ReportWriterFormatIndex"
import SetCurrentReportWriterFormat from "./components/SetCurrentReportWriterFormat"
import RefreshIcon from "@mui/icons-material/Refresh"
import ReportWriterInfo from "./components/ReportWriterInfo"

const repository = new RestRepository<IReportFormat>(REPORT_FORMAT_ENDPOINT)

interface IKeys {
  keys_in_template: string[]
  keys_available_to_insert: string[]
}

/**
 * Renders a page to view report format details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data, loading, call } = useApiRead<IReportFormat>({ apiFunction: repository.read })
  const { id } = useParams()
  const apiAction = useApiAction<IReportFormat>({ repository, itemId: id })

  const { tab, handleTabChange } = useTabPanel()
  const { t } = useTranslation()
  const drawerUrl = useDrawerWithUrl()
  const navigate = useNavigate()

  const [selectedTemplate, setSelectedTemplate] = useState("")
  const [selectedFile, setSelectedFile] = useState<IStorageFile | null | undefined>(null)

  const [keys, setKeys] = useState<IKeys | undefined>()

  const [openCopy, setOpenCopy] = React.useState(false)
  const handleCopy = useCallback(() => {
    setOpenCopy(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpenCopy(false)
  }, [])

  const selectedFileAsIFile = useMemo((): IFile | null => {
    if (selectedFile === null || selectedFile === undefined) {
      return null
    }
    return {
      id: 0,
      name: "report_writer_template",
      ext: "docx",
      size: null,
      exists: false,
      is_report: false,
      file: selectedFile,
    } satisfies IFile
  }, [selectedFile])

  const handleChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTemplate(event.target.value)
    const paging: IPaging = { filters: [{ field: "risk_writer_name", value: event.target.value }] }
    const keys = await apiAction.callAction<IKeys>("keys_for_report_writer", paging)
    setKeys(keys)
  }, [])

  const handleRefresh = useCallback(async () => {
    const paging: IPaging = { filters: [{ field: "risk_writer_name", value: selectedTemplate }] }
    const keys = await apiAction.callAction<IKeys>("keys_for_report_writer", paging)
    setKeys(keys)
  }, [selectedTemplate])

  useEffect(() => {
    setSelectedFile(data?.report_writer_format?.templates?.[selectedTemplate])
  }, [data, selectedTemplate])

  const handleUploadFile = useCallback(() => {
    navigate(drawerUrl("file_upload"))
  }, [data, selectedTemplate])

  const theFilter = [
    {
      field: "report_format",
      value: id,
      title: "Report Format",
      display: "This Report Format",
      canBeDelete: false,
    },
  ]
  const rwfPagingResults = useOnDemandPaged<IReportWriterFormat>(
    REPORT_WRITER_FORMAT_ENDPOINT,
    theFilter,
    "REPORT_FORMATS",
  )

  return (
    <PageViewBody loading={loading}>
      {data !== undefined && (
        <>
          <PageHeader title={t("Report Format")} titlePlural={data.name} onRefresh={call} />
          <Grid item xs={12}>
            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Overview")} value={0} />
              {data.use_report_writer && <Tab label={t("Templates")} value={1} />}
              <Tab label={t("Versions")} value={2} />
            </TabsList>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" color="textSecondary">
                        {t("Report Format")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <PaperLocal>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <ViewProperty label={t("Uses Report Writer")}>
                              <CheckMark value={data.use_report_writer} />
                            </ViewProperty>
                          </Grid>
                          <Grid item xs={12}>
                            <ViewProperty label={t("Report Writer Format")}>
                              <ViewListItems>{data.report_writer_format}</ViewListItems>
                            </ViewProperty>
                          </Grid>
                          <Grid item xs={12}>
                            <ViewProperty label={t("Created")}>
                              <FormatDate value={data.created} />
                            </ViewProperty>
                          </Grid>
                        </Grid>
                      </PaperLocal>
                    </Grid>
                    <Grid item xs={12}>
                      <PaperLocal>
                        <ViewProperty label={t("Description")} vertical>
                          <ViewRichText>{data.description}</ViewRichText>
                        </ViewProperty>
                      </PaperLocal>
                    </Grid>
                  </Grid>
                </Grid>
                {data.report_writer_format !== null && (
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" color="textSecondary">
                          {t("Report Writer")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <PaperLocal>
                          <ReportWriterInfo reportWriterFormat={data.report_writer_format} />
                        </PaperLocal>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Box>
                {data.report_writer_format?.data !== undefined && data.report_writer_format.data !== null && (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <TextField
                        label={t("Select Template")}
                        select
                        fullWidth
                        value={selectedTemplate}
                        onChange={handleChange}
                        variant="outlined">
                        <MenuItem value={data.report_writer_format.data.name}>
                          {t("Inspection")} | {data.report_writer_format.data.title}
                        </MenuItem>
                        {data.report_writer_format.data.summary_formats?.map(format => (
                          <MenuItem key={format.name} value={format.name}>
                            {t("Summary")} | {format.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {selectedTemplate !== "" && (
                      <Grid item>
                        <IconButton onClick={handleRefresh}>
                          <RefreshIcon />
                        </IconButton>
                      </Grid>
                    )}
                    <Grid item xs />
                    {selectedTemplate !== "" && !apiAction.saving && apiAction.connectionError === undefined && (
                      <>
                        <Grid item>
                          {selectedFileAsIFile !== null && (
                            <FileViewerDialog withButtonText selectedFile={selectedFileAsIFile} />
                          )}
                        </Grid>
                        <Grid item>
                          {selectedFileAsIFile !== null && (
                            <FileDownloadButton withText file={selectedFileAsIFile} variant="text" />
                          )}
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      {selectedTemplate !== "" && (
                        <Button startIcon={<UploadIcon />} onClick={handleUploadFile}>
                          {t("File Upload")}
                        </Button>
                      )}
                      <FileUploadDrawer selectedFile={selectedFile} onDrawerClose={call} />
                    </Grid>
                    <Grid item xs={12}>
                      <ViewLoading loading={apiAction.saving} inline message={t("Loading template keys.")} />
                      <ErrorMessage error={apiAction.connectionError} />
                    </Grid>
                    {keys !== undefined && (
                      <Grid item xs={12}>
                        <Snackbar
                          open={openCopy}
                          anchorOrigin={{ vertical: "top", horizontal: "center" }}
                          autoHideDuration={1000}
                          onClose={handleClose}>
                          <Alert severity="info">{t("Key Copied.")}</Alert>
                        </Snackbar>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <Typography variant="h5" color="textSecondary">
                              {t("Template Keys")}
                            </Typography>
                            <PaperLocal sx={{ mt: 2 }}>
                              <List>
                                {keys.keys_in_template.map((key: string) => (
                                  <ListItem
                                    key={key}
                                    dense
                                    secondaryAction={
                                      <CopyToClipboard text={key} onCopy={handleCopy}>
                                        <IconButton edge="end" aria-label="copy">
                                          <ContentCopyIcon />
                                        </IconButton>
                                      </CopyToClipboard>
                                    }>
                                    <Alert sx={{ display: "block", width: "100%" }} icon={false} severity="success">
                                      {key}
                                    </Alert>
                                  </ListItem>
                                ))}
                              </List>
                            </PaperLocal>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Typography variant="h5" color="textSecondary">
                              {t("Available Keys")}
                            </Typography>
                            <PaperLocal sx={{ mt: 2 }}>
                              <List>
                                {keys.keys_available_to_insert.map((key: string) => (
                                  <ListItem
                                    key={key}
                                    dense
                                    secondaryAction={
                                      <CopyToClipboard text={key} onCopy={handleCopy}>
                                        <IconButton edge="end" aria-label="copy">
                                          <ContentCopyIcon />
                                        </IconButton>
                                      </CopyToClipboard>
                                    }>
                                    <Alert
                                      sx={{ display: "block", width: "100%" }}
                                      icon={false}
                                      severity={keys.keys_in_template.includes(key) ? "success" : "info"}>
                                      {key}
                                    </Alert>
                                  </ListItem>
                                ))}
                              </List>
                            </PaperLocal>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={2} onChange={rwfPagingResults.call}>
              <ReportWriterFormatIndex
                pagingResults={rwfPagingResults}
                currentReportWriterFormat={data.report_writer_format}
                cellActions={(reportWriterFormat: IReportWriterFormat) => (
                  <>
                    {reportWriterFormat.id !== data.report_writer_format?.id && (
                      <SetCurrentReportWriterFormat
                        reportWriterFormat={reportWriterFormat}
                        onComplete={call}
                        parentId={data.id}
                        repository={repository}
                      />
                    )}
                  </>
                )}
              />
            </TabPanel>
          </Grid>
        </>
      )}
    </PageViewBody>
  )
}

export default ViewPage
