import { Button, Grid, useMediaQuery, useTheme } from "@mui/material"
import React, { useCallback, useContext, useEffect } from "react"
import { PanariskAppContext } from "../../../app/PanariskApp"
import { useTranslation } from "react-i18next"

interface IProps {
  disabled?: boolean
  onCancel?: () => void
  showCancel?: boolean
}

/**
 * A standard forms save can cancel panel for reuse.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the action panel.
 */
const FormActionPanel: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { disabled = false, onCancel, showCancel = true } = props

  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const { setActionItem } = useContext(PanariskAppContext)
  const { t } = useTranslation()

  const handleCancel = useCallback(() => {
    if (onCancel !== undefined) {
      onCancel()
    } else {
      if (typeof window !== "undefined") {
        window.history.go(-1)
      }
    }
  }, [onCancel])

  useEffect(() => {
    if (isSmall) {
      setActionItem?.(undefined)
    }
  }, [isSmall, disabled])

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mt: 3 }}>
      <Grid item xs>
        {showCancel && <Button onClick={handleCancel}>{t("Cancel")}</Button>}
      </Grid>
      <Grid item>
        <Button type="submit" disableElevation variant="contained" color="secondary" disabled={disabled}>
          {t("Save")}
        </Button>
      </Grid>
    </Grid>
  )
}

export default FormActionPanel
