import React, { useCallback } from "react"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../../shared/models/service/IInspectionRecommendation"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import { LoadingButton } from "@mui/lab"
import useApiAction, { type IUseApiActionProps } from "../../../../../shared/hooks/useApiAction"
import ViewListItems from "../../../../../shared/components/display/ViewListItems"
import FormatDate from "../../../../../shared/components/format/FormatDate"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import { INSPECTION_RECOMMENDATION_VIEW_URL } from "../../../config/urls"
import ViewCurrency from "../../../../../shared/components/display/ViewCurrency"
import TruncateText from "../../../../../shared/components/TruncateText"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IInspectionRecommendation>(INSPECTION_RECOMMENDATION_ENDPOINT)

/**
 * Renders a view page for an inspection report recommendation.
 *
 * @param {IInfoViewProps} props See IInfoViewProps for more info
 * @returns {React.ReactElement} The rendered view page.
 */
const InspectionRecommendationInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const apiRead = useApiRead<IInspectionRecommendation>({ apiFunction: repository.read, itemId: infoId })
  const { data } = apiRead

  const actionProps: IUseApiActionProps<IInspectionRecommendation> = { repository, itemId: data?.id }
  const apiAction = useApiAction<IInspectionRecommendation>(actionProps)

  const handleToggleCompleted = useCallback(async () => {
    await apiAction.callAction("toggle_completed")
    await apiRead.call()
  }, [apiAction.callAction])

  const { t } = useTranslation()

  return (
    <>
      {data !== undefined && (
        <>
          <Grid container spacing={2}>
            <Grid item xs>
              <ItemViewerTitle to={`${INSPECTION_RECOMMENDATION_VIEW_URL}/${infoId}`}>
                <TruncateText>{data.title}</TruncateText>
              </ItemViewerTitle>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Location")}>
                <ViewListItems>{data.location}</ViewListItems>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Identifier")}>{data.identifier}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Order")}>{data.order}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Cost To Complete")}>
                <ViewCurrency>{data.cost_to_complete}</ViewCurrency>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Loss Estimate After")}>
                <ViewCurrency>{data.loss_estimate_after}</ViewCurrency>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Loss Estimate Before")}>
                <ViewCurrency>{data.loss_estimate_before}</ViewCurrency>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Type")}>{data.type?.name}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Status")}>{data.status?.name}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Priority")}>{data.priority?.name}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Risk Score Deduction")}>{data.risk_score_deduction}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Risk Score Category")}>{data.risk_score_category?.name}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Completed")}>
                <LoadingButton
                  onClick={handleToggleCompleted}
                  color={data.completed === null ? "primary" : "error"}
                  loading={apiAction.saving}>
                  {data.completed === null ? t("Complete") : t("Reopen")}
                </LoadingButton>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Completed By")} show={data.completed !== null}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs>
                    <FormatDate value={data.completed} />
                  </Grid>
                  <Grid item>
                    <ViewListItems>{data.completed_by}</ViewListItems>
                  </Grid>
                </Grid>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Description")}>
                <ViewRichText>{data.body}</ViewRichText>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Loss Estimate")}>
                <ViewRichText>{data.loss_estimate}</ViewRichText>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Comment")}>
                <ViewRichText>{data.comment}</ViewRichText>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Reply")}>
                <ViewRichText>{data.reply}</ViewRichText>
              </ViewProperty>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default InspectionRecommendationInfo
