import React, { useCallback } from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import ContactForm from "./components/ContactForm"
import ErrorMessage from "../../../../shared/components/ErrorMessage"
import { CONTACT_ENDPOINT, type IContact } from "../../../../shared/models/service/IContact"
import { CONTACT_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IContact>(CONTACT_ENDPOINT)
const redirectView = CONTACT_VIEW_URL

/**
 * This page will allow adding of a contact.
 *
 * @returns {React.FC} the contact add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()

  const props: IUseApiAddProps<IContact> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const { saving, handleAdd, connectionError } = useApiAdd<IContact>(props)
  const { t } = useTranslation()

  const handleSave = useCallback(
    async (contact: IContact) => {
      contact.latitude = contact.latitude === "" ? 0 : contact.latitude
      contact.longitude = contact.longitude === "" ? 0 : contact.longitude
      contact.zoom = contact.zoom === "" ? 0 : contact.zoom
      await handleAdd(contact)
    },
    [handleAdd],
  )

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title={t("Add Contact")} loading={saving} errors={form.formState.errors} />
        <Grid item xs={12}>
          <ErrorMessage error={connectionError} />
        </Grid>
        <FormBox form={form} onSubmit={handleSave}>
          <ContactForm form={form} />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default AddPage
