import React, { useContext } from "react"
import { PanariskAppContext } from "../../../app/PanariskApp"

interface IProps {
  component: React.FunctionComponent<any>
  accessDeniedComponent: React.FunctionComponent<any>
  addonKey?: string
  path?: string
}

/**
 * This component show or hide a component and check to see if it is accessible.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the component or empty fragment.
 */
const AddonComponent: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { component: Component, accessDeniedComponent: AccessDeniedComponent, addonKey = "", ...rest } = props
  const { appSettings } = useContext(PanariskAppContext)

  // Check to see if the route has an access group and if the user is in that group.
  if (appSettings?.serverInfo?.addons.includes(addonKey) !== true) {
    return <AccessDeniedComponent {...rest} />
  }
  return <Component {...rest} />
}

export default AddonComponent
