import React, { type ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { type IRiskWriterSection } from "../../models/IRiskWriterSection"
import {
  type IRiskWriterSectionData,
  type ISectionDataTemplate,
  SectionType,
} from "../../models/IRiskWriterSectionData"
import { Alert, Box, Button, Grid, IconButton, MenuItem, TextField } from "@mui/material"
import AlertDialog from "../../../AlertDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import { RiskWriterContext } from "../../context/RiskWriterProvider"
import HtmlToolTip from "../../../HtmlToolTip"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"
import EditIcon from "@mui/icons-material/Edit"
import { nameToLabel } from "../../../../utilities/form_utility"
import { useTranslation } from "react-i18next"

interface IProps {
  section: IRiskWriterSection
  content: IRiskWriterSectionData
  viewerOnly: boolean
}

/**
 * ContentTemplateEditor is a React functional component responsible for rendering
 * and managing a content editor section within a risk writer application. It allows for
 * editing, moving, saving, and deleting content entries based on the provided props.
 *
 * @param {IProps} props - The properties required for the component, including content, section, and viewerOnly.
 * @returns {React.ReactElement} The rendered ContentTemplateEditor component.
 */
const ContentTemplateEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { content, section, viewerOnly } = props
  const { t } = useTranslation()

  const { currentContent, reportWriterFormat, onChangeContent, onDeleteContent, onContentMove, onEditContent } =
    useContext(RiskWriterContext)

  const [template, setTemplate] = useState<string>("")

  const sectionDataTemplate = content.data as ISectionDataTemplate

  const handleContentMoveUp = useCallback(() => {
    onContentMove?.(content, "up")
  }, [content])

  const handleContentMoveDown = useCallback(() => {
    onContentMove?.(content, "down")
  }, [content])

  const handleSaveText = useCallback(() => {
    onChangeContent?.(content.name, null)
  }, [content])

  const handleDeleteContent = useCallback(async () => {
    onDeleteContent?.(content)
  }, [content])

  const handleEditContent = useCallback(() => {
    onEditContent?.(section, content)
  }, [section, content])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTemplate(event.target.value)
      const sectionContent: ISectionDataTemplate = {
        template_name: event.target.value,
      }
      onChangeContent?.(content.name, sectionContent, false)
    },
    [content],
  )

  const currentTemplate = useMemo(() => {
    return reportWriterFormat?.section_templates?.find(template => template.name === sectionDataTemplate?.template_name)
  }, [template, reportWriterFormat, sectionDataTemplate])

  useEffect(() => {
    if (content.section_type === SectionType.TEMPLATE_EDITOR) {
      setTemplate(sectionDataTemplate?.template_name)
    }
  }, [content])

  return (
    <>
      {content.section_type === SectionType.TEMPLATE_EDITOR && (
        <Box sx={{ m: 1, mr: 0, ml: 0 }}>
          {content.name === currentContent?.name && !viewerOnly ? (
            <Box>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Alert severity="info">{t("Templates will be displayed here.")}</Alert>
                </Grid>
                <Grid item>
                  <HtmlToolTip title={t("Move the template up in the section.")}>
                    <IconButton color="primary" onClick={handleContentMoveUp} size="small">
                      <ArrowUpward />
                    </IconButton>
                  </HtmlToolTip>
                  <HtmlToolTip title={t("Move the template down in the section.")}>
                    <IconButton color="primary" onClick={handleContentMoveDown} size="small">
                      <ArrowDownward />
                    </IconButton>
                  </HtmlToolTip>
                </Grid>
                <Grid item xs>
                  <AlertDialog
                    buttonText="Remove"
                    buttonIcon={<DeleteIcon />}
                    onYes={handleDeleteContent}
                    message={t("Are you sure you want to remove the template?")}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    value={template}
                    label={t("Select Template")}
                    onChange={handleChange}>
                    {reportWriterFormat?.section_templates?.map(template => {
                      return (
                        <MenuItem key={template.name} value={template.name}>
                          {nameToLabel(template.name, template.title)}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item>
                  <Button onClick={handleSaveText}>{t("Done")}</Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Alert
                severity="info"
                action={
                  !viewerOnly && (
                    <Grid item>
                      <IconButton size="small" onClick={handleEditContent}>
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  )
                }>
                {currentTemplate !== undefined ? (
                  <>{t("Displayed here")} <strong>{nameToLabel(currentTemplate.name, currentTemplate.title)}</strong></>
                ) : (
                  t("No template selected.")
                )}
              </Alert>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

export default ContentTemplateEditor
