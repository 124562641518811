import React from "react"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import { type IOccupancy, OCCUPANCY_ENDPOINT } from "../../../../shared/models/service/IOccupancy"
import { OCCUPANCY_ADD_URL, OCCUPANCY_VIEW_URL } from "../../config/urls"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import TableData from "../../../../shared/components/tables/TableData"
import ListData from "../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../shared/components/lists/ListDataItem"
import TableCellData from "../../../../shared/components/tables/TableCellData"
import { type IListItem } from "../../../../shared/models/components/IListItem"
import ListProperty from "../../../../shared/components/lists/ListProperty"
import TableCellCheckMark from "../../../../shared/components/tables/TableCellCheckMark"
import TableCellCenter from "../../../../shared/components/tables/TableCellCenter"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IOccupancy>(OCCUPANCY_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(OCCUPANCY_ENDPOINT)

/**
 * Renders the index page for the occupancies.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const pagingResults = useApiPagedLocal<IOccupancy>({ apiFunction: repository.findAll })
  const { data } = pagingResults
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={2}>
      <PageHeader title={t("Occupancy")} titlePlural={t("Occupancies")} toAdd={OCCUPANCY_ADD_URL} />
      <TableData pagingResults={pagingResults} to={OCCUPANCY_VIEW_URL} repository={gotoRepo}>
        <TableHead>
          <TableCellCenter field="name" pagingResults={pagingResults}>
            {t("Name")}
          </TableCellCenter>
          <TableCellCenter field="sic_code" pagingResults={pagingResults}>
            {t("SIC Code")}
          </TableCellCenter>
          <TableCellCenter field="enabled" pagingResults={pagingResults}>
            {t("Enabled")}
          </TableCellCenter>
        </TableHead>
        <TableBody>
          {data?.results.map((item: IOccupancy) => (
            <TableRow key={item.id}>
              <TableCellData field="name">{item.name}</TableCellData>
              <TableCellData field="sic_code">{item.sic_code}</TableCellData>
              <TableCellCheckMark field="enabled">{item.enabled}</TableCellCheckMark>
            </TableRow>
          ))}
        </TableBody>
      </TableData>
      <ListData pagingResults={pagingResults}>
        {data?.results.map((item: IOccupancy) => (
          <ListDataItem key={item.id} to={`${OCCUPANCY_VIEW_URL}/${item.id}`} title={item.name}>
            <ListProperty label={t("SIC Code")}>{item.sic_code}</ListProperty>
          </ListDataItem>
        ))}
      </ListData>
    </Grid>
  )
}

export default IndexPage
