import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { type IOccupancy } from "../../../../../shared/models/service/IOccupancy"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import FhMuiCheckboxField from "../../../../../shared/components/forms/FhMuiCheckboxField"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import { useTranslation } from "react-i18next"

interface IProps {
  form: UseFormReturn
  occupancy?: IOccupancy | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit an occupancy.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const OccupancyForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { occupancy, form, isEdit = false } = props

  const { t } = useTranslation()

  useLoadFormData<IOccupancy>(
    (data: IOccupancy) => {
      form.setValue("id", data.id)
      form.setValue("name", data.name)
      form.setValue("sic_code", data.sic_code)
      form.setValue("notes", data.notes)
      form.setValue("enabled", data.enabled)
    },
    occupancy,
    isEdit,
    form.setValue,
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <PaperLocal>
          {isEdit && <FhMuiHiddenField control={form.control} />}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} name="name" rules={requiredRule()} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiTextField control={form.control} label={t("SIC Code")} name="sic_code" />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FhMuiCheckboxField control={form.control} label={t("Enabled")} name="enabled" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiRichTextField control={form.control} name="notes" />
            </Grid>
          </Grid>
        </PaperLocal>
      </Grid>
    </Grid>
  )
}

export default OccupancyForm
