import React, { type SyntheticEvent, useCallback, useEffect, useState } from "react"
import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Popper,
  type PopperProps,
  TextField,
  Tooltip,
} from "@mui/material"
import useDebounce from "react-debounced"
import { type IListItem } from "../../models/components/IListItem"
import { type IFilter } from "../../models/components/IFilter"
import { type RestRepository } from "../../repositories/RestRepository"
import { CONNECTION_ERROR, type IConnectionError } from "../../models/components/IConnectionError"
import { type IPaging } from "../../models/components/IPaging"
import ErrorMessage from "../ErrorMessage"
import { type UseFormReturn } from "react-hook-form/dist/types"
import ErrorIcon from "@mui/icons-material/Error"
import { type AxiosError } from "axios"
import { nameToLabel } from "../../utilities/form_utility"
import InfoIcon from "@mui/icons-material/Info"
import { useDrawerWithUrl } from "../containers/DrawerRight"
import { Link } from "react-router-dom"
import TruncateText from "../TruncateText"
import type { Control } from "react-hook-form/dist/types/form"

export type TUseSelectFilteredSingleResponse = [item: IListItem | null, handleItem: (item1: IListItem | null) => void]

/**
 * Returns a tuple containing an item and a handler function for selecting a filtered single item.
 *
 * @param {string} name - The name of the field.
 * @param {UseFormReturn} form - The forms object returned by useForm hook.
 * @returns {TUseSelectFilteredSingleResponse} - A tuple containing an item and a handler function.
 */
export const useSelectFilteredSingle = (name: string, form: UseFormReturn): TUseSelectFilteredSingleResponse => {
  const [item, setItem] = useState<IListItem | null>(null)

  const handleItem = useCallback(
    (item1: IListItem | null) => {
      form.clearErrors(name)
      if (item1 !== null) {
        form.setValue(name, item1.id)
        setItem({ name: item1.name, id: item1.id })
      } else {
        form.setValue(name, null)
        setItem(null)
      }
    },
    [name, form],
  )

  return [item, handleItem]
}

interface IProps {
  name: string
  repository: RestRepository<IListItem>
  onChange: (listItem: IListItem | null) => void
  onSearch?: (search: string | null) => void
  showId?: boolean
  freeSolo?: boolean
  size?: "small" | "medium"
  autoFocus?: boolean
  label?: string
  defaultValue?: IListItem | null
  filters?: IFilter[]
  onFocus?: () => void
  onBlur?: () => void
  infoViewPrefix?: string
  control?: Control
  disabled?: boolean
}

/**
 * This component provides a single autocomplete forms select.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC} the component.
 */
const SelectFilteredSingle: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    name,
    repository,
    onChange,
    onSearch,
    showId = true,
    freeSolo = false,
    size = "medium",
    autoFocus = false,
    filters = [],
    defaultValue,
    label,
    onFocus = () => {},
    onBlur = () => {},
    infoViewPrefix,
    control,
    disabled = false
  } = props

  const fieldState = control?.getFieldState(name)

  const debounce = useDebounce()

  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const [listItems, setListItems] = useState<readonly IListItem[]>([])
  const [selectedListItem, setSelectedListItem] = useState<IListItem | null>(null)
  const [error, setError] = useState<IConnectionError | null>(null)
  const [search, setSearch] = useState<string>("")

  const drawerUrl = useDrawerWithUrl()

  const handleOpen = useCallback(async () => {
    setOpen(true)
    setLoading(true)
    setError(null)
    if (selectedListItem !== null) setListItems([selectedListItem])
    try {
      const paging: IPaging = { filters }
      const results = await repository.list(paging)
      setListItems(results)
    } catch (reason) {
      if ((reason as AxiosError).response !== undefined) {
        setError((reason as AxiosError).response as IConnectionError)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [filters, selectedListItem])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleListItemClick = useCallback((_event: SyntheticEvent, item: IListItem | string | null) => {
    if (typeof item === "string") {
      setSelectedListItem({ id: item, name: item } satisfies IListItem)
      onChange({ id: item, name: item } satisfies IListItem)
    } else {
      setSelectedListItem(item)
      onChange(item)
    }
  }, [])

  const handleBlur = useCallback(() => {
    if (freeSolo) {
      setSelectedListItem({ id: search, name: search } satisfies IListItem)
      onChange({ id: search, name: search } satisfies IListItem)
    }
    onBlur()
  }, [onBlur, freeSolo])

  // noinspection DuplicatedCode
  const handleSearch = useCallback(
    async (_event: SyntheticEvent, search: string) => {
      onSearch?.(search)
      setSearch(search)
      setError(null)
      setLoading(true)
      // noinspection DuplicatedCode
      debounce(async () => {
        try {
          const paging: IPaging = {
            filters: [
              ...filters,
              {
                field: "search",
                value: search,
              },
            ],
          }
          const results = await repository.list(paging)
          setListItems(results)
        } catch (reason) {
          if ((reason as AxiosError).response !== undefined) {
            setError((reason as AxiosError).response as IConnectionError)
          } else {
            setError(CONNECTION_ERROR)
          }
        }
        setLoading(false)
      })
    },
    [filters, onSearch],
  )

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null && !open) {
      if( defaultValue.id !== null && defaultValue.name !== null) {
        const item: IListItem = { id: defaultValue.id, name: defaultValue.name }
        setSelectedListItem(item)
      }
    }
  }, [defaultValue, open])

  const getOptionLabel = useCallback((option: string | IListItem) => {
    return showId ? `${(option as IListItem).id} - ${(option as IListItem).name}` : (option as IListItem).name
  }, [])

  const theLabel = nameToLabel(name, label)

  /**
   * This is needed so the autocomplete can be used in right drawer on the report writer.
   * See public/index.html for styling.
   */
  const PopperSelectFilter = useCallback((props: PopperProps) => {
    return <Popper {...props} id="AutocompletePopper" />
  }, [])

  // note: warnings involving isOptionEqualToValue means the names don't match.
  // example: "118 | Wolfe, Thomas and Hamilton => Allianz" !== "Wolfe, Thomas and Hamilton"
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Autocomplete
          freeSolo={freeSolo}
          disabled={disabled}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          PopperComponent={PopperSelectFilter}
          onChange={handleListItemClick}
          value={selectedListItem}
          onInputChange={handleSearch}
          onFocus={onFocus}
          onBlur={handleBlur}
          autoHighlight
          isOptionEqualToValue={(option: IListItem, value: IListItem) => option.id === value.id}
          getOptionLabel={getOptionLabel}
          options={listItems}
          loading={loading}
          renderOption={(props, option: IListItem) => (
            <li {...props} key={option.id}>
              <TruncateText>{getOptionLabel(option)}</TruncateText>
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              name={name}
              size={size}
              autoFocus={autoFocus}
              label={theLabel}
              helperText={fieldState?.error?.message}
              error={fieldState?.invalid}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                    {error !== null && (
                      <Tooltip title={<ErrorMessage error={error} />} sx={{ p: 0 }} placement="right">
                        <ErrorIcon color="error" />
                      </Tooltip>
                    )}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      {infoViewPrefix !== undefined && selectedListItem !== null && (
        <Grid item>
          <IconButton component={Link} to={drawerUrl(`${infoViewPrefix}info/${selectedListItem.id}`)}>
            <InfoIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default SelectFilteredSingle
