import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Button, Drawer, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { type ItemPrefixes } from "../../../config/config"
import PrimaryButton from "../PrimaryButton"
import { useTranslation } from "react-i18next"

/**
 * Generates the URL for the item edit dialog based on the given prefix and infoId.
 *
 * @param {ItemPrefixes} prefix - The prefix used in the generated URL.
 * @param {number|string|null} infoId - The ID of the item or null if it does not exist.
 * @returns {string} The generated URL for the item edit dialog.
 */
export const useItemEditDialogUrl = (prefix: ItemPrefixes, infoId: number | string | null | undefined): string => {
  const params = useParams()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  return useMemo(() => {
    if ("*" in params) {
      const parentPath = location.pathname.replace("/" + params["*"], "")
      if (parentPath.startsWith("/")) {
        return `${parentPath}/${prefix}edit/${infoId}?${searchParams.toString()}`
      }
      return `/${parentPath}/${prefix}edit/${infoId}?${searchParams.toString()}`
    }
    return `${location.pathname}/${prefix}edit/${infoId}`
  }, [params, prefix, infoId])
}

export interface IInfoViewProps {
  infoId: number | string | null
}

interface IProps {
  title?: string
  prefix: ItemPrefixes
  infoView?: React.ComponentType<IInfoViewProps>
  canEdit?: boolean
}

/**
 * Render an information viewer drawer component.
 *
 * @param {IProps} props - The properties for the component.
 * @returns {React.ReactElement} The rendered information viewer drawer component.
 */
const ItemViewerDrawer: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { title, prefix, infoView: InfoView, canEdit = false } = props
  const [width, setWidth] = useState<number>(450)

  const [open, setOpen] = useState<boolean>(false)
  const [infoId, setInfoId] = useState<string | null>(null)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const itemEditDialogUrl = useItemEditDialogUrl(prefix, infoId)

  const handleEdit = useCallback(() => {
    setOpen(false)
    navigate(itemEditDialogUrl, { replace: true })
  }, [itemEditDialogUrl])

  const handleClose = useCallback(() => {
    setOpen(false)
    if ("*" in params) {
      const parentPath = location.pathname.replace("/" + params["*"], "")
      navigate(`${parentPath}?${searchParams.toString()}`, { replace: true })
    }
  }, [params, location, searchParams])

  useEffect(() => {
    setWidth(isSmall ? window.outerWidth - 20 : 450)
  }, [isSmall])

  useEffect(() => {
    if ("*" in params) {
      const parts = params["*"]?.split("/")
      if (parts !== undefined && parts[0] === `${prefix}info` && parts.length > 0) {
        setOpen(true)
        setInfoId(parts[1])
      } else {
        setOpen(false)
      }
    }
  }, [params])

  return (
    <Drawer open={open} onClose={handleClose} anchor="right" sx={{ zIndex: 1403 }}>
      <Box sx={{ width }} role="presentation">
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs>
              <Typography variant="h4">{title}</Typography>
            </Grid>
            <Grid item>
              <Button startIcon={<CloseIcon />} onClick={handleClose}>
                {t("Close")}
              </Button>
            </Grid>
            {canEdit && (
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <PrimaryButton onClick={handleEdit} edit>
                  {t("Edit")}
                </PrimaryButton>
              </Grid>
            )}
          </Grid>
          {InfoView !== undefined && <InfoView infoId={infoId} />}
        </Box>
      </Box>
    </Drawer>
  )
}

export default ItemViewerDrawer
