import React, { useCallback, useEffect, useRef, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { AdvancedMarker, Map, useMap } from "@vis.gl/react-google-maps"
import { DEFAULT_CENTER, DEFAULT_GOOGLE_MAP_ID } from "../../../config/config"
import useAuth, { type IProfilePatch } from "../../hooks/useAuth"
import { Box, Grid } from "@mui/material"
import HtmlToolTip from "../HtmlToolTip"
import InfoIcon from "@mui/icons-material/Info"

interface IProps {
  form: UseFormReturn
  redraw?: boolean
  helperText?: string
}

const height = "400px"

/**
 * Renders a map with a marker based on the provided props and form values.
 *
 * @param {IProps} props - The props object containing the "form" property.
 * @returns {React.ReactElement} - The rendered map component.
 */
const FhMap: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { form, redraw, helperText = null } = props
  const map = useMap()
  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(null)
  const [zoom, setZoom] = useState<number | null>(null)
  const initialLoad = useRef<boolean>(false)
  const { currentUser, updateProfile } = useAuth()
  const mapType = currentUser?.user.profile.map_type ?? "satellite"

  const handleMapTypeChange = useCallback(async () => {
    if (map?.getMapTypeId() !== undefined && currentUser?.user.profile.id !== undefined) {
      const patch: IProfilePatch = { id: currentUser.user.profile.id, name: "", map_type: map.getMapTypeId() }
      await updateProfile?.(patch)
    }
  }, [map, currentUser])

  const handleZoomChange = useCallback(() => {
    const zoom1 = map?.getZoom()
    if (zoom1 !== undefined) {
      form.setValue("zoom", zoom1)
      setZoom(zoom1)
    }
  }, [map, form])

  const handleCenterChange = useCallback(() => {
    if (map !== null && initialLoad.current) {
      const center1 = map.getCenter()
      if (center1 !== undefined) {
        form.setValue("latitude", center1.lat())
        form.setValue("longitude", center1.lng())
        setCenter({ lat: center1.lat(), lng: center1.lng() })
      }
      handleZoomChange()
    }
  }, [map, form, initialLoad, handleZoomChange])

  useEffect(() => {
    if (map !== null) {
      const lat = Number(form.getValues("latitude"))
      const lng = Number(form.getValues("longitude"))
      if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
        if (center === null && lat !== 0 && lng !== 0) {
          map.setCenter({ lat, lng })
          setCenter({ lat, lng })
        }
        const zoom1 = Number(form.getValues("zoom"))
        if (!Number.isNaN(zoom1) && zoom1 !== 0) {
          map.setZoom(zoom1)
          setZoom(zoom1)
        }
        initialLoad.current = true
      }
    }
  }, [map])

  useEffect(() => {
    if (initialLoad.current && map !== null) {
      const lat = Number(form.getValues("latitude"))
      const lng = Number(form.getValues("longitude"))
      if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
        if (lat !== 0 && lng !== 0) {
          map.setCenter({ lat, lng })
          setCenter({ lat, lng })
        }
        const zoom1 = Number(form.getValues("zoom"))
        if (!Number.isNaN(zoom1) && zoom1 !== 0) {
          map.setZoom(zoom1)
          setZoom(zoom1)
        }
      }
    }
  }, [redraw])

  return (
    <Grid container>
      {helperText !== null && (
        <Grid item>
          <Box sx={{ textAlign: "right", cursor: "pointer" }}>
            <HtmlToolTip title={<Box dangerouslySetInnerHTML={{ __html: helperText }} />} placement="left">
              <InfoIcon color="secondary" />
            </HtmlToolTip>
          </Box>
        </Grid>
      )}
      <Grid item xs>
        <Map
          mapId={DEFAULT_GOOGLE_MAP_ID}
          mapTypeId={mapType}
          style={{ flexGrow: "1", height }}
          defaultCenter={DEFAULT_CENTER}
          defaultZoom={zoom ?? 18}
          onZoomChanged={handleZoomChange}
          onMapTypeIdChanged={handleMapTypeChange}
          onCenterChanged={handleCenterChange}>
          {center !== null ? <AdvancedMarker position={center} /> : <AdvancedMarker position={DEFAULT_CENTER} />}
        </Map>
      </Grid>
    </Grid>
  )
}

export default FhMap
