import React, { useCallback, useContext, useState } from "react"
import { Box, Button, Container, Grid, Tab, type Theme, Typography } from "@mui/material"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import ViewProperty from "../../../../shared/components/ViewProperty"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type ISetting, SETTING_ENDPOINT } from "../../../../shared/models/service/ISetting"
import { SETTING_EDIT_URL } from "../../config/urls"
import CheckMark from "../../../../shared/components/display/CheckMark"
import ViewSelectOption from "../../../../shared/components/display/ViewSelectOption"
import { ItemPrefixes, SelectOptions } from "../../../../config/config"
import ViewColor from "../../../../shared/components/display/ViewColor"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import FormatDate from "../../../../shared/components/format/FormatDate"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { type IPanariskAppContext } from "../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import ErrorMessage from "../../../../shared/components/ErrorMessage"
import ViewLoading from "../../../../shared/components/ViewLoading"
import ViewImage from "../../../../shared/components/display/ViewImage"
import DrawerRight, { useDrawerWithUrl } from "../../../../shared/components/containers/DrawerRight"
import UploadIcon from "@mui/icons-material/Upload"
import FileDropZone from "../../../../shared/components/files/FileDropZone"
import MuiFile from "../../../../shared/components/forms/MuiFile"
import useAzureStorage from "../../../../shared/hooks/useAzureStorage"
import { type IFileUpload } from "../../../../shared/models/components/IFileUpload"
import { type IStorageFile } from "../../../../shared/models/service/IStorageFile"
import { useNavigate } from "react-router-dom"
import DeleteIcon from "@mui/icons-material/Delete"
import useApiAction from "../../../../shared/hooks/useApiAction"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import TabsList from "../../../../shared/components/tabs/TabsList"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import EmailTemplateInfo from "../email_templates/components/EmailTemplateInfo"

const repository = new RestRepository<ISetting>(SETTING_ENDPOINT)

/**
 * Renders the index page with settings.
 *
 * @returns {React.ReactElement} The rendered index page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const { appSettings, loadServerInfo } = useContext<IPanariskAppContext>(PanariskAppContext)
  const itemId = appSettings.serverInfo?.setting_id
  const { data, error, loading, call } = useApiRead<ISetting>({ apiFunction: repository.read, itemId })

  const { tab, handleTabChange } = useTabPanel()
  const apiAction = useApiAction<ISetting>({ repository, itemId })

  const { t } = useTranslation()
  const drawerUrl = useDrawerWithUrl()
  const navigate = useNavigate()

  const [selectedFile, setSelectedFile] = useState<IStorageFile | null>(null)
  const [saving, setSaving] = useState(false)
  const { progress, uploadFile } = useAzureStorage()

  const handleFileUpdate = useCallback(
    async (files: IFileUpload[]) => {
      if (selectedFile !== null) {
        setSaving(true)

        const fileUpload = files[0]
        await uploadFile(selectedFile, fileUpload.file)

        await call()
        setSaving(false)
        await loadServerInfo?.()
      }
      setSelectedFile(null)
    },
    [selectedFile],
  )

  const handleUploadFile = useCallback(
    (file: IStorageFile) => () => {
      setSelectedFile(file)
      navigate(drawerUrl("file_upload"))
    },
    [],
  )

  const handleClearSmallLogo = useCallback(async () => {
    await apiAction.callAction("delete_logo_small")
    await call()
    await loadServerInfo?.()
  }, [])

  const handleClearLargeLogo = useCallback(async () => {
    await apiAction.callAction("delete_logo_large")
    await call()
    await loadServerInfo?.()
  }, [])

  console.log(data)

  return (
    <Container fixed>
      <ErrorMessage error={error} />
      <ViewLoading loading={loading} />
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title={t("Settings")} toEdit={`${SETTING_EDIT_URL}/${data.id}`} onRefresh={call} />
          <Grid item xs={12}>
            <ItemViewerDrawer
              title={t("Email Template")}
              prefix={ItemPrefixes.emailTemplate}
              infoView={EmailTemplateInfo}
            />
            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Overview")} value={0} />
              <Tab label={t("Emails")} value={1} />
            </TabsList>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" color="textSecondary">
                          {data.company_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Enable Username and Password Login")}>
                          <CheckMark value={data.enable_username_password_login} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Units")}>
                          <ViewSelectOption keyLookup={data.units} selectOption={SelectOptions.UNITS_SYSTEMS} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Currency")}>
                          <ViewSelectOption keyLookup={data.currency} selectOption={SelectOptions.CURRENCY_CHOICES} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Date Format")} vertical>
                          <Grid container>
                            <Grid item xs>
                              <small>
                                {t("Sample")}: <FormatDate value={moment()} />
                              </small>
                            </Grid>
                            <Grid item>{data.date_format}</Grid>
                          </Grid>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Primary Color")}>
                          <ViewColor>{data.primary_color}</ViewColor>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Secondary Color")}>
                          <ViewColor>{data.secondary_color}</ViewColor>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <DrawerRight
                      title={t("File Upload")}
                      icon={<UploadIcon />}
                      stayOpen={saving}
                      showButton={false}
                      onDrawerClose={call}
                      openCloseWithUrl={true}
                      openUrlPrefix="file_upload">
                      <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                          {selectedFile?.filename}
                        </Grid>
                        <Grid item xs={12}>
                          <FileDropZone single onChange={handleFileUpdate} loading={saving} progress={progress} />
                        </Grid>
                        <Grid item xs={12}>
                          {!saving && <MuiFile single onChange={handleFileUpdate} clear={!saving} />}
                        </Grid>
                      </Grid>
                    </DrawerRight>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Small Logo")} vertical>
                          {data.file_logo_small?.read_url !== undefined && (
                            <Grid container>
                              <Grid item xs={12} sx={{ textAlign: "right" }}>
                                <Button startIcon={<UploadIcon />} onClick={handleUploadFile(data.file_logo_small)}>
                                  {t("File Upload")}
                                </Button>
                                <Button startIcon={<DeleteIcon />} onClick={handleClearSmallLogo}>
                                  {t("Clear")}
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={(theme: Theme) => ({ backgroundColor: theme.palette.grey["300"] })}>
                                <Box sx={(theme: Theme) => ({ p: 2, backgroundColor: theme.palette.grey["300"] })}>
                                  <ViewImage src={data.file_logo_small.read_url} alt={t("Small Logo")} width="50%" />
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ mt: 4 }}>
                          <ViewProperty label={t("Large Logo")} vertical>
                            {data.file_logo_large?.read_url !== undefined && (
                              <Grid container>
                                <Grid item xs={12} sx={{ textAlign: "right" }}>
                                  <Button startIcon={<UploadIcon />} onClick={handleUploadFile(data.file_logo_large)}>
                                    {t("File Upload")}
                                  </Button>
                                  <Button startIcon={<DeleteIcon />} onClick={handleClearLargeLogo}>
                                    {t("Clear")}
                                  </Button>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box sx={(theme: Theme) => ({ p: 2, backgroundColor: theme.palette.grey["300"] })}>
                                    <ViewImage src={data.file_logo_large.read_url} alt={t("Large Logo")} width="100%" />
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                          </ViewProperty>
                        </Box>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <PaperLocal>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={5}>
                    <ViewProperty label={t("Enable All Emails")}>
                      <CheckMark value={data.enable_all_emails} />
                    </ViewProperty>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email User Created")}>
                          <CheckMark value={data.enable_email_user_created} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template User Created")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_user_created}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email User Onboarding")}>
                          <CheckMark value={data.enable_email_user_onboarding} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template User Onboarding")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_user_onboarding}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email User Forgot Password")}>
                          <CheckMark value={data.enable_email_user_forgot_password} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template User Forgot Password")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_user_forgot_password}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email Vendor Assigned")}>
                          <CheckMark value={data.enable_email_vendor_assigned} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template Vendor Assigned")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_vendor_assigned}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email Vendor Member Assigned")}>
                          <CheckMark value={data.enable_email_vendor_member_assigned} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template Vendor Member Assigned")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_vendor_member_assigned}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email Inspection Returned")}>
                          <CheckMark value={data.enable_email_inspection_returned} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template Inspection Returned")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_inspection_returned}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email Inspection Completed")}>
                          <CheckMark value={data.enable_email_inspection_completed} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template Inspection Completed")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_inspection_completed}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email Inspection Share")}>
                          <CheckMark value={data.enable_email_inspection_share} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template Inspection Share")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_inspection_share}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email Inspection Visit Date Changed")}>
                          <CheckMark value={data.enable_email_inspection_visit_date_changed} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template Inspection Visit Date Changed")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_inspection_visit_date_changed}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>

                      <Grid item xs={12} lg={5}>
                        <ViewProperty label={t("Enable Email Policy Expiry")}>
                          <CheckMark value={data.enable_email_policy_expiry} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <ViewProperty label={t("Email Template Policy Expiry")}>
                          <ViewListItems prefix={ItemPrefixes.emailTemplate}>
                            {data.email_template_policy_expiry}
                          </ViewListItems>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </PaperLocal>
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default IndexPage
