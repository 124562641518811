import React, { useCallback, useContext, useEffect, useState } from "react"
import { Alert, Box, Grid, IconButton, Snackbar } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { type IRestApiKey, REST_API_KEY_ENDPOINT, restPermissions } from "../../../../shared/models/service/IRestApiKey"
import ViewProperty from "../../../../shared/components/ViewProperty"
import FormatDate from "../../../../shared/components/format/FormatDate"
import { REST_API_KEY_EDIT_URL } from "../../config/urls"
import PageViewBody from "../../../../shared/components/pages/PageViewBody"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import { useTranslation } from "react-i18next"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import CheckMark from "../../../../shared/components/display/CheckMark"

const repository = new RestRepository<IRestApiKey>(REST_API_KEY_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data, loading } = useApiRead<IRestApiKey>({ apiFunction: repository.read })
  const { t } = useTranslation()

  const [restApiKey, setRestApiKey] = useState<string | undefined>()

  const panariskAppContext = useContext(PanariskAppContext)
  const [open, setOpen] = useState(false)

  const handleCopy = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    if (panariskAppContext.restApiKey !== undefined) {
      setRestApiKey(panariskAppContext.restApiKey)
      panariskAppContext.setRestApiKey?.(undefined)
    }
  }, [panariskAppContext.restApiKey])

  return (
    <PageViewBody loading={loading}>
      {data !== undefined && (
        <>
          <PageHeader
            title={t("API Key")}
            titlePlural={data.name}
            toEdit={data.revoked ? undefined : `${REST_API_KEY_EDIT_URL}/${data.id}`}
          />
          {restApiKey !== undefined && (
            <Grid item xs={12}>
              <Snackbar
                open={open}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={1000}
                onClose={handleClose}>
                <Alert severity="info">{t("API key copied.")}</Alert>
              </Snackbar>
              <Alert
                severity="warning"
                action={
                  <CopyToClipboard text={restApiKey} onCopy={handleCopy}>
                    <IconButton size="small">
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </CopyToClipboard>
                }>
                {t("This is your API key. Please store it somewhere safe, you will not be able to see it again.")}
                <Box><strong>{restApiKey}</strong></Box>
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={5}>
                <PaperLocal>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Prefix")}>{data.prefix}</ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Revoked")}>
                        <CheckMark value={data.revoked} />
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Created")}>
                        <FormatDate value={data.created} />
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewProperty label={t("Expires")}>
                        <FormatDate value={data.expiry_date} />
                      </ViewProperty>
                    </Grid>
                  </Grid>
                </PaperLocal>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PaperLocal>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <strong>{t("Access")}</strong>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "center" }}>
                  <strong>{t("Create")}</strong>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "center" }}>
                  <strong>{t("Read")}</strong>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "center" }}>
                  <strong>{t("Update")}</strong>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: "center" }}>
                  <strong>{t("Delete")}</strong>
                </Grid>

                {restPermissions.map(permission => {
                  return (
                    <React.Fragment key={permission.id}>
                      <Grid item xs={4}>
                        {permission.name}
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "center" }}>
                        <CheckMark value={(data as any)[`${permission.id}_create`]} />
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "center" }}>
                        <CheckMark value={(data as any)[`${permission.id}_read`]} />
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "center" }}>
                        <CheckMark value={(data as any)[`${permission.id}_update`]} />
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "center" }}>
                        <CheckMark value={(data as any)[`${permission.id}_delete`]} />
                      </Grid>
                    </React.Fragment>
                  )
                })}
              </Grid>
            </PaperLocal>
          </Grid>
        </>
      )}
    </PageViewBody>
  )
}

export default ViewPage
