import React from "react"
import { Alert, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import ViewLoading from "../ViewLoading"
import { type FieldErrors } from "react-hook-form/dist/types/errors"
import { useTranslation } from "react-i18next"

interface IProps {
  title: string
  loading?: boolean
  errors?: FieldErrors
  goSmall?: boolean
}

/**
 * Use this as a default header for a forms. Has errors built in.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the forms header.
 */
const FormHeader: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { title, loading = false, errors, goSmall = false } = props
  const errorFields = errors !== undefined ? Object.keys(errors) : []

  const { t } = useTranslation()

  const isSmall = goSmall || useMediaQuery(useTheme().breakpoints.down("md"))

  return (
    <Grid item xs={12}>
      {!isSmall ? (
        <Paper sx={isSmall ? {} : { pl: 0, pr: 0 }} elevation={0}>
          <Grid container alignItems="baseline" spacing={2}>
            <Grid item xs={12} md>
              <Typography variant="h4" color="textSecondary">
                {title}
              </Typography>
            </Grid>
            <Grid item xs>
              <ViewLoading loading={loading} />
            </Grid>
            <Grid item>
              {errorFields.length > 0 && (
                <Alert color="error">
                  {t("Errors were found.")}
                </Alert>
              )}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <>
          <ViewLoading loading={loading} />
          {errorFields.length > 0 && (
            <Alert color="error">
              {t("Errors were found.")}
            </Alert>
          )}
        </>
      )}
    </Grid>
  )
}

export default FormHeader
