import React from "react"
import { Container, Grid, Tab } from "@mui/material"

import { useApiRead } from "../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { CONTACT_ENDPOINT, type IContact } from "../../../../shared/models/service/IContact"
import ViewProperty from "../../../../shared/components/ViewProperty"
import { CONTACT_EDIT_URL } from "../../config/urls"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ViewPhone from "../../../../shared/components/display/ViewPhone"
import ViewEmail from "../../../../shared/components/display/ViewEmail"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import { ItemPrefixes, SelectOptions } from "../../../../config/config"
import ViewMap from "../../../../shared/components/display/ViewMap"
import { useTranslation } from "react-i18next"
import TabPanel, { useTabPanel } from "../../../../shared/components/tabs/TabPanel"
import TabsList from "../../../../shared/components/tabs/TabsList"
import CheckMark from "../../../../shared/components/display/CheckMark"
import CompanyInfo from "../companies/components/CompanyInfo"
import ViewSelectOption from "../../../../shared/components/display/ViewSelectOption"

const repository = new RestRepository<IContact>(CONTACT_ENDPOINT)

/**
 * Renders a page to view locations impairment details.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data } = useApiRead<IContact>({ apiFunction: repository.read })
  const { t } = useTranslation()

  const { tab, handleTabChange } = useTabPanel()

  return (
    <Container fixed>
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title={t("Contact")} titlePlural={data.name} toEdit={`${CONTACT_EDIT_URL}/${data.id}`} />
          <Grid item xs={12}>
            <TabsList value={tab} onChange={handleTabChange}>
              <Tab label={t("Overview")} value={0} />
              <Tab label={t("Driver")} value={1} />
            </TabsList>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <ItemViewerDrawer
                      title={t("Service User")}
                      prefix={ItemPrefixes.serviceUser}
                      infoView={ServiceUserInfo}
                    />
                    <ItemViewerDrawer title={t("Company")} prefix={ItemPrefixes.company} infoView={CompanyInfo} />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Job Title")}>{data.job_title}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Business Name")}>{data.business_name}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Companies")}>
                          <ViewListItems prefix={ItemPrefixes.company}>{data.companies}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Phone")}>
                          <ViewPhone ext={data.phone_ext}>{data.phone}</ViewPhone>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Phone 2")}>
                          <ViewPhone ext={data.phone_2_ext}>{data.phone_2}</ViewPhone>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Email")}>
                          <ViewEmail>{data.email}</ViewEmail>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Email 2")}>
                          <ViewEmail>{data.email_2}</ViewEmail>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Linked Service User")}>
                          <ViewListItems prefix={ItemPrefixes.serviceUser}>{data.service_user}</ViewListItems>
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Notes")} vertical>
                          <ViewRichText>{data.notes}</ViewRichText>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <ViewMap place={data} />
                  </PaperLocal>
                  <PaperLocal sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Address")}>{data.address}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("City")}>{data.city}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("State/Region")}>{data.state_region}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Postal Code")}>{data.postal_code}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Country")}>{data.country}</ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid item xs={12}>
                <Grid item xs={12} lg={6}>
                  <PaperLocal>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Is Driver")}>
                          <CheckMark value={data.is_driver} />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("License Number")}>{data.license_number}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("License State/Region")}>{data.license_state_region}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("License Points")}>{data.license_points}</ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("License Status")}>
                          <ViewSelectOption
                            keyLookup={data.license_status}
                            selectOption={SelectOptions.LICENSE_STATUS_OPTIONS}
                          />
                        </ViewProperty>
                      </Grid>
                      <Grid item xs={12}>
                        <ViewProperty label={t("Driver Notes")}>
                          <ViewRichText>{data.driver_notes}</ViewRichText>
                        </ViewProperty>
                      </Grid>
                    </Grid>
                  </PaperLocal>
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
