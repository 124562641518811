import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../components/forms/FhMuiHiddenField"
import type IProfile from "../../../models/users/IProfile"
import useLoadFormData from "../../../hooks/useLoadFormData"
import FhMuiSelectField from "../../../components/forms/FhMuiSelectField"
import { useSelectOptions } from "../../../hooks/useSelectOptions"
import { SelectOptions } from "../../../../config/config"
import PaperLocal from "../../../components/containers/PaperLocal"
import FhMuiCheckboxField from "../../../components/forms/FhMuiCheckboxField"

interface IProps {
  form: UseFormReturn
  profile?: IProfile | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit a profile.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const ProfileForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { profile, form, isEdit = false } = props
  const languages = useSelectOptions(SelectOptions.LANGUAGE_CHOICES)
  const mapTypes = useSelectOptions(SelectOptions.MAP_TYPES)

  useLoadFormData<IProfile>(
    (data: IProfile) => {
      form.setValue("id", data.id)
      form.setValue("language", data.language ?? "en")
      form.setValue("auto_save_risk_writer", data.auto_save_risk_writer)
      form.setValue("map_type", data.map_type ?? "roadmap")
      form.setValue("rich_text_boxes_full_length", data.rich_text_boxes_full_length)
    },
    profile,
    isEdit,
    form.setValue,
  )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <PaperLocal>
            {isEdit && <FhMuiHiddenField control={form.control} />}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <FhMuiSelectField control={form.control} name="language" items={languages} />
              </Grid>
              <Grid item xs={12}>
                <FhMuiSelectField control={form.control} name="map_type" items={mapTypes} />
              </Grid>
              <Grid item xs={12}>
                <FhMuiCheckboxField control={form.control} name="auto_save_risk_writer" />
              </Grid>
              <Grid item xs={12}>
                <FhMuiCheckboxField control={form.control} name="rich_text_boxes_full_length" />
              </Grid>
            </Grid>
          </PaperLocal>
        </Grid>
      </Grid>
    </>
  )
}

export default ProfileForm
