import React from "react"
import { type IReportWriterFormat } from "../../../../../shared/models/service/IReportFormat"
import { Alert, Grid } from "@mui/material"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import CheckMark from "../../../../../shared/components/display/CheckMark"
import { useTranslation } from "react-i18next"

interface IProps {
  reportWriterFormat: IReportWriterFormat
}

/**
 * Renders the ReportWriterInfo component that displays information about the report writer format.
 *
 * @param {IProps} props - The properties containing the `reportWriterFormat` object.
 * @returns {React.ReactElement} A React element containing the report writer information.
 */
const ReportWriterInfo: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { reportWriterFormat } = props
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      {reportWriterFormat.data !== null ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">{t("Information from the default report format.")}</Alert>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Title")}>{reportWriterFormat.data.title}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Name")}>{reportWriterFormat.data.name}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Version")}>{reportWriterFormat.data.version}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Templates")}>{Object.keys(reportWriterFormat.templates).length}</ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Option Lists")}>
                {reportWriterFormat.data.options !== null
                  ? Object.keys(reportWriterFormat.data.options).length
                  : t("No options in this version.")}
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Summaries")}>
                {reportWriterFormat.data.summary_formats !== null
                  ? reportWriterFormat.data.summary_formats.length
                  : t("No summaries in this version.")}
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Brief Form")}>
                <Grid container spacing={2}>
                  <Grid item xs>
                    {reportWriterFormat.data.brief_form !== null && <>{reportWriterFormat.data.brief_form.title}</>}
                  </Grid>
                  <Grid item>
                    <CheckMark value={reportWriterFormat.data.brief_form !== null} />
                  </Grid>
                </Grid>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Building Form")}>
                <Grid container spacing={2}>
                  <Grid item xs>
                    {reportWriterFormat.data.building_form !== null && (
                      <>{reportWriterFormat.data.building_form.title}</>
                    )}
                  </Grid>
                  <Grid item>
                    <CheckMark value={reportWriterFormat.data.building_form !== null} />
                  </Grid>
                </Grid>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Section Templates")}>
                {reportWriterFormat.data.section_templates !== null
                  ? reportWriterFormat.data.section_templates.length
                  : t("No section templates in this version.")}
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Tables")}>
                {reportWriterFormat.data.tables !== null
                  ? reportWriterFormat.data.tables.length
                  : t("No tables in this version.")}
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Narrative Sections")}>
                {reportWriterFormat.data.sections !== null
                  ? reportWriterFormat.data.sections.length
                  : t("No sections in this version.")}
              </ViewProperty>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Alert severity="warning">{t("The report writer format is missing.")}</Alert>
        </Grid>
      )}
    </Grid>
  )
}

export default ReportWriterInfo
