import React, { useCallback } from "react"
import AlertDialog from "../../../../shared/components/AlertDialog"
import RemoveIcon from "@mui/icons-material/Remove"
import { type IPolicy } from "../../../../shared/models/service/IPolicy"
import useApiAction, { type IUseApiActionProps } from "../../../../shared/hooks/useApiAction"
import { type RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IPaging } from "../../../../shared/models/components/IPaging"
import { type IAccount } from "../../../../shared/models/service/IAccount"
import { useTranslation } from "react-i18next"

interface IProps {
  policy: IPolicy
  onComplete: () => void
  parentId: number
  repository: RestRepository<IAccount>
}

/**
 * React component for removing a policy.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} The rendered React component.
 */
const RemovePolicy: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { policy, onComplete, parentId, repository } = props

  const actionProps: IUseApiActionProps<IAccount> = { repository, itemId: parentId }
  const apiAction = useApiAction<IAccount>(actionProps)

  const { t } = useTranslation()

  const handleYes = useCallback(async () => {
    const paging: IPaging = { filters: [{ field: "id", value: policy.id }] }
    await apiAction.callAction("remove_policy", paging)
    onComplete()
  }, [policy])

  return (
    <AlertDialog
      buttonText={t("Remove")}
      buttonIcon={<RemoveIcon />}
      title={t("Confirm Remove Policy")}
      message={
        <>
          {t("Are you sure you want to remove?")}
          <p>
            <em>{policy.name}</em>
          </p>
        </>
      }
      onYes={handleYes}
    />
  )
}

export default RemovePolicy
