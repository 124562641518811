export enum LookupType {
  CONTACT = 'contact',
  BUILDING = 'building',
  LOCATION = 'location',
}

export const LOOKUP_FIELD_LABELS = {
  contact_name: 'Contact Name',
  contact_address: 'Contact Address',
  contact_city: 'Contact City',
  contact_state: 'Contact State',
  contact_zip: 'Contact Zip',
  contact_country: 'Contact Country',
  contact_email: 'Contact Email',
  contact_phone: 'Contact Phone',
  contact_email_2: 'Contact Email 2',
  contact_phone_2: 'Contact Phone 2',
  contact_title: 'Contact Title',
} as const
