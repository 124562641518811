import React, { useCallback, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid, Tab } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { type ISetting } from "../../../../../shared/models/service/ISetting"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import FhMuiCheckboxField from "../../../../../shared/components/forms/FhMuiCheckboxField"
import FhMuiColorField, { useColorField } from "../../../../../shared/components/forms/FhMuiColorField"
import FhMuiSelectField from "../../../../../shared/components/forms/FhMuiSelectField"
import { useSelectOptions } from "../../../../../shared/hooks/useSelectOptions"
import { ItemPrefixes, SelectOptions } from "../../../../../config/config"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import FormatDate from "../../../../../shared/components/format/FormatDate"
import moment from "moment"
import TabPanel, { useTabPanel } from "../../../../../shared/components/tabs/TabPanel"
import { useTranslation } from "react-i18next"
import TabsList from "../../../../../shared/components/tabs/TabsList"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { EMAIL_TEMPLATE_ENDPOINT, type IEmailTemplate } from "../../../../../shared/models/service/IEmailTemplate"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import EmailTemplateInfo from "../../email_templates/components/EmailTemplateInfo"

const repository = new RestRepository<IEmailTemplate | IListItem>(EMAIL_TEMPLATE_ENDPOINT)

interface IProps {
  form: UseFormReturn
  setting?: ISetting | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit a setting.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const SettingForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { setting, form, isEdit = false } = props
  const { tab, handleTabChange } = useTabPanel()
  const { t } = useTranslation()

  const [dateFormat, setDateFormat] = useState<string | null>(null)

  const [primaryColor, setPrimaryColor] = useColorField({ form, field: "primary_color" })
  const [secondaryColor, setSecondaryColor] = useColorField({ form, field: "secondary_color" })
  const units = useSelectOptions(SelectOptions.UNITS_SYSTEMS)
  const currencies = useSelectOptions(SelectOptions.CURRENCY_CHOICES)

  const [userCreated, setUserCreated] = useSelectFilteredSingle("email_template_user_created", form)
  const [userOnboarding, setUserOnboarding] = useSelectFilteredSingle("email_template_user_onboarding", form)
  const [userForgotPassword, setUserForgotPassword] = useSelectFilteredSingle(
    "email_template_user_forgot_password",
    form,
  )
  const [vendorAssigned, setVendorAssigned] = useSelectFilteredSingle("email_template_vendor_assigned", form)
  const [vendorMemberAssigned, setVendorMemberAssigned] = useSelectFilteredSingle(
    "email_template_vendor_member_assigned",
    form,
  )
  const [inspectionReturned, setInspectionReturned] = useSelectFilteredSingle(
    "email_template_inspection_returned",
    form,
  )
  const [inspectionCompleted, setInspectionCompleted] = useSelectFilteredSingle(
    "email_template_inspection_completed",
    form,
  )
  const [inspectionShare, setInspectionShare] = useSelectFilteredSingle("email_template_inspection_share", form)
  const [policyExpiry, setPolicyExpiry] = useSelectFilteredSingle("email_template_policy_expiry", form)

  const [inspectionVisitDateChanged, setInspectionVisitDateChanged] = useSelectFilteredSingle(
    "email_template_inspection_visit_date_changed",
    form,
  )
  const handleDateFormatChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDateFormat(event.target.value)
  }, [])

  useLoadFormData<ISetting>(
    (data: ISetting) => {
      form.setValue("id", data.id)
      form.setValue("company_name", data.company_name)
      form.setValue("enable_all_emails", data.enable_all_emails)
      form.setValue("enable_username_password_login", data.enable_username_password_login)
      form.setValue("units", data.units)
      form.setValue("currency", data.currency)
      form.setValue("date_format", data.date_format)
      setDateFormat(data.date_format)
      setPrimaryColor(data.primary_color)
      setSecondaryColor(data.secondary_color)

      form.setValue("enable_email_user_created", data.enable_email_user_created)
      form.setValue("enable_email_user_onboarding", data.enable_email_user_onboarding)
      form.setValue("enable_email_user_forgot_password", data.enable_email_user_forgot_password)
      form.setValue("enable_email_vendor_assigned", data.enable_email_vendor_assigned)
      form.setValue("enable_email_vendor_member_assigned", data.enable_email_vendor_member_assigned)
      form.setValue("enable_email_inspection_returned", data.enable_email_inspection_returned)
      form.setValue("enable_email_inspection_completed", data.enable_email_inspection_completed)
      form.setValue("enable_email_inspection_share", data.enable_email_inspection_share)
      form.setValue("enable_email_policy_expiry", data.enable_email_policy_expiry)
      form.setValue("enable_email_inspection_visit_date_changed", data.enable_email_inspection_visit_date_changed)

      setUserCreated(data.email_template_user_created)
      setUserOnboarding(data.email_template_user_onboarding)
      setUserForgotPassword(data.email_template_user_forgot_password)
      setVendorAssigned(data.email_template_vendor_assigned)
      setVendorMemberAssigned(data.email_template_vendor_member_assigned)
      setInspectionReturned(data.email_template_inspection_returned)
      setInspectionCompleted(data.email_template_inspection_completed)
      setInspectionShare(data.email_template_inspection_share)
      setPolicyExpiry(data.email_template_policy_expiry)
      setInspectionVisitDateChanged(data.email_template_inspection_visit_date_changed)
    },
    setting,
    isEdit,
    form.setValue,
  )

  return (
    <>
      <Grid item xs={12}>
        <ItemViewerDrawer
          title={t("Email Template")}
          prefix={ItemPrefixes.emailTemplate}
          infoView={EmailTemplateInfo}
        />
        <TabsList value={tab} onChange={handleTabChange}>
          <Tab label={t("Overview")} value={0} />
          <Tab label={t("Emails")} value={1} />
        </TabsList>
      </Grid>
      <TabPanel value={tab} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <PaperLocal>
              {isEdit && <FhMuiHiddenField control={form.control} />}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <FhMuiTextField control={form.control} name="company_name" rules={requiredRule()} />
                </Grid>
                <Grid item xs={12}>
                  <FhMuiCheckboxField control={form.control} name="enable_username_password_login" />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FhMuiTextField control={form.control} name="date_format" onChange={handleDateFormatChange} />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <small>
                    {t("Sample")}: <FormatDate value={moment()} format={dateFormat} />
                  </small>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FhMuiSelectField control={form.control} name="units" items={units} />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FhMuiSelectField control={form.control} name="currency" items={currencies} />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FhMuiColorField form={form} name="primary_color" colorValue={primaryColor} />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FhMuiColorField form={form} name="secondary_color" colorValue={secondaryColor} />
                </Grid>
              </Grid>
            </PaperLocal>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <PaperLocal>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5}>
              <FhMuiCheckboxField control={form.control} name="enable_all_emails" />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_user_created" />
                </Grid>
                <Grid item xs={7}>
                  <SelectFilteredSingle
                    name="email_template_user_created"
                    label={t("Email Template User Created")}
                    defaultValue={userCreated}
                    repository={repository}
                    onChange={setUserCreated}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_user_onboarding" />
                </Grid>
                <Grid item xs={7}>
                  <SelectFilteredSingle
                    name="email_template_user_onboarding"
                    label={t("Email Template User Onboarding")}
                    defaultValue={userOnboarding}
                    repository={repository}
                    onChange={setUserOnboarding}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_user_forgot_password" />
                </Grid>
                <Grid item xs={7}>
                  <SelectFilteredSingle
                    name="email_template_user_forgot_password"
                    label={t("Email Template User Forgot Password")}
                    defaultValue={userForgotPassword}
                    repository={repository}
                    onChange={setUserForgotPassword}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_vendor_assigned" />
                </Grid>
                <Grid item xs={7}>
                  <SelectFilteredSingle
                    name="email_template_vendor_assigned"
                    label={t("Email Template Vendor Assigned")}
                    defaultValue={vendorAssigned}
                    repository={repository}
                    onChange={setVendorAssigned}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_vendor_member_assigned" />
                </Grid>
                <Grid item xs={7}>
                  <SelectFilteredSingle
                    name="email_template_vendor_member_assigned"
                    label={t("Email Template Vendor Member Assigned")}
                    defaultValue={vendorMemberAssigned}
                    repository={repository}
                    onChange={setVendorMemberAssigned}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_inspection_returned" />
                </Grid>
                <Grid item xs={7}>
                  <SelectFilteredSingle
                    name="email_template_inspection_returned"
                    label={t("Email Template Inspection Returned")}
                    defaultValue={inspectionReturned}
                    repository={repository}
                    onChange={setInspectionReturned}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_inspection_completed" />
                </Grid>
                <Grid item xs={7}>
                  <SelectFilteredSingle
                    name="email_template_inspection_completed"
                    label={t("Email Template Inspection Completed")}
                    defaultValue={inspectionCompleted}
                    repository={repository}
                    onChange={setInspectionCompleted}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_inspection_share" />
                </Grid>
                <Grid item xs={7}>
                  <SelectFilteredSingle
                    name="email_template_inspection_Share"
                    label={t("Email Template Inspection Share")}
                    defaultValue={inspectionShare}
                    repository={repository}
                    onChange={setInspectionShare}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_inspection_visit_date_changed" />
                </Grid>
                <Grid item xs={7}>
                  <SelectFilteredSingle
                    name="email_template_inspection_visit_date_changed"
                    label={t("Email Template Inspection Visit Date Changed")}
                    defaultValue={inspectionVisitDateChanged}
                    repository={repository}
                    onChange={setInspectionVisitDateChanged}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <FhMuiCheckboxField control={form.control} name="enable_email_policy_expiry" />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <SelectFilteredSingle
                    name="email_template_policy_expiry"
                    label={t("Email Template Policy Expiry")}
                    defaultValue={policyExpiry}
                    repository={repository}
                    onChange={setPolicyExpiry}
                    infoViewPrefix={ItemPrefixes.emailTemplate}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PaperLocal>
      </TabPanel>
    </>
  )
}

export default SettingForm
