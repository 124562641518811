import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../shared/models/service/ILocation"
import {
  ADDON_COMPANIES,
  ADDON_MAP_WEATHER,
  DEFAULT_CENTER,
  DEFAULT_GOOGLE_MAP_ID,
  ItemPrefixes,
  PriorityTypes,
} from "../../../../config/config"
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  Popper,
  useMediaQuery, useTheme
} from "@mui/material"
import { AdvancedMarker, InfoWindow, Map, Pin, useMap } from "@vis.gl/react-google-maps"
import { Link } from "react-router-dom"
import FiltersData from "../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../shared/components/filters/FilterSearch"
import FilterAccount from "../../../../shared/components/filters/FilterAccount"
import FilterLocationCity from "../../../../shared/components/filters/FilterLocationCity"
import FilterLocationStateRegion from "../../../../shared/components/filters/FilterLocationStateRegion"
import FilterLocationCountry from "../../../../shared/components/filters/FilterLocationCountry"
import { type IUseApiPagedResultsProps, useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import ItemViewerDrawer, { useItemEditDialogUrl } from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import LocationInfo from "../locations/components/LocationInfo"
import { useTranslation } from "react-i18next"
import useAuth, { type IProfilePatch } from "../../../../shared/hooks/useAuth"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import CloudIcon from "@mui/icons-material/Cloud"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import useLocalStorage from "beautiful-react-hooks/useLocalStorage"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../shared/models/service/IInspection"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../shared/models/service/IServiceUser"
import { COMPANY_ENDPOINT, type ICompany } from "../../../../shared/models/service/ICompany"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import InspectionInfo from "../inspections/components/InspectionInfo"
import FilterLocation from "../../../../shared/components/filters/FilterLocation"
import FilterReportFormat from "../../../../shared/components/filters/FilterReportFormat"
import FilterInspectionType from "../../../../shared/components/filters/FilterInspectionType"
import FilterPriority from "../../../../shared/components/filters/FilterPriority"
import FilterDate from "../../../../shared/components/filters/FilterDate"
import FilterVendor from "../../../../shared/components/filters/FilterVendor"
import { BASE_URL } from "../../../../shared/utilities/request_utility"
import type { IPanariskAppContext } from "../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import CompanyInfo from "../companies/components/CompanyInfo"
import { CONTACT_ENDPOINT, type IContact } from "../../../../shared/models/service/IContact"
import ContactInfo from "../contacts/components/ContactInfo"

const height = "calc(100vh - 100px)"
const zoom = 4

/**
 * Calculates adjusted latitude and longitude values based on the input location and index.
 *
 * @param {ILocation} location An object containing the latitude and longitude properties.
 * @param {number} index A numerical value used to adjust the coordinates.
 * @returns {Record<string, number>} An object with `lat` and `lng` properties representing the adjusted coordinates.
 */
const latLong = (
  location: ILocation | IServiceUser | ICompany | IContact,
  index: number,
): google.maps.LatLngLiteral => {
  return {
    lat: Number(location.latitude) + Math.sin(index) / 10000,
    lng: Number(location.longitude) + Math.sin(index) / 10000,
  }
}

const locationRepository = new RestRepository<ILocation>(LOCATION_ENDPOINT)
const inspectionRepository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)
const userRepository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)
const companyRepository = new RestRepository<ICompany>(COMPANY_ENDPOINT)
const contactRepository = new RestRepository<IContact>(CONTACT_ENDPOINT)

const defaultLimit = 100

/**
 * Displays a map of the locations.
 *
 * @returns {React.FC} the locations map.
 */
const LocationsMap: React.FC = (): React.ReactElement => {
  const propsLocation: IUseApiPagedResultsProps<ILocation> = {
    apiFunction: locationRepository.findAll,
    defaultLimit,
    prefix: "LOCATION_P",
  }
  const pagingResultsLocation = useApiPagedLocal<ILocation>(propsLocation)

  const propsInspection: IUseApiPagedResultsProps<IInspection> = {
    apiFunction: inspectionRepository.findAll,
    defaultLimit,
    prefix: "INSPECTION_P",
  }
  const pagingResultsInspection = useApiPagedLocal<IInspection>(propsInspection)

  const propsUser: IUseApiPagedResultsProps<IServiceUser> = {
    apiFunction: userRepository.findAll,
    defaultLimit,
    prefix: "USER_P",
    initialFilters: [
      {
        field: "has_lat_long",
        value: "true",
        title: "Has Lat/Long",
        display: "Yes",
        canBeDelete: false,
      },
    ],
  }
  const pagingResultsUser = useApiPagedLocal<IServiceUser>(propsUser)

  const propsCompany: IUseApiPagedResultsProps<ICompany> = {
    apiFunction: companyRepository.findAll,
    defaultLimit,
    prefix: "COMPANY_P",
    initialFilters: [
      {
        field: "has_lat_long",
        value: "true",
        title: "Has Lat/Long",
        display: "Yes",
        canBeDelete: false,
      },
    ],
  }
  const pagingResultsCompany = useApiPagedLocal<ICompany>(propsCompany)

  const propsContact: IUseApiPagedResultsProps<IContact> = {
    apiFunction: contactRepository.findAll,
    defaultLimit,
    prefix: "CONTACT_P",
    initialFilters: [
      {
        field: "has_lat_long",
        value: "true",
        title: "Has Lat/Long",
        display: "Yes",
        canBeDelete: false,
      },
    ],
  }
  const pagingResultsContact = useApiPagedLocal<IContact>(propsContact)

  const { t } = useTranslation()
  const map = useMap()
  const { currentUser, updateProfile } = useAuth()
  const mapType = currentUser?.user.profile.map_type ?? "satellite"
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)

  // Inside LocationsMap function component
  const [selectedMarkerLocation, setSelectedMarkerLocation] = useState<ILocation | null>(null)
  const [selectedMarkerInspection, setSelectedMarkerInspection] = useState<IInspection | null>(null)
  const [selectedMarkerUser, setSelectedMarkerUser] = useState<IServiceUser | null>(null)
  const [selectedMarkerCompany, setSelectedMarkerCompany] = useState<ICompany | null>(null)
  const [selectedMarkerContact, setSelectedMarkerContact] = useState<IContact | null>(null)

  const [showInspections, setShowInspections] = useLocalStorage<boolean>("MAP_SHOW_INSPECTIONS", true)
  const [showLocations, setShowLocations] = useLocalStorage<boolean>("MAP_SHOW_LOCATIONS", true)
  const [showUsers, setShowUsers] = useLocalStorage<boolean>("MAP_SHOW_USERS", true)
  const [showCompanies, setShowCompanies] = useLocalStorage<boolean>("MAP_SHOW_COMPANIES", true)
  const [showContacts, setShowContacts] = useLocalStorage<boolean>("MAP_SHOW_CONTACTS", true)
  const [showWeather, setShowWeather] = useLocalStorage<boolean>("MAP_SHOW_WEATHER", false)

  const handleShowInspections = useCallback(() => {
    setShowInspections(showInspection => !showInspection)
  }, [])

  const handleShowUsers = useCallback(() => {
    setShowUsers(showUsers => !showUsers)
  }, [])

  const handleShowLocations = useCallback(() => {
    setShowLocations(showLocations => !showLocations)
  }, [])

  const handleShowCompany = useCallback(() => {
    setShowCompanies(showCompanies => !showCompanies)
  }, [])

  const handleShowContact = useCallback(() => {
    setShowContacts(showContacts => !showContacts)
  }, [])

  const handleShowWeather = useCallback(() => {
    setShowWeather(showWeather => !showWeather)
  }, [])

  const handleMapTypeChange = useCallback(async () => {
    if (map?.getMapTypeId() !== undefined && currentUser?.user.profile.id !== undefined) {
      const patch: IProfilePatch = { id: currentUser.user.profile.id, name: "", map_type: map.getMapTypeId() }
      await updateProfile?.(patch)
    }
  }, [map, currentUser])

  const handleSetSelectedMarkerLocation = useCallback(
    (marker: ILocation | null) => () => {
      setSelectedMarkerLocation(marker)
    },
    [],
  )

  const handleSetSelectedMarkerInspection = useCallback(
    (marker: IInspection | null) => () => {
      setSelectedMarkerInspection(marker)
    },
    [],
  )

  const handleSetSelectedMarkerUser = useCallback(
    (marker: IServiceUser | null) => () => {
      setSelectedMarkerUser(marker)
    },
    [],
  )

  const handleSetSelectedMarkerCompany = useCallback(
    (marker: ICompany | null) => () => {
      setSelectedMarkerCompany(marker)
    },
    [],
  )

  const handleSetSelectedMarkerContact = useCallback(
    (marker: IContact | null) => () => {
      setSelectedMarkerContact(marker)
    },
    [],
  )

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  /**
   * Creates and returns a weather layer for a Google Map, displaying cloud coverage tiles from OpenWeatherMap.
   *
   * @returns {any|null} Returns a Google Maps ImageMapType object if the Google Maps API is defined, otherwise returns null.
   */
  const weatherLayer = useMemo((): google.maps.ImageMapType | null => {
    if (appSettings?.serverInfo?.addons?.includes(ADDON_MAP_WEATHER) !== true) {
      return null
    }
    if (window.google?.maps?.ImageMapType !== undefined) {
      const token = currentUser?.token.access
      // noinspection JSUnusedGlobalSymbols
      return new window.google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          return `${BASE_URL}/lookups/open_weather_map?type=clouds_new&zoom=${zoom}&x=${coord.x}&y=${coord.y}&token=${token}`
        },
        tileSize: new window.google.maps.Size(256, 256),
      })
    }
    return null
  }, [window.google?.maps?.ImageMapType, appSettings?.serverInfo?.addons])

  useEffect(() => {
    if (weatherLayer !== null) {
      if (map !== null && showWeather === true) {
        const index = map.overlayMapTypes.getArray().indexOf(weatherLayer)
        if (index === -1) {
          map.overlayMapTypes.push(weatherLayer)
        }
      } else if (map !== null && showWeather === false) {
        // Remove weather layer if toggle off
        const index = map.overlayMapTypes.getArray().indexOf(weatherLayer)
        if (index > -1) {
          map.overlayMapTypes.removeAt(index)
        }
      }
    }
  }, [map, weatherLayer, showWeather])

  const locationFiltersUrl = useItemEditDialogUrl(ItemPrefixes.locationFilters, 1)
  const inspectionFiltersUrl = useItemEditDialogUrl(ItemPrefixes.inspectionFilters, 1)
  const serviceUserFiltersUrl = useItemEditDialogUrl(ItemPrefixes.serviceUserFilters, 1)
  const companyFiltersUrl = useItemEditDialogUrl(ItemPrefixes.companyFilters, 1)
  const contactFiltersUrl = useItemEditDialogUrl(ItemPrefixes.contactFilters, 1)

  return (
    <>
      <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
      <ItemViewerDrawer title={t("Service User")} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
      <ItemViewerDrawer title={t("Inspection")} prefix={ItemPrefixes.inspection} infoView={InspectionInfo} />
      <ItemViewerDrawer title={t("Company")} prefix={ItemPrefixes.company} infoView={CompanyInfo} />
      <ItemViewerDrawer title={t("Contact")} prefix={ItemPrefixes.contact} infoView={ContactInfo} />

      {pagingResultsLocation !== undefined && (
        <FiltersData
          title={t("Locations")}
          pagingResults={pagingResultsLocation}
          openCloseWithUrl={true}
          showButton={false}
          modelName="location"
          showPaging
          openUrlPrefix={ItemPrefixes.locationFilters + "edit"}>
          <FilterSearch />
          <FilterAccount field="accounts" />
          <FilterLocationCity field="city" />
          <FilterLocationStateRegion field="state_region" />
          <FilterLocationCountry field="country" />
        </FiltersData>
      )}

      {pagingResultsInspection !== undefined && (
        <FiltersData
          title={t("Inspections")}
          pagingResults={pagingResultsInspection}
          openCloseWithUrl={true}
          showButton={false}
          modelName="inspection"
          showPaging
          openUrlPrefix={ItemPrefixes.inspectionFilters + "edit"}>
          <FilterSearch />
          <FilterAccount field="account" />
          <FilterLocation field="location" />
          <FilterReportFormat field="report_format" />
          <FilterInspectionType />
          <FilterPriority field="priority" priorityType={PriorityTypes.INSPECTION} />
          <FilterDate field="completed" title={t("Completed")} />
          <FilterVendor field="vendor" />
          <FilterDate field="vendor_due_date" title={t("Vendor Due Date")} />
          <FilterDate field="vendor_site_visit_date" title={t("Vendor Site Visit Date")} />
          <FilterDate field="vendor_submitted_date" title={t("Vendor Submitted Date")} />
          <FilterDate field="vendor_returned_date" title={t("Vendor Returned Date")} />
        </FiltersData>
      )}

      {pagingResultsUser !== undefined && (
        <FiltersData
          title={t("Service Users")}
          pagingResults={pagingResultsUser}
          openCloseWithUrl={true}
          showButton={false}
          modelName="service_user"
          showPaging
          openUrlPrefix={ItemPrefixes.serviceUserFilters + "edit"}>
          <FilterSearch />
          <FilterLocationCity field="city" />
          <FilterLocationStateRegion field="state_region" />
          <FilterLocationCountry field="country" />
          <FilterVendor field="vendor_leads" />
          <FilterVendor field="vendor_members" />
        </FiltersData>
      )}

      {pagingResultsCompany !== undefined && (
        <FiltersData
          title={t("Companies")}
          pagingResults={pagingResultsCompany}
          openCloseWithUrl={true}
          showButton={false}
          modelName="company"
          showPaging
          openUrlPrefix={ItemPrefixes.companyFilters + "edit"}>
          <FilterSearch />
        </FiltersData>
      )}

      {pagingResultsContact !== undefined && (
        <FiltersData
          title={t("Contacts")}
          pagingResults={pagingResultsContact}
          openCloseWithUrl={true}
          showButton={false}
          modelName="contact"
          showPaging
          openUrlPrefix={ItemPrefixes.contactFilters + "edit"}>
          <FilterSearch />
        </FiltersData>
      )}

      <Grid item xs={12}>
        <Box sx={{ position: "absolute", mt: isSmall ? 12 : 1.5, ml: isSmall ? 1.5 : 25, zIndex: 900 }}>
          <ButtonGroup ref={anchorRef} variant="contained" color="info">
            <Button onClick={handleToggle}>{t("Map Layers")}</Button>
            <Button size="small" onClick={handleToggle}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper sx={{ zIndex: 900}} open={open} anchorEl={anchorRef.current} disablePortal>
            <PaperLocal sx={{ p: 0, ml: 10  }}>
              <ClickAwayListener onClickAway={handleClose}>
                <List dense>
                  <ListItem
                    dense
                    secondaryAction={
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        checked={showLocations === true}
                        onChange={handleShowLocations}
                      />
                    }>
                    <ListItemButton dense component={Link} to={locationFiltersUrl}>
                      <ListItemIcon>
                        <Box
                          component="img"
                          src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                          alt={t("Locations")}
                          sx={{ width: 20 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={t("Locations")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    dense
                    secondaryAction={
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        checked={showInspections === true}
                        onChange={handleShowInspections}
                      />
                    }>
                    <ListItemButton dense component={Link} to={inspectionFiltersUrl}>
                      <ListItemIcon>
                        <Box
                          component="img"
                          src="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                          alt={t("Inspections")}
                          sx={{ width: 20 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={t("Inspections")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    dense
                    secondaryAction={
                      <Checkbox edge="start" tabIndex={-1} checked={showUsers === true} onChange={handleShowUsers} />
                    }>
                    <ListItemButton dense component={Link} to={serviceUserFiltersUrl}>
                      <ListItemIcon>
                        <Box
                          component="img"
                          src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                          alt={t("Users")}
                          sx={{ width: 20 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={t("Users")} />
                    </ListItemButton>
                  </ListItem>

                  {appSettings?.serverInfo?.addons.includes(ADDON_COMPANIES) === true && (
                    <ListItem
                      dense
                      secondaryAction={
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          checked={showCompanies === true}
                          onChange={handleShowCompany}
                        />
                      }>
                      <ListItemButton dense component={Link} to={companyFiltersUrl}>
                        <ListItemIcon>
                          <Box
                            component="img"
                            src="https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
                            alt={t("Companies")}
                            sx={{ width: 20 }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={t("Companies")} />
                      </ListItemButton>
                    </ListItem>
                  )}

                  <ListItem
                    dense
                    secondaryAction={
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        checked={showContacts === true}
                        onChange={handleShowContact}
                      />
                    }>
                    <ListItemButton dense component={Link} to={contactFiltersUrl}>
                      <ListItemIcon>
                        <Box
                          component="img"
                          src="https://maps.google.com/mapfiles/ms/icons/purple-dot.png"
                          alt={t("Contacts")}
                          sx={{ width: 20 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={t("Contacts")} />
                    </ListItemButton>
                  </ListItem>

                  {appSettings?.serverInfo?.addons.includes(ADDON_MAP_WEATHER) === true && (
                    <ListItem
                      dense
                      secondaryAction={
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          checked={showWeather === true}
                          onChange={handleShowWeather}
                        />
                      }>
                      <ListItemButton dense>
                        <ListItemIcon>
                          <CloudIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Weather")} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </ClickAwayListener>
            </PaperLocal>
          </Popper>
        </Box>
        <Map
          mapId={DEFAULT_GOOGLE_MAP_ID}
          style={{ flexGrow: "1", height }}
          defaultZoom={zoom}
          mapTypeId={mapType}
          onMapTypeIdChanged={handleMapTypeChange}
          defaultCenter={DEFAULT_CENTER}>
          {showLocations === true &&
            pagingResultsLocation?.data?.results.map((item, index) => {
              return (
                <AdvancedMarker
                  key={index}
                  position={latLong(item, index)}
                  onClick={handleSetSelectedMarkerLocation(item)}>
                  <Pin scale={0.75} background={"red"} borderColor={"black"} glyphColor={"black"}  />
                </AdvancedMarker>
              )
            })}

          {showInspections === true &&
            pagingResultsInspection?.data?.results.map((item, index) => {
              return (
                <AdvancedMarker
                  key={index}
                  position={latLong(item.location, index)}
                  onClick={handleSetSelectedMarkerInspection(item)}>
                  <Pin scale={0.75} background={"green"} borderColor={"black"} glyphColor={"black"} />
                </AdvancedMarker>
              )
            })}

          {showUsers === true &&
            pagingResultsUser?.data?.results.map((item, index) => {
              return (
                <AdvancedMarker key={index} position={latLong(item, index)} onClick={handleSetSelectedMarkerUser(item)}>
                  <Pin scale={0.75} background={"blue"} borderColor={"black"} glyphColor={"black"}  />
                </AdvancedMarker>
              )
            })}

          {showCompanies === true &&
            appSettings?.serverInfo?.addons.includes(ADDON_COMPANIES) === true &&
            pagingResultsCompany?.data?.results.map((item, index) => {
              return (
                <AdvancedMarker
                  key={index}
                  position={latLong(item, index)}
                  onClick={handleSetSelectedMarkerCompany(item)}>
                  <Pin scale={0.75} background={"yellow"} borderColor={"black"} glyphColor={"black"}  />
                </AdvancedMarker>
              )
            })}

          {showContacts === true &&
            pagingResultsContact?.data?.results.map((item, index) => {
              return (
                <AdvancedMarker
                  key={index}
                  position={latLong(item, index)}
                  onClick={handleSetSelectedMarkerContact(item)}>
                  <Pin scale={0.75} background={"purple"} borderColor={"black"} glyphColor={"black"}  />
                </AdvancedMarker>
              )
            })}

          {showLocations === true && selectedMarkerLocation !== null && (
            <InfoWindow
              position={latLong(selectedMarkerLocation, 0)}
              onCloseClick={handleSetSelectedMarkerLocation(null)}
              headerContent={t("Location")}>
              <ViewListItems prefix={ItemPrefixes.location}>{selectedMarkerLocation}</ViewListItems>
            </InfoWindow>
          )}

          {showInspections === true && selectedMarkerInspection !== null && (
            <InfoWindow
              position={latLong(selectedMarkerInspection.location, 0)}
              onCloseClick={handleSetSelectedMarkerInspection(null)}
              headerContent={t("Inspection")}>
              <ViewListItems prefix={ItemPrefixes.inspection}>{selectedMarkerInspection}</ViewListItems>
            </InfoWindow>
          )}

          {showUsers === true && selectedMarkerUser !== null && (
            <InfoWindow
              position={latLong(selectedMarkerUser, 0)}
              onCloseClick={handleSetSelectedMarkerUser(null)}
              headerContent={t("Service User")}>
              <ViewListItems prefix={ItemPrefixes.serviceUser}>{selectedMarkerUser}</ViewListItems>
            </InfoWindow>
          )}

          {showCompanies === true && selectedMarkerCompany !== null && (
            <InfoWindow
              position={latLong(selectedMarkerCompany, 0)}
              onCloseClick={handleSetSelectedMarkerCompany(null)}
              headerContent={t("Company")}>
              <ViewListItems prefix={ItemPrefixes.company}>{selectedMarkerCompany}</ViewListItems>
            </InfoWindow>
          )}

          {showContacts === true && selectedMarkerContact !== null && (
            <InfoWindow
              position={latLong(selectedMarkerContact, 0)}
              onCloseClick={handleSetSelectedMarkerContact(null)}
              headerContent={t("Contact")}>
              <ViewListItems prefix={ItemPrefixes.contact}>{selectedMarkerContact}</ViewListItems>
            </InfoWindow>
          )}
        </Map>
      </Grid>
    </>
  )
}

export default LocationsMap
