import React, { useCallback, useState } from "react"
import { Box, Button, Grid } from "@mui/material"
import DrawerRight from "../containers/DrawerRight"
import EditIcon from "@mui/icons-material/Edit"
import { RestRepository } from "../../repositories/RestRepository"
import { type IListItem } from "../../models/components/IListItem"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import { type IInspection, INSPECTION_ENDPOINT } from "../../models/service/IInspection"
import { type IMainModel } from "../../models/service/IMainModel"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import ViewLoading from "../ViewLoading"
import ErrorMessage from "../ErrorMessage"
import { type IInspectionStatus, INSPECTION_STATUS_ENDPOINT } from "../../models/service/IInspectionStatus"
import { useTranslation } from "react-i18next"

interface IPatch extends IMainModel {
  inspection_status: number | null
}

const inspectionStatusRepository = new RestRepository<IInspectionStatus | IListItem>(INSPECTION_STATUS_ENDPOINT)
const inspectRepository = new RestRepository<IPatch>(INSPECTION_ENDPOINT)

interface IProps {
  inspection: IInspection
  onChange: () => void
}

/**
 * EditInspectionStatus is a React Functional Component that renders a form for editing
 * the inspection status.
 *
 * @param {IProps} props - The properties object containing the inspection status information.
 * @returns {React.ReactElement} The rendered component.
 */
const EditInspectionStatus: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspection, onChange } = props
  const axiosRequest = useAxiosRequest()
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const [inspectionStatus, setInspectionStatus] = useState<IInspectionStatus | IListItem | null>(null)

  const handleAssign = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      const inspectionStatusId = inspectionStatus?.id !== undefined ? Number(inspectionStatus.id) : null
      const patch: IPatch = { id: inspection.id, name: inspection.name, inspection_status: inspectionStatusId }
      await inspectRepository.patch(patch, inspection.id)
      onChange()
      setOpen(open => !open)
      setInspectionStatus(null)
    })
  }, [inspection, inspectionStatus])

  return (
    <DrawerRight icon={<EditIcon />} title={t("Edit Status")} showIconButton closeDrawer={open}>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorMessage error={axiosRequest.errorMessage} />
          </Grid>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="status"
              defaultValue={inspectionStatus}
              repository={inspectionStatusRepository}
              onChange={setInspectionStatus}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Button onClick={handleAssign} disabled={axiosRequest.loading}>
              {t("Update")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ViewLoading loading={axiosRequest.loading} inline />
          </Grid>
        </Grid>
      </Box>
    </DrawerRight>
  )
}

export default EditInspectionStatus
